import { Component, OnInit, Input } from '@angular/core';
import { ApiServices } from 'src/app/apiServices.service';
import { URL_API } from 'src/app/app.api';

@Component({
  selector: 'app-historico-consultas',
  templateUrl: './historico-consultas.component.html',
  styleUrls: ['./historico-consultas.component.css'],
  providers: [ApiServices]
})
export class HistoricoConsultasComponent implements OnInit {

  @Input() dadosUsuario: any;
  public msg: string = '';
  public msgClass: string = 'alert-warning';
  public registros: any[] = [];
  public usuarios: any[] = [];
  public usuariosBusca: string[] = [];
  public limit: number = 20;
  public page: number = 1;
  public total: number = 1;
  public hasPrevPage: Boolean = true;
  public hasNextPage: Boolean = true;
  public totalPages: number = 1;
  public prev: number = 1;
  public next: number = 1;
  public arrayPages: any[] = [];
  public limitPages: number = 9;
  public loadingRegistros: Boolean = true;


  constructor(private api: ApiServices) { }

  ngOnInit() {
    this.getUsuarios();
  }

  public getUsuarios(): void {
    this.api.get(`${URL_API}/site/user/by-client/${this.dadosUsuario.client._id}`)
      .then(r => {
        this.usuarios = r;
        this.usuarios.map(item => {
          this.usuariosBusca.push(item._id);

        });
        this.getRegistros();
      })
      .catch(err => console.log(err))
  }

  public getRegistros(): void {
    this.loadingRegistros = true;
    let body = {
      user: {
        "$in": this.usuariosBusca
      }
    }
    let url = `${URL_API}/site/log-query?limit=${this.limit}&page=${this.page}`;
    this.api.post(url, body)
      .then(r => {
        this.loadingRegistros = false;
        this.registros = r.result.rows;

        this.total = r.result.total;
        this.hasPrevPage = r.result.hasPrevPage;
        this.hasNextPage = r.result.hasNextPage;
        this.totalPages = r.result.totalPages;
        this.prev = r.result.prev;
        this.next = r.result.next;
        this.arrayPages = [];
        if (this.totalPages > this.limitPages) {
          let z = (this.page - 4);
          let w = (z < 1) ? 1 : z;
          for (let x = w; x < (this.limitPages + (w - 1)); x++) {
            if (x <= this.totalPages) {
              this.arrayPages.push(x);
            }
          }
        } else {
          for (let x = 1; x < this.totalPages; x++) {
            this.arrayPages.push(x);
          }
        }
      })
      .catch(err => console.log(err))
  }

  public trocaPagina(page: number): void {
    this.page = page;
    this.getRegistros();
  }

  getUrlConsulta(row: any) {
    if (row.url.indexOf('consulta/cnh-sp') !== -1) {
      return '/area-logada/consulta-cnh-sp';
    } else if (row.url.indexOf('consulta/cnh') !== -1) {
      return '/area-logada/consulta-cnh';
    } else if (row.url.indexOf('consulta/fdnet-pf') !== -1) {
      return '/area-logada/consulta-fdnet-pf';
    } else if (row.url.indexOf('consulta/fdnet-pj') !== -1) {
      return '/area-logada/consulta-fdnet-pj';
    } else {
      return '/area-logada/consultas';
    }
  }


}
