/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pj/cadastral-pj.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../mascara.pipe";
import * as i4 from "./participacao-empresa-n1-pj.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_ParticipacaoEmpresaN1PjComponent = [i0.styles];
var RenderType_ParticipacaoEmpresaN1PjComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParticipacaoEmpresaN1PjComponent, data: {} });
export { RenderType_ParticipacaoEmpresaN1PjComponent as RenderType_ParticipacaoEmpresaN1PjComponent };
function View_ParticipacaoEmpresaN1PjComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_ParticipacaoEmpresaN1PjComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_ParticipacaoEmpresaN1PjComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.cnpj_participacao, "cnpj")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.razao_social_participacao; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.situacao_rf_participacao; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.total_empresa_nv2; _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.nivel; _ck(_v, 11, 0, currVal_4); }); }
function View_ParticipacaoEmpresaN1PjComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CNPJ Participa\u00E7\u00E3o"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Raz\u00E3o Social Participa\u00E7\u00E3o"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Situa\u00E7\u00E3o Participa\u00E7\u00E3o"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total Empresas"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00EDvel"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipacaoEmpresaN1PjComponent_4)), i1.ɵdid(17, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.participacao_empresa_n1; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_ParticipacaoEmpresaN1PjComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.MascaraPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Participa\u00E7\u00E3o Empresarial N\u00EDvel 1"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipacaoEmpresaN1PjComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipacaoEmpresaN1PjComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipacaoEmpresaN1PjComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.dados.participacao_empresa_n1; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ParticipacaoEmpresaN1PjComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-participacao-empresa-n1-pj", [], null, null, null, View_ParticipacaoEmpresaN1PjComponent_0, RenderType_ParticipacaoEmpresaN1PjComponent)), i1.ɵdid(1, 114688, null, 0, i4.ParticipacaoEmpresaN1PjComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParticipacaoEmpresaN1PjComponentNgFactory = i1.ɵccf("app-participacao-empresa-n1-pj", i4.ParticipacaoEmpresaN1PjComponent, View_ParticipacaoEmpresaN1PjComponent_Host_0, { dados: "dados" }, {}, []);
export { ParticipacaoEmpresaN1PjComponentNgFactory as ParticipacaoEmpresaN1PjComponentNgFactory };
