<div class="bloco">
    <h5 class="titulo">Sócio Antifraude Relação</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
            <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
            <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.antifraude_socio_relacao">
        <div class="row">
          <table class="table table-sm ">
            <thead>
              <tr>
                <th>CPF Relação</th>
                <th>Nome</th>
                <th>Faixa de Idade</th>
                <th>Situação</th>
                <th>Possível Óbito</th>
                <th>CCF</th>
                <th>PPE(Pessoa Politicamente Exposta)</th>
                <th>Servidor Público Federal</th>
                <th>Possíveis Imóveis</th>
                <th>Valor do M²</th>
                <th>Possíveis Veículo</th>
                <th>Possíveis Aeronaves</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.antifraude_socio_relacao">
                <td>{{row.cpf_relacionado | mascara: 'cpf'}}</td>
                <td>{{row.nome}}</td>
                <td>{{row.faixa_idade}}</td>
                <td>{{row.situacao_rf}}</td>
                <td>{{row.possivel_obito}}</td>
                <td>{{row.indicacao_ccf}}</td>
                <td>{{row.indicacao_ppe}}</td>
                <td>{{row.indicacao_sv_federal}}</td>
                <td>{{row.indicacao_imovel}}</td>
                <td>{{row.valor_m2_regiao | currency:'BRL':true}}</td>
                <td>{{row.indicacao_veiculo}}</td>
                <td>{{row.indicacao_aeronave}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>