/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./posicao-billing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../apiServices.service";
import * as i3 from "@angular/http";
import * as i4 from "@angular/router";
import * as i5 from "./posicao-billing.component";
var styles_PosicaoBillingComponent = [i0.styles];
var RenderType_PosicaoBillingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PosicaoBillingComponent, data: {} });
export { RenderType_PosicaoBillingComponent as RenderType_PosicaoBillingComponent };
export function View_PosicaoBillingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["border", "0"], ["frameborder", "0"], ["height", "800"], ["src", "https://app.powerbi.com/view?r=eyJrIjoiNzhiM2I3OTktMzE3My00YWNkLWI4ZDktMDljYzM0MjdjZjdlIiwidCI6IjY3N2Q0MGUyLTA2ZmEtNGM0Yi1iN2UyLTM4NWQ4NjNjYjczOSJ9"], ["style", "margin: 10px 0"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rodapeMapa"]], null, null, null, null, null))], null, null); }
export function View_PosicaoBillingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-posicao-billing", [], null, null, null, View_PosicaoBillingComponent_0, RenderType_PosicaoBillingComponent)), i1.ɵprd(512, null, i2.ApiServices, i2.ApiServices, [i3.Http, i4.Router]), i1.ɵdid(2, 114688, null, 0, i5.PosicaoBillingComponent, [i2.ApiServices, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PosicaoBillingComponentNgFactory = i1.ɵccf("app-posicao-billing", i5.PosicaoBillingComponent, View_PosicaoBillingComponent_Host_0, {}, {}, []);
export { PosicaoBillingComponentNgFactory as PosicaoBillingComponentNgFactory };
