import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlNomeConsulta'
})
export class UrlNomeConsultaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value.indexOf('/consulta/pf') !== -1){
      return 'Pessoa Física';
    }
    if(value.indexOf('/consulta/fdnet-pj') !== -1){
      return 'Crédito Light PJ';
    }
    if(value.indexOf('/consulta/fdnet-pf') !== -1){
      return 'Crédito Light PF';
    }
    if(value.indexOf('/consulta/pj') !== -1){
      return 'Pessoa Jurídica';
    }
    if(value.indexOf('/consulta/cnh-sp') !== -1){
      return 'CNH SP';
    }
    if(value.indexOf('/consulta/cnh') !== -1){
      return 'CNH';
    }
    if(value.indexOf('/consulta/veicular-roubo-furto') !== -1){
      return 'Veicular Roubo e Furto';
    }
    if(value.indexOf('/consulta/veicular-renajud') !== -1){
      return 'Veicular Renajud';
    }
    if(value.indexOf('/consulta/veicular-estadual') !== -1){
      return 'Veicular Estadual';
    }
    if(value.indexOf('/consulta/veicular-gravame') !== -1){
      return 'Veicular Gravame';
    }
    return value;
  }

}
