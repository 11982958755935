/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./estudos-brain.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./estudos-brain.component";
var styles_EstudosBrainComponent = [i0.styles];
var RenderType_EstudosBrainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EstudosBrainComponent, data: {} });
export { RenderType_EstudosBrainComponent as RenderType_EstudosBrainComponent };
export function View_EstudosBrainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["border", "0"], ["frameborder", "0"], ["height", "600"], ["src", "https://app.powerbi.com/view?r=eyJrIjoiYjU5OWJlNzQtNDk5MC00YWRmLThlMTAtZjczNTdmOGExYjAzIiwidCI6IjY3N2Q0MGUyLTA2ZmEtNGM0Yi1iN2UyLTM4NWQ4NjNjYjczOSJ9"], ["style", "margin: 10px 0"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rodapeMapa"]], null, null, null, null, null))], null, null); }
export function View_EstudosBrainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-estudos-brain", [], null, null, null, View_EstudosBrainComponent_0, RenderType_EstudosBrainComponent)), i1.ɵdid(1, 114688, null, 0, i2.EstudosBrainComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstudosBrainComponentNgFactory = i1.ɵccf("app-estudos-brain", i2.EstudosBrainComponent, View_EstudosBrainComponent_Host_0, {}, {}, []);
export { EstudosBrainComponentNgFactory as EstudosBrainComponentNgFactory };
