<div class="bloco">
  <h5 class="titulo">Dados cadastrais</h5>
  
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.dados_cadastrais">
    
    <div class="row">
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cpf">
        <label>Nome</label>
        {{dados.dados_cadastrais.nome || 'Não informado'}}
      </div>
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cnpj">
        <label>Razão social</label>
        {{dados.dados_cadastrais.razao_social || 'Não informado'}}
      </div>
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cnpj">
        <label>Nome fantasia</label>
        {{dados.dados_cadastrais.nomeFantasia || 'Não informado'}}
      </div>

      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cpf">
        <label>CPF</label>
        {{dados.dados_cadastrais.cpf || 'Não informado'}}
      </div>
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cnpj">
        <label>CNPJ</label>
        {{dados.dados_cadastrais.cnpj || 'Não informado'}}
      </div>

      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cpf">
        <label>Data de nascimento</label>
        {{dados.dados_cadastrais.data_nascimento || 'Não informado'}}
      </div>
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cnpj">
        <label>Data fundação</label>
        {{dados.dados_cadastrais.data_fundacao || 'Não informado'}}
      </div>

      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cpf">
        <label>Situação CPF</label>
        {{dados.dados_cadastrais.situacao || 'Não informado'}}
      </div>
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cnpj">
        <label>Situação CNPJ</label>
        {{dados.dados_cadastrais.situacao || 'Não informado'}}
      </div>

      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cpf">
        <label>Data situação CPF</label>
        {{dados.dados_cadastrais.data_situacao || 'Não informado'}}
      </div>
      <div class="item col-auto" *ngIf="dados.dados_cadastrais.cnpj">
        <label>Data situação CNPJ</label>
        {{dados.dados_cadastrais.data_situacao || 'Não informado'}}
      </div>

      <!-- <div class="item col-auto" *ngIf="dados.dados_cadastrais.cpf">
        <label>Nome da mãe</label>
        {{dados.dados_cadastrais.nomeMae || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Valor total ocorrências</label>
        {{dados.dados_cadastrais.valorTotalOCorrencias || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Data última ocorrência</label>
        {{dados.dados_cadastrais.dataUltimaOcorrencia || 'Não informado'}}
      </div> -->
    </div>
  </div>
</div>
  
  