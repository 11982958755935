<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Gestão de Riscos Financeiros</h1>
        <div class="sub_heading_page">Minimize os riscos e capitalize mais</div>
      </div>
    </div>
  </div>
</section>  
  
<section class="safe_host">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Gestão de Riscos Financeiros</h3>
        </div>
      </div>
      <div class="pr_info">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="info_right">
              <p class="text-left">
                Através de nossas soluções de gestão de riscos podemos aumentar a assertividade e auxiliar nossos clientes na tomada de decisão para minimizar as perdas financeiras, gerando uma maior capitalização e menor investimento em métodos de cobrança. Consulte nossas soluções!
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-formContato text="Entre em contato conosco"></app-formContato>