import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { URL_API, TOKEN_NAME } from './app.api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UploadService = /** @class */ (function () {
    function UploadService(httpClient) {
        this.httpClient = httpClient;
    }
    UploadService.prototype.getHeaderAuth = function () {
        var headers = new HttpHeaders({
            //'Content-Type':'application/json',
            'x-access-token': localStorage.getItem(TOKEN_NAME)
        });
        return headers;
    };
    UploadService.prototype.upload = function (data) {
        var uploadURL = URL_API + "/api/upload-file-client";
        var headers = this.getHeaderAuth();
        return this.httpClient.post(uploadURL, data, {
            headers: headers,
            reportProgress: true,
            observe: 'events'
        }).pipe(map(function (event) {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    var progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                case HttpEventType.Response:
                    return { status: 'ok', body: event.body };
                default:
                    return "Unhandled event: " + event.type;
            }
        }));
    };
    UploadService.ngInjectableDef = i0.defineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.inject(i1.HttpClient)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
