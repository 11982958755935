<section class="page_banner internal">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Em desenvolvimento...</h1>
      </div>
    </div>
  </div>
</section>

<section class="contact_form">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-xs-12">
        Página em desenvolvimento. Desculpe-nos pelo transtorno.
      </div>
  </div>
  </div>
</section>