import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mineracao-de-dados',
  templateUrl: './mineracao-de-dados.component.html',
  styleUrls: ['./mineracao-de-dados.component.css']
})
export class MineracaoDeDadosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
