<div class="main_menu">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <div class="logo mt-4"  routerLink="/"><img src="assets/images/logoSite.png" alt="logo" title="logo" /></div>
      </div>
      <div class="col-md-9 col-sm-9 col-xs-12 noprint">
        <nav class="navbar navbar-default d-none d-sm-block">
          <div class="container-fluid">
            <div class="navbar-header">
              <button type="button" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
                <i class="fa fa-bars" aria-hidden="true"></i>
              </button>
            </div>
            <div class="collapse navbar-collapse text-center" [collapse]="isCollapsed">
              <ul class="nav navbar-nav">
                <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                <li><a href="javascript:void(0)" routerLink="/future-data">A Future Data</a></li>
                <li><a href="javascript:void(0)" routerLink="/solucoes">Soluções <b class="caret"></b></a>
                  <ul class="wm-dropdown-menu">
                    <li><a href="javascript:void(0)" routerLink="/solucoes/big-data">Big Data</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/bi-dashboards">Business Intelligence</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/gestao-de-riscos">Gestão de Riscos Financeiros</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/portal-e-apis">Portal e API</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/mineracao-de-dados">Mineração de Dados</a></li>
                    <li><a href="javascript:void(0)" routerLink="/solucoes/seguranca-e-compliance">Prevenção à Fraude e Compliance</a></li>
                    <!-- <li><a href="javascript:void(0)" routerLink="/solucoes/gestao-de-campanhas">Gestão de Campanhas WhatsApp e SMS</a></li> -->
                  </ul>
                </li>
                <li><a href="javascript:void(0)" routerLink="/painel">Área do cliente</a></li>
                <li><a href="javascript:void(0)" routerLink="/lei-lgpd">LGPD</a></li>
                <li><a href="javascript:void(0)" routerLink="/contato">Fale conosco</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <!--MOBILE NAVBAR-->
        <div class="navbar-collapse text-center d-block d-sm-none" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="navbar-toggle collapsed dropdown-toggle"
          aria-controls="dropdown-basic">
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="collapse navbar-collapse text-center"
          role="menu" aria-labelledby="button-basic">
            <li class="nav-item"><a class="nav-link active" style="color: white" href="javascript:void(0)" routerLink="/">Home</a></li>
            <li class="nav-item"><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/future-data">A Future Data</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" style="color: white" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false" routerLink="/solucoes">Soluções </a>
              <div class="dropdown-menu">
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/big-data">Big Data</a></li>
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/bi-dashboards">Business Intelligence</a></li>
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/gestao-de-riscos">Gestão de Riscos Financeiros</a></li>
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/portal-e-apis">Portal e API</a></li>
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/mineracao-de-dados">Mineração de Dados</a></li>
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/seguranca-e-compliance">Prevenção à Fraude e Compliance</a></li>
                  <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/solucoes/gestao-de-campanhas">Gestão de Campanhas WhatsApp e SMS</a></li>
              </div>
            </li>
            <li class="nav-item"><a class="nav-link " style="color: white" href="#" routerLink="/painel">Área do cliente</a></li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" style="color: white" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false">Mais</a>
                <div class="dropdown-menu">
                    <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/politica-de-privacidade">Termos e Política de Privacidade</a></li>
                    <li><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/lei-lgpd">Lei Geral da Proteção de Dados</a></li>
                </div>
              </li>
            <li class="nav-item"><a class="nav-link " style="color: white" href="javascript:void(0)" routerLink="/contato">Fale conosco</a></li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>