<div class="bloco">
  <h5 class="titulo">Inscrições Estaduais</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.inscricao_estadual">
    <div class="row">
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Inscrição</th>
            <th>Situação</th>            
            <th>UF</th>            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dados.inscricao_estadual">
            <td>{{row.inscricao}}</td>
            <td>{{row.situacao_cadastral}}</td>
            <td>{{row.uf}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  
  