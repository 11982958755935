import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-documento-pf',
  templateUrl: './documento-pf.component.html',
  styleUrls: ['./../cadastral-pf/cadastral-pf.component.css','./documento-pf.component.css']
})
export class DocumentoPfComponent implements OnInit {

  @Input() dados:any;
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  public doc:any;

  constructor(private modalService:BsModalService) { }

  ngOnInit() {
    if(this.dados.documento===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.documento===null){
      this.naoEncontrouRegistros=true;
    }
  }

}
