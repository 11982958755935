<div class="bloco">
  <h5 class="titulo">Dados da CNH</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.Dados_CNH">
    <div class="row">
      <div class="item col-auto">
        <label>Número renach</label>
        {{dados.Dados_CNH.NumeroRenach || 'Não informado'}}
      </div>

      <div class="item col-auto">
        <label>Número registro</label>
        {{dados.Dados_CNH.NumeroRegistro || 'Não informado'}}
      </div>

      <div class="item col-auto">
        <label>Categoria</label>
        {{dados.Dados_CNH.Categoria || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Data emissão</label>
        {{dados.Dados_CNH.DataEmissao || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Data primeira habilitação</label>
        {{dados.Dados_CNH.DataPrimeiraHabilitacao || 'Não informado'}}
      </div>
    </div>
    <div class="row">
      <div class="item col-auto">
        <label>Observação</label>
        {{dados.Dados_CNH.Observacao || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Portaria</label>
        {{dados.Dados_CNH.Portaria || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Impedimento</label>
        {{dados.Dados_CNH.Impedimento || 'Não informado'}}
      </div>
    </div>
  </div>
</div>
  
  