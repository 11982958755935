<div class="bloco">
    <h5 class="titulo">Participação Empresarial Nível 1</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
            <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
            <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.participacao_empresa_n1">
        <div class="row">
          <table class="table table-sm ">
            <thead>
              <tr>
                <th>CNPJ Participação</th>
                <th>Razão Social Participação</th>
                <th>Situação Participação</th>
                <th>Total Empresas</th>
                <th>Nível</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.participacao_empresa_n1">
                <td>{{row.cnpj_participacao | mascara:'cnpj'}}</td>
                <td>{{row.razao_social_participacao}}</td>
                <td>{{row.situacao_rf_participacao}}</td>
                <td>{{row.total_empresa_nv2}}</td>
                <td>{{row.nivel}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>