import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { ApiServices } from "src/app/apiServices.service";
import { URL_API } from "src/app/app.api";
// var URL_API = 'http://localhost:8089';
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
@Component({
  selector: "app-clientes",
  templateUrl: "./clientes.component.html",
  styleUrls: ["./clientes.component.css"],
})
export class ClientesComponent implements OnInit {
  @Input() dadosUsuario: any;
  public isFutureUser: Boolean = false;
  public isFutureUserRoot: Boolean = false;
  public clientes: any[] = [];
  public clientesFiltrados: any[] = [];
  public permissoesPrecos: any[] = [];
  public permissoesPrecosFiltrados: any[] = [];

  public limitacoesConsultas: any[] = [];
  public limitacoesConsultasFiltrados: any[] = [];

  public permissoesCliente: any = {};
  public loadingClientes: Boolean = true;
  public loadingPermissoes: Boolean = true;
  public loadingBillings: Boolean = true;
  public modalRef: BsModalRef;

  public msgCadastro: string = "";
  public msgCadastroClass: string = "alert-warning";
  public msgCadastroBill: string = "";
  public msgCadastroBillClass: string = "alert-warning";
  public txtSubmitCadastro: string = "Salvar";
  public enviandoCadastro: Boolean = false;
  public registroSelecionado: any = null;
  public showLista: Boolean = true;
  public showForm: Boolean = false;
  public valorCredito: Number = 0.0;
  public descCredito: String = "";

  public lancamentosFatura: any[] = [];
  sort: string = "companyName";
  order: string = "asc";
  columns = [
    {
      label: "Empresa",
      field: "companyName",
    },
    {
      label: "Contato",
      field: "contactName",
    },
    {
      label: "E-mail",
      field: "email",
    },
  ];

  columnsPermissoes = [
    {
      label: "Consulta",
      field: "query.name",
    },
  ];

  public formCadastro: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    document: new FormControl(null, [Validators.required]),
    companyName: new FormControl(null, [Validators.required]),
    phone: new FormControl("1100000000", [Validators.required]),
    pass: new FormControl(null, [Validators.required]),
    passConfirm: new FormControl(null, [Validators.required]),
  });
  public formBilling: FormGroup = new FormGroup({
    contactName: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    invoiceLimit: new FormControl(null, [Validators.required]),
  });

  public savingClient: any = { saving: false, text: "", show: false };
  public savingClientQueries: any = { saving: false, text: "", show: false };

  constructor(
    private api: ApiServices,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    if (this.dadosUsuario.group != undefined) {
      this.api
        .get(`${URL_API}/site/group/${this.dadosUsuario.group}`)
        .then((r) => {
          if (r.type == "adm") {
            this.isFutureUser = true;
            const usersRoot = ["5bae34ca5eb20c3ece0ca96e","5ccb4c014274cc04e0c717b8"];
            if (usersRoot.includes(this.dadosUsuario._id)) {
              this.isFutureUserRoot = true;
            }
            this.loadClientes();
          } else {
            this.router.navigate(["/painel"]);
          }
        });
    }
  }

  public loadClientes(): void {
    this.loadingClientes = true;
    this.api.get(`${URL_API}/site/client/ativos`).then((r) => {
      this.clientes = r;

      this.clientesFiltrados = this.clientes.sort((a, b) =>
        a.companyName.trim().toLowerCase() > b.companyName.trim().toLowerCase()
          ? 1
          : -1
      );
      this.loadingClientes = false;
    });
  }

  private filterClientes(e: any): void {
    const value = e
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    this.clientesFiltrados = this.clientes.filter((e: any) => {
      for (const c of this.columns) {
        if (e[c.field]) {
          const valueField = e[c.field]
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          if (valueField.indexOf(value) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  private filterPermissoes(e: any): void {
    const value = e
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    this.permissoesPrecosFiltrados = this.permissoesPrecos.filter((e: any) => {
      const valueField = e.query.name
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      if (valueField.indexOf(value) !== -1) {
        return true;
      }
      return false;
    });
  }

  private filterLimitacoes(e: any): void {
    const value = e
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    this.limitacoesConsultasFiltrados = this.limitacoesConsultas.filter(
      (e: any) => {
        const valueField = e.name
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        if (valueField.indexOf(value) !== -1) {
          return true;
        }
        return false;
      }
    );
  }

  public abreForm(): void {
    this.msgCadastro = "";
    this.showForm = true;
    this.showLista = false;
    this.formCadastro = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      document: new FormControl(null, [Validators.required]),
      companyName: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      pass: new FormControl(null, [Validators.required]),
      passConfirm: new FormControl(null, [Validators.required]),
    });

    // this.formCadastro = new FormGroup({
    //   'name':new FormControl(null,[Validators.required]),
    //   'email':new FormControl(null,[Validators.required, Validators.email]),
    //   'document':new FormControl(null,[Validators.required]),
    //   'companyName':new FormControl(null,[Validators.required]),
    //   'phone':new FormControl('1100000000',[Validators.required]),
    //   'pass':new FormControl(null,[Validators.required]),
    //   'passConfirm':new FormControl(null,[Validators.required])
    // });
  }

  public fechaForm(): void {
    this.showForm = false;
    this.showLista = true;
    this.msgCadastro = "";
    this.loadClientes();
  }

  public faturamento(registro: any, template: TemplateRef<any>): void {
    this.registroSelecionado = registro;
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    this.carregaFaturamento();
  }

  public carregaFaturamento(): void {
    this.loadingPermissoes = true;
    this.configFormCadastroBilling();
  }

  public configFormCadastroBilling(): void {
    let clientBilling = {
      contactName: null,
      phone: null,
      email: null,
      invoiceLimit: null,
    };
    this.api
      .get(`${URL_API}/site/client-billing/${this.registroSelecionado.billing}`)
      .then((r) => {
        clientBilling = r;
        this.formBilling = new FormGroup({
          contactName: new FormControl(clientBilling.contactName, [
            Validators.required,
          ]),
          phone: new FormControl(clientBilling.phone, [Validators.required]),
          email: new FormControl(clientBilling.email, [
            Validators.required,
            Validators.email,
          ]),
          invoiceLimit: new FormControl(clientBilling.invoiceLimit, [
            Validators.required,
          ]),
        });
        this.loadingPermissoes = false;
        this.carregaUltimosBillings();
      });
  }

  public carregaUltimosBillings() {
    this.loadingBillings = true;
    this.lancamentosFatura = [];
    let urlBill = `${URL_API}/site/billing-client/last-by-client/${this.registroSelecionado._id}`;
    this.api.get(urlBill).then((bill) => {
      this.lancamentosFatura = bill;
      this.lancamentosFatura.sort((a, b) => (a.createAt > b.createAt ? 1 : -1));
      this.loadingBillings = false;
    });
  }

  public salvarCadastroBilling(): void {
    let values = this.formBilling.value;

    let billingData = {};
    let campos = ["email", "phone", "contactName", "invoiceLimit"];
    campos.forEach((campo) => {
      billingData[campo] = this.formCadastro.value[campo];
    });

    //if(this.dadosUsuario.client.billing){
    //billingData['_id']=this.registroSelecionado.billing;
    //}

    if (this.formBilling.valid) {
      this.enviandoCadastro = true;
      this.msgCadastroBill = "";
      this.txtSubmitCadastro = "Enviando...";
      this.api
        .put(
          `${URL_API}/site/client-billing/${this.registroSelecionado.billing}`,
          values
        )
        .then((r) => {
          // let clientData = {billing:r._id};
          // this.api.put(`${URL_API}/api/client/${this.dadosUsuario.client._id}`,clientData)
          // .then(ruser=>{
          if (r) {
            this.msgCadastroBill = `Dados atualizados com sucesso`;
            this.msgCadastroBillClass = "alert-success";
            setTimeout(() => {
              this.msgCadastroBill = "";

              if (this.dadosUsuario.client.billing == undefined) {
                //this.router.navigate(['/area-logada']);
                this.dadosUsuario.client.billing = r;
              }
            }, 3000);
          } else {
            this.msgCadastroBill = "Erro ao atualizar os Dados";
            this.msgCadastroBillClass = "alert-danger";
          }
          this.enviandoCadastro = false;
          this.txtSubmitCadastro = "Salvar";
          // })
          // .catch(r=>{
          //   this.msgCadastro='Ocorreu um erro na requisição';
          //   this.msgCadastroClass= 'alert-danger';
          //   this.enviandoCadastro=false;
          //   this.txtSubmitCadastro="Salvar";
          // })
        })
        .catch((r) => {
          this.msgCadastroBill = "Ocorreu um erro na requisição";
          this.msgCadastroBillClass = "alert-danger";
          this.enviandoCadastro = false;
          this.txtSubmitCadastro = "Salvar";
        });
    } else {
      this.msgCadastroBill = "Verique os campos obrigatórios";
      this.msgCadastroBillClass = "alert-warning";
      let campos = ["email", "phone", "contactName", "invoiceLimit"];
      campos.forEach((campo) => {
        this.formCadastro.controls[campo].markAsTouched();
      });
    }
  }

  public permissoes(registro: any, template: TemplateRef<any>): void {
    this.registroSelecionado = registro;
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    this.carregaPermissoesPrecos();
  }

  public carregaPermissoesPrecos(): void {
    this.loadingPermissoes = true;
    this.permissoesPrecos = [];
    this.permissoesPrecosFiltrados = [];
    this.permissoesCliente = {};

    let url = `${URL_API}/site/client-query/by-client/${this.registroSelecionado._id}`;
    this.api.get(url).then((r) => {
      this.permissoesCliente = r[0];
      this.permissoesPrecos = this.permissoesCliente.queries;
      this.permissoesPrecos.forEach((p) => {
        p.showFeatures = false;
      });
      this.permissoesPrecosFiltrados = this.permissoesPrecos;
      this.loadingPermissoes = false;
    });
  }

  public limitacoes(registro: any, template: TemplateRef<any>): void {
    this.registroSelecionado = registro;
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    this.atualizaLimitacoes();
  }

  public atualizaLimitacoes(): void {
    this.loadingPermissoes = true;
    this.limitacoesConsultas = [];
    this.limitacoesConsultasFiltrados = [];
    this.permissoesCliente = {};

    let url = `${URL_API}/site/client/get-limit-queries/${this.registroSelecionado._id}`;
    this.api.get(url).then((r) => {
      this.limitacoesConsultas = r.rows.map((e) => {
        e.add = 0;
        return e;
      });
      this.limitacoesConsultasFiltrados = this.limitacoesConsultas;
      this.loadingPermissoes = false;
    });
  }

  public resetarLimitacoes(): void {
    const payload = {
      client: this.registroSelecionado._id,
      queries: [],
    };

    for (const query of this.limitacoesConsultas) {
      const o = {
        code: query.code,
        qty: query.limitPerDay,
        reset: true,
      };
      payload.queries.push(o);
    }

    this.loadingPermissoes = true;

    let url = `${URL_API}/site/client/set-limit-queries`;
    this.api.post(url, payload).then((r) => {
      this.atualizaLimitacoes();
      this.registroSelecionado.lastResetLimitAt = Date.now();
    });
  }

  public salvarCadastro(): void {
    let values = this.formCadastro.value;

    if (this.formCadastro.valid) {
      if (values["pass"] != values["passConfirm"]) {
        this.msgCadastro = "As senhas não estão iguais!";
        this.msgCadastroClass = "alert-danger";
        return;
      }

      //this.enviandoCadastro=true;
      this.msgCadastro = "";
      this.txtSubmitCadastro = "Enviando...";
      values["passTemp"] = true;
      this.api.get(`${URL_API}/site/group/by-type/user`).then((r) => {
        values["group"] = r[0]._id;

        this.api
          .post(`${URL_API}/site/client/newclient`, values)
          .then((r) => {
            console.log(r);
            if (r.obj && r.obj._id) {
              this.msgCadastro = `Cadastro enviado com sucesso!`;
              this.msgCadastroClass = "alert-success";
              this.enviandoCadastro = false;
              this.txtSubmitCadastro = "Salvar";
              setTimeout(() => {
                this.msgCadastro = ``;
                this.fechaForm();
              }, 3000);
            } else {
              if (
                r.err.errmsg &&
                r.err.errmsg.indexOf("email_1 dup key") != -1
              ) {
                this.msgCadastro = "E-mail já existente no cadastro.";
              } else {
                this.msgCadastro = r.msg;
              }
              this.msgCadastroClass = "alert-danger";
              this.enviandoCadastro = false;
              this.txtSubmitCadastro = "Salvar";
            }
          })
          .catch((r) => {
            this.msgCadastro = "Ocorreu um erro na requisição";
            if (r.err.errmsg && r.err.errmsg.indexOf("email_1 dup key") != -1) {
              this.msgCadastro = "E-mail já existente no cadastro.";
            }
            this.enviandoCadastro = false;
            this.txtSubmitCadastro = "Salvar";
            this.msgCadastroClass = "alert-danger";
            this.enviandoCadastro = false;
            this.txtSubmitCadastro = "Salvar";
          });
      });
    } else {
      this.msgCadastro = "Verique os campos obrigatórios";
      this.msgCadastroClass = "alert-warning";
      let campos = ["name", "email", "pass", "passConfirm"];
      campos.forEach((campo) => {
        this.formCadastro.controls[campo].markAsTouched();
      });
    }
  }

  public save(): void {
    this.savingClientQueries.saving = true;
    this.savingClientQueries.text = "Salvando atualizações";
    let url = `${URL_API}/site/client-query/${this.permissoesCliente._id}`;
    const payload = { ...this.permissoesCliente };
    for (const item of payload.queries) {
      item.query = { _id: item.query._id, name: item.query.name };
    }
    // console.log(payload);

    this.api
      .put(url, this.permissoesCliente)
      .then((r) => {
        this.savingClientQueries.saving = true;
        this.savingClientQueries.text = r.msg;
        setTimeout(() => {
          this.savingClientQueries.saving = false;
          this.savingClientQueries.text = "";
        }, 3000);
        console.log(r);
      })
      .catch((e) => {
        this.savingClientQueries.text = "Ocorreu um erro. Tente novamente";
        setTimeout(() => {
          this.savingClientQueries.saving = false;
          this.savingClientQueries.text = "";
        }, 3000);
        console.log("ERRO", e);
      });
  }

  public saveClient(obj): void {
    let url = `${URL_API}/site/client/${obj._id}`;
    this.api
      .put(url, obj)
      .then((r) => {
        console.log("OK");
      })
      .catch((e) => console.log("ERRO", e));
  }

  public zerarFeatures(query): void {
    query.features.forEach((feature) => {
      feature.price = 0.0;
    });
    this.save();
  }

  public changeQueryLimited(obj, bool: Boolean): void {
    obj.queryLimited = bool;
    this.saveClient(obj);
  }

  public changePermission(obj, bool: Boolean): void {
    obj.permission = bool;
    this.save();
  }

  public bloquearTodos(obj): void {
    obj.forEach((o) => {
      o.permission = false;
      if (o.features != undefined) {
        o.features.forEach((f) => {
          f.permission = false;
        });
      }
    });
    this.save();
  }

  public liberarTodos(obj): void {
    obj.forEach((o) => {
      o.permission = true;
      if (o.features != undefined) {
        o.features.forEach((f) => {
          f.permission = true;
        });
      }
    });
    this.save();
  }

  public verificarNovasConsultas(): void {
    this.loadingPermissoes = true;
    this.api
      .get(
        `${URL_API}/site/client/configure/permissions/${this.registroSelecionado._id}`
      )
      .then((r) => {
        setTimeout(() => this.carregaPermissoesPrecos(), 2000);
      });
  }

  public addCredito() {
    if (confirm("Deseja continuar com o crédito?")) {
      this.loadingBillings = true;
      let obj = {
        client: this.registroSelecionado._id,
        value: this.valorCredito,
        description: this.descCredito,
      };
      this.api.post(`${URL_API}/site/client/addCredit`, obj).then((r) => {
        this.carregaUltimosBillings();
        this.descCredito = "";
        this.valorCredito = 0.0;

        //setTimeout(()=>this.carregaPermissoesPrecos(),2000);
      });
    }
  }

  public excluir(registro: any): void {
    if (confirm("Deseja mesmo excluir o cliente?")) {
      this.loadingClientes = true;
      this.api.get(`${URL_API}/site/deleteClient/${registro._id}`).then((r) => {
        console.log(r);

        setTimeout(() => this.loadClientes(), 2000);
      });
    }
  }

  public addQuery(query: any): void {
    if (query.add && query.add > 0) {
      const client = this.registroSelecionado._id;
      query.isProcessing = true;
      const payload = {
        client,
        queries: [
          {
            code: query.code,
            qty: query.add,
            reset: false,
          },
        ],
      };
      this.api
        .post(`${URL_API}/site/client/set-limit-queries`, payload)
        .then((r) => {
          query.isProcessing = false;
          this.atualizaLimitacoes();
        });
    }
  }

  public resetQuery(query: any): void {
    const client = this.registroSelecionado._id;
    query.isProcessing = true;
    const payload = {
      client,
      queries: [
        {
          code: query.code,
          qty: query.limitPerDay,
          reset: true,
        },
      ],
    };
    this.api
      .post(`${URL_API}/site/client/set-limit-queries`, payload)
      .then((r) => {
        query.isProcessing = false;
        this.atualizaLimitacoes();
      });
  }

  public zeroQuery(query: any): void {
    const client = this.registroSelecionado._id;
    query.isProcessing = true;
    const payload = {
      client,
      queries: [
        {
          code: query.code,
          qty: 0,
          reset: true,
        },
      ],
    };
    this.api
      .post(`${URL_API}/site/client/set-limit-queries`, payload)
      .then((r) => {
        query.isProcessing = false;
        this.atualizaLimitacoes();
      });
  }
}
