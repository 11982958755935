<div class="bloco">
  <h5 class="titulo">Representantes</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.representante">
    <div class="row">
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>CPF</th>
            <th>Nome</th>            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dados.representante">
            <td id="documentoRepresentante">
              <i class="fas fa-search"></i> 
              <a 
                href="javascript:void(0)" 
                tooltip="Clique aqui para consultar este documento {{row.cpf | mascara:'cpf'}}"
                (click)="capturaDoc(row.cpf)"
                routerLink="/area-logada/consultas"> 
                  {{row.cpf  | mascara:'cpf'}}
              </a>
            </td>
            <td>{{row.nome}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  
  