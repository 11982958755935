import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { SITE_KEY_GCAPTCHA, URL_API } from 'src/app/app.api';
import { ApiServices } from 'src/app/apiServices.service';

@Component({
    selector: 'app-formContato',
    templateUrl: './formContato.component.html',
    styleUrls: ['./formContato.component.css'],
    providers: [ApiServices]
})

export class FormContatoComponent implements OnInit{
    
    formContato: FormGroup;

    tokenGCaptcha:string='';
    msgCadastro:string = '';
    msgCadastroClass:string = 'alert-warning';

    @Input() text='';

    constructor(
        private reCaptchaV3Service: ReCaptchaV3Service,
        private formBuilder: FormBuilder,
        private api: ApiServices) {}
    
    
    ngOnInit(): void {

        this.configFormContato();
        this.getTokenCaptcha();
    }

    public configFormContato() {

        this.formContato = this.formBuilder.group({
            nome: [
                '', 
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(60)
                ]
            ],
            telefone: [
                '', 
                    [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.maxLength(11),
                        Validators.pattern(/^[0-9]*$/)
                    ]
                ],
            assunto: [
                '', 
                [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(30)
                ]
            ],
            email: [
                '', 
                    [
                        Validators.required,
                        Validators.email
                    ]
                ],
            mensagem: [
                '', 
                [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(500)
                ]
            ]
        });
    }

    public enviarEmail() {

        let values = this.formContato.value;

        if(this.formContato.valid) {

            if(this.tokenGCaptcha == '') {

                this.msgCadastro = 'Ocorreu um erro no recaptcha!';
                this.msgCadastroClass = 'alert-danger';
                return;
            }

            this.msgCadastro = '';

            this.api
            .post(`${URL_API}/formContato`, values, this.tokenGCaptcha)
            .then(r => {
                
                if(r.success) {

                    console.log('Mensagem enviada com sucesso!');
                    this.msgCadastro=`Sua mensagem foi enviada com sucesso! <br /> Iremos te retornar o mais breve possível.`;
                    this.msgCadastroClass= 'alert-success';
                    this.resetForm();
                } else {

                   if(r.err) {

                       console.log('erro');
                       this.msgCadastro = r.msg;
                   }else {

                    this.msgCadastro = r.msg;
                   }

                   this.msgCadastroClass= 'alert-danger';
                }

                this.getTokenCaptcha();
            })
            .catch(r => {
                
                console.log("Erro na requisição");
                this.msgCadastro='Ocorreu um erro na requisição';
                this.msgCadastroClass= 'alert-danger';
                this.getTokenCaptcha();
            })
        } else {
            console.log('Erro no formulário');
            this.msgCadastro='Verique os campos obrigatórios';
            this.msgCadastroClass='alert-warning';
            let campos = ['nome', 'telefone', 'email', 'assunto', 'mensagem'];
            campos.forEach(campo => {
                this.formContato.controls[campo].markAsTouched();
            });
        }

    }

    public resetForm():void{
        let campos =['nome','telefone','assunto','email', 'mensagem'];
          campos.forEach(campo=>{
            this.formContato.controls[campo].setValue('');
            this.formContato.controls[campo].markAsUntouched();
          });
      }

    public getTokenCaptcha() {
        
        this.reCaptchaV3Service.execute(SITE_KEY_GCAPTCHA, 'formContato', (token) => {
            this.tokenGCaptcha = token;
        },
        {
            useGlobalDomain: false
        });
    }

}