<div class="bloco">
  <h5 class="titulo">Dados Cadastrais</h5>
  <div class="row">
    <div class="item col-12">
      <label>CNPJ</label>
      {{dados.cnpj | mascara:'cnpj'}}
    </div>
    <div class="item col-6">
      <label>Razão Social</label>
      {{dados.razao_social}}
    </div>
    <div class="item col-6">
      <label>Última Razão Social</label>
      {{dados.ultima_razao_social}}
    </div>
    <div class="item col-auto">
      <label>Nome Fantasia</label>
      {{dados.nome_fantasia}}
    </div>
    <div class="item col-auto">
      <label>Data de abertura</label>
      {{dados.data_abertura}}
    </div>
    <div class="item col-auto">
      <label>Situação na Receita</label>
      <span *ngIf="dados.situacao_rf!='ATIVA' && dados.situacao_rf!='BAIXADA'" class="text-primary">{{dados.situacao_rf}}</span>
      <span *ngIf="dados.situacao_rf=='BAIXADA'" class="text-danger font-weight-bold">{{dados.situacao_rf}}</span>
      <span *ngIf="dados.situacao_rf=='ATIVA'" class="text-success font-weight-bold">{{dados.situacao_rf}}</span>
    </div>
    <div class="item col-auto">
      <label>Porte RF</label>
      {{dados.porte_rf}}
    </div>
    <div class="item col-auto">
      <label>Data Situação na Receita</label>
      {{dados.data_situacao_rf}}
    </div>
    <div class="item col-auto">
      <label>Data da consulta na Receita</label>
      {{dados.data_consulta_rf}}
    </div>
    <div class="item col-auto">
      <label>Matriz/Filial</label>
      {{dados.matrizfilial}}
    </div>
    <div class="item col-auto">
      <label>Capital Social</label>
      {{dados.capital_social | currency:'BRL'}}
    </div>
    <div class="item col-auto">
      <label>EFR</label>
      {{dados.efr}}
    </div>
    <div class="item col-auto">
      <label>Situação Especial</label>
      {{dados.situacao_especial}}&nbsp;
    </div>
    <div class="item col-auto">
      <label>Motivo da Situação</label>
      {{dados.motivo_situacao}}
    </div>
    <div class="item col-auto">
      <label>Faixa de Idade</label>
      {{dados.faixa_idade_cnpj}}
    </div>
  </div>
</div>