/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./credito-ccf.component.css.shim.ngstyle";
import * as i1 from "../fdnet.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./credito-ccf.component";
var styles_CreditoCcfComponent = [i0.styles, i1.styles];
var RenderType_CreditoCcfComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CreditoCcfComponent, data: {} });
export { RenderType_CreditoCcfComponent as RenderType_CreditoCcfComponent };
function View_CreditoCcfComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_CreditoCcfComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), (_l()(), i2.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(6, null, ["", ""])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(8, null, ["", ""])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.data_da_ocorrencia; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.banco; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.nome_banco; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.agencia; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.quantidade_cheques; _ck(_v, 10, 0, currVal_4); }); }
function View_CreditoCcfComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 15, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 11, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Data"])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["N\u00BA banco"])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nome banco"])), (_l()(), i2.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["N\u00BA ag\u00EAncia"])), (_l()(), i2.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Quantidade"])), (_l()(), i2.ɵeld(15, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CreditoCcfComponent_3)), i2.ɵdid(17, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.cheque_sem_fundo.registro; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_CreditoCcfComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["CCF"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CreditoCcfComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CreditoCcfComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.dados.cheque_sem_fundo.registro.length > 0); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CreditoCcfComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-credito-ccf", [], null, null, null, View_CreditoCcfComponent_0, RenderType_CreditoCcfComponent)), i2.ɵdid(1, 114688, null, 0, i4.CreditoCcfComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreditoCcfComponentNgFactory = i2.ɵccf("app-credito-ccf", i4.CreditoCcfComponent, View_CreditoCcfComponent_Host_0, { dados: "dados" }, {}, []);
export { CreditoCcfComponentNgFactory as CreditoCcfComponentNgFactory };
