import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TOKEN_NAME, URL_API } from './app.api';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:Http, private router:Router) { }

  public logar(email: string, senha: string): Promise<any> {

    let payload = {
      email: email,
      pass: senha
    }
    
    let headers:Headers = new Headers();
    headers.append('x-site', '1');

    return this.http.post(`${URL_API}/auth`, payload, {headers})
      .toPromise()
      .then((resposta: any) => {
        let _json = resposta.json()
        if (_json.success) {
          localStorage.setItem(TOKEN_NAME, _json.token);
          return this.validateToken()
        }else{
          return _json
        }
      })
  }

  public autenticado(): boolean {    
    if (localStorage.getItem(TOKEN_NAME) !== null) {
      this.validateToken();
      return true;
    } else {
      this.router.navigate(['/painel']);
    }

    return false;
  }

  public validateToken(): Promise<any> {
    
    let headers:Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', localStorage.getItem(TOKEN_NAME));

    return this.http.get(`${URL_API}/api/validateToken`,{headers:headers})
      .toPromise()
      .then((resposta:any)=>{
        let _json = resposta.json()
        if (_json.success) {
          localStorage.setItem('usuarioLogadoSite', JSON.stringify(_json.tokenDecoded))
        }else{
          this.logout();
        }
        return _json
      })
      .catch(e => {
        this.logout(); 
      })
  }

  public logout(): void {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem('usuarioLogadoSite');
    this.router.navigate(['/painel'])
  }

}
