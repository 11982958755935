<div class="bloco">
  <h5 class="titulo">Documentos</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.documento">
    <div class="row">
        <div class="item col-auto">
          <label>CPF</label>
          {{dados.documento.cpf | mascara:'cpf'}}
        </div>
        <div class="item col-auto">
          <label>RG</label>
          {{dados.documento.rg?dados.documento.rg:'--'}}
        </div>
        <div class="item col-auto">
          <label>Órgão Emissor</label>
          {{dados.documento.orgao_emissor?dados.documento.orgao_emissor:''}}
        </div>
        <div class="item col-auto">
          <label>PIS</label>
          {{dados.documento.pis?dados.documento.pis:'--'}}
        </div>
    </div><div class="row">
        <div class="item col-auto">
          <label>Título Eleitor</label>
          {{dados.documento.titulo_eleitor?dados.documento.titulo_eleitor:'--'}}
        </div>
        <div class="item col-auto">
          <label>Zona</label>
          {{dados.documento.zona?dados.documento.zona:'--'}}
        </div>
        <div class="item col-auto">
          <label>Seção</label>
          {{dados.documento.secao?dados.documento.secao:'--'}}
        </div>
        <div class="item col-auto">
          <label>Local Votação</label>
          {{dados.documento.local_votacao?dados.documento.local_votacao:'--'}}
        </div>
        </div><div class="row">
        <div class="item col-auto">
          <label>CTPS</label>
          {{dados.documento.ctps?dados.documento.ctps:'--'}}
        </div>
        <div class="item col-auto">
          <label>Série</label>
          {{dados.documento.serie?dados.documento.serie:'--'}}
        </div>
        <div class="item col-auto">
          <label>UF</label>
          {{dados.documento.uf?dados.documento.uf:'--'}}
        </div>
        </div><div class="row">
        <div class="item col-auto">
          <label>Cartão Nacional de Saúde</label>
          {{dados.documento.cartao_nacional_saude?dados.documento.cartao_nacional_saude:'--'}}
        </div>
        <div class="item col-auto">
          <label>CRM</label>
          {{dados.documento.crm?dados.documento.crm:'--'}}
        </div>
    </div>
  </div>
</div>
  
  