<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Sobre Nós</h1>
        <div class="sub_heading_page">Tecnologia e inovação</div>
      </div>
    </div>
  </div>
</section>

<!-- ========= Inner Page Banner Section End ========= --> 

<!-- ========= About US Section Start ========= -->

<section class="support_features">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="headings black_text">
            <h3>Future Data</h3>
            <div class="sub_heading_page">
              <br>
              Somos uma empresa de tecnologia da informação com foco no desenvolvimento de soluções
              relacionadas aos dados e seus fins, auxiliando o mercado na expansão das negociações
              utilizando-se das informações e dos meios de acesso a mesma.
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="support_features_inner">
            <h5 class="section_sub_heading text-center">Quem Somos</h5>
            <p>Somos uma empresa de tecnologia com uma equipe sempre em busca de facilitar o acesso a informação, fornecendo soluções simples e seguras. Nosso conceito é de que não há tecnologia ou serviço melhor ou mais consistente, pois acreditamos que a perfeição se encontra no uso coerente e inovador do conjunto de tecnologias e serviços, só assim é possível alcançar a potência máxima e aplicar na necessidade adequada.</p>
            <p>Nossa expertise está em entender da melhor maneira as necessidades dos clientes, levando em consideração seu ramo de atividade, seus princípios e valores, alinhando assim toda nossa experiência, ao resultado final esperado pelo cliente, podendo se utilizar de vários meios tecnológicos, nunca se limitando a plataformas, linguagens e frameworks determinados, pois aplicar a melhor solução é nossa especialidade.</p>
            <h5 class="section_sub_heading text-center">Nossa Equipe</h5>
            <p>Nossa equipe é formada por profissionais que prezam pela qualidade e alta disponibilidade, utilizando-se
                de pontos de apoio em vários locais do mundo. Contamos com analistas de dados, programadores,
                especialistas em infraestrutura, especialistas em projetos, dentre outros que se comprometem com a missão
                de criar serviços e produtos para esse novo mundo digital.</p>
          </div>
          </div>
      </div>
    </div>
  </section>
