var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, TemplateRef } from "@angular/core";
import { ApiServices } from "src/app/apiServices.service";
import { URL_API } from "src/app/app.api";
// var URL_API = 'http://localhost:8089';
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
var ClientesComponent = /** @class */ (function () {
    function ClientesComponent(api, router, modalService) {
        this.api = api;
        this.router = router;
        this.modalService = modalService;
        this.isFutureUser = false;
        this.isFutureUserRoot = false;
        this.clientes = [];
        this.clientesFiltrados = [];
        this.permissoesPrecos = [];
        this.permissoesPrecosFiltrados = [];
        this.limitacoesConsultas = [];
        this.limitacoesConsultasFiltrados = [];
        this.permissoesCliente = {};
        this.loadingClientes = true;
        this.loadingPermissoes = true;
        this.loadingBillings = true;
        this.msgCadastro = "";
        this.msgCadastroClass = "alert-warning";
        this.msgCadastroBill = "";
        this.msgCadastroBillClass = "alert-warning";
        this.txtSubmitCadastro = "Salvar";
        this.enviandoCadastro = false;
        this.registroSelecionado = null;
        this.showLista = true;
        this.showForm = false;
        this.valorCredito = 0.0;
        this.descCredito = "";
        this.lancamentosFatura = [];
        this.sort = "companyName";
        this.order = "asc";
        this.columns = [
            {
                label: "Empresa",
                field: "companyName",
            },
            {
                label: "Contato",
                field: "contactName",
            },
            {
                label: "E-mail",
                field: "email",
            },
        ];
        this.columnsPermissoes = [
            {
                label: "Consulta",
                field: "query.name",
            },
        ];
        this.formCadastro = new FormGroup({
            name: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            document: new FormControl(null, [Validators.required]),
            companyName: new FormControl(null, [Validators.required]),
            phone: new FormControl("1100000000", [Validators.required]),
            pass: new FormControl(null, [Validators.required]),
            passConfirm: new FormControl(null, [Validators.required]),
        });
        this.formBilling = new FormGroup({
            contactName: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            invoiceLimit: new FormControl(null, [Validators.required]),
        });
        this.savingClient = { saving: false, text: "", show: false };
        this.savingClientQueries = { saving: false, text: "", show: false };
    }
    ClientesComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.dadosUsuario.group != undefined) {
            this.api
                .get(URL_API + "/site/group/" + this.dadosUsuario.group)
                .then(function (r) {
                if (r.type == "adm") {
                    _this.isFutureUser = true;
                    var usersRoot = ["5bae34ca5eb20c3ece0ca96e", "5ccb4c014274cc04e0c717b8"];
                    if (usersRoot.includes(_this.dadosUsuario._id)) {
                        _this.isFutureUserRoot = true;
                    }
                    _this.loadClientes();
                }
                else {
                    _this.router.navigate(["/painel"]);
                }
            });
        }
    };
    ClientesComponent.prototype.loadClientes = function () {
        var _this = this;
        this.loadingClientes = true;
        this.api.get(URL_API + "/site/client/ativos").then(function (r) {
            _this.clientes = r;
            _this.clientesFiltrados = _this.clientes.sort(function (a, b) {
                return a.companyName.trim().toLowerCase() > b.companyName.trim().toLowerCase()
                    ? 1
                    : -1;
            });
            _this.loadingClientes = false;
        });
    };
    ClientesComponent.prototype.filterClientes = function (e) {
        var _this = this;
        var value = e
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
        this.clientesFiltrados = this.clientes.filter(function (e) {
            for (var _i = 0, _a = _this.columns; _i < _a.length; _i++) {
                var c = _a[_i];
                if (e[c.field]) {
                    var valueField = e[c.field]
                        .toString()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase();
                    if (valueField.indexOf(value) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        });
    };
    ClientesComponent.prototype.filterPermissoes = function (e) {
        var value = e
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
        this.permissoesPrecosFiltrados = this.permissoesPrecos.filter(function (e) {
            var valueField = e.query.name
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
            if (valueField.indexOf(value) !== -1) {
                return true;
            }
            return false;
        });
    };
    ClientesComponent.prototype.filterLimitacoes = function (e) {
        var value = e
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
        this.limitacoesConsultasFiltrados = this.limitacoesConsultas.filter(function (e) {
            var valueField = e.name
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
            if (valueField.indexOf(value) !== -1) {
                return true;
            }
            return false;
        });
    };
    ClientesComponent.prototype.abreForm = function () {
        this.msgCadastro = "";
        this.showForm = true;
        this.showLista = false;
        this.formCadastro = new FormGroup({
            name: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            document: new FormControl(null, [Validators.required]),
            companyName: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            pass: new FormControl(null, [Validators.required]),
            passConfirm: new FormControl(null, [Validators.required]),
        });
        // this.formCadastro = new FormGroup({
        //   'name':new FormControl(null,[Validators.required]),
        //   'email':new FormControl(null,[Validators.required, Validators.email]),
        //   'document':new FormControl(null,[Validators.required]),
        //   'companyName':new FormControl(null,[Validators.required]),
        //   'phone':new FormControl('1100000000',[Validators.required]),
        //   'pass':new FormControl(null,[Validators.required]),
        //   'passConfirm':new FormControl(null,[Validators.required])
        // });
    };
    ClientesComponent.prototype.fechaForm = function () {
        this.showForm = false;
        this.showLista = true;
        this.msgCadastro = "";
        this.loadClientes();
    };
    ClientesComponent.prototype.faturamento = function (registro, template) {
        this.registroSelecionado = registro;
        this.modalRef = this.modalService.show(template, { class: "modal-lg" });
        this.carregaFaturamento();
    };
    ClientesComponent.prototype.carregaFaturamento = function () {
        this.loadingPermissoes = true;
        this.configFormCadastroBilling();
    };
    ClientesComponent.prototype.configFormCadastroBilling = function () {
        var _this = this;
        var clientBilling = {
            contactName: null,
            phone: null,
            email: null,
            invoiceLimit: null,
        };
        this.api
            .get(URL_API + "/site/client-billing/" + this.registroSelecionado.billing)
            .then(function (r) {
            clientBilling = r;
            _this.formBilling = new FormGroup({
                contactName: new FormControl(clientBilling.contactName, [
                    Validators.required,
                ]),
                phone: new FormControl(clientBilling.phone, [Validators.required]),
                email: new FormControl(clientBilling.email, [
                    Validators.required,
                    Validators.email,
                ]),
                invoiceLimit: new FormControl(clientBilling.invoiceLimit, [
                    Validators.required,
                ]),
            });
            _this.loadingPermissoes = false;
            _this.carregaUltimosBillings();
        });
    };
    ClientesComponent.prototype.carregaUltimosBillings = function () {
        var _this = this;
        this.loadingBillings = true;
        this.lancamentosFatura = [];
        var urlBill = URL_API + "/site/billing-client/last-by-client/" + this.registroSelecionado._id;
        this.api.get(urlBill).then(function (bill) {
            _this.lancamentosFatura = bill;
            _this.lancamentosFatura.sort(function (a, b) { return (a.createAt > b.createAt ? 1 : -1); });
            _this.loadingBillings = false;
        });
    };
    ClientesComponent.prototype.salvarCadastroBilling = function () {
        var _this = this;
        var values = this.formBilling.value;
        var billingData = {};
        var campos = ["email", "phone", "contactName", "invoiceLimit"];
        campos.forEach(function (campo) {
            billingData[campo] = _this.formCadastro.value[campo];
        });
        //if(this.dadosUsuario.client.billing){
        //billingData['_id']=this.registroSelecionado.billing;
        //}
        if (this.formBilling.valid) {
            this.enviandoCadastro = true;
            this.msgCadastroBill = "";
            this.txtSubmitCadastro = "Enviando...";
            this.api
                .put(URL_API + "/site/client-billing/" + this.registroSelecionado.billing, values)
                .then(function (r) {
                // let clientData = {billing:r._id};
                // this.api.put(`${URL_API}/api/client/${this.dadosUsuario.client._id}`,clientData)
                // .then(ruser=>{
                if (r) {
                    _this.msgCadastroBill = "Dados atualizados com sucesso";
                    _this.msgCadastroBillClass = "alert-success";
                    setTimeout(function () {
                        _this.msgCadastroBill = "";
                        if (_this.dadosUsuario.client.billing == undefined) {
                            //this.router.navigate(['/area-logada']);
                            _this.dadosUsuario.client.billing = r;
                        }
                    }, 3000);
                }
                else {
                    _this.msgCadastroBill = "Erro ao atualizar os Dados";
                    _this.msgCadastroBillClass = "alert-danger";
                }
                _this.enviandoCadastro = false;
                _this.txtSubmitCadastro = "Salvar";
                // })
                // .catch(r=>{
                //   this.msgCadastro='Ocorreu um erro na requisição';
                //   this.msgCadastroClass= 'alert-danger';
                //   this.enviandoCadastro=false;
                //   this.txtSubmitCadastro="Salvar";
                // })
            })
                .catch(function (r) {
                _this.msgCadastroBill = "Ocorreu um erro na requisição";
                _this.msgCadastroBillClass = "alert-danger";
                _this.enviandoCadastro = false;
                _this.txtSubmitCadastro = "Salvar";
            });
        }
        else {
            this.msgCadastroBill = "Verique os campos obrigatórios";
            this.msgCadastroBillClass = "alert-warning";
            var campos_1 = ["email", "phone", "contactName", "invoiceLimit"];
            campos_1.forEach(function (campo) {
                _this.formCadastro.controls[campo].markAsTouched();
            });
        }
    };
    ClientesComponent.prototype.permissoes = function (registro, template) {
        this.registroSelecionado = registro;
        this.modalRef = this.modalService.show(template, { class: "modal-lg" });
        this.carregaPermissoesPrecos();
    };
    ClientesComponent.prototype.carregaPermissoesPrecos = function () {
        var _this = this;
        this.loadingPermissoes = true;
        this.permissoesPrecos = [];
        this.permissoesPrecosFiltrados = [];
        this.permissoesCliente = {};
        var url = URL_API + "/site/client-query/by-client/" + this.registroSelecionado._id;
        this.api.get(url).then(function (r) {
            _this.permissoesCliente = r[0];
            _this.permissoesPrecos = _this.permissoesCliente.queries;
            _this.permissoesPrecos.forEach(function (p) {
                p.showFeatures = false;
            });
            _this.permissoesPrecosFiltrados = _this.permissoesPrecos;
            _this.loadingPermissoes = false;
        });
    };
    ClientesComponent.prototype.limitacoes = function (registro, template) {
        this.registroSelecionado = registro;
        this.modalRef = this.modalService.show(template, { class: "modal-lg" });
        this.atualizaLimitacoes();
    };
    ClientesComponent.prototype.atualizaLimitacoes = function () {
        var _this = this;
        this.loadingPermissoes = true;
        this.limitacoesConsultas = [];
        this.limitacoesConsultasFiltrados = [];
        this.permissoesCliente = {};
        var url = URL_API + "/site/client/get-limit-queries/" + this.registroSelecionado._id;
        this.api.get(url).then(function (r) {
            _this.limitacoesConsultas = r.rows.map(function (e) {
                e.add = 0;
                return e;
            });
            _this.limitacoesConsultasFiltrados = _this.limitacoesConsultas;
            _this.loadingPermissoes = false;
        });
    };
    ClientesComponent.prototype.resetarLimitacoes = function () {
        var _this = this;
        var payload = {
            client: this.registroSelecionado._id,
            queries: [],
        };
        for (var _i = 0, _a = this.limitacoesConsultas; _i < _a.length; _i++) {
            var query = _a[_i];
            var o = {
                code: query.code,
                qty: query.limitPerDay,
                reset: true,
            };
            payload.queries.push(o);
        }
        this.loadingPermissoes = true;
        var url = URL_API + "/site/client/set-limit-queries";
        this.api.post(url, payload).then(function (r) {
            _this.atualizaLimitacoes();
            _this.registroSelecionado.lastResetLimitAt = Date.now();
        });
    };
    ClientesComponent.prototype.salvarCadastro = function () {
        var _this = this;
        var values = this.formCadastro.value;
        if (this.formCadastro.valid) {
            if (values["pass"] != values["passConfirm"]) {
                this.msgCadastro = "As senhas não estão iguais!";
                this.msgCadastroClass = "alert-danger";
                return;
            }
            //this.enviandoCadastro=true;
            this.msgCadastro = "";
            this.txtSubmitCadastro = "Enviando...";
            values["passTemp"] = true;
            this.api.get(URL_API + "/site/group/by-type/user").then(function (r) {
                values["group"] = r[0]._id;
                _this.api
                    .post(URL_API + "/site/client/newclient", values)
                    .then(function (r) {
                    console.log(r);
                    if (r.obj && r.obj._id) {
                        _this.msgCadastro = "Cadastro enviado com sucesso!";
                        _this.msgCadastroClass = "alert-success";
                        _this.enviandoCadastro = false;
                        _this.txtSubmitCadastro = "Salvar";
                        setTimeout(function () {
                            _this.msgCadastro = "";
                            _this.fechaForm();
                        }, 3000);
                    }
                    else {
                        if (r.err.errmsg &&
                            r.err.errmsg.indexOf("email_1 dup key") != -1) {
                            _this.msgCadastro = "E-mail já existente no cadastro.";
                        }
                        else {
                            _this.msgCadastro = r.msg;
                        }
                        _this.msgCadastroClass = "alert-danger";
                        _this.enviandoCadastro = false;
                        _this.txtSubmitCadastro = "Salvar";
                    }
                })
                    .catch(function (r) {
                    _this.msgCadastro = "Ocorreu um erro na requisição";
                    if (r.err.errmsg && r.err.errmsg.indexOf("email_1 dup key") != -1) {
                        _this.msgCadastro = "E-mail já existente no cadastro.";
                    }
                    _this.enviandoCadastro = false;
                    _this.txtSubmitCadastro = "Salvar";
                    _this.msgCadastroClass = "alert-danger";
                    _this.enviandoCadastro = false;
                    _this.txtSubmitCadastro = "Salvar";
                });
            });
        }
        else {
            this.msgCadastro = "Verique os campos obrigatórios";
            this.msgCadastroClass = "alert-warning";
            var campos = ["name", "email", "pass", "passConfirm"];
            campos.forEach(function (campo) {
                _this.formCadastro.controls[campo].markAsTouched();
            });
        }
    };
    ClientesComponent.prototype.save = function () {
        var _this = this;
        this.savingClientQueries.saving = true;
        this.savingClientQueries.text = "Salvando atualizações";
        var url = URL_API + "/site/client-query/" + this.permissoesCliente._id;
        var payload = __assign({}, this.permissoesCliente);
        for (var _i = 0, _a = payload.queries; _i < _a.length; _i++) {
            var item = _a[_i];
            item.query = { _id: item.query._id, name: item.query.name };
        }
        // console.log(payload);
        this.api
            .put(url, this.permissoesCliente)
            .then(function (r) {
            _this.savingClientQueries.saving = true;
            _this.savingClientQueries.text = r.msg;
            setTimeout(function () {
                _this.savingClientQueries.saving = false;
                _this.savingClientQueries.text = "";
            }, 3000);
            console.log(r);
        })
            .catch(function (e) {
            _this.savingClientQueries.text = "Ocorreu um erro. Tente novamente";
            setTimeout(function () {
                _this.savingClientQueries.saving = false;
                _this.savingClientQueries.text = "";
            }, 3000);
            console.log("ERRO", e);
        });
    };
    ClientesComponent.prototype.saveClient = function (obj) {
        var url = URL_API + "/site/client/" + obj._id;
        this.api
            .put(url, obj)
            .then(function (r) {
            console.log("OK");
        })
            .catch(function (e) { return console.log("ERRO", e); });
    };
    ClientesComponent.prototype.zerarFeatures = function (query) {
        query.features.forEach(function (feature) {
            feature.price = 0.0;
        });
        this.save();
    };
    ClientesComponent.prototype.changeQueryLimited = function (obj, bool) {
        obj.queryLimited = bool;
        this.saveClient(obj);
    };
    ClientesComponent.prototype.changePermission = function (obj, bool) {
        obj.permission = bool;
        this.save();
    };
    ClientesComponent.prototype.bloquearTodos = function (obj) {
        obj.forEach(function (o) {
            o.permission = false;
            if (o.features != undefined) {
                o.features.forEach(function (f) {
                    f.permission = false;
                });
            }
        });
        this.save();
    };
    ClientesComponent.prototype.liberarTodos = function (obj) {
        obj.forEach(function (o) {
            o.permission = true;
            if (o.features != undefined) {
                o.features.forEach(function (f) {
                    f.permission = true;
                });
            }
        });
        this.save();
    };
    ClientesComponent.prototype.verificarNovasConsultas = function () {
        var _this = this;
        this.loadingPermissoes = true;
        this.api
            .get(URL_API + "/site/client/configure/permissions/" + this.registroSelecionado._id)
            .then(function (r) {
            setTimeout(function () { return _this.carregaPermissoesPrecos(); }, 2000);
        });
    };
    ClientesComponent.prototype.addCredito = function () {
        var _this = this;
        if (confirm("Deseja continuar com o crédito?")) {
            this.loadingBillings = true;
            var obj = {
                client: this.registroSelecionado._id,
                value: this.valorCredito,
                description: this.descCredito,
            };
            this.api.post(URL_API + "/site/client/addCredit", obj).then(function (r) {
                _this.carregaUltimosBillings();
                _this.descCredito = "";
                _this.valorCredito = 0.0;
                //setTimeout(()=>this.carregaPermissoesPrecos(),2000);
            });
        }
    };
    ClientesComponent.prototype.excluir = function (registro) {
        var _this = this;
        if (confirm("Deseja mesmo excluir o cliente?")) {
            this.loadingClientes = true;
            this.api.get(URL_API + "/site/deleteClient/" + registro._id).then(function (r) {
                console.log(r);
                setTimeout(function () { return _this.loadClientes(); }, 2000);
            });
        }
    };
    ClientesComponent.prototype.addQuery = function (query) {
        var _this = this;
        if (query.add && query.add > 0) {
            var client = this.registroSelecionado._id;
            query.isProcessing = true;
            var payload = {
                client: client,
                queries: [
                    {
                        code: query.code,
                        qty: query.add,
                        reset: false,
                    },
                ],
            };
            this.api
                .post(URL_API + "/site/client/set-limit-queries", payload)
                .then(function (r) {
                query.isProcessing = false;
                _this.atualizaLimitacoes();
            });
        }
    };
    ClientesComponent.prototype.resetQuery = function (query) {
        var _this = this;
        var client = this.registroSelecionado._id;
        query.isProcessing = true;
        var payload = {
            client: client,
            queries: [
                {
                    code: query.code,
                    qty: query.limitPerDay,
                    reset: true,
                },
            ],
        };
        this.api
            .post(URL_API + "/site/client/set-limit-queries", payload)
            .then(function (r) {
            query.isProcessing = false;
            _this.atualizaLimitacoes();
        });
    };
    ClientesComponent.prototype.zeroQuery = function (query) {
        var _this = this;
        var client = this.registroSelecionado._id;
        query.isProcessing = true;
        var payload = {
            client: client,
            queries: [
                {
                    code: query.code,
                    qty: 0,
                    reset: true,
                },
            ],
        };
        this.api
            .post(URL_API + "/site/client/set-limit-queries", payload)
            .then(function (r) {
            query.isProcessing = false;
            _this.atualizaLimitacoes();
        });
    };
    return ClientesComponent;
}());
export { ClientesComponent };
