import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-score-pf',
  templateUrl: './score-pf.component.html',
  styleUrls: ['./../cadastral-pf/cadastral-pf.component.css','./score-pf.component.css']
})
export class ScorePFComponent implements OnInit {

  @Input() dados:any;
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  public canvasWidth = 300;
  public needleValue = 65;
  public centralLabel = '';
  public name = '';
  public bottomLabel = '65';
  public options = {
    hasNeedle: true,
    needleColor: 'red',
    needleUpdateSpeed: 1000,
    arcColors: ['red', 'lightgray'],
    arcDelimiters: [30],
    rangeLabel: ['0%', '100%'],
    needleStartValue: 0,
  }

  public protestoSelecionado=null;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.score_mercado===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.score_mercado===null){
      this.naoEncontrouRegistros=true;
    }else{
      let perc = parseFloat(this.dados.score_mercado.scoreFutureData.pontualidadePagamento);
      this.needleValue=perc;
      if(perc > 30 && perc < 70){
        this.options.arcColors = ['orange','lightgray'];
        this.options.needleColor='orange';
      }else if(perc >= 70){
        this.options.needleColor='green';
        this.options.arcColors = ['green','lightgray'];
      }
      this.bottomLabel=perc+'%';
      this.options.arcDelimiters=[perc];
    }
  }

}
