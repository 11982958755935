/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./generic.component";
var styles_GenericComponent = [i0.styles];
var RenderType_GenericComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericComponent, data: {} });
export { RenderType_GenericComponent as RenderType_GenericComponent };
export function View_GenericComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "page_banner internal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-12 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Em desenvolvimento..."])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "section", [["class", "contact_form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" P\u00E1gina em desenvolvimento. Desculpe-nos pelo transtorno. "]))], null, null); }
export function View_GenericComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic", [], null, null, null, View_GenericComponent_0, RenderType_GenericComponent)), i1.ɵdid(1, 114688, null, 0, i2.GenericComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericComponentNgFactory = i1.ɵccf("app-generic", i2.GenericComponent, View_GenericComponent_Host_0, {}, {}, []);
export { GenericComponentNgFactory as GenericComponentNgFactory };
