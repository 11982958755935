import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-credito-ccf',
  templateUrl: './credito-ccf.component.html',
  styleUrls: ['./credito-ccf.component.css', './../fdnet.component.css']
})
export class CreditoCcfComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() { }

  ngOnInit() {
    
    if (this.dados.cheque_sem_fundo.registro.length === 0) {
      this.naoEncontrouRegistros = true;
    }
  }

}
