<section class="inner_page_header">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="inner_headin_s">
          <h1>Termos de uso e política de privacidade</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="accordian_main">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="headings black_text">
            <p class="text-left">
              Nós da Future Data sabemos o quanto é importante para nossos clientes saber sobre a utilização dos seus dados pessoais. Nós nos preocupamos em esclarecer e divulgar nossos termos de uso e política de privacidade para garantir a transparência na utilização dos nossos serviços. Deste modo fica aberto para consulta e melhor entendimento sobre as informações que obtemos e como as utilizamos. Pedimos que leia com atenção e em caso de dúvidas nos contate através dos canais disponíveis. Essa política pode ser atualizada de tempos em tempos e os usuários serão comunicados em cada alteração para garantir a qualidade dos serviços e a concordância com a mesma.
            </p>
            <div class="sep white_bg"></div>
          </div>
        </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="accordion">
              <app-accordion-termos
                *ngFor="let termo of termos" 
                  [header]="termo.header" 
                  [textos]="termo.textos">
              </app-accordion-termos>
          </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="headings black_text">
            <p class="text-right">
              <br>
              {{atualizacaoTermo}}
            </p>
            <div class="sep white_bg"></div>
          </div>
        </div>
    </div>
  </div>
</section>