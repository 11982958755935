import { Pipe, PipeTransform } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';

@Pipe({
  name: 'strpadleft'
})
export class StrpadleftPipe implements PipeTransform {

  transform(value: string, args: number): any {
    return this.pad(value,args);
  }

  pad(num:string, size:number): string {
      let s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
  }

}
