/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pj/cadastral-pj.component.css.shim.ngstyle";
import * as i1 from "./imovel-pj.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./imovel-pj.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_ImovelPjComponent = [i0.styles, i1.styles];
var RenderType_ImovelPjComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ImovelPjComponent, data: {} });
export { RenderType_ImovelPjComponent as RenderType_ImovelPjComponent };
function View_ImovelPjComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_ImovelPjComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_ImovelPjComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), (_l()(), i2.ɵeld(3, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), i2.ɵppd(5, 3)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.metragem_terreno; _ck(_v, 2, 0, currVal_0); var currVal_1 = i2.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i2.ɵnov(_v.parent.parent, 0), _v.context.$implicit.valor_estimado, "BRL", true)); _ck(_v, 4, 0, currVal_1); }); }
function View_ImovelPjComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 9, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Metragem Terreno"])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Valor Estimado"])), (_l()(), i2.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImovelPjComponent_4)), i2.ɵdid(11, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.imovel; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_ImovelPjComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i3.CurrencyPipe, [i2.LOCALE_ID]), (_l()(), i2.ɵeld(1, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Poss\u00EDveis Im\u00F3veis"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImovelPjComponent_1)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImovelPjComponent_2)), i2.ɵdid(7, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImovelPjComponent_3)), i2.ɵdid(9, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.dados.imovel; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ImovelPjComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-imovel-pj", [], null, null, null, View_ImovelPjComponent_0, RenderType_ImovelPjComponent)), i2.ɵdid(1, 114688, null, 0, i4.ImovelPjComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImovelPjComponentNgFactory = i2.ɵccf("app-imovel-pj", i4.ImovelPjComponent, View_ImovelPjComponent_Host_0, { dados: "dados" }, {}, []);
export { ImovelPjComponentNgFactory as ImovelPjComponentNgFactory };
