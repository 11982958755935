import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-e-apis',
  templateUrl: './portal-e-apis.component.html',
  styleUrls: ['./portal-e-apis.component.css']
})
export class PortalEApisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
