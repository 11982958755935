<div class="row">
  <div class="col-12">
    <div class="login_form">
      <fieldset>
        <ng-container>
            <div class="section msg col-12 {{msgClass}}" *ngIf="msg!=''">
              <span [innerHTML]="msg"></span>
            </div>
            <div class="row">
              <div class="col-12">
                  <nav>
                      <ul class="pagination float-right">
                        <li class="page-item disabled">
                          <a class="page-link"><span><b>Total de {{total}}</b></span></a>
                        </li>
                        <li class="page-item {{loadingRegistros || !hasPrevPage?'disabled':''}}">
                          <a class="page-link" href="javascript:void(0)" (click)="trocaPagina(prev)">
                            <span>&laquo;</span>
                          </a>
                        </li>
      
                        <li *ngFor="let npage of arrayPages" class="page-item  {{loadingRegistros?'disabled':''}} {{((npage) == page)?'active':''}}">
                          <a class="page-link" (click)="trocaPagina(npage)" href="javascript:void(0)">{{npage}}</a>
                        </li>
                        <li class="page-item  {{loadingRegistros || !hasNextPage?'disabled':''}}">
                          <a class="page-link" href="javascript:void(0)" (click)="trocaPagina(next)">
                            <span>&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>                
              </div>
            
            </div>
            <div class="row">    
              
              <table class="table">
                  <thead>
                    <!-- <tr>
                      <th colspan="5">
                        <div class="row">
                        <div class="col-3">
                          <select class="form-control">
                            <option>Usuario</option>
                            <option>Usuario</option>
                            <option>Usuario</option>
                          </select>
                        </div>
                        <div class="col-3">
                          <select class="form-control">
                            <option>Usuario</option>
                            <option>Usuario</option>
                            <option>Usuario</option>
                          </select>
                        </div>
                        <div class="col-3">
                          <select class="form-control">
                            <option>Usuario</option>
                            <option>Usuario</option>
                            <option>Usuario</option>
                          </select>
                        </div>
                        <div class="col-3">
                          <select class="form-control">
                            <option>Usuario</option>
                            <option>Usuario</option>
                            <option>Usuario</option>
                          </select>
                        </div>
                      </div>
                      </th>
                    </tr> -->
                    <tr>
                      <th>Usuário</th>
                      <th>Consulta</th>
                      <th>Pesquisou por</th>
                      <th>Data</th>
                      <th>Tempo</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="registros.length==0 && !loadingRegistros">
                      <tr>
                        <td colspan="5">Sem registros</td>
                      </tr>
                    </ng-container>

                    <ng-container *ngIf="loadingRegistros">
                      <tr>
                        <td colspan="5">Processando...</td>
                      </tr>
                    </ng-container>

                    <ng-container *ngIf="registros.length > 0 && !loadingRegistros">
                      <tr *ngFor="let row of registros">
                        <td>{{row.user.name}}</td>
                        <td><span class="badge {{row.url|corTipoConsulta}}"> {{row.url | urlNomeConsulta}}</span></td>
                        <td>{{row.input | inputConsulta}}</td>
                        <td>{{row.startAt | date:'dd/MM/yyyy HH:mm'}}</td>
                        <td>{{row.time/1000}}s</td>
                        <td>
                          <button *ngIf="row.success" class="btn btn-sm btn-success" tooltip="{{row.output.msg}}" [routerLink]="[getUrlConsulta(row)]" [queryParams]="{logid: row._id}"><i class="fas fa-thumbs-up"></i> Ver consulta</button>
                          <button *ngIf="!row.success" class="btn btn-sm btn-danger" tooltip="{{row.output.msg}}"><i class="fas fa-thumbs-down"></i></button>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
            </div>
          </ng-container>
        </fieldset>
    </div>
  </div>
</div>