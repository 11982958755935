/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pf/cadastral-pf.component.css.shim.ngstyle";
import * as i1 from "./escolaridade-pf.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./escolaridade-pf.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_EscolaridadePfComponent = [i0.styles, i1.styles];
var RenderType_EscolaridadePfComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_EscolaridadePfComponent, data: {} });
export { RenderType_EscolaridadePfComponent as RenderType_EscolaridadePfComponent };
function View_EscolaridadePfComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_EscolaridadePfComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_EscolaridadePfComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "item col-auto"]], null, null, null, null, null)), (_l()(), i2.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.escolaridade.escolaridade; _ck(_v, 3, 0, currVal_0); }); }
export function View_EscolaridadePfComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Escolaridade"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_EscolaridadePfComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_EscolaridadePfComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_EscolaridadePfComponent_3)), i2.ɵdid(8, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.dados.escolaridade; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_EscolaridadePfComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-escolaridade-pf", [], null, null, null, View_EscolaridadePfComponent_0, RenderType_EscolaridadePfComponent)), i2.ɵdid(1, 114688, null, 0, i4.EscolaridadePfComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EscolaridadePfComponentNgFactory = i2.ɵccf("app-escolaridade-pf", i4.EscolaridadePfComponent, View_EscolaridadePfComponent_Host_0, { dados: "dados" }, {}, []);
export { EscolaridadePfComponentNgFactory as EscolaridadePfComponentNgFactory };
