import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-lei-lgpd',
    templateUrl: './lei-lgpd.component.html',
    styleUrls: ['./lei-lgpd.component.css']
})

export class LeiLGPDComponent implements OnInit {
    

    
    ngOnInit(): void {
        
    }

}