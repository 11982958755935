<div class="bloco">
    <h5 class="titulo">Filiais</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
          <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
          <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.filial">
      <div class="row">
        <table class="table table-sm ">
          <thead>
            <tr>
              <th>CNPJ Filial</th>
              <th>Razão Social</th>
              <th>Situação</th>
              <th>Cidade</th>
              <th>UF</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dados.filial">
              <td id="documentoFilial">
                <i class="fas fa-search"></i> 
                <a 
                  href="javascript:void(0)" 
                  tooltip="Clique aqui para consultar este documento {{row.cnpj_filial | mascara:'cnpj'}}"
                  (click)="capturaDoc(row.cnpj_filial)"
                  routerLink="/area-logada/consultas"> 
                    {{row.cnpj_filial  | mascara:'cnpj'}}
                </a>
              </td>
              <td>{{row.razao_social}}</td>
              <td>
                <span *ngIf="row.situacao_rf!='ATIVA' && dados.situacao_rf!='BAIXADA'" class="text-primary">{{row.situacao_rf}}</span>
                <span *ngIf="row.situacao_rf=='BAIXADA'" class="text-danger font-weight-bold">{{row.situacao_rf}}</span>
                <span *ngIf="row.situacao_rf=='ATIVA'" class="text-success font-weight-bold">{{row.situacao_rf}}</span>
              </td>
              <td>{{row.cidade_rf}}</td>
              <td>{{row.uf_rf}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    
    