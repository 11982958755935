<section class="page_banner internal">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Área do Cliente</h1>
        <!-- <div class="sub_heading_page"><strong> </strong> in Minutes!</div> -->
      </div>
    </div>
  </div>
</section>
  
<section class="login_box">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <!-- <div class="col-12"> -->
          <form [formGroup]="formLogin" novalidate (ngSubmit)="enviarLogin()">
          <div class="login_form">
            <div class="login_header bgamarelo">Login</div>
            <fieldset>
                <div class="section msg col-12 {{msgLoginClass}}" *ngIf="msgLogin!=''">
                    <span [innerHTML]="msgLogin"></span>
                  </div>
              <div class="section">
                <div class="row">
                  <div class="col-md-12">
                    <label class="input">
                      <input type="email" placeholder="email@dominio.com" formControlName="email">
                    </label>
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="row">
                  <div class="col-md-12">
                    <label class="input">
                      <input type="password" placeholder="senha" formControlName="pass">
                    </label>
                    <!-- <div class="note"><a href="javascript:void(0)" routerLink="/nova-senha">Esqueceu a senha?</a></div> -->
                  </div>
                </div>
              </div>

            </fieldset>
            <div class="login_footer">
              <button type="submit" class="button" [disabled]="enviandoLogin">
                {{txtSubmitLogin}}
              </button>
            </div>
          </div>
          </form>
        </div>
        <!-- <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="login_form">
            <form [formGroup]="formCadastro" novalidate (ngSubmit)="enviarCadastro()">
            <div class="login_header bgvermelho">Novo cliente</div>
            <fieldset>
              <div class="row">
                <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
                  <span [innerHTML]="msgCadastro"></span>
                </div>
                
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <input placeholder="Nome" type="text" formControlName="name" />
                  </label>
                </div>
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <input placeholder="E-mail" type="email" autocomplete="false" formControlName="email">
                  </label>
                </div>
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <input placeholder="Senha" type="password" autocomplete="false" formControlName="pass">
                  </label>
                </div>
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <input placeholder="Confirme a senha" type="password"  formControlName="passConf">
                  </label>
                </div>
                <div class="clear"></div>
                <div class="section col-md-12 col-sm-12 col-xs-12">
                  <label class="checkbox">
                    <input name="checkbox" type="checkbox"  formControlName="acceptMail">
                    <i></i>Eu gostaria de receber novidades por e-mail</label>
                </div>
              </div>
            </fieldset>
            <div class="login_footer">
              <button type="submit" class="button" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
            </div>
          </form> 
          </div>
          
        </div> -->
      </div>
    </div>
  </section>

  <!-- <ng-template #templateTermos>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Termos de contrato e uso</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      
      <div class="modal-body">
        <textarea class="formcontrol" rows="10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lorem mauris, efficitur eget efficitur et, blandit id nisi. Quisque sodales pulvinar metus. Donec ut risus cursus diam maximus mattis. Sed nec pulvinar nunc. Nullam aliquet ut leo eget aliquam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent accumsan egestas bibendum. Nam commodo ante et nisi sollicitudin, ac condimentum ipsum rhoncus. Nulla ligula urna, malesuada vel bibendum sed, tempus in diam.

            Nulla ornare porta eros, non faucibus urna tincidunt bibendum. Pellentesque consectetur sem eu efficitur mollis. Morbi non libero nisi. Vivamus massa turpis, fermentum sed condimentum vel, dictum at metus. Mauris facilisis libero ac tempus egestas. Aenean non maximus velit. Phasellus in enim a orci hendrerit ullamcorper id eget felis. Phasellus pellentesque laoreet ligula, sit amet cursus est hendrerit vel. Morbi tellus arcu, aliquet nec enim sit amet, volutpat vulputate dui. Etiam fringilla urna ac felis porta, in tempus massa pellentesque. Nam placerat mauris at placerat porttitor. Sed scelerisque nisl vel mi tincidunt interdum. In accumsan enim a hendrerit commodo.
            
            Duis eget vehicula est. Pellentesque in metus ipsum. Ut bibendum nisi eget viverra laoreet. Curabitur mollis imperdiet orci, sed interdum nulla commodo at. Sed dignissim, velit vitae semper convallis, metus purus tincidunt quam, at euismod erat ipsum non nisi. Praesent leo ligula, varius sed pretium fermentum, congue at nulla. Pellentesque aliquam velit ut dolor volutpat, a varius velit luctus. Mauris non elit nec urna ultricies tincidunt.
            
            Praesent semper interdum urna, dignissim lobortis est aliquet volutpat. Morbi interdum, elit eu auctor malesuada, augue dolor finibus tellus, a vestibulum tellus magna sit amet massa. Nunc nec varius nulla. Sed ut ante at libero venenatis consequat sit amet vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pulvinar mauris nunc, ac feugiat orci volutpat quis. Donec in elit blandit, maximus massa vel, tincidunt lectus. Mauris sed enim eget nisi pellentesque elementum. Integer placerat non nulla eget vehicula. Maecenas commodo suscipit malesuada.
            
            Pellentesque finibus imperdiet libero, non iaculis ligula condimentum tincidunt. Nulla tempor placerat enim, quis eleifend massa elementum non. In ipsum purus, hendrerit a ligula quis, tempor congue nisl. Etiam pretium augue ac eros condimentum feugiat. Aenean placerat ut enim quis facilisis. Praesent quam dui, sollicitudin non vehicula molestie, blandit nec nulla. Cras tortor nunc, accumsan quis nisl id, eleifend sollicitudin nisi. Integer et condimentum felis. Pellentesque eu turpis risus.
        </textarea>

      </div>


    </ng-template>
    -->