<div class="row">
  <div class="col-3">
    <div class="login_form">
      <div class="login_header bgamarelo">Faturamento</div>
      <fieldset>
        <div class="row" *ngIf="isFutureUser">
          <div class="d-flex col-8">
            <label class="input">
              <select class="w-100" [(ngModel)]="anoAtual">
                <option
                  *ngFor="let ano of rangeAno"
                  [value]="ano"
                  [selected]="anoAtual === ano"
                >
                  {{ ano }}
                </option>
              </select>
            </label>
            <label class="input">
              <select class="w-100" [(ngModel)]="mesAtual">
                <option
                  *ngFor="let mes of rangeMes"
                  [value]="mes"
                  [selected]="mesAtual === mes"
                >
                  {{ mes }}
                </option>
              </select>
            </label>
            <label class="input ml-2">
              <button
                class="btn btn-sm btn-info"
                (click)="carregarFaturamento()"
                tooltip="Atualizar"
              >
                <i class="fas fa-sync-alt"></i> Atualizar
              </button>
            </label>

          </div>
          
          

        </div>
        <ng-container *ngIf="carregandoFaturas">
          <div class="row">
            <div class="col-12">Carregando...</div>
          </div>
        </ng-container>
        <ng-container *ngIf="!carregandoFaturas">
          <div class="row cardsFaturamento">
            <div class="card bg-info">
              <div class="title">Total do mês</div>
              <div class="description">
                {{ totalFaturamento | currency: "BRL":true }}
              </div>
            </div>
            <ng-container *ngFor="let fatura of faturas">
              <div class="card bg-success">
                <div class="title">{{ fatura.client.companyName }}</div>
                <div class="description">
                  {{ fatura.total * -1 | currency: "BRL":true }}
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </fieldset>
    </div>
  </div>

  <div class="col-9">
    <div class="login_form">
      <div class="login_header bgvermelho">Saúde das Consultas</div>
      <fieldset>
        <div class="row">
          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <select class="w-100" [(ngModel)]="periodoAtualMaisUsadas">
                <option
                  *ngFor="let periodo of maisUsadasPeriodos"
                  [value]="periodo.value"
                  [selected]="periodoAtualMaisUsadas === periodo.value"
                >
                  {{ periodo.label }}
                </option>
              </select>
            </label>
          </div>

          
          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <button
                class="btn btn-sm btn-info"
                (click)="carregarConsultasMaisUsadas()"
                tooltip="Atualizar"
              >
                <i class="fas fa-sync-alt"></i> Atualizar
              </button>
            </label>
          </div>
        </div>

        <ng-container *ngIf="carregandoMaisUsadas">
          <div class="row">
            <div class="col-12">Carregando...</div>
          </div>
        </ng-container>
        <ng-container *ngIf="!carregandoMaisUsadas">
        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th colspan="7">
                  <input class="w-100" placeholder="Digite para filtrar" (keyup)="filterMaisUsadas($event.target.value)" />
                </th>
              </tr>
              <tr>
                <ng-container *ngFor="let c of columns">
                  <th>
                    <div class="sorting" (click)="changeSort(c.field)">
                      <span class="labelsort">{{c.label}}</span>
                      <div class="action">
                        <i class="fas fa-sort-down down {{isSortedAsc(c.field)}}"></i>
                        <i class="fas fa-sort-up up {{isSortedDesc(c.field)}}"></i>
                        <!-- <i class="fas fa-sort-down down {{order==='asc'?'sorted':''}}"></i> -->
                        <!-- <i class="fas fa-sort-up up {{order==='desc'?'sorted':''}}"></i> -->
                      </div>
                    </div>                  
                  </th>
                </ng-container>
                <!-- <th>
                  <div class="sorting">
                    <span class="labelsort">Consulta</span>
                    <div class="action" (click)="changeSort('queryName','desc')">
                      <i class="fas fa-sort-down down {{order==='asc'?'sorted':''}}"></i>
                      <i class="fas fa-sort-up up {{order==='desc'?'sorted':''}}"></i>
                    </div>
                  </div>
                </th>
                <th>Sucessos</th>
                <th>Erros</th>
                <th>Total</th>
                <th>Tempo médio</th>
                <th>Assertividade %</th> -->
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let c of maisUsadasFiltrados">
                <tr>
                  <td>{{c.queryName}}</td>
                  <td>{{c.supplier}}</td>
                  <td>{{c.success}}</td>
                  <td>{{c.errors}}</td>
                  <td>{{c.total}}</td>
                  <!-- <td>{{(c.minTime/1000).toFixed(2)}}s</td> -->
                  <td>{{(c.avgTime/1000).toFixed(2)}}s</td>
                  <!-- <td>{{(c.maxTime/1000).toFixed(2)}}s</td> -->
                  <td>{{c.accuracy}}%  <span *ngIf="c.accuracy < 50" class="ml-1 badge badge-danger">Ruim</span></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        </ng-container>
      </fieldset>
    </div>
  </div>
</div>
