<section class="page_banner">
    <div class="container">
        <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h1>LGPD</h1>
            <div class="sub_heading_page">Lei Geral de Proteção de Dados Pessoais nº 13.709/2018</div>
        </div>
        </div>
    </div>
</section>

<section class="blog-padding cor-letra">
    <div class="container">
        <div class="row">
            <div class="card-body">
                <div class="col-md-12">
                    <h2 class="text-center perguntas-h2"><strong>Perguntas frequentes sobre a LGPD</strong></h2>
                    <br>
                    <h3 class="text-left titulo-perguntas-h3"><strong>O que estabelece a Lei Geral de Proteção de Dados Pessoais?</strong></h3>
                    <p class="card-text margin-20">
                        Em linhas gerais, a Lei Geral de Proteção de Dados Pessoais (LGPD) estabelece os princípios, direitos e deveres que deverão
                        ser observados no tratamento de dados pessoais.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3"><strong>A Lei afeta nossa oferta de produtos?</strong></h3>
                    <p class="card-text margin-20">
                        Entedemos que não haverá impactos na comercialização das soluções atuais, especialmente diante das diversas hipóteses de
                        tratamento de dados contempladas pela Lei, tais como a proteção ao crédito e o legítimo interesse. A Lei entrará em vigor em
                        fevereiro de 2020, 18 meses após a sua publicação, período em que o mercado deverá implementar os ajustes eventualmente
                        necessários. Além disso, diversos artigos da LGPD estão sujeitos à regulamentação posterior e à definição de critérios pela
                        Autoridade Nacional de Proteção de Dados (ANPD).
                    </p>
                    <h3 class="text-left titulo-perguntas-h3"><strong>Como podemos avaliar a nova Lei?</strong></h3>
                    <p class="card-text margin-20">
                        A nova legislação, além de proteger os cidadãos, trará mais transparência e segurança jurídica para as entidades dos setores
                        público e privado, já que ela cria regras claras sobre o que pode ou não ser feito em relação ao tratamento de dados pessoais.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3"><strong>A quem deve ser aplicada a LGPD?</strong></h3>
                    <p class="card-text margin-20">
                        A Lei Geral de Proteção de Dados Pessoais é aplicável aos dados de pessoas naturais e deve ser cumprida por pessoa
                        natural e entidades públicas ou privadas, independentemente do país de sua sede ou de onde os dados estejam localizados,
                        que realizem qualquer operação de tratamento de dados pessoais, tais como a coleta, armazenamento e compartilhamento de
                        dados com terceiros, desde que esse tratamento (i) seja realizado no território nacional, (ii) tenha por objeto a oferta ou o
                        fornecimento de bens ou serviços ou o tratamento de dados de indivíduos localizados no território nacional, ou, ainda, (iii)
                        quando os dados pessoais tiverem sido coletados em território nacional.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3"><strong>O que teremos de oferecer ao cidadão?</strong></h3>
                    <p class="card-text margin-20">
                        É importante destacar que a proteção de dados pessoais não é um assunto novo, já que ele tem sido tratado por diversas leis
                        esparsas, como a Constituição Federal, a Lei do Habeas Data, o Marco Civil da Internet, a Lei do Cadastro Positivo e o Código
                        de Defesa do Consumidor. Dessa forma, teremos que desde já garantir ao titular o exercício de diversos direitos previstos na
                        Lei Geral de Proteção de Dados Pessoais, em especial os que se referem à garantia de acesso e retificação de seus dados,
                        por meio de canais de atendimento. A LGPD, entretanto, trouxe novidades que deverão ser implementadas, em especial a
                        portabilidade de dados, cujos critérios serão estabelecidos pela Autoridade Nacional de Proteção de Dados (ANPD), quando
                        esta for definida.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3"><strong>Quando implantaremos a nova Lei?</strong></h3>
                    <p class="card-text margin-20">
                        A Lei Geral de Proteção de Dados Pessoais entrará em vigor em fevereiro de 2020, 18 meses após a sua publicação, período
                        no qual todo o mercado terá a oportunidade de reavaliar seus processos internos e implementar eventuais ajustes. Temos um
                        projeto multidisciplinar que avaliará a adequação de suas operações para garantir que qualquer ajuste necessário esteja
                        pronto dentro desse prazo.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Estamos preparados para atender a Lei?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Sim. Sempre adotamos como prática os mais altos níveis de governança e segurança de dados. Agora, faremos eventuais
                        ajustes para assegurar a total conformidade com a Lei Geral de Proteção de Dados Pessoais antes de sua entrada em vigor.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Com a nova Lei, o que muda na prática para o cidadão?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Na prática, a mudança aumenta a transparência e o controle do titular sobre os seus dados. Quando a Lei Geral de Proteção
                        de Dados Pessoais entrar em vigor, o titular poderá requerer o acesso a todos os seus dados que estão sendo tratados por
                        qualquer pessoa natural ou entidade – pública ou privada, bem como solicitar que eles sejam corrigidos ou atualizados,
                        quando for o caso. A medida empodera o cidadão em relação aos dados pessoais, uma vez que as empresas terão mais
                        responsabilidades no tratamento desses dados, especialmente no que se refere à garantia de transparência e adoção de
                        critérios e medidas mais rígidas de governança e segurança de dados.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Quais serão os direitos do cidadão?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Em linhas gerais, o titular tem o direito de obter a confirmação da existência de tratamento de dados e de acessá-los, de forma
                        gratuita e facilitada. Se for o caso, o titular poderá requerer a correção de dados incompletos, inexatos ou desatualizados.
                        Quando o tratamento não observar os requisitos da Lei ou os dados forem considerados desnecessários ou excessivos, será
                        possível requerer a sua anonimização, bloqueio ou eliminação. O titular também poderá requerer a revisão manual de
                        decisões automatizadas, revogar o consentimento por ele fornecido ou requerer a portabilidade de seus dados, cujos critérios
                        ainda serão regulamentados pela Autoridade Nacional de Proteção de Dados (ANPD), entidade que será criada por Medida
                        Provisória ou Projeto de Lei de iniciativa do Executivo.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Como será possível fazer a revogação do consentimento?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Com a Lei Geral de Proteção de Dados Pessoais, o consentimento poderá ser revogado a qualquer momento, mediante
                        manifestação expressa do titular de dados, por procedimento gratuito e facilitado. Teremos que disponibilizar e informar em
                        nosso site e nos termos de uso os Canais de Atendimento para os quais os titulares de dados poderão encaminhar as suas
                        solicitações.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Com a LGPD, como fica o Cadastro Positivo?<strong></strong></h3>
                    <p class="card-text margin-20">
                        No Artigo 7º, Inciso X, o legislador cita expressamente que o tratamento dos dados pessoais pode ser realizado para a “proteção do crédito”.
                        No mesmo inciso, reconhece os dispositivos existentes na legislação pertinente, incluindo assim as leis que tratam especificamente de
                        crédito. Portanto, o Projeto de Lei que altera as regras do Cadastro Positivo está em consonância com a LGPD, que, como vimos, valida a
                        inclusão automática para fins de proteção de crédito e reconhece, na segunda parte do inciso X, os dispositivos específicos que tratam de
                        crédito.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Somos a favor da criação da ANPD?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Sim! A criação da ANPD (Autoridade Nacional de Proteção de Dados Pessoais), composta por profissionais técnicos
                        capacitados para lidar com o tema, será importante para regulamentar e atuar consultivamente sobre a Lei Geral de Proteção
                        de Dados Pessoais, trazendo mais segurança jurídica especialmente aos agentes de tratamento.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Como fica o uso de dados da administração pública?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Os dados provenientes de fontes públicas, ou seja, os dados públicos, podem ser coletados ou fornecidos às entidades
                        privadas quando estiverem publicamente acessíveis ou nas hipóteses de tratamento previstas na Lei Geral de Proteção de
                        Dados Pessoais, tais como a proteção ao crédito e o legítimo interesse do controlador ou de terceiros. Nesse sentido, o uso
                        desses dados deve respeitar o disposto na legislação, observados os direitos e princípios dos titulares de dados,
                        especialmente em relação ao uso de acordo com as finalidades para as quais esses dados foram disponibilizados, a garantia
                        de livre acesso e a minimização do tratamento, bem como a qualidade dos dados.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">O que significa o consentimento do usuário?<strong></strong></h3>
                    <p class="card-text margin-20">
                        O consentimento é a autorização do titular para o tratamento de dados para uma finalidade determinada, que deverá ser
                        fornecida mediante manifestação livre, informada e inequívoca, por escrito ou qualquer outro meio que demonstre a sua
                        manifestação de vontade. Caso seja fornecido por escrito, a cláusula deverá estar destacada das demais. Teremos que adotar
                        termos de uso em sites, onde são informadas todas as finalidades para as quais os dados coletados serão utilizados e as
                        categorias de terceiros com as quais os dados serão compartilhados. E também disponibiliza diversos canais de atendimento,
                        onde o titular dos dados poderá acessar e retificar as suas informações. Além disso, revisaremos todos os nossos processos e
                        de fornecedores para garantir que eventuais ajustes estejam prontos antes da vigência da Lei.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">Há um marco legal sobre o uso de dados das pessoas?<strong></strong></h3>
                    <p class="card-text margin-20">
                        Além da Constituição Federal, há diversas leis esparsas que tratam da proteção de dados pessoais, tais como o Código de
                        Defesa do Consumidor (especialmente o artigo 43, que regulamenta as atividades dos bancos de dados de proteção ao
                        crédito), a Lei de Acesso à Informação, a Lei do Cadastro Positivo e o Marco Civil da Internet.
                    </p>
                    <h3 class="text-left titulo-perguntas-h3">O que é o princípio da finalidade?<strong></strong></h3>
                    <p class="card-text margin-20">
                        O princípio da finalidade obriga o tratamento de dados para propósitos legítimos, específicos, explícitos e informados ao titular,
                        sem a possibilidade de tratamento posterior de forma incompatível com essas finalidades.
                    </p>
                    <h2 class="text-center titulo-perguntas-h3">Quem vai <strong class="vermelhoStrong">regular</strong>?</h2>
                    <br>
                    <p class="card-text text-center"><strong>Autoridade Nacional de Proteção de Dados Pessoais (ANPD)</strong></p>
                    <p class="card-text margin-20">
                        Com veto presidencial (inconstitucionalidade por vicio de iniciativa), a entidade deve ser criada por Medida Provisória ou Projeto de Lei de iniciativa do Executivo.
                    </p>
                    <p class="card-text">
                        Tem a missão de fiscalizar, regulamentar, interpretar a Lei e definir critérios, inclusive para o tratamento baseado em <strong>legitimo interesse</strong>.
                    </p>
                    <div class="card-body"><img class="card-img-top" src="assets/images/lgpd.jpg" alt=""></div>
                </div>
                <div class="col-md-12">
                    <br><br>
                    <h2 class="text-center perguntas-h2"><strong>Principais conceitos</strong></h2>
                </div>
                <div class="col-md-4">
                    <div class="support_features_inner">
                        <h5 class="section_sub_heading laranjaFD">Dado pessoal</h5>
                        <ul>
                            <li>Informação relacionada a uma pessoa natural identificada ou identificável.</li>
                        </ul>
                    </div>                          
                </div>
                <div class="col-md-4">
                    <div class="support_features_inner">
                        <h5 class="section_sub_heading laranjaFD">Dado anonimizado</h5>
                        <ul>
                            <li>Relativo ao titular que não pode ser identificado, considerando o uso de meios técnicos razoáveis e
                            disponíveis na ocasião de seu tratamento</li>
                        </ul>
                    </div>                          
                </div>
                <div class="col-md-4">
                    <div class="support_features_inner">
                        <h5 class="section_sub_heading laranjaFD">Consentimento</h5>
                        <ul>
                            <li>Manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus
                            dados pessoais para uma determinada finalidade.</li>
                        </ul>
                    </div>                    
                    <br><br> 
                </div>
                <div class="support_features">
                    <div class="col-md-12">
                        <h2 class="text-center perguntas-h2"><strong>Os direitos do titular dos dados</strong></h2>
                    </div>
                    <div class="col-md-6">
                        <div class="flechasUl">
                            <ul>
                                <li>Confirmação da existência do tratamento;</li>
                                <li>Acesso a correção de dados incompletos, inexatos ou desatualizados;</li>
                                <li>Anonimização, bloqueio ou eliminação de dados desnecessários;</li>
                                <li>Portabilidade (resguardados os segredos comercial ou industrial);</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="flechasUl">
                            <ul>
                                <li>Revogação do consentimento/eliminação dos dados;</li>
                                <li>Informação sobre com quem os dados foram compartilhados;</li>
                                <li>Informação sobre o poder de não consentir e suas consequências;</li>
                                <li>Revisão manual de decisões autorizadas.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="text_center">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <h2 class="perguntas-h2"><strong>Os principais atores</strong></h2>
                    </div>
                    <div class="why_choose_hosting">
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <div class="hosting_box">
                                <h5 class="section_sub_heading">Titular</h5>
                                <p>A quem se referem os dados pessoais</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <div class="hosting_box">
                                <h5 class="section_sub_heading">Controlador</h5>
                                <p>Responsável pelas decisões sobre o tratamento</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <div class="hosting_box">
                                <h5 class="section_sub_heading">Operador</h5>
                                <p>Quem faz o tratamento em nome do controlador</p>
                            </div>
                        </div>
                        <div class="col-md- col-sm-3 col-xs-12">
                            <div class="hosting_box">
                                <h5 class="section_sub_heading">Encarregado</h5>
                                <p>Canal de comunicação entre controlador, titulares e a ANPD</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>
    