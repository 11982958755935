<!-- ========= Inner Page Banner Section Start ========= -->

<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Soluções</h1>
        <div class="sub_heading_page">Aqui nós temos as <strong>melhores soluções</strong> para a <strong>sua empresa</strong></div>
      </div>
    </div>
  </div>
</section>

<!-- ========= Inner Page Banner Section End ========= --> 

<!-- ========= Offers Section Start ========= -->

<section class="text_center">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Conheça mais sobre cada solução</h3>
        </div>
      </div>
      <div class="offer">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="offer_box ident_box1">
            <h5 class="section_sub_heading white_text">Big Data</h5>
            <div class="offer_info">
              <p class="text-center">
                  Com a utilização desta solução podemos gerenciar todo o processo percorrido pela informação, proporcionando dados assertivos para consumo e estudos de caso, utilizando-se também do conceito de inferência de dados. 
              </p>
              <a class="primary_button" [routerLink]="['big-data']"><span>Veja Mais</span></a> </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="offer_box ident_box1">
            <h5 class="section_sub_heading white_text">Business Intelligence</h5>
            <div class="offer_info">
              <p>
                  A solução de Business Intelligence oferece um conjunto de técnicas e conceitos que auxiliam a tomada de decisão. Uso de dashboards intuitivos que permitem visualizar os processos ou dados necessários e filtrar diversas informações. 
              </p>
              <a class="primary_button" [routerLink]="['bi-dashboards']"><span>Veja Mais</span></a> </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="offer_box ident_box1">
            <h5 class="section_sub_heading white_text">Gestão de Riscos Financeiros</h5>
            <div class="offer_info">
              <p>
                  Através de nossas soluções de gestão de riscos podemos aumentar a assertividade e auxiliar nossos clientes na tomada de decisão para minimizar as perdas financeiras, gerando uma maior capitalização e menor investimento em métodos de cobrança.
              </p>
              <a class="primary_button" [routerLink]="['gestao-de-riscos']"><span>Veja Mais</span></a> </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="offer">
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="offer_box ident_box2">
              <h5 class="section_sub_heading white_text">Portal e API</h5>
              <div class="offer_info">
                <p>
                    Realize consultas dentro do nosso portal de forma fácil e intuitiva. Combine os retornos que deseja e tenha rapidamente em mãos os dados solicitados. Todas as soluções oferecidas são passíveis de consumo via API RESTful por meio de processo de integração.
                </p>
                <a class="primary_button" [routerLink]="['portal-e-apis']"><span>Veja Mais</span></a> </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="offer_box ident_box2">
              <h5 class="section_sub_heading white_text">Mineração de Dados</h5>
              <div class="offer_info">
                  <p>Com o uso de algoritmos e de um conjunto de técnicas avançadas capazes de sustentar e orientar por meio de inteligência artificial mecanismos escaláveis para mineração, explorando diversos conjuntos de dados, extraindo e auxiliando a evidenciar padrões consistentes e relacionamentos sistemáticos entre as variáveis dos dados.</p>
                <a class="primary_button" [routerLink]="['mineracao-de-dados']"><span>Veja Mais</span></a> </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="offer_box ident_box2">
              <h5 class="section_sub_heading white_text">Prevenção à Fraude e Compliance</h5>
              <div class="offer_info">
                  <p>
                      Nossa solução entrega informações customizáveis para prevenção à fraudes com base nos dados (cadastrais, biometria, similaridade textual, dentre outros) e inferência. Essa solução pode ser aplicada a vários fins, como por exemplo: controle de acesso, reconhecimento de padrões, padrões de segurança, instituições financeiras e muito mais.
                  </p>
                  <a class="primary_button" [routerLink]="['seguranca-e-compliance']"><span>Veja Mais</span></a> </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</section>

<!-- ========= Offers Section End ========= --> 