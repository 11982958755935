import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bloqueios-cnh',
  templateUrl: './bloqueios-cnh.component.html',
  styleUrls: ['./../consulta-cnh.component.css', './bloqueios-cnh.component.css']
})
export class BloqueiosCnhComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.dados.Bloqueios === undefined) {
      this.naoEncontrouRegistros = true;
    } else {
      if (!Array.isArray(this.dados.Bloqueios.registro)) {
        let { registro } = this.dados.Bloqueios;
        this.dados.Bloqueios.registro = [registro];
      }
    }
  }

}
