<div class="bloco">
  <h5 class="titulo">Protestos Estaduais</h5>
  <div *ngIf="naoEncontrouRegistros">
    <div class="row">
      <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
    </div>
  </div>
  
  <div *ngIf="dados.protesto.registro.length > 0">
    <div class="row">
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Data</th>
            <th>Moeda</th>
            <th>Valor</th>
            <th>Cartório</th>
            <th>Origem</th>
            <th>UF</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dados.protesto.registro">
            <td>{{row.data_protesto}}</td>
            <td>{{row.moeda}}</td>
            <td>{{row.valor}}</td>
            <td>{{row.cartorio}}</td>
            <td>{{row.origem}}</td>
            <td>{{row.estado}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>