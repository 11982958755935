/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pf/cadastral-pf.component.css.shim.ngstyle";
import * as i1 from "./imposto-renda-pf.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./imposto-renda-pf.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_ImpostoRendaPfComponent = [i0.styles, i1.styles];
var RenderType_ImpostoRendaPfComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ImpostoRendaPfComponent, data: {} });
export { RenderType_ImpostoRendaPfComponent as RenderType_ImpostoRendaPfComponent };
function View_ImpostoRendaPfComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_ImpostoRendaPfComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_ImpostoRendaPfComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), (_l()(), i2.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(6, null, ["", ""])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(8, null, ["", ""])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.situacao; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.banco; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.agencia; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.ano_base; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.data_credito; _ck(_v, 10, 0, currVal_4); }); }
function View_ImpostoRendaPfComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 15, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 11, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Situa\u00E7\u00E3o"])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Banco"])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Ag\u00EAncia"])), (_l()(), i2.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Ano Base"])), (_l()(), i2.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Data Cr\u00E9dito"])), (_l()(), i2.ɵeld(15, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImpostoRendaPfComponent_4)), i2.ɵdid(17, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.irpf; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_ImpostoRendaPfComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["IRPF"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImpostoRendaPfComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImpostoRendaPfComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImpostoRendaPfComponent_3)), i2.ɵdid(8, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.dados.irpf; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ImpostoRendaPfComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-imposto-renda-pf", [], null, null, null, View_ImpostoRendaPfComponent_0, RenderType_ImpostoRendaPfComponent)), i2.ɵdid(1, 114688, null, 0, i4.ImpostoRendaPfComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImpostoRendaPfComponentNgFactory = i2.ɵccf("app-imposto-renda-pf", i4.ImpostoRendaPfComponent, View_ImpostoRendaPfComponent_Host_0, { dados: "dados" }, {}, []);
export { ImpostoRendaPfComponentNgFactory as ImpostoRendaPfComponentNgFactory };
