<div class="bloco">
  <h5 class="titulo">Pontuação</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.Pontuacao">
    <div class="row">
      <div class="item col-auto">
        <label>Total de pontos</label>
        {{dados.Pontuacao.TotalPontos || 'Não informado'}}
      </div>
    </div>
  </div>
</div>
  
  