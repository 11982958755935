import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { ApiServices } from '../apiServices.service';
import { URL_API, TOKEN_NAME } from '../app.api';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-ativacao-usuario',
  templateUrl: './ativacao-usuario.component.html',
  styleUrls: ['./ativacao-usuario.component.css'],
  providers:[ApiServices]
})
export class AtivacaoUsuarioComponent implements OnInit {

  public pontos:string='';
  public mensagem:string='';
  public processando:Boolean=true;
  public sucesso:Boolean=false;
  public token:string='--';
  constructor(private route:ActivatedRoute, private api:ApiServices, private loginService:LoginService) { }

  ngOnInit() {
    this.route.url.subscribe(
      (p: any) => {
        let urlSegment:UrlSegment[] = p;
        if(urlSegment.length > 1){
          this.token = urlSegment[1].toString();
        }
      });
    let t = setInterval(()=>{
      this.pontos += '.';
      if(this.pontos=='....'){
        this.pontos = ''
      }
      
    },500 )

    this.api.get(`${URL_API}/activate/${this.token}`)
    .then(r=>{
      this.sucesso=r.success;
      this.mensagem=r.message;
      this.processando=false;
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem('usuarioLogadoSite');
    })
    .catch(e=>{
      console.log(e);
      this.sucesso=false;
      this.processando=false;
      this.mensagem='Erro na solicitação';
    });
  }

}
