<div class="bloco">
    <h5 class="titulo">Possíveis relações</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
          <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
          <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.relacionamento">
      <div class="row">
          <table class="table table-sm ">
              <thead>
                <tr>
                  <th>CPF Relação</th>
                  <th>Grau de relação</th>
                  <th>Nome</th>
                  <th>Data de Nascimento</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dados.relacionamento">
                  <td>{{row.cpf_relacao | mascara:'cpf'}}</td>
                  <td>{{row.grau_relacao}}</td>
                  <td>{{row.nome}}</td>
                  <td>{{row.data_nascimento}}</td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>