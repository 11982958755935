import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cadastral-pj',
  templateUrl: './cadastral-pj.component.html',
  styleUrls: ['./cadastral-pj.component.css']
})
export class CadastralPjComponent implements OnInit {

  constructor() { }
  @Input() dados:any;

  ngOnInit() {
  }

}
