<!-- ========= Home Slider Section Start ========= -->
<app-carousel></app-carousel>

<!-- ========= Home Slider Section End ========= --> 

<!-- ========= Home Page Plan Section Start ========= -->

<section class="why_register text_center">
    <div class="container">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Alta tecnologia para tratamento de dados</h3>
          <div class="sep black_bg"></div>
          <h5>
            Utilizamos a tecnologia para fornecer o melhor tratamento de dados e as melhores informações para auxiliar nas tomadas de decisões, campanhas e resultados.
          </h5>
        </div>
      </div>
      <div class="register_choose_section">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12">
            <div class="choose_r_options"><i class="fas fa-address-book"></i>
              <h5 class="section_sub_heading">Dados Cadastrais</h5>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12">
            <div class="choose_r_options"><i class="far fa-address-card"></i>
              <h5 class="section_sub_heading">Documentação</h5>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12">
            <div class="choose_r_options"><i class="fas fa-home"></i>
              <h5 class="section_sub_heading">Patrimônio</h5>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="choose_r_options"><i class="fas fa-map-marker-alt"></i>
                <h5 class="section_sub_heading">Localização</h5>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="choose_r_options"><i class="fas fa-cart-arrow-down"></i>
                <h5 class="section_sub_heading">Comportamento/Hábitos de Consumo</h5>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="choose_r_options"><i class="fas fa-users"></i>
                <h5 class="section_sub_heading">Relacionamento</h5>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>

<!-- ========= Home Page Plan Section End ========= -->

<!-- ========= Why Register Section End ========= --> 

<app-formContato text="Entre em contato conosco"></app-formContato>