/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../consulta-cnh.component.css.shim.ngstyle";
import * as i1 from "./pontuacao-cnh.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./pontuacao-cnh.component";
var styles_PontuacaoCnhComponent = [i0.styles, i1.styles];
var RenderType_PontuacaoCnhComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_PontuacaoCnhComponent, data: {} });
export { RenderType_PontuacaoCnhComponent as RenderType_PontuacaoCnhComponent };
function View_PontuacaoCnhComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_PontuacaoCnhComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 3, "div", [["class", "item col-auto"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Total de pontos"])), (_l()(), i2.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dados.Pontuacao.TotalPontos || "N\u00E3o informado"); _ck(_v, 5, 0, currVal_0); }); }
export function View_PontuacaoCnhComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Pontua\u00E7\u00E3o"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_PontuacaoCnhComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_PontuacaoCnhComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.dados.Pontuacao; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PontuacaoCnhComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-pontuacao-cnh", [], null, null, null, View_PontuacaoCnhComponent_0, RenderType_PontuacaoCnhComponent)), i2.ɵdid(1, 114688, null, 0, i4.PontuacaoCnhComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PontuacaoCnhComponentNgFactory = i2.ɵccf("app-pontuacao-cnh", i4.PontuacaoCnhComponent, View_PontuacaoCnhComponent_Host_0, { dados: "dados" }, {}, []);
export { PontuacaoCnhComponentNgFactory as PontuacaoCnhComponentNgFactory };
