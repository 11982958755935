import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ApiServices } from 'src/app/apiServices.service';
import { GenericValidator } from 'src/validators/cpfCnpj';
import { URL_API } from 'src/app/app.api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meus-dados',
  templateUrl: './meus-dados.component.html',
  styleUrls: ['./meus-dados.component.css'],
  providers: [ApiServices]
})
export class MeusDadosComponent implements OnInit {

  public formAcesso:FormGroup;
  public formCadastro:FormGroup;

  public msgAcesso:string = '';
  public msgAcessoClass:string = 'alert-warning';
  public txtSubmitAcesso:string = 'Salvar';
  public enviandoAcesso:Boolean = false;

  public msgCadastro:string = '';
  public msgCadastroClass:string = 'alert-warning';
  public txtSubmitCadastro:string = 'Salvar';
  public enviandoCadastro:Boolean = false;

  @Input() dadosUsuario:any;

  constructor(private api:ApiServices, private router:Router) { }

  ngOnInit() {
    this.configFormAcesso();
    this.configFormCadastro();
  }

  public configFormAcesso():void{
    this.formAcesso = new FormGroup({
      'pass':new FormControl(null,[Validators.required]),
      'newpass':new FormControl(null,[Validators.required]),
      'newpassConf':new FormControl(null,[Validators.required])
    });
  }

  public configFormCadastro():void{
    let user = this.dadosUsuario;
    let client = {
      document:null,
      companyName:null,
      contactName:null,
      phone:null,
      email:null
    };
    if(user.client){
      client = user.client;
    }

    this.formCadastro = new FormGroup({
      'name':new FormControl(user.name,[Validators.required]),
      'email':new FormControl(user.email,[Validators.required, Validators.email]),
      'client.document':new FormControl(client.document,[Validators.required]),
      'client.companyName':new FormControl(client.companyName,[Validators.required]),
      'client.contactName':new FormControl(client.contactName,[Validators.required]),
      'client.phone':new FormControl(client.phone,[Validators.required]),
      'client.email':new FormControl(client.email,[Validators.required,Validators.email]),
    });
  }

  public salvarCadastro():void{
    let values = this.formCadastro.value;
    
    let userData = {}
    let campos =['name','email'];
    campos.forEach(campo=>{
      userData[campo] = this.formCadastro.value[campo];
    });

    let clientData = {}
    campos =['client.email','client.phone','client.contactName','client.document','client.companyName'];
    campos.forEach(campo=>{
      clientData[campo.replace('client.','')] = this.formCadastro.value[campo];
    });

    
    
    if(this.dadosUsuario.client){
      clientData['_id']=this.dadosUsuario.client._id;
    }

    if(this.formCadastro.valid){

      if(
        !(GenericValidator.isValidCnpj(values['client.document'])
        ||
        GenericValidator.isValidCpf(values['client.document']))
      ){
        this.msgCadastro='O Documento não é válido.';
        this.msgCadastroClass='alert-danger';
        return;
      }
      
      this.enviandoCadastro=true;
      this.msgCadastro='';
      this.txtSubmitCadastro='Enviando...';
      this.api.upsert('client',clientData)
      .then(r=>{
        console.log('Client>>',r);
        userData['client'] = r._id;
        this.api.put(`${URL_API}/site/user/${this.dadosUsuario._id}`,userData)
        .then(ruser=>{
          console.log('User>>',ruser);
          if(ruser){
            this.msgCadastro=`Dados atualizados com sucesso`;
            this.msgCadastroClass= 'alert-success';
            setTimeout(()=>{
              this.msgCadastro='';
              console.log(this.dadosUsuario.client);
              
              if(this.dadosUsuario.client == undefined){
                this.router.navigate(['/area-logada']);
              }
            },3000);
          }else{
            this.msgCadastro='Erro ao atualizar os Dados';
            this.msgCadastroClass= 'alert-danger';
          }
          this.enviandoCadastro=false;
          this.txtSubmitCadastro="Salvar";
        })
        .catch(r=>{
          this.msgCadastro='Ocorreu um erro na requisição';
          this.msgCadastroClass= 'alert-danger';
          this.enviandoCadastro=false;
          this.txtSubmitCadastro="Salvar";
        })
      })
      .catch(r=>{
        this.msgCadastro='Ocorreu um erro na requisição';
        this.msgCadastroClass= 'alert-danger';
        this.enviandoCadastro=false;
        this.txtSubmitCadastro="Salvar";
      })
    }else{
      this.msgCadastro='Verique os campos obrigatórios';
      this.msgCadastroClass='alert-warning';
      let campos =['client.email','client.phone','client.contactName','name','email','client.document','client.companyName'];
      campos.forEach(campo=>{
        this.formCadastro.controls[campo].markAsTouched();
      });
    }
  }

  public salvarSenha():void{
    let values = this.formAcesso.value;

    if(this.formAcesso.valid){

      if(values['newpass'] != values['newpassConf']){
        this.msgAcesso='As senhas não estão iguais!';
        this.msgAcessoClass= 'alert-danger';
        return;
      }

      this.enviandoAcesso=true;
      this.msgAcesso='';
      this.txtSubmitAcesso='Enviando...';
      
      this.api.post(`${URL_API}/site/user/update-pass/${this.dadosUsuario._id}`,values)
      .then(r=>{
        
        if(r.success){
          this.msgAcesso=`Senha atualizada com sucesso`;
          this.msgAcessoClass= 'alert-success';
          
        }else{
          this.msgAcesso=r.message;
          this.msgAcessoClass= 'alert-danger';
        }
        this.enviandoAcesso=false;
        this.txtSubmitAcesso="Salvar";
        this.resetFormAcesso();
      })
      .catch(r=>{
        this.msgAcesso='Ocorreu um erro na requisição';
        this.msgAcessoClass= 'alert-danger';
        this.enviandoAcesso=false;
        this.txtSubmitAcesso="Salvar";
      })
    }else{
      this.msgAcesso='Verique os campos obrigatórios';
      this.msgAcessoClass='alert-warning';
      let campos =['pass','newpass','newpassConf'];
      campos.forEach(campo=>{
        this.formAcesso.controls[campo].markAsTouched();
      });
    }
  }

  public resetFormAcesso():void{
    let campos =['pass','newpass','newpassConf'];
      campos.forEach(campo=>{
        this.formAcesso.controls[campo].markAsUntouched();
        this.formAcesso.controls[campo].setValue('');
      });
    
      setTimeout(()=>{
        this.msgAcesso='';
      },3000);
  }
}

