import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-ppe-pf',
  templateUrl: './ppe-pf.component.html',
  styleUrls: ['./../cadastral-pf/cadastral-pf.component.css','./ppe-pf.component.css']
})
export class PpePfComponent implements OnInit {

  @Input() dados:any;
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.ppe===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.ppe===null){
      this.naoEncontrouRegistros=true;
    }
  }
}
