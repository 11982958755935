<div class="bloco">
  <h5 class="titulo">Resumo</h5>

  <div *ngIf="naoEncontrouRegistros">
    <div class="row">
      <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
    </div>
  </div>
  <div *ngIf="dados.resumo">

    <div class="row">
      <table class="table table-sm ">
        <thead>

          <tr>
            <th>Apontamento</th>
            <th>Quantidade</th>
            <th>Última ocorrência</th>
            <th>Valor total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Pefin</td>
            <td>{{dados.resumo.pefin.quantidade}}</td>
            <td>{{dados.resumo.pefin.data_ultimo}}</td>
            <td>{{dados.resumo.pefin.valor_total}}</td>
          </tr>
          <tr>
            <td>Refin</td>
            <td>{{dados.resumo.refin.quantidade}}</td>
            <td>{{dados.resumo.refin.data_ultimo}}</td>
            <td>{{dados.resumo.refin.valor_total}}</td>
          </tr>
          <tr>
            <td>Pendências vencidas</td>
            <td>{{dados.resumo.pendencias_vencidas.quantidade}}</td>
            <td>{{dados.resumo.pendencias_vencidas.data_ultimo}}</td>
            <td>{{dados.resumo.pendencias_vencidas.valor_total}}</td>
          </tr>
          <tr>
            <td>Protesto</td>
            <td>{{dados.resumo.protesto.quantidade}}</td>
            <td>{{dados.resumo.protesto.data_ultimo}}</td>
            <td>{{dados.resumo.protesto.valor_total}}</td>
          </tr>
          <tr>
            <td>Cheque sem fundo</td>
            <td>{{dados.resumo.cheque_sem_fundo.quantidade}}</td>
            <td>{{dados.resumo.cheque_sem_fundo.data_ultimo}}</td>
            <td>{{dados.resumo.cheque_sem_fundo.valor_total}}</td>
          </tr>
        </tbody>
      </table>

    </div>
    
  </div>
</div>