import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputConsulta'
})
export class InputConsultaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let camposTexto = {
      cnpj: 'CNPJ',
      razao_social: 'Razão Social',
      telefone: 'Telefone',
      cpf: 'CPF',
      nome: 'Nome',
      placa: 'Placa',
      chassi: 'Chassi'
    }
    for (let campo in camposTexto) {
      if (value.hasOwnProperty(campo)) {
        return `${camposTexto[campo]} = ${value[campo]}`;

      }
    }
    return value;
  }

}
