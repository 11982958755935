<div class="bloco">
    <h5 class="titulo">Histórico de Endereço</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
            <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
            <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.historico_endereco">
        <div class="row">
          <table class="table table-sm ">
            <thead>
              <tr>
                <th>Logradouro</th>
                <th>Nº</th>
                <th>Compl.</th>
                <th>Bairro</th>
                <th>Cidade/UF</th>
                <th width="100">CEP</th>
                <th>Pontuação</th>
                <th width="100">Cód. IBGE</th>
                <th>Valor do M²</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.historico_endereco">
                <!-- <td id="documentoFilial">
                  <i class="fas fa-search"></i> 
                  <a 
                    href="javascript:void(0)" 
                    tooltip="Clique aqui para consultar este documento {{row.cnpj_filial | mascara:'cnpj'}}"
                    (click)="capturaDoc(row.cnpj_filial)"
                    routerLink="/area-logada/consultas"> 
                      {{row.cnpj_filial  | mascara:'cnpj'}}
                  </a>
                </td> -->
                <td>{{row.logradouro_rf}}</td>
                <td>{{row.numero_rf}}</td>
                <td>{{row.complemento_rf}}</td>
                <td>{{row.bairro_rf}}</td>
                <td>{{row.cidade_rf}}/{{row.uf_rf}}</td>
                <td>{{row.cep_rf | mascara:'cep'}}</td>
                <td>{{row.score}}</td>
                <td>{{row.codigo_ibge}}</td>
                <td>{{row.valor_m2_regiao | currency:'BRL':true}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>