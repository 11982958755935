import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-mapa-google',
  templateUrl: './mapa-google.component.html',
  styleUrls: ['./mapa-google.component.css']
})
export class MapaGoogleComponent implements OnInit {

  public lat:any;
  public lng:any;
  public zoom: number = 15;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
    
    
  }

}
