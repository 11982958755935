import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estudos-brain',
  templateUrl: './estudos-brain.component.html',
  styleUrls: ['./estudos-brain.component.css']
})
export class EstudosBrainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
