/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pf/cadastral-pf.component.css.shim.ngstyle";
import * as i1 from "./ccf-pf.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./ccf-pf.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_CcfPfComponent = [i0.styles, i1.styles];
var RenderType_CcfPfComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CcfPfComponent, data: {} });
export { RenderType_CcfPfComponent as RenderType_CcfPfComponent };
function View_CcfPfComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 3, "div", [["class", "col-auto item text-success"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-laugh-wink"], ["style", "font-size:19px"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" N\u00E3o existem CCF para essa pessoa"]))], null, null); }
function View_CcfPfComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_CcfPfComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "td", [["class", "text-danger"], ["style", "font-size:19px"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "td", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "td", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i2.ɵted(6, null, ["", ""])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "td", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i2.ɵted(8, null, ["", ""])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "td", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i2.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.banco; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.agencia; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.quantidade; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.data_ocorrencia; _ck(_v, 10, 0, currVal_3); }); }
function View_CcfPfComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 14, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 10, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 0, "th", [["width", "30"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Banco"])), (_l()(), i2.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Ag\u00EAncia"])), (_l()(), i2.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Quantidade"])), (_l()(), i2.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Data Ocorr\u00EAncia"])), (_l()(), i2.ɵeld(14, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CcfPfComponent_4)), i2.ɵdid(16, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.ccf; _ck(_v, 16, 0, currVal_0); }, null); }
export function View_CcfPfComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Indicativo de CCF"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CcfPfComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CcfPfComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CcfPfComponent_3)), i2.ɵdid(8, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.dados.ccf; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_CcfPfComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-ccf-pf", [], null, null, null, View_CcfPfComponent_0, RenderType_CcfPfComponent)), i2.ɵdid(1, 114688, null, 0, i4.CcfPfComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CcfPfComponentNgFactory = i2.ɵccf("app-ccf-pf", i4.CcfPfComponent, View_CcfPfComponent_Host_0, { dados: "dados" }, {}, []);
export { CcfPfComponentNgFactory as CcfPfComponentNgFactory };
