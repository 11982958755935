/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pj/cadastral-pj.component.css.shim.ngstyle";
import * as i1 from "./representante-pj.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "ngx-bootstrap/tooltip";
import * as i6 from "ngx-bootstrap/component-loader";
import * as i7 from "ngx-bootstrap/positioning";
import * as i8 from "../../../mascara.pipe";
import * as i9 from "./representante-pj.component";
import * as i10 from "ngx-bootstrap/modal";
var styles_RepresentantePjComponent = [i0.styles, i1.styles];
var RenderType_RepresentantePjComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_RepresentantePjComponent, data: {} });
export { RenderType_RepresentantePjComponent as RenderType_RepresentantePjComponent };
function View_RepresentantePjComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_RepresentantePjComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_RepresentantePjComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 7, "td", [["id", "documentoRepresentante"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-search"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 16777216, null, null, 5, "a", [["href", "javascript:void(0)"], ["routerLink", "/area-logada/consultas"]], [[1, "target", 0], [8, "href", 4], [1, "aria-describedby", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.capturaDoc(_v.context.$implicit.cpf) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i2.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i2.ɵdid(5, 212992, null, 0, i5.TooltipDirective, [i2.ViewContainerRef, i6.ComponentLoaderFactory, i5.TooltipConfig, i2.ElementRef, i2.Renderer2, i7.PositioningService], { tooltip: [0, "tooltip"] }, null), i2.ɵppd(6, 2), (_l()(), i2.ɵted(7, null, [" ", " "])), i2.ɵppd(8, 2), (_l()(), i2.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_3 = "/area-logada/consultas"; _ck(_v, 4, 0, currVal_3); var currVal_4 = i2.ɵinlineInterpolate(1, "Clique aqui para consultar este documento ", i2.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i2.ɵnov(_v.parent.parent, 0), _v.context.$implicit.cpf, "cpf")), ""); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 4).target; var currVal_1 = i2.ɵnov(_v, 4).href; var currVal_2 = i2.ɵnov(_v, 5).ariaDescribedby; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i2.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i2.ɵnov(_v.parent.parent, 0), _v.context.$implicit.cpf, "cpf")); _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.context.$implicit.nome; _ck(_v, 10, 0, currVal_6); }); }
function View_RepresentantePjComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 9, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["CPF"])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nome"])), (_l()(), i2.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_RepresentantePjComponent_4)), i2.ɵdid(11, 278528, null, 0, i4.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.representante; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_RepresentantePjComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i8.MascaraPipe, []), (_l()(), i2.ɵeld(1, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Representantes"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_RepresentantePjComponent_1)), i2.ɵdid(5, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_RepresentantePjComponent_2)), i2.ɵdid(7, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_RepresentantePjComponent_3)), i2.ɵdid(9, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.dados.representante; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_RepresentantePjComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-representante-pj", [], null, null, null, View_RepresentantePjComponent_0, RenderType_RepresentantePjComponent)), i2.ɵdid(1, 114688, null, 0, i9.RepresentantePjComponent, [i10.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepresentantePjComponentNgFactory = i2.ɵccf("app-representante-pj", i9.RepresentantePjComponent, View_RepresentantePjComponent_Host_0, { dados: "dados" }, { documentoRepresentante: "documentoRepresentante" }, []);
export { RepresentantePjComponentNgFactory as RepresentantePjComponentNgFactory };
