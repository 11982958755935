import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dados-cnh',
  templateUrl: './dados-cnh.component.html',
  styleUrls: ['./../consulta-cnh.component.css', './dados-cnh.component.css']
})
export class DadosCnhComponent implements OnInit {
  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.dados.Dados_CNH === undefined){
      this.naoEncontrouRegistros = true;
    }
  }

}
