<div class="" *ngIf="carregandoTela">
    Aguarde...
</div>

<div class="" *ngIf="showResultado">
  <div class="botoes d-flex justify-content-end noprint">
    <button class="btn btn-primary" (click)="voltar()"><i class="fas fa-search"></i> Nova consulta</button>
    <button class="btn btn-secondary" (click)="voltarHistorico()"><i class="fas fa-history"></i> Histórico consultas</button>
    <button class="btn btn-info" (click)="print();"><i class="fas fa-print"></i> Imprimir</button>
  </div>
    <div class="" *ngIf="carregandoResultado">
      Carregando o resultado da consulta
    </div>
    <div class="resultadoBusca mb-3" id="resultadoConsulta" *ngIf="!carregandoResultado">
      <div class="bloco">
        <h5 class="titulo">Informações da Consulta - {{consulta.segmento.label}}</h5>
        <!-- <pre>{{consulta.input|json}}</pre> -->
        <div class="row">
            <div class="item col-auto">
                <label>Pesquisou por</label>
                {{consulta.input | inputConsulta}}
              </div>
        </div>
        <div class="row">
          <div class="item col-auto">
            <label>ID</label>
            {{consulta._id}}
          </div>
          
          <div class="item col-auto">
            <label>Segmento</label>
            {{consulta.segmento.label}}
          </div>
          <div class="item col-auto">
            <label>Data Início</label>
            {{consulta.startAt | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <div class="item col-auto">
            <label>Data Fim</label>
            {{consulta.endAt | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <div class="item col-auto">
            <label>Tempo total</label>
            {{consulta.time/1000}}
          </div>
          <div class="item col-auto">
            <label>Solicitante</label>
            {{consulta.user.name}}
          </div>
        </div>
      </div>
      <ng-container *ngIf="consulta.output.dados.length">
        <div class="bloco">
          <h5 class="titulo">{{consulta.output.dados.length}} Possíveis resultados</h5>
          <div class="row">
            <div class="item col-auto">
              <label>Essa consulta exibiu uma listagem de possíveis resultados baseado nos seus critérios de pesquisa</label>
              Está consulta não é cobrada.
            </div>
            <div class="col-12 resultados log">
            <ng-container *ngFor="let resultado of consulta.output.dados">
                <div *ngIf="consulta.segmento.value=='pf'" class="resultado col-12">
                  CPF: {{resultado.cpf | strpadleft:11}} | {{resultado.nome}}
                </div>
                <div *ngIf="consulta.segmento.value=='pj'" class="resultado col-12">
                  CNPJ: {{resultado.cnpj | strpadleft:14}} | {{resultado.razao_social}}
                </div>
            </ng-container>
            </div>

          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!consulta.output.dados.length">
        <ng-container *ngIf="consulta.segmento.value=='pf'">
          <app-cadastral-pf [dados]="consulta.output.dados"></app-cadastral-pf>
          <!-- <app-score-pf
            *ngIf="consulta.output.dados.score_mercado!==undefined" [dados]="consulta.output.dados"></app-score-pf>
          <app-credito-plus-pf
            *ngIf="consulta.output.dados.credito_plus!==undefined" [dados]="consulta.output.dados"></app-credito-plus-pf>
          <app-endereco-pf 
            *ngIf="consulta.output.dados.localizacao!==undefined" [dados]="consulta.output.dados"></app-endereco-pf>
          <app-telefone-movel-pf 
            *ngIf="consulta.output.dados.telefone_movel!==undefined" [dados]="consulta.output.dados"></app-telefone-movel-pf>
          <app-telefone-fixo-pf 
            *ngIf="consulta.output.dados.telefone_fixo!==undefined" [dados]="consulta.output.dados"></app-telefone-fixo-pf>
          <app-email-pf
            *ngIf="consulta.output.dados.email!==undefined" [dados]="consulta.output.dados"></app-email-pf>
          <app-documento-pf
            *ngIf="consulta.output.dados.documento!==undefined" [dados]="consulta.output.dados"></app-documento-pf>
          <app-renda-estimada-pf
            *ngIf="consulta.output.dados.renda_estimada!==undefined" [dados]="consulta.output.dados"></app-renda-estimada-pf>
          <app-ppe-pf
            *ngIf="consulta.output.dados.ppe!==undefined" [dados]="consulta.output.dados"></app-ppe-pf>
          <app-escolaridade-pf 
            *ngIf="consulta.output.dados.escolaridade!==undefined" [dados]="consulta.output.dados"></app-escolaridade-pf>
          <app-nacionalidade-pf 
            *ngIf="consulta.output.dados.nacionalidade!==undefined" [dados]="consulta.output.dados"></app-nacionalidade-pf>
          <app-imposto-renda-pf 
            *ngIf="consulta.output.dados.irpf!==undefined" [dados]="consulta.output.dados"></app-imposto-renda-pf>
          <app-ccf-pf 
            *ngIf="consulta.output.dados.ccf!==undefined" [dados]="consulta.output.dados"></app-ccf-pf>
          <app-veiculo-pf 
            *ngIf="consulta.output.dados.veiculo!==undefined" [dados]="consulta.output.dados"></app-veiculo-pf>
          <app-aeronave-pf 
            *ngIf="consulta.output.dados.aeronave!==undefined" [dados]="consulta.output.dados"></app-aeronave-pf>
          <app-imovel-pf 
            *ngIf="consulta.output.dados.imovel!==undefined" [dados]="consulta.output.dados"></app-imovel-pf>
          <app-socio-empresa-pf 
            *ngIf="consulta.output.dados.sociedade!==undefined" [dados]="consulta.output.dados" (documentoCNPJ)="reciverDocumento($event,'cnpj')"></app-socio-empresa-pf>
          <app-parente-pf 
            *ngIf="consulta.output.dados.relacionamento!==undefined" [dados]="consulta.output.dados"></app-parente-pf> -->
          
        </ng-container>

        <ng-container *ngIf="consulta.segmento.value=='pj'">  
          <app-cadastral-pj [dados]="consulta.output.dados"></app-cadastral-pj>
          <app-endereco-pj
            *ngIf="consulta.output.dados.localizacao!==undefined" [dados]="consulta.output.dados"></app-endereco-pj>
          <app-historico-endereco-pj
            *ngIf="consulta.output.dados.historico_endereco!==undefined" [dados]="consulta.output.dados"></app-historico-endereco-pj>
          <app-telefone-movel-pj
            *ngIf="consulta.output.dados.telefone_movel!==undefined" [dados]="consulta.output.dados"></app-telefone-movel-pj>
          <app-telefone-fixo-pj
            *ngIf="consulta.output.dados.telefone_fixo!==undefined" [dados]="consulta.output.dados"></app-telefone-fixo-pj>
          <app-email-pj
            *ngIf="consulta.output.dados.email!==undefined" [dados]="consulta.output.dados"></app-email-pj>
          <app-site-pj
            *ngIf="consulta.output.dados.site!==undefined" [dados]="consulta.output.dados"></app-site-pj>
          <app-historico-razao-social-pj
            *ngIf="consulta.output.dados.historico_razao_social!==undefined" [dados]="consulta.output.dados"></app-historico-razao-social-pj>
          <app-inscricao-estadual-pj
            *ngIf="consulta.output.dados.inscricao_estadual!==undefined" [dados]="consulta.output.dados"></app-inscricao-estadual-pj>
          <app-cnae-pj
            *ngIf="consulta.output.dados.cnae!==undefined" [dados]="consulta.output.dados"></app-cnae-pj>
          <app-socio-pj
            *ngIf="consulta.output.dados.socio!==undefined" [dados]="consulta.output.dados" (documentoSocio)="reciverDocumento($event,'cpf')"></app-socio-pj>
          <app-representante-pj
            *ngIf="consulta.output.dados.representante!==undefined" [dados]="consulta.output.dados" (documentoRepresentante)="reciverDocumento($event,'cpf')"></app-representante-pj>
          <app-natureza-juridica-pj
            *ngIf="consulta.output.dados.natureza_juridica!==undefined" [dados]="consulta.output.dados"></app-natureza-juridica-pj>
          <app-faturamento-pj
            *ngIf="consulta.output.dados.faturamento!==undefined" [dados]="consulta.output.dados"></app-faturamento-pj>
          <app-funcionario-pj
            *ngIf="consulta.output.dados.funcionario!==undefined" [dados]="consulta.output.dados"></app-funcionario-pj>
          <app-ccf-pj
            *ngIf="consulta.output.dados.ccf!==undefined" [dados]="consulta.output.dados"></app-ccf-pj>
          <app-veiculo-pj
            *ngIf="consulta.output.dados.veiculo!==undefined" [dados]="consulta.output.dados"></app-veiculo-pj>
          <app-aeronave-pj
            *ngIf="consulta.output.dados.aeronave!==undefined" [dados]="consulta.output.dados"></app-aeronave-pj>
          <app-imovel-pj
            *ngIf="consulta.output.dados.imovel!==undefined" [dados]="consulta.output.dados"></app-imovel-pj>
          <app-filial-pj
            *ngIf="consulta.output.dados.filial!==undefined" [dados]="consulta.output.dados" (documentoFilial)="reciverDocumento($event, 'cnpj')"></app-filial-pj>
          <app-antifraude-socio-pj
          *ngIf="consulta.output.dados.antifraude_socio!==undefined" [dados]="consulta.output.dados"></app-antifraude-socio-pj>
          <app-antifraude-socio-relacao-pj
          *ngIf="consulta.output.dados.antifraude_socio_relacao!==undefined" [dados]="consulta.output.dados"></app-antifraude-socio-relacao-pj>
          <app-grupo-empresarial-n1-pj
          *ngIf="consulta.output.dados.grupo_empresarial_n1!==undefined" [dados]="consulta.output.dados"></app-grupo-empresarial-n1-pj>
          <app-participacao-empresa-n1-pj
          *ngIf="consulta.output.dados.participacao_empresa_n1!==undefined" [dados]="consulta.output.dados"></app-participacao-empresa-n1-pj>
        </ng-container>
      </ng-container>
    </div>
</div>

<div class="" *ngIf="showBusca">
    <div class="login_form">
        <div class="" *ngIf="carregandoPermissoes">Carregando permissões de acesso</div>
        <div class="" *ngIf="!carregandoPermissoes">
  
          <div class="segmentos col-12 d-flex justify-content-between">
            <ng-container *ngFor="let segmento of segmentosBusca;let lastItem = last;let firstItem = last">
              <button class="btn btn-lg btn-{{segmento.value==segmentoSelecionado?'':'outline-'}}{{segmento.class}} " (click)="changeSegmento(segmento.value)" [disabled]="segmento.disabled" type="button">
                <i class="far {{segmento.value==segmentoSelecionado?'fa-check-square':'fa-square'}}"></i>
                {{segmento.label}}
              </button>
            </ng-container>
          </div>
          <div class="clearfix"></div>
          <div class="col-auto">
            <label class="input campoBusca">
              <input class="inputpf" *ngIf="segmentoSelecionado=='pf'" placeholder="CPF, Nome ou Telefone com DDD" type="text" autocomplete="false" [(ngModel)]="palavraChave" (blur)="validaPalavraChave()" >
              <input class="inputpj" *ngIf="segmentoSelecionado=='pj'" placeholder="CNPJ, Razão Social ou Telefone com DDD" type="text" autocomplete="false" [(ngModel)]="palavraChave" (blur)="validaPalavraChave()">
            </label>
            <div class="alert-danger msgPalavraChave" *ngIf="msgPalavraChave!=''"><i class="fas fa-exclamation-triangle"></i> &nbsp; {{msgPalavraChave}}</div>
          </div>
  
          <div class="col-12 features">
            
            <div class="">
              <ng-container *ngFor="let segmento of segmentosBusca;let lastItem = last;let firstItem = first">
                <h4 *ngIf="firstItem">Escolha uma opção</h4> 
                <!-- <h4 *ngIf="firstItem && segmento.groups.length > 0">Adicionar as seguintes informações:</h4>  -->
                <ng-container *ngFor="let group of segmento.groups;">
                  <div *ngIf="segmento.value==segmentoSelecionado && group.features.length > 0" 
                    class="group btn btn btn-{{!allFeaturesSelecionados(group.features)?'outline-':''}}{{group.class}}" 
                    (click)="changeFeatures(group.features)"
                    >
                    <i class="far fa-square" *ngIf="!allFeaturesSelecionados(group.features)"></i>
                    <i class="far fa-check-square" *ngIf="allFeaturesSelecionados(group.features)"></i>
                    {{group.label}}  <span *ngIf="group.new" class="ml-1 badge badge-light">NOVO!</span>
                  </div>
                </ng-container>
              </ng-container>
              <div class="clearfix"><hr/></div>
              <h5>Informações da consulta</h5> 
              <ng-container *ngFor="let segmento of segmentosBusca;">
                <ng-container *ngFor="let group of segmento.groups;">
                  <ng-container *ngFor="let feature of group.features;">
                    <div *ngIf="segmento.value==segmentoSelecionado" 
                    class="feature btn col-2 btn-sm btn-{{features[segmento.value][feature.value]?'':'outline-'}}{{group.class}}" 
                    >
                      {{feature.label}}  <span *ngIf="feature.new" class="ml-1 badge badge-light">NOVO!</span>
                    </div>
                  </ng-container>
                </ng-container>
                
              </ng-container>
            </div>
          </div>
  
          
          <div class="col-12 d-flex justify-content-around">
            <button class="btn btn-lg btn-danger btn-block btn-consultar" (click)="enviar()" [disabled]="isBlockedSubmit()" type="button">
              <i class="fas fa-chevron-circle-right"></i> {{txtSubmit}}
            </button>
          </div>
  
          <div class="col-12 " *ngIf="msg!=''">
            <div class="col-12 section msg {{msgClass}}">
              <span [innerHTML]="msg"></span>
            </div>
          </div>
          
  
          <div class="col-12 resultados" *ngIf="resultadosBusca.dados.length >= 1">
              <h4>Possíveis resultados: {{resultadosBusca.dados.length}}</h4>
              <h6>Limitamos o resultado em no máximo 100 registros, escolha algum dos resultados abaixo ou refine melhor sua busca</h6>
              <div class="">
                <ng-container *ngFor="let resultado of resultadosBusca.dados">
                    <div *ngIf="segmentoSelecionado=='pf'" class="resultado btn col-12 btn-lg btn-secondary" (click)="consultarResultado(resultado.cpf)">
                      CPF: {{resultado.cpf | strpadleft:11}} | {{resultado.nome}}
                    </div>
                    <div *ngIf="segmentoSelecionado=='pj'" class="resultado btn col-12 btn-lg btn-secondary" (click)="consultarResultadoPJ(resultado.cnpj)">
                      CNPJ: {{resultado.cnpj | strpadleft:14}} | {{resultado.razao_social}}
                    </div>
                </ng-container>
                
              </div>
            </div>
        </div>
    </div>
  </div>