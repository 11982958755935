import { OnInit, Component, Input } from '@angular/core';
import { ApiServices } from 'src/app/apiServices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_API } from 'src/app/app.api';
import { GenericValidators } from 'src/validators/generic-validators';

@Component({
    selector: 'app-antifraude',
    templateUrl: './antifraude.component.html',
    styleUrls: ['./antifraude.component.css'],
    providers: [ApiServices]
})

export class AntifraudeComponent implements OnInit {

  @Input() dadosUsuario:any;

  public classSegmentoBusca:string='primary';
  public segmentoSelecionado:string='pj';
  public selecionarTodosPF:Boolean=false;
  public selecionarTodosPJ:Boolean=false;
  public msg:string='';
  public msgPalavraChave:string='';
  public msgClass:string='alert-info';

  public palavraChave:string='';
  public erroPalavraChave:Boolean=false;
  public consultando:Boolean=false;
  public campoBuscar='cnpj';
  public txtSubmit:string='Consultar';

  public carregandoTela:Boolean=true;
  public showBusca:Boolean=false;
  public showResultado:Boolean=false;
  public resultadosBusca:any={dados:[]};
  public carregandoResultado:Boolean=true;
  public logid:string='';
  public segmentosBusca:any[]=[];
  public segmentosBuscaBase:any[]=[
      // {label: 'Pessoa Física', value: 'pf', class: 'primary', disabled: false, 
      // groups:[
      //   {
      //     label: 'Broze', new: true, features: [
      //     ]
      //   },
      //   {
      //     label: 'Silver', new: true, features: [
      //     ]
      //   },
      //   {
      //     label: 'Gold', new: true, features: [
      //     ]
      //   },
      // ]},
      {label: 'Pessoa Jurídica', value: 'pj', class: 'success', disabled: false, 
      groups: [
        {
          label: 'Bronze', new: false, class: 'warning bronze', features: [
            {label: 'Histórico de Razão Social', value: 'historico_razao_social'},
            {label: 'Histórico de Endereço', value: 'historico_endereco'},
            {label: 'Antifraude Sócio', value: 'antifraude_socio'},
            {label: 'Antifraude Sócio Relação', value: 'antifraude_socio_relacao'},
            {label: 'Participações em empresa N1', value: 'participacao_empresa_n1'},
            {label: 'Grupo Empresarial N1', value: 'grupo_empresarial_n1'},
            {label: 'Veículos', value: 'veiculo'},
            {label: 'Aeronaves', value: 'aeronave'},
            {label: 'Filiais', value: 'filial'},
          ]
        },
        // {
        //   label:'Silver', new:false, class: 'secondary silver', features:[
        //     {label: 'Histórico de Razão Social', value: 'historico_razao_social'},
        //     {label: 'Histórico de Endereço', value: 'historico_endereco'},
        //     {label: 'Antifraude Sócio', value: 'antifraude_socio'},
        //     {label: 'Antifraude Sócio Relação', value: 'antifraude_socio_relacao'},
        //     {label: 'Participações em empresa N1', value: 'participacao_empresa_n1'},
        //     {label: 'Grupo Empresarial N1', value: 'grupo_empresarial_n1'},
        //     {label: 'Veículos', value: 'veiculo'},
        //     {label: 'Aeronaves', value: 'aeronave'},
        //     {label: 'Filiais', value: 'filial'},
        //   ]
        // },
        // {
        //   label:'Gold', new:false, class: 'warning gold', features:[
        //     {label: 'Histórico de Razão Social', value: 'historico_razao_social'},
        //     {label: 'Histórico de Endereço', value: 'historico_endereco'},
        //     {label: 'Antifraude Sócio', value: 'antifraude_socio'},
        //     {label: 'Antifraude Sócio Relação', value: 'antifraude_socio_relacao'},
        //     {label: 'Participações em empresa N1', value: 'participacao_empresa_n1'},
        //     {label: 'Grupo Empresarial N1', value: 'grupo_empresarial_n1'},
        //     {label: 'Veículos', value: 'veiculo'},
        //     {label: 'Aeronaves', value: 'aeronave'},
        //     {label: 'Filiais', value: 'filial'},
        //   ]
        // },
      ],
    }
  ];

  public features: any = {
      pf: {
        localizacao: false, telefone_movel: false, telefone_fixo: false,
        email: false
      },
      pj: {
        historico_razao_social: false, historico_endereco: false, antifraude_socio: false,
        antifraude_socio_relacao: false, participacao_empresa_n1: false, grupo_empresarial_n1: false,
        veiculo: false, aeronave: false, filial: false
      }
  }

  public permissoes: any = null;
  public carregandoPermissoes: Boolean = true;

  constructor(
      private api:ApiServices,
      private route:ActivatedRoute,
      private router:Router) {}

      ngOnInit() {
        this.route.queryParams.subscribe((p: any) => {
          if(p.logid != undefined){
            this.logid = p.logid;
            this.showResultado=true;
            this.showBusca = false;
            this.getResultadoConsulta();
          }else{
            this.showBusca=true;
            this.showResultado=false;
          }
          this.carregandoTela=false;
          
          this.changeSelecionaTodos();
          this.getPermissoesCliente();
        });
        
      }

  public getPermissoesCliente(): void {

    this.segmentosBusca = [];
    this.carregandoPermissoes = true;
    this.api
    .get(`${URL_API}/api/client/getPermissions`)
    .then(r => {
      
      this.permissoes = r;
      this.segmentosBuscaBase
      .forEach((item, index) => {
          
        let segmento = JSON.parse(JSON.stringify(item));
        delete segmento.groups;

        if(this.hasPermission(segmento.value, undefined)) {
          let groups = [];
          
          item.groups
          .forEach((itemGroup, indexGroup) => {

            let group = JSON.parse(JSON.stringify(itemGroup));
            delete group.features;
            let features = [];

            itemGroup.features
            .forEach(itemFeature => {
                
              let feature = JSON.parse(JSON.stringify(itemFeature));
              if(this.hasPermission(segmento.value, itemFeature.value)) {
                features.push(feature);
              }
            })
            
            group.features = features;

            if(features.length > 0) {

              groups.push(group);
            }
          });

          segmento.groups = groups;
          this.segmentosBusca.push(segmento);
        }
      });

      this.carregandoPermissoes = false;
    })
    .catch(e => console.log(e));
  }

  public hasPermission(code:String,feature:String):Boolean{
    let r = false;
    this.permissoes.queries.forEach(q=>{
      if(feature == undefined){
        if(q.code==code && q.permission){
          r = true;
        }
      }else{
        if(q.code==code && q.permission){
          q.features.forEach(f=>{
            if(f.name==feature && f.permission){
              r=true;
            }
          })
        }
      }
    })

    return r;
  }

  public changeSelecionaTodos():void{
    let pf = this.features.pf;
    let pj = this.features.pj;

    if(this.selecionarTodosPF){
      Object.keys(pf).forEach(function(ele,index) {
        pf[ele]=true;
      })
    }else{
      Object.keys(pf).forEach(function(ele,index) {
        pf[ele]=false;
      })
    }
    if(this.selecionarTodosPJ){
      Object.keys(pj).forEach(function(ele,index) {
        pj[ele]=true;
      })
    }else{
      Object.keys(pj).forEach(function(ele,index) {
        pj[ele]=false;
      })
    }
  }

  public consulta:any;

  public getResultadoConsulta():void{
    this.showBusca=false;
    this.showResultado=true;
    this.carregandoResultado=true;

    this.api.get(`${URL_API}/api/log-query/${this.logid}`)
    .then(r=>{
      
      this.consulta=r.obj;
      
      this.consulta.segmento = this.getSegmento(this.consulta.url.replace('/consulta/',''));
      this.carregandoResultado=false;
    })
    .catch(e=>console.log(e))
  }

  public changeSegmento(segmento:string):void{
    this.segmentoSelecionado = segmento;
    this.msg='';

    this.segmentosBusca.forEach(seg=>{
      if(seg.value == this.segmentoSelecionado){
        this.classSegmentoBusca=seg.class;
      }
      console.log(seg);
    })
    
    this.validaPalavraChave();
  }

  public getSegmento(str:string):any{
    let ret =null;
    this.segmentosBuscaBase.forEach(seg=>{
      if(seg.value == str){
        ret=seg;
      }
    });
    return ret;
  }

  public getTextoPesquisado(input:any):string{
    
    if(input.nome!= undefined){
      return input.nome;
    }else if(input.telefone!= undefined){
      return input.telefone;
    }else if(input.cpf!= undefined){
      return input.cpf;
    }else if(input.cnpj!= undefined){
      return input.cnpj;
    }
  }

  public changeFeature(feature:string):void{
    let valueAtual:Boolean = this.features[this.segmentoSelecionado][feature];

    if(valueAtual){
      this.features[this.segmentoSelecionado][feature] = false;
    }else{
      this.features[this.segmentoSelecionado][feature] = true;
    }
  }

  public validaPalavraChave():void{
    let qtdValidacoes;
    let qtdErros=0;

    if(this.segmentoSelecionado=='pf'){
      qtdValidacoes = 3;

      if(!GenericValidators.isText(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='nome';
      }

      if(!GenericValidators.isPhoneNumber(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='telefone';
      }

      if(!GenericValidators.isValidCpf(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='cpf';
      }
      

      if(qtdErros == qtdValidacoes && this.palavraChave.length >= 3){
        this.msgPalavraChave='A palavra chave não parece ser um Nome, CPF ou Telefone'
        this.erroPalavraChave=true;
      }else{
        this.erroPalavraChave=false;
        this.msgPalavraChave='';
      }
    }

    if(this.segmentoSelecionado=='pj'){
      qtdValidacoes = 3;

      if(!GenericValidators.isText(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='razao_social';
      }

      if(!GenericValidators.isPhoneNumber(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='telefone';
      }

      if(!GenericValidators.isValidCnpj(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='cnpj';
      }
      
      if(qtdErros == qtdValidacoes && this.palavraChave.length >= 3){
        this.msgPalavraChave='A palavra chave não parece ser uma Razão Social, CNPJ ou Telefone'
        this.erroPalavraChave=true;
      }else{
        this.erroPalavraChave=false;
        this.msgPalavraChave='';
      }
    }
  }

  public isBlockedSubmit():Boolean{
    if(this.consultando){
      return true;
    }
    if(this.palavraChave.length >= 3){
      if(!this.erroPalavraChave){
        return false;
      }
    }
    return true;
  }

  public enviar():void{
    this.resultadosBusca = {dados:[]}
    this.txtSubmit='Consultando. Aguarde...';
    this.consultando=true;
    let body = {};
    this.msg='';
    this.msgClass='';
    let urlBusca = `${URL_API}/api/consulta/${this.segmentoSelecionado}`;
    body[this.campoBuscar]=this.palavraChave.toString();
    body['features']=this.features[this.segmentoSelecionado];
    
      this.api.post(urlBusca,body)
      .then(r=>{
        if(r.success){
          this.resultadosBusca = r;        
          if(!Array.isArray(this.resultadosBusca.dados)){
            this.logid=this.resultadosBusca.logQuery;
            this.router.navigate(['/area-logada/antifraude'],{queryParams:{logid:this.logid}});
            //this.getResultadoConsulta();
          }
        }else{
          this.msg=r.msg;
          this.msgClass='alert-danger';
        }
        
        this.txtSubmit='Consultar';
        this.consultando=false;
      })
  }

  public voltar():void{
    this.logid='';
    this.resultadosBusca = {dados:[]}
    this.showResultado=false;
    this.showBusca=true;
    this.router.navigate(['/area-logada/antifraude']);
  }

  public voltarHistorico():void{
    this.router.navigate(['/area-logada/historico-consultas']);
  }

  public consultarResultado(cpf:string):void{
    this.campoBuscar='cpf';
    this.palavraChave = cpf;
    //this.validaPalavraChave();
    //setTimeout(() => {
      this.enviar();
    //}, 1000);
    

  }

  public consultarResultadoPJ(cnpj:string):void{
    this.campoBuscar='cnpj';
    this.palavraChave = cnpj;
    this.enviar();
  }
  public changeFeatures(features:any[]):void{
    
    if(this.allFeaturesSelecionados(features)){
      features.forEach(f=>{
        this.features[this.segmentoSelecionado][f.value]=false;
      })
    }else{
      features.forEach(f=>{
        this.features[this.segmentoSelecionado][f.value]=true;
      })
    }
  }

  public allFeaturesSelecionados(features:any[]):Boolean{
    let r:Boolean = true;
    features.forEach(f=>{
      if(!this.features[this.segmentoSelecionado][f.value]){
        r=false;
      }
    })
    return r;
  }

  public print():void{
    setTimeout(()=>{
      window.print();
    },200);
  }

}