<div class="bloco">
  <h5 class="titulo">Score Mercado <span class="ml-1 badge badge-light">NOVO!</span></h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.score_mercado">
    <div class="row">
      <div class="col-6">
          <div class="row">
            <div class="item col-auto">
              <label>CNPJ</label>
              {{dados.cnpj | mascara:'cnpj'}}
            </div>
            <!-- <div class="item col-auto">
              <label>Restrição</label>
              {{dados.score_mercado.restricao}}
            </div> -->
          </div>
          <div class="row">
            <div class="item col-auto">
              <label>Score</label>
              <div class="text-success display-4" *ngIf="dados.score_mercado.scoreFutureData.score >= 700">{{dados.score_mercado.scoreFutureData.score}}</div>
              <div class="text-warning display-4" *ngIf="dados.score_mercado.scoreFutureData.score > 300 && dados.score_mercado.scoreFutureData.score < 700">{{dados.score_mercado.scoreFutureData.score}}</div>
              <div class="text-danger display-4" *ngIf="dados.score_mercado.scoreFutureData.score <= 300">{{dados.score_mercado.scoreFutureData.score}}</div>
            </div>
            <div class="item col-auto">
              <label>Restrição</label>
      
              <div class="text-success display-4" *ngIf="dados.score_mercado.restricao=='NADA CONSTA'"><i class="far fa-laugh-wink"></i> NADA CONSTA</div>
              <div class="text-secondary display-4" *ngIf="dados.score_mercado.restricao=='Não informado'"><i class="far fa-dot-circle"></i> Não informado</div>
              <div class="text-danger display-4" *ngIf="dados.score_mercado.restricao!='NADA CONSTA' && dados.score_mercado.restricao!='Não informado'"><i class="fas fa-sad-tear"></i> CONSTA RESTRIÇÃO</div>
              
            </div>
          </div>      
        </div>
        <div class="col-6">
          <div class="row">
            <div class="item col-auto">
              <label>Pontualidade</label>
              {{dados.score_mercado.scoreFutureData.descricaoPontualidade}}
      
              <rg-gauge-chart
              [canvasWidth]="canvasWidth"
              [needleValue]="needleValue"
              [centralLabel]="centralLabel"
              [options]="options"
              [name]="name"
              [bottomLabel]="bottomLabel"></rg-gauge-chart>
            </div>
          </div>
        </div>
    </div>
    
  




</div>
