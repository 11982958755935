<div class="bloco">
    <h5 class="titulo">Grupo Empresarial Nível 1</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
            <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
            <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.grupo_empresarial_n1">
        <div class="row">
          <table class="table table-sm ">
            <thead>
              <tr>
                <th>Tipo Documento</th>
                <th>Documento</th>
                <th>Nome</th>
                <th>Cargo</th>
                <th>CNPJ</th>
                <th>Razão Social</th>
                <th>Situação</th>
                <th>Total de Empresas N2</th>
                <th>Nível</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.grupo_empresarial_n1">
                <td>{{row.tipo_documento}}</td>
                <td>{{row.documento | mascara: 'cpf'}}</td>
                <td>{{row.nome_razao_social}}</td>
                <td>{{row.cargo}}</td>
                <td>{{row.cnpj_grupo | mascara: 'cnpj'}}</td>
                <td>{{row.razao_social_grupo}}</td>
                <td>{{row.situacao_rf_grupo}}</td>
                <td>{{row.total_empresa_n2}}</td>
                <td>{{row.nivel}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>