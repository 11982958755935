import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-telefone-movel-pf',
  templateUrl: './telefone-movel-pf.component.html',
  styleUrls: ['./../cadastral-pf/cadastral-pf.component.css','./telefone-movel-pf.component.css']
})
export class TelefoneMovelPfComponent implements OnInit {
  @Input() dados:any;
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.telefone_movel===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.telefone_movel===null){
      this.naoEncontrouRegistros=true;
    }
  }

}
