<div class="bloco">
  <h5 class="titulo">Score Mercado <span class="ml-1 badge badge-light">NOVO!</span></h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.score_mercado">
    <div class="row">
      <div class="col-6">
          <div class="row">
            <div class="item col-auto">
              <label>CPF</label>
              {{dados.cpf | mascara:'cpf'}}
            </div>
             <!-- <div class="item col-auto">
              <label>Restrição</label>
              {{dados.score_mercado.restricao}}
            </div> -->
          </div>
          <div class="row">
            <div class="item col-auto">
              <label>Score</label>
              <div class="text-success display-4" *ngIf="dados.score_mercado.scoreFutureData.score >= 700">{{dados.score_mercado.scoreFutureData.score}}</div>
              <div class="text-warning display-4" *ngIf="dados.score_mercado.scoreFutureData.score > 300 && dados.score_mercado.scoreFutureData.score < 700">{{dados.score_mercado.scoreFutureData.score}}</div>
              <div class="text-danger display-4" *ngIf="dados.score_mercado.scoreFutureData.score <= 300">{{dados.score_mercado.scoreFutureData.score}}</div>
            </div>
            <div class="item col-auto">
              <label>Restrição</label>
      
              <div class="text-success display-4" *ngIf="dados.score_mercado.restricao=='NADA CONSTA'"><i class="far fa-laugh-wink"></i> NADA CONSTA</div>
              <div class="text-secondary display-4" *ngIf="dados.score_mercado.restricao=='Não informado'"><i class="far fa-dot-circle"></i> Não informado</div>
              <div class="text-danger display-4" *ngIf="dados.score_mercado.restricao!='NADA CONSTA' && dados.score_mercado.restricao!='Não informado'"><i class="fas fa-sad-tear"></i> CONSTA RESTRIÇÃO</div>
              
            </div>
          </div>      
        </div>
        <div class="col-6">
          <div class="row">
            <div class="item col-auto">
              <label>Pontualidade</label>
              {{dados.score_mercado.scoreFutureData.descricaoPontualidade}}
      
              <rg-gauge-chart
              [canvasWidth]="canvasWidth"
              [needleValue]="needleValue"
              [centralLabel]="centralLabel"
              [options]="options"
              [name]="name"
              [bottomLabel]="bottomLabel"></rg-gauge-chart>
            </div>
          </div>
        </div>
    </div>
    
  

    <!-- <div class="row" *ngIf="dados.credito_plus.pefin && !dados.credito_plus.pefin.mensagem">
      <h6 class="titulo">PFIN Indicador I</h6>
      <div class="item col-auto">
        <label>Quantidade</label>
        {{dados.credito_plus.pefin.pefin_quantidade}}
      </div>
      <div class="item col-auto">
        <label>Restrição</label>
        {{dados.credito_plus.pefin.restricao}}
      </div> 
      <div class="item col-auto">
        <label>Valor </label>
        {{dados.credito_plus.pefin.pefin_valor_total}}
      </div> 
      <div class="item col-auto">
        <label>Última Ocorrência</label>
        {{dados.credito_plus.pefin.pefin_data_ultima_ocorrencia}}
      </div>     
      <h6 class="titulo">PFIN Indicador I Detalhe</h6>   
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Avalista</th>
            <th>Contrato</th>
            <th>Data Ocorrência</th>
            <th>Origem</th>
            <th>Valor pendência</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dados.credito_plus.pefin.pefin_detalhe.length">
            <tr *ngFor="let pefin of dados.credito_plus.pefin.pefin_detalhe">
              <td>{{pefin.pefin_avalista}}</td>
              <td>{{pefin.pefin_contrato}}</td>
              <td>{{pefin.pefin_data_ocorrencia}}</td>
              <td>{{pefin.pefin_origem}}</td>
              <td>{{pefin.pefin_valor_pendencia}}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!dados.credito_plus.pefin.pefin_detalhe.length">
            <tr>
              <td>{{dados.credito_plus.pefin.pefin_detalhe.pefin_avalista}}</td>
              <td>{{dados.credito_plus.pefin.pefin_detalhe.pefin_contrato}}</td>
              <td>{{dados.credito_plus.pefin.pefin_detalhe.pefin_data_ocorrencia}}</td>
              <td>{{dados.credito_plus.pefin.pefin_detalhe.pefin_origem}}</td>
              <td>{{dados.credito_plus.pefin.pefin_detalhe.pefin_valor_pendencia}}</td>
            </tr>
          </ng-container>
          
        </tbody>
      </table>
    </div>

    <div class="row"  *ngIf="dados.credito_plus.pefin_bvs && dados.credito_plus.pefin_bvs.detalhe_pefin">
      <h6 class="titulo">PFIN Indicador II</h6>
      <div class="item col-auto">
        <label>Quantidade</label>
        {{dados.credito_plus.pefin_bvs.detalhe_pefin.resumo_pefin.quantidade}}
      </div>
      <div class="item col-auto">
        <label>Valor </label>
        {{dados.credito_plus.pefin_bvs.detalhe_pefin.resumo_pefin.valor}}
      </div> 
      <div class="item col-auto">
        <label>Última Ocorrência</label>
        {{dados.credito_plus.pefin_bvs.detalhe_pefin.resumo_pefin.data_ultima_ocorrencia}}
      </div>     
      <h6 class="titulo">PFIN Indicador II Detalhe</h6>   
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Tipo Débito</th>
            <th>Contrato</th>
            <th>Data Ocorrência</th>
            <th>Data Disp.</th>
            <th>Informante</th>
            <th>Consulente</th>
            <th>Cidade</th>
            <th>UF</th>
            <th>Tipo Moeda</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dados.credito_plus.pefin_bvs.detalhe_pefin.registro.length">
            <tr *ngFor="let pefin of dados.credito_plus.pefin_bvs.detalhe_pefin.registro">
              <td>{{pefin.tipo_debito}}</td>
              <td>{{pefin.contrato}}</td>
              <td>{{pefin.data_ocorrencia}}</td>
              <td>{{pefin.data_disponibilizacao}}</td>
              <td>{{pefin.informante}}</td>
              <td>{{pefin.consulente}}</td>
              <td>{{pefin.cidade}}</td>
              <td>{{pefin.uf}}</td>
              <td>{{pefin.tipo_moeda}}</td>
              <td>{{pefin.valor}}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!dados.credito_plus.pefin_bvs.detalhe_pefin.registro.length">
            <tr>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.tipo_debito}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.contrato}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.data_ocorrencia}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.data_disponibilizacao}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.informante}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.consulente}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.cidade}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.uf}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.tipo_moeda}}</td>
              <td>{{dados.credito_plus.pefin_bvs.detalhe_pefin.registro.valor}}</td>
            </tr>
          </ng-container>
          
        </tbody>
      </table>
    </div>


    <div class="row" *ngIf="dados.credito_plus.ieptb && !dados.credito_plus.ieptb.mensagem">
      <h6 class="titulo">Protestos Nacionais (IEPTB)</h6>
      
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>UF</th>
            <th>Cartório</th>
            <th>Telefone</th>
            <th>Qtd</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dados.credito_plus.ieptb.registro.length">
            <ng-container *ngFor="let protesto of dados.credito_plus.ieptb.registro">
              <ng-container *ngIf="protesto.cartorios.length">
                <tr *ngFor="let cartorio of protesto.cartorios">
                  <td>{{protesto.uf}}</td>
                  <td>{{cartorio.descricao}}</td>
                  <td>{{cartorio.telefone}}</td>
                  <td>{{cartorio.quantidade}}</td>
                  <td><a href="javascript:void(0)" class="text-danger" (click)="abreProtestos(cartorio.protesto,templateProtestos)"><i class="fas fa-gavel"></i></a></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!protesto.cartorios.length">
                  <tr>
                    <td>{{protesto.uf}}</td>
                    <td>{{protesto.cartorios.descricao}}</td>
                    <td>{{protesto.cartorios.telefone}}</td>
                    <td>{{protesto.cartorios.quantidade}}</td>
                    <td><a href="javascript:void(0)" class="text-danger" (click)="abreProtestos(protesto.cartorios.protesto,templateProtestos)"><i class="fas fa-gavel"></i></a></td>
                  </tr>
                </ng-container>
            </ng-container>
          </ng-container>
          
          <ng-container *ngIf="!dados.credito_plus.ieptb.registro.length">
            <ng-container *ngIf="dados.credito_plus.ieptb.registro.cartorios.length">
              <tr *ngFor="let cartorio of dados.credito_plus.ieptb.registro.cartorios">
                <td>{{dados.credito_plus.ieptb.registro.uf}}</td>
                <td>{{cartorio.descricao}}</td>
                <td>{{cartorio.telefone}}</td>
                <td>{{cartorio.quantidade}}</td>
                <td><a href="javascript:void(0)" class="text-danger" (click)="abreProtestos(cartorio.protesto,templateProtestos)"><i class="fas fa-gavel"></i></a></td>
              </tr>
            </ng-container>
            <ng-container *ngIf="!dados.credito_plus.ieptb.registro.cartorios.length">
              <tr>
                <td>{{dados.credito_plus.ieptb.registro.uf}}</td>
                <td>{{dados.credito_plus.ieptb.registro.cartorios.descricao}}</td>
                <td>{{dados.credito_plus.ieptb.registro.cartorios.telefone}}</td>
                <td>{{dados.credito_plus.ieptb.registro.cartorios.quantidade}}</td>
                <td><a href="javascript:void(0)" class="text-danger" (click)="abreProtestos(dados.credito_plus.ieptb.registro.cartorios.protesto,templateProtestos)"><i class="fas fa-gavel"></i></a></td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>


  <div class="row" *ngIf="dados.credito_plus.protesto && !dados.credito_plus.protesto.mensagem">
      <h6 class="titulo">Protestos Estaduais</h6>
      <div class="item col-auto">
        <label>Quantidade</label>
        {{dados.credito_plus.protesto.protesto_quantidade}}
      </div>
      <div class="item col-auto">
        <label>Valor </label>
        {{dados.credito_plus.protesto.protesto_valor_total}}
      </div> 
      <div class="item col-auto">
        <label>Primeira Ocorrência</label>
        {{dados.credito_plus.protesto.protesto_data_primeira_ocorrencia}}
      </div>
      <div class="item col-auto">
        <label>Última Ocorrência</label>
        {{dados.credito_plus.protesto.protesto_data_ultima_ocorrencia}}
      </div>
      <h6 class="titulo">Protestos Estaduais Detalhe</h6>  
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Data</th>
            <th>Cartório</th>
            <th>Cidade</th>
            <th>UF</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dados.credito_plus.protesto.protesto_detalhe.length">
            <tr *ngFor="let protesto of dados.credito_plus.protesto.protesto_detalhe">
              <td>{{protesto.protesto_data}}</td>
              <td>{{protesto.protesto_nome_cartorio}}</td>
              <td>{{protesto.protesto_cidade_cartorio}}</td>
              <td>{{protesto.protesto_uf_cartorio}}</td>
              <td>{{protesto.protesto_valor}}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!dados.credito_plus.protesto.protesto_detalhe.length">
            <tr>
              <td>{{dados.credito_plus.protesto.protesto_detalhe.protesto_data}}</td>
              <td>{{dados.credito_plus.protesto.protesto_detalhe.protesto_nome_cartorio}}</td>
              <td>{{dados.credito_plus.protesto.protesto_detalhe.protesto_cidade_cartorio}}</td>
              <td>{{dados.credito_plus.protesto.protesto_detalhe.protesto_uf_cartorio}}</td>
              <td>{{dados.credito_plus.protesto.protesto_detalhe.protesto_valor}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>


    <div class="row" *ngIf="dados.credito_plus.consulta_realizada && !dados.credito_plus.consulta_realizada.mensagem">
      <h6 class="titulo">Consultas realizadas</h6>
      <div class="item col-auto">
        <label>Quantidade</label>
        {{dados.credito_plus.consulta_realizada.quantidade}}
      </div>
      
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Data</th>
            <th>Nome Associado</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="dados.credito_plus.consulta_realizada.registro.length">
            <tr *ngFor="let consulta of dados.credito_plus.consulta_realizada.registro">
              <td>{{consulta.data_consulta}}</td>
              <td>{{consulta.nome_associado}}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!dados.credito_plus.consulta_realizada.registro.length">
            <tr>
              <td>{{dados.credito_plus.consulta_realizada.registro.data_consulta}}</td>
              <td>{{dados.credito_plus.consulta_realizada.registro.nome_associado}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div> -->



</div>
