<div class="bloco">
    <h5 class="titulo">Possíveis Imóveis</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
          <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
          <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.imovel">
      <div class="row">
          <table class="table table-sm ">
              <thead>
                <tr>
                  <th>Metragem Terreno</th>
                  <th>Valor Estimado</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dados.imovel">
                  <td>{{row.metragem_terreno}}</td>
                  <td>{{row.valor_estimado | currency:'BRL':true}}</td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>