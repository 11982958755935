import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mascara'
})
export class MascaraPipe implements PipeTransform {

  transform(value: any, tipo: any): any {
    if(tipo =='cpf'){
      return this.formataCpf(value);
    }else if(tipo == 'cnpj'){
      return this.formataCnpj(value);
    }else if(tipo == 'cep'){
      return this.formataCep(value);
    }

    return value;
  }

  pad(num:string, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  formataCpf(value:any):any{
    value = this.pad(value,11);
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    return value;
  }

  formataCnpj(value:any):any{
    value = this.pad(value,14);
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  formataCep(value:any):any{
    value = this.pad(value,8);
    return value.replace(/^(\d{5})(\d{3})/, "$1-$2");
  }

  //"14397462000109".replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")

}
