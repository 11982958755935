import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estudos-agenciaas',
  templateUrl: './estudos-agenciaas.component.html',
  styleUrls: ['./estudos-agenciaas.component.css']
})
export class EstudosAgenciaasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
