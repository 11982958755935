<section class="page_banner">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h1>Gestão de Campanhas</h1>
          <div class="sub_heading_page">Alto nível de alcance e assertividade</div>
        </div>
      </div>
    </div>
  </section>  
    
  <section class="safe_host">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="headings black_text">
            <h3>Gestão de Campanhas</h3>
          </div>
        </div>
        <div class="pr_info">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="info_right">
                <p class="text-left">
                    Alcance alto nível de comunicação com essa solução desenvolvida sob medida para atingir usuários de smartphones e celulares. Esse método tem o maior nível de conversão, pois entrega diretamente a informação ao público alvo, podendo conter diversas informações e ser usado para várias finalidades, como prospecção, publicidade, propaganda, dentre outros fins. As possibilidades são infinitas!
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <app-formContato text="Entre em contato conosco"></app-formContato>