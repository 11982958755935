/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pj/cadastral-pj.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../mascara.pipe";
import * as i4 from "./historico-razao-social-pj.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_HistoricoRazaoSocialPjComponent = [i0.styles];
var RenderType_HistoricoRazaoSocialPjComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HistoricoRazaoSocialPjComponent, data: {} });
export { RenderType_HistoricoRazaoSocialPjComponent as RenderType_HistoricoRazaoSocialPjComponent };
function View_HistoricoRazaoSocialPjComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_HistoricoRazaoSocialPjComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_HistoricoRazaoSocialPjComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.cnpj, "cnpj")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.ultima_razao_social; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.data_modificacao; _ck(_v, 7, 0, currVal_2); }); }
function View_HistoricoRazaoSocialPjComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CNPJ"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00DAltima raz\u00E3o social"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Data de modifica\u00E7\u00E3o"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoricoRazaoSocialPjComponent_4)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.historico_razao_social; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_HistoricoRazaoSocialPjComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.MascaraPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hist\u00F3rico de Raz\u00E3o Social"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoricoRazaoSocialPjComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoricoRazaoSocialPjComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HistoricoRazaoSocialPjComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.dados.historico_razao_social; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_HistoricoRazaoSocialPjComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-historico-razao-social-pj", [], null, null, null, View_HistoricoRazaoSocialPjComponent_0, RenderType_HistoricoRazaoSocialPjComponent)), i1.ɵdid(1, 114688, null, 0, i4.HistoricoRazaoSocialPjComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HistoricoRazaoSocialPjComponentNgFactory = i1.ɵccf("app-historico-razao-social-pj", i4.HistoricoRazaoSocialPjComponent, View_HistoricoRazaoSocialPjComponent_Host_0, { dados: "dados" }, {}, []);
export { HistoricoRazaoSocialPjComponentNgFactory as HistoricoRazaoSocialPjComponentNgFactory };
