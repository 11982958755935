import { Component, OnInit } from '@angular/core';
import { ApiServices } from 'src/app/apiServices.service';
import { URL_API } from 'src/app/app.api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-posicao-billing',
  templateUrl: './posicao-billing.component.html',
  styleUrls: ['./posicao-billing.component.css'],
  providers:[ApiServices]
})
export class PosicaoBillingComponent implements OnInit {

  public usuario:any = JSON.parse(localStorage.getItem('usuarioLogadoSite'));

  constructor(private api:ApiServices,private router:Router) { }

  ngOnInit() {
    console.log(this.usuario.id);
    this.api.get(`${URL_API}/api/user/${this.usuario.id}?populate=client`)
    .then(r=>{
      if(r.client.contactName != 'FutureData'){
        this.router.navigate(['/home'])
      }
      
    });
  }

}
