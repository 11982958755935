import { OnInit } from '@angular/core';
var ɵ0 = {
    interval: 8000,
    noPause: true,
    ShowIndicators: true
};
var CarouselComponent = /** @class */ (function () {
    function CarouselComponent() {
    }
    CarouselComponent.prototype.ngOnInit = function () {
    };
    return CarouselComponent;
}());
export { CarouselComponent };
export { ɵ0 };
