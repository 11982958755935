<div class="">
    <div class="login_form">
      <form [formGroup]="formAcesso" novalidate (ngSubmit)="salvarSenha()">
      <div class="login_header bgamarelo">Troca de senha</div>
      <fieldset>
        <div class="row">
          <div class="section msg col-12 {{msgAcessoClass}}" *ngIf="msgAcesso!=''">
            <span [innerHTML]="msgAcesso"></span>
          </div>
            <div class="section col-md-4 col-xs-12">
              <label class="input">
                <input placeholder="Senha Atual" type="password" autocomplete="false" formControlName="pass">
              </label>
          </div>
          <div class="section col-md-4 col-xs-12">
            <label class="input">
              <input placeholder="Nova Senha" type="password" autocomplete="false" formControlName="newpass">
            </label>
          </div>
          <div class="section col-md-4 col-xs-12">
            <label class="input">
              <input placeholder="Confirme a nova senha" type="password" autocomplete="false" formControlName="newpassConf">
            </label>
          </div>
        </div>
      </fieldset>
      <div class="login_footer">
        <button type="submit" class="btn btn-lg btn-primary" [disabled]="enviandoAcesso">{{txtSubmitAcesso}}</button>
      </div>
    </form> 
    </div>

    <div class="login_form">
      <form [formGroup]="formCadastro" novalidate (ngSubmit)="salvarCadastro()">
      <div class="login_header bgamarelo">Cadastro</div>
      <fieldset>
        <div class="row">
          <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
            <span [innerHTML]="msgCadastro"></span>
          </div>
          

          <div class="section col-md-6 col-sm-6 col-xs-12">
            <label class="input">
              <label>Nome (Usuário da conta)</label>
              <input placeholder="Nome" type="text" formControlName="name" />
            </label>
          </div>
          <div class="section col-md-6 col-sm-6 col-xs-12">
            <label class="input">
              <label>E-mail (Usuário da conta)</label>
              <input placeholder="E-mail" type="email" autocomplete="false" formControlName="email">
            </label>
          </div>
          <ng-container *ngIf="dadosUsuario.isAdm">
            <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>Documento</label>
                <input placeholder="CPF ou CNPJ" type="text" autocomplete="false" formControlName="client.document">
              </label>
            </div>

            <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>Razão Social</label>
                <input placeholder="" type="text" autocomplete="false" formControlName="client.companyName">
              </label>
            </div>
            <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>E-mail de contato</label>
                <input placeholder="" type="text" autocomplete="false" formControlName="client.email">
              </label>
            </div>
            <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>Nome de contato</label>
                <input placeholder="" type="text" autocomplete="false" formControlName="client.contactName">
              </label>
            </div>
            <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>Telefone de contato</label>
                <input placeholder="" mask='(00) 00009-0000' type="text" autocomplete="false" formControlName="client.phone">
              </label>
            </div>
          </ng-container>
          <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>Cadastrado em</label>
                <input type="text" readonly value="{{dadosUsuario.createAt | date:'dd/MM/yyyy HH:mm:ss'}}"/>
              </label>
            </div>
            <div class="section col-md-6 col-sm-6 col-xs-12">
              <label class="input">
                <label>Ativado em</label>
                <input type="text" readonly value="{{dadosUsuario.activatedAt | date:'dd/MM/yyyy HH:mm:ss'}}"/>
              </label>
            </div>
        </div>
      </fieldset>
      <div class="login_footer">
        <button type="submit" class="btn btn-lg btn-primary" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
      </div>
    </form> 
    </div>
  </div>