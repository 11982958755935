/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contato.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/form-contato/formContato.component.ngfactory";
import * as i3 from "../apiServices.service";
import * as i4 from "@angular/http";
import * as i5 from "@angular/router";
import * as i6 from "../shared/form-contato/formContato.component";
import * as i7 from "ngx-captcha";
import * as i8 from "@angular/forms";
import * as i9 from "./contato.component";
var styles_ContatoComponent = [i0.styles];
var RenderType_ContatoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContatoComponent, data: {} });
export { RenderType_ContatoComponent as RenderType_ContatoComponent };
export function View_ContatoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "page_banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-12 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fale conosco"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "sub_heading_page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Entre em contato com a nossa equipe"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-formContato", [["text", "Entre em contato conosco"]], null, null, null, i2.View_FormContatoComponent_0, i2.RenderType_FormContatoComponent)), i1.ɵprd(512, null, i3.ApiServices, i3.ApiServices, [i4.Http, i5.Router]), i1.ɵdid(10, 114688, null, 0, i6.FormContatoComponent, [i7.ReCaptchaV3Service, i8.FormBuilder, i3.ApiServices], { text: [0, "text"] }, null)], function (_ck, _v) { var currVal_0 = "Entre em contato conosco"; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_ContatoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contato", [], null, null, null, View_ContatoComponent_0, RenderType_ContatoComponent)), i1.ɵdid(1, 114688, null, 0, i9.ContatoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContatoComponentNgFactory = i1.ɵccf("app-contato", i9.ContatoComponent, View_ContatoComponent_Host_0, {}, {}, []);
export { ContatoComponentNgFactory as ContatoComponentNgFactory };
