/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integracao.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../safe.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./integracao.component";
var styles_IntegracaoComponent = [i0.styles];
var RenderType_IntegracaoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegracaoComponent, data: {} });
export { RenderType_IntegracaoComponent as RenderType_IntegracaoComponent };
export function View_IntegracaoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A integra\u00E7\u00E3o por API funcionar\u00E1 com suas credenciais de acesso ao portal. Clique no bot\u00E3o abaixo para baixar o manual.\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "btn btn-lg btn-info"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-book"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Manual de Integra\u00E7\u00E3o de API"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.UrlDocumentacao, "resourceUrl")); _ck(_v, 4, 0, currVal_0); }); }
export function View_IntegracaoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-integracao", [], null, null, null, View_IntegracaoComponent_0, RenderType_IntegracaoComponent)), i1.ɵdid(1, 114688, null, 0, i4.IntegracaoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegracaoComponentNgFactory = i1.ɵccf("app-integracao", i4.IntegracaoComponent, View_IntegracaoComponent_Host_0, { dadosUsuario: "dadosUsuario" }, {}, []);
export { IntegracaoComponentNgFactory as IntegracaoComponentNgFactory };
