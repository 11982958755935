import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestao-de-campanhas',
  templateUrl: './gestao-de-campanhas.component.html',
  styleUrls: ['./gestao-de-campanhas.component.css']
})
export class GestaoDeCampanhasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
