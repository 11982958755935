import { Component, OnInit, Input } from '@angular/core';
import { URL_API } from 'src/app/app.api';

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.css']
})
export class IntegracaoComponent implements OnInit {

  @Input() dadosUsuario:any;
  public UrlDocumentacao:string;
  constructor() { }

  ngOnInit() {
    this.UrlDocumentacao = 'http://integracao.futuredata.com.br';
  }

}
