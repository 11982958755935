import { OnInit, Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'app-grupo-empresarial-n1-pj',
    templateUrl: './grupo-empresarial-n1-pj.component.html',
    styleUrls: ['./../cadastral-pj/cadastral-pj.component.css']
})

export class GrupoEmpresarialN1PjComponent implements OnInit {

    @Input() dados:any;

    public txtButtonConsultar:string='Consultar agora';
    public consultando:Boolean=false;
    public naoSolicitou:Boolean=false;
    public naoEncontrouRegistros:Boolean=false;
    public modalRef:BsModalRef;

    constructor(private modalService:BsModalService) {}

    ngOnInit(): void {
        
        if(this.dados.grupo_empresarial_n1 === undefined){
            this.naoSolicitou = true;
        } else if(this.dados.grupo_empresarial_n1 === null) {
            this.naoEncontrouRegistros = true;
        }
    }
}