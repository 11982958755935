import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cadastral-pf',
  templateUrl: './cadastral-pf.component.html',
  styleUrls: ['./cadastral-pf.component.css']
})
export class CadastralPfComponent implements OnInit {

  constructor() { }
  @Input() dados:any;

  ngOnInit() {
    
  }

}
