<section class="page_banner">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h1>Prevenção à Fraude e compliance</h1>
          <div class="sub_heading_page"></div>
        </div>
      </div>
    </div>
  </section>
    
  <section class="safe_host">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="headings black_text">
            <h3>Prevenção à Fraude e Compliance</h3>
          </div>
        </div>
        <div class="pr_info">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="info_right text-left">
              <p>
                  Nossa solução entrega informações customizáveis para prevenção à fraudes com base nos dados (cadastrais, biometria, similaridade textual, dentre outros) e inferência. Essa solução pode ser aplicada a vários fins, como por exemplo: controle de acesso, reconhecimento de padrões, padrões de segurança, instituições financeiras e muito mais.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <app-formContato text="Entre em contato conosco"></app-formContato>