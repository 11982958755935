import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { TOKEN_NAME, URL_API } from './app.api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "@angular/router";
var LoginService = /** @class */ (function () {
    function LoginService(http, router) {
        this.http = http;
        this.router = router;
    }
    LoginService.prototype.logar = function (email, senha) {
        var _this = this;
        var payload = {
            email: email,
            pass: senha
        };
        var headers = new Headers();
        headers.append('x-site', '1');
        return this.http.post(URL_API + "/auth", payload, { headers: headers })
            .toPromise()
            .then(function (resposta) {
            var _json = resposta.json();
            if (_json.success) {
                localStorage.setItem(TOKEN_NAME, _json.token);
                return _this.validateToken();
            }
            else {
                return _json;
            }
        });
    };
    LoginService.prototype.autenticado = function () {
        if (localStorage.getItem(TOKEN_NAME) !== null) {
            this.validateToken();
            return true;
        }
        else {
            this.router.navigate(['/painel']);
        }
        return false;
    };
    LoginService.prototype.validateToken = function () {
        var _this = this;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', localStorage.getItem(TOKEN_NAME));
        return this.http.get(URL_API + "/api/validateToken", { headers: headers })
            .toPromise()
            .then(function (resposta) {
            var _json = resposta.json();
            if (_json.success) {
                localStorage.setItem('usuarioLogadoSite', JSON.stringify(_json.tokenDecoded));
            }
            else {
                _this.logout();
            }
            return _json;
        })
            .catch(function (e) {
            _this.logout();
        });
    };
    LoginService.prototype.logout = function () {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem('usuarioLogadoSite');
        this.router.navigate(['/painel']);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.Http), i0.inject(i2.Router)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
