var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { ApiServices } from "src/app/apiServices.service";
import { URL_API_V2 } from "src/app/app.api";
var RelatoriosComponent = /** @class */ (function () {
    function RelatoriosComponent(api, router, modalService) {
        this.api = api;
        this.router = router;
        this.modalService = modalService;
        this.anoAtual = new Date().getFullYear();
        this.mesAtual = new Date().getMonth() + 1;
        this.periodoAtualMaisUsadas = "1-today";
        this.rangeAno = [new Date().getFullYear() - 1, new Date().getFullYear()];
        this.rangeMes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.maisUsadasPeriodos = [
            { label: "Hoje", value: "1-today" },
            { label: "Última hora", value: "1-hours" },
            { label: "Últimas 2 horas", value: "2-hours" },
            { label: "Últimas 6 horas", value: "6-hours" },
            { label: "Últimas 12 horas", value: "12-hours" },
            { label: "Últimas 24 horas", value: "1-days" },
            { label: "Últimos 7 dias", value: "7-days" },
            { label: "Últimos 15 dias", value: "15-days" },
            { label: "Últimos 30 dias", value: "30-days" },
        ];
        this.totalFaturamento = 0;
        this.faturas = [];
        this.maisUsadas = [];
        this.maisUsadasFiltrados = [];
        this.carregandoFaturas = false;
        this.carregandoMaisUsadas = false;
        this.sort = "total";
        this.order = "desc";
        this.columns = [
            {
                label: "Consulta",
                field: "queryName",
            },
            {
                label: "Fornecedor",
                field: "supplier",
            },
            {
                label: "Sucessos",
                field: "success",
            },
            {
                label: "Erros",
                field: "errors",
            },
            {
                label: "Total",
                field: "total",
            },
            {
                label: "Tempo médio",
                field: "avgTime",
            },
            {
                label: "Assertividade %",
                field: "accuracy",
            },
        ];
    }
    RelatoriosComponent.prototype.ngOnInit = function () {
        this.carregarFaturamento();
        this.carregarConsultasMaisUsadas();
    };
    RelatoriosComponent.prototype.carregarFaturamento = function () {
        return __awaiter(this, void 0, void 0, function () {
            var reference, billing, filtered, total;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.carregandoFaturas = true;
                        reference = this.anoAtual + "-" + this.mesAtual
                            .toString()
                            .padStart(2, "0");
                        return [4 /*yield*/, this.api.get(URL_API_V2 + "/panel/finances/billing/" + reference)];
                    case 1:
                        billing = _a.sent();
                        filtered = billing.filter(function (row) { return row.total < 0; });
                        total = filtered
                            .map(function (row) { return row.total; })
                            .reduce(function (a, v) {
                            return a + v;
                        });
                        this.totalFaturamento = parseFloat(Math.abs(total).toFixed(2));
                        this.faturas = filtered;
                        this.carregandoFaturas = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    RelatoriosComponent.prototype.changeSort = function (sort, order) {
        if (sort === void 0) { sort = "total"; }
        if (order === void 0) { order = "asc"; }
        if (this.isSorted(sort)) {
            this.order = this.order === 'asc' ? 'desc' : 'asc';
        }
        else {
            this.order = order;
        }
        this.sort = sort;
        this.carregarConsultasMaisUsadas();
    };
    RelatoriosComponent.prototype.carregarConsultasMaisUsadas = function () {
        return __awaiter(this, void 0, void 0, function () {
            var parsePeriodo, qtd, frequencia, mostUseds;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.carregandoMaisUsadas = true;
                        parsePeriodo = this.periodoAtualMaisUsadas.split("-");
                        qtd = parsePeriodo[0], frequencia = parsePeriodo[1];
                        return [4 /*yield*/, this.api.get(URL_API_V2 + "/panel/log-query/mosts-used/last/" + qtd + "/" + frequencia + "?sort=" + this.sort + "&order=" + this.order)];
                    case 1:
                        mostUseds = _a.sent();
                        // const mostUseds = await this.api.get(`${URL_API_V2}/panel/log-query/mosts-used/last/${qtd}/${frequencia}`);
                        this.maisUsadas = mostUseds.rows;
                        this.maisUsadasFiltrados = this.maisUsadas;
                        this.carregandoMaisUsadas = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    RelatoriosComponent.prototype.isSorted = function (field) {
        return this.sort === field;
    };
    RelatoriosComponent.prototype.filterMaisUsadas = function (e) {
        var _this = this;
        var value = e.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        this.maisUsadasFiltrados = this.maisUsadas.filter(function (e) {
            for (var _i = 0, _a = _this.columns; _i < _a.length; _i++) {
                var c = _a[_i];
                if (e[c.field]) {
                    var valueField = e[c.field].toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                    if (valueField.indexOf(value) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        });
    };
    RelatoriosComponent.prototype.isSortedAsc = function (field) {
        if (this.sort === field) {
            return this.order === 'asc' ? 'sorted' : '';
        }
        return '';
    };
    RelatoriosComponent.prototype.isSortedDesc = function (field) {
        if (this.sort === field) {
            return this.order === 'desc' ? 'sorted' : '';
        }
        return '';
    };
    return RelatoriosComponent;
}());
export { RelatoriosComponent };
