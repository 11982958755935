export class Invoice {
    public _id?: String;
    public reference?: String;
    public billing?: InvoiceBilling[];
    public totalBilling?: Number;
    public createAt?: Date;
    public dueAt?: Date;
    public deleted?: Boolean;
    public paid?: Boolean;
    public closed?:Boolean;
    constructor(private obj: any) {
        Object.keys(obj).map(v => {
            this[v] = obj[v];
        });
    }

    public getCompetencia(): String {
        const competencia = this.reference.split('-');
        // tslint:disable-next-line: radix
        if (parseInt(competencia[1]) === new Date().getMonth() + 1 && parseInt(competencia[0]) === new Date().getFullYear()) {
            return `Atual`;
        } else {
            return `${competencia[1]}/${competencia[0]}`;
        }
    }

    public getCompetenciaMes(): String {
        const competencia = this.reference.split('-');
        return `${competencia[1]}/${competencia[0]}`;
    }

    public getSituacao(): Object {
        let situacao = 'Fechada';
        let badge = 'bg-warning';
        const dueAt = new Date(this.dueAt);
        const now = new Date();
        if (dueAt < now) {
            situacao = 'Vencida';
            badge = 'bg-danger';
            if (this.paid) {
                situacao = 'Paga';
                badge = 'bg-primary';
            }
        } else if (dueAt > now) {
            situacao = 'Aberta';
            badge = 'bg-success';
        }
        // console.log('=======================================');
        // console.log(this.reference, new Date(this.dueAt), now);
        // console.log('\n');
        
        
        return {situacao, badge};
    }
}

 export class InvoiceBilling {
    public _id?: String;
    public queryName?: String;
    public queryPrice?: Number;
    public queryId?: String;
    public count?: Number;
    public totalPrice?: Number;
}