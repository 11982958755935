<div class="bloco">
  <h5 class="titulo">Bloqueios</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.Bloqueios.qtdBloqueios === '0'">
    <div class="row">
      <div class="col-auto item">Nenhum bloqueio encontrado</div>
    </div>
  </div>
  <div *ngIf="dados.Bloqueios.qtdBloqueios > 0">
    <div class="row">
        <table class="table table-sm ">
            <thead>
              <tr>
                <th>Data bloqueio</th>
                <th>Início penalidade</th>
                <th>Fim penalidade</th>
                <th>Doc. gerador</th>
                <th>Órgão resp.</th>
                <th>Recolhimento CNH</th>
                <th>UF Detran liberação</th>
                <th>Descrição</th>
                <th>Motivo</th>
                <th>Tipo decisão</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.Bloqueios.registro">
                <td>{{row.DataBloqueio}}</td>
                <td>{{row.DataInicioPenalidade}}</td>
                <td>{{row.DataFimPenalidade}}</td>
                <td>{{row.DocumentoGerador}}</td>
                <td>{{row.OrgaoResponsavel}}</td>
                <td>{{row.Recolhimento_CNH}}</td>
                <td>{{row.UF_Detran_Liberacao}}</td>
                <td>{{row.Descricao}}</td>
                <td>{{row.Motivo}}</td>
                <td>{{row.Tipo_Decisao}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>
  
  