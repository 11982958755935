<div class="" *ngIf="carregandoTela">
  Aguarde...
</div>
<div class="" *ngIf="showResultado">
  <div class="botoes d-flex justify-content-end noprint">
    <button class="btn btn-primary" (click)="voltar()"><i class="fas fa-search"></i> Nova consulta</button>
    <button class="btn btn-secondary" (click)="voltarHistorico()"><i class="fas fa-history"></i> Histórico
      consultas</button>
    <button class="btn btn-info" (click)="print();"><i class="fas fa-print"></i> Imprimir</button>
  </div>
  <div class="" *ngIf="carregandoResultado">
    Carregando o resultado da consulta
  </div>
  <div class="resultadoBusca mb-3" id="resultadoConsulta" *ngIf="!carregandoResultado">
    <ng-container *ngIf="consulta.inProcessing">
      <div class="bloco">
        <h5 class="titulo">Informações da Consulta</h5>
        <div class="row">
          <div class="item col-auto">
            <label>Pesquisou por</label>
            {{consulta.input | inputConsulta}}
          </div>
        </div>
        <div class="row">
          <div class="item col-auto" style="font-size: 18px;">
            Sua consulta está sendo processada. <br />
            Aguarde o recarregamento automático ou <a href="javascript:void(0)" (click)="getResultadoConsulta()" style="font-weight: bold;">clique aqui</a> para verificar se o resultado já foi processado
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!consulta.inProcessing">

      <div class="bloco">
        <h5 class="titulo">Informações da Consulta</h5>

        <div class="row">
          <div class="item col-auto">
            <label>Pesquisou por</label>
            {{consulta.input | inputConsulta}}
          </div>
        </div>
        <div class="row">
          <div class="item col-auto">
            <label>ID</label>
            {{consulta._id}}
          </div>

          <div class="item col-auto">
            <label>Segmento</label>
            CNH
          </div>
          <div class="item col-auto">
            <label>Data Início</label>
            {{consulta.startAt | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <div class="item col-auto">
            <label>Data Fim</label>
            {{consulta.endAt | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <div class="item col-auto">
            <label>Tempo total</label>
            {{consulta.time/1000}}
          </div>
          <div class="item col-auto">
            <label>Solicitante</label>
            {{consulta.user.name}}
          </div>
        </div>
      </div>

      <ng-container>
        <div class="bloco">
          <h5 class="titulo">Dados do condutor</h5>
          
          <div>
            <div class="row">
              <div class="item col-auto">
                <label>Nome</label>
                {{consulta.output.dados.nome || 'Não informado'}}
              </div>
        
              <div class="item col-auto">
                <label>CPF</label>
                {{consulta.output.dados.num_cpf || 'Não informado'}}
              </div>
        
              <div class="item col-auto">
                <label>Data de nascimento</label>
                {{consulta.output.dados.data_nascimento || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Cidade</label>
                {{consulta.output.dados.municipio || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>UF</label>
                {{consulta.output.dados.identidade_estado || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Nome da mãe</label>
                {{consulta.output.dados.nome_mae || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Nome do pai</label>
                {{consulta.output.dados.nome_pai || 'Não informado'}}
              </div>
            </div>
          </div>
        </div>

        <div class="bloco">
          <h5 class="titulo">Dados da CNH</h5>
          
          <div>
            <div class="row">
              <div class="item col-auto">
                <label>Número CNH</label>
                {{consulta.output.dados.num_cnh || 'Não informado'}}
              </div>
        
              <div class="item col-auto">
                <label>Categoria</label>
                {{consulta.output.dados.categoria || 'Não informado'}}
              </div>
        
              <div class="item col-auto">
                <label>Data primeira habilitação</label>
                {{consulta.output.dados.data_primeira_habilitacao || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Data validade</label>
                {{consulta.output.dados.data_validade || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Multas</label>
                {{consulta.output.dados.multas || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Pontos</label>
                {{consulta.output.dados.pontos || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Bloqueio</label>
                {{consulta.output.dados.bloqueio || 'Não informado'}}
              </div>
              <div class="item col-auto">
                <label>Observações</label>
                {{consulta.output.dados.observacoes || 'Não informado'}}
              </div>
            </div>
          </div>
        </div>
          
<!--           
        <app-dados-cnh [dados]="consulta.output.dados"></app-dados-cnh>
        <app-pontuacao-cnh [dados]="consulta.output.dados"></app-pontuacao-cnh>
        <app-bloqueios-cnh [dados]="consulta.output.dados"></app-bloqueios-cnh>
        <app-infracoes-cnh [dados]="consulta.output.dados"></app-infracoes-cnh>
        <app-exames-cnh [dados]="consulta.output.dados"></app-exames-cnh> -->
      </ng-container>
    </ng-container>
  </div>

</div>
<div class="" *ngIf="showBusca">
  <div class="login_form">
    <!-- <button (click)="getPermissoesCliente()">Carrega permissões</button> -->
    <div class="" *ngIf="carregandoPermissoes">Carregando permissões de acesso</div>
    <div class="" *ngIf="!carregandoPermissoes">

      <div class="clearfix"></div>
      <div class="col-auto">
        <label class="input campoBusca">
          <input class="inputpf" *ngIf="segmentoSelecionado=='pf'" placeholder="Digite o CPF para consultar a CNH"
            type="text" autocomplete="false" [(ngModel)]="palavraChave" (blur)="validaPalavraChave()">
        </label>
        <div class="alert-danger msgPalavraChave" *ngIf="msgPalavraChave!=''"><i
            class="fas fa-exclamation-triangle"></i> &nbsp; {{msgPalavraChave}}</div>
      </div>




      <div class="col-12 d-flex justify-content-around">
        <button class="btn btn-lg btn-danger btn-block btn-consultar" (click)="enviar()" [disabled]="isBlockedSubmit()"
          type="button">
          <i class="fas fa-chevron-circle-right"></i> {{txtSubmit}}
        </button>
      </div>

      <div class="col-12 " *ngIf="msg!=''">
        <div class="col-12 section msg {{msgClass}}">
          <span [innerHTML]="msg"></span>
        </div>
      </div>


    </div>
  </div>
</div>