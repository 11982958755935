<div class="row">
  <div class="col-12">
    <div class="login_form">
      <fieldset>
        
          <ng-container *ngIf="showForm">
            <form [formGroup]="formCadastro" novalidate (ngSubmit)="salvarCadastro()">
              <div class="row d-flex justify-content-end">
                  <button class="btn btn-warning" (click)="fechaForm()" tooltip="Voltar para listagem">Voltar</button>
              </div>
              <div class="row my-3">
                <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
                  <span [innerHTML]="msgCadastro"></span>
                </div>    
                <div class="section col-6">
                  <label class="input">
                    <label>Nome</label>
                    <input placeholder="Nome" type="text" formControlName="name" />
                  </label>
                </div>
                <div class="section col-6">
                  <label class="input">
                    <label>E-mail</label>
                    <input placeholder="E-mail" type="email" autocomplete="false" formControlName="email">
                  </label>
                </div>
                <div class="section col-6">
                  <label class="input">
                    <label>Senha</label>
                    <input placeholder="Senha" type="password" autocomplete="false" formControlName="pass" />
                  </label>
                </div>
                <div class="section col-6">
                  <label class="input">
                    <label>Confirme Senha</label>
                    <input placeholder="Confirme Senha" type="password" autocomplete="false" formControlName="passConfirm">
                  </label>
                </div>
                
              </div>
              <div class="row d-flex justify-content-end">
                <button class="btn btn-success" type="submit" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="showLista">
            <div class="row d-flex justify-content-end">
                <button class="btn btn-success" (click)="abreForm()" tooltip="Criar usuário"><i class="fas fa-plus"></i> Criar</button>
            </div>
            <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
              <span [innerHTML]="msgCadastro"></span>
            </div>
            <div class="row">    
              <table class="table">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Status</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr *ngFor="let usuario of usuarios">
                      <ng-container *ngIf="!usuario.editando">
                        <td>{{usuario.name}} <span *ngIf="usuario.isAdm" class="badge bg-primary">Adm</span></td>
                        <td>{{usuario.email}}</td>
                      </ng-container>
                      <ng-container *ngIf="usuario.editando">
                        <td><input type="text" class="form-control" [(ngModel)]="usuario.name" #ctrlModel /></td>
                        <td><input type="email" class="form-control" [(ngModel)]="usuario.email" #ctrlModel /></td>
                      </ng-container>
                      <td>
                        <span *ngIf="usuario.status && !usuario.deleted" class="badge bg-success">Ativo</span>
                        <span *ngIf="usuario.deleted" class="badge bg-danger">Desativado</span>
                        <span *ngIf="!usuario.status && !usuario.deleted" class="badge bg-warning">Bloqueado</span>
                      </td>
                      <td class="acoes">
                        <button *ngIf="!usuario.status || usuario.deleted" [disabled]="salvandoUsuario" class="btn btn-sm btn-success" tooltip="Reativar usuário" (click)="ativarDesativar(usuario)"><i class="fas fa-undo"></i></button>
                        <button *ngIf="usuario.status && !usuario.deleted && !usuario.editando && !usuario.isAdm" [disabled]="salvandoUsuario" class="btn btn-sm btn-warning" tooltip="Bloquear usuário" (click)="ativarDesativar(usuario)"><i class="fas fa-lock"></i></button>
                        <button *ngIf="usuario.status && !usuario.deleted && !usuario.editando && !usuario.isAdm" [disabled]="salvandoUsuario" (click)="editarUsuario(usuario)" class="btn btn-sm btn-success" tooltip="Editar usuário"><i class="fas fa-edit"></i></button>
                        <button *ngIf="usuario.status && !usuario.deleted && usuario.editando && !usuario.isAdm" [disabled]="salvandoUsuario" (click)="salvarUsuario(usuario)" class="btn btn-sm btn-success" tooltip="Salvar usuário"><i class="fas fa-check"></i> Salvar</button>
                        <button *ngIf="usuario.status && !usuario.deleted && usuario.editando && !usuario.isAdm" [disabled]="salvandoUsuario" (click)="cancelaEditarUsuario(usuario)" class="btn btn-sm btn-danger" tooltip="Cancelar"><i class="fas fa-times"></i> Cancelar</button>
                        <!-- <button *ngIf="usuario.status && !usuario.deleted && !usuario.editando && !usuario.isAdm" [disabled]="salvandoUsuario" class="btn btn-sm btn-info" tooltip="Redefinir senha" (click)="redefineSenha(usuario, templateSenha)"><i class="fas fa-key"></i></button> -->
                        <button *ngIf="usuario.status && !usuario.deleted && !usuario.editando && !usuario.isAdm" [disabled]="salvandoUsuario" class="btn btn-sm btn-danger" tooltip="Desativar usuário" (click)="excluir(usuario, templateExcluir)"><i class="fas fa-ban"></i></button>
                      </td>
                    </tr>
  
                  </tbody>
                </table>
            </div>
          </ng-container>
        </fieldset>
      
    </div>
  </div>
</div>

<ng-template #templateExcluir>
  <div class="modal-body text-center">
    <p>Tem certeza que deseja desativar o usuário?</p>
    <button type="button" class="btn btn-info" (click)="excluirConfirma()" >Sim</button>
    &nbsp;
    <button type="button" class="btn btn-danger" (click)="excluirDeclina()" >Não</button>
  </div>
</ng-template>

<ng-template #templateSenha>
  <div class="modal-body text-center">
    <p>Tem certeza que deseja redefinir a senha do usuário?</p>
    <small>O sistema enviará uma senha provisória para o e-mail cadastrado</small>
    <hr />
    <button type="button" class="btn btn-info" (click)="redefineConfirma()" >Sim</button>
    &nbsp;
    <button type="button" class="btn btn-danger" (click)="excluirDeclina()" >Não</button>
  </div>
</ng-template>