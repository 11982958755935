import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fdnet-resumo',
  templateUrl: './fdnet-resumo.component.html',
  styleUrls: ['./fdnet-resumo.component.css' , './../fdnet.component.css']
})
export class FdnetResumoComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.dados.resumo === undefined){
      this.naoEncontrouRegistros = true;
    }
  }

}
