import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ApiServices } from 'src/app/apiServices.service';
import { Router } from '@angular/router';
import { URL_API } from 'src/app/app.api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericValidators } from 'src/validators/generic-validators';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [ApiServices]
})
export class UsuariosComponent implements OnInit {

  @Input() dadosUsuario:any;

  public usuarios:any[]=[]; 
  public registroExcluir:any = null;
  public modalRef:BsModalRef;
  public showLista:Boolean=true;
  public showForm:Boolean=false;
  public formCadastro:FormGroup = new FormGroup({}); 

  public msgCadastro:string = '';
  public msgCadastroClass:string = 'alert-warning';
  public txtSubmitCadastro:string = 'Salvar';
  public enviandoCadastro:Boolean = false;
  public salvandoUsuario:Boolean = false;

  public usuarioEditar:any=null;

  constructor(private api:ApiServices, private router:Router,private modalService:BsModalService ) { }

  ngOnInit() {
    this.loadUsuarios();
  }

  public loadUsuarios():void{
    let client = this.dadosUsuario.client._id; 
    this.api.get(`${URL_API}/site/user/by-client/${client}`)
    .then(r=>{
      this.usuarios = r;
      this.usuarios.forEach(u=>{
        u['editando']=false;
      })
    })
    
  }

  public abreForm():void{
    this.msgCadastro='';
    this.showForm=true;
    this.showLista=false;
    this.formCadastro = new FormGroup({
      'name':new FormControl(null,[Validators.required]),
      'email':new FormControl(null,[Validators.required, Validators.email]),
      'pass':new FormControl(null,[Validators.required]),
      'passConfirm':new FormControl(null,[Validators.required]),
      'testUser': new FormControl(null)
    });
  }

  public fechaForm():void{
    this.showForm=false;
    this.showLista=true;
    this.msgCadastro='';
    this.loadUsuarios();
  }

  public salvarCadastro():void{
    let client = this.dadosUsuario.client._id; 
    let values = this.formCadastro.value;

    if(this.formCadastro.valid){

      if(values['pass'] != values['passConfirm']){
        this.msgCadastro='As senhas não estão iguais!';
        this.msgCadastroClass= 'alert-danger';
        return;
      }
      
      this.enviandoCadastro=true;
      this.msgCadastro='';
      this.txtSubmitCadastro='Enviando...';
      values['client'] = client;
      values['passTemp'] = true;
      this.api.get(`${URL_API}/site/group`)
      .then(r=>{
        const group = r.find(e=>e.type==='user');
        values['group'] = group._id;
        values['activatedAt']=Date.now();
        this.api.post(`${URL_API}/site/user`,values)
        .then(r=>{
          if(r.success){
            this.msgCadastro=`Cadastro enviado com sucesso!`;
            this.msgCadastroClass= 'alert-success';
            this.enviandoCadastro=false;
            this.txtSubmitCadastro="Salvar";
            setTimeout(()=>{
              this.msgCadastro=``;
              this.fechaForm();
            },3000)
          }else{
            if(r.errmsg && r.errmsg.indexOf('email_1 dup key') != -1){
              this.msgCadastro='E-mail já existente no cadastro.';
            }else{
              this.msgCadastro=r.msg;
            }
            this.msgCadastroClass= 'alert-danger';
            this.enviandoCadastro=false;
            this.txtSubmitCadastro="Salvar";
          }
        })
        .catch((r)=>{ 
          this.msgCadastro='Ocorreu um erro na requisição';
          this.msgCadastroClass= 'alert-danger';
          this.enviandoCadastro=false;
          this.txtSubmitCadastro="Salvar";
          this.msgCadastroClass= 'alert-danger';
          this.enviandoCadastro=false;
          this.txtSubmitCadastro="Salvar";
        })        
      });
      
    }else{
      this.msgCadastro='Verique os campos obrigatórios';
      this.msgCadastroClass='alert-warning';
      let campos =['name','email','pass','passConfirm'];
      campos.forEach(campo=>{
        this.formCadastro.controls[campo].markAsTouched();
      });
    }
  }

  public ativarDesativar(registro:any):void{
    let status = (registro.status)?false:true;
    let registroPost = registro;
    registroPost.status = status;
    delete registroPost.pass;

    if(registroPost.status){
      registroPost.deleted=false;
    }
    this.api.put(`${URL_API}/site/user/${registro._id}`,registroPost)
      .then((r:any)=>{
        registro.status = status;
      })
  }

  public excluir(registro:any, template: TemplateRef<any>):void{
    this.registroExcluir = registro;
    this.modalRef = this.modalService.show(template,{class: 'modal-sm'});
  }

  public redefineSenha(registro:any, template: TemplateRef<any>):void{
    this.registroExcluir = registro;
    this.modalRef = this.modalService.show(template,{class: 'modal-md'});
  }

  public redefineConfirma(): void {
    this.modalRef.hide();
    this.msgCadastro=`Aguarde... Solicitando redefinição de senha.`;
    this.msgCadastroClass=`alert-info`;

    this.api.get(`${URL_API}/site/user/newpass/${this.registroExcluir._id}`)
      .then((r)=>{    
        if(r.success){
          this.msgCadastroClass='alert-success';
          this.msgCadastro=`Solicitação feita com sucesso`;
        }else{
          this.msgCadastroClass='alert-danger';
          this.msgCadastro=r.message;
        }
        
        setTimeout(()=>{
          this.msgCadastro=``;
        },5000)
      })
      .catch(r=>{
        this.msgCadastro='Ocorreu um erro na requisição';
        this.msgCadastroClass= 'alert-danger';
        setTimeout(()=>{
          this.msgCadastro=``;
        },5000)
      })
    
  }

  public excluirConfirma(): void {
    this.registroExcluir.deleted = true;
    this.registroExcluir.status = false;
    delete this.registroExcluir.pass;
    this.api.put(`${URL_API}/site/user/${this.registroExcluir._id}`,this.registroExcluir)
      .then(()=>{
        this.modalRef.hide();
        this.loadUsuarios();
      })
    
  }
 
  public excluirDeclina(): void {
    this.modalRef.hide();
  }

  public salvarUsuario(usuario:any):void{
    this.msgCadastro='';
    if(!GenericValidators.isEmail(usuario.email)){
      this.msgCadastro='E-mail não é válido';
      this.msgCadastroClass='alert-danger';
      return;
    }
    
    if(usuario.name == ''){
      this.msgCadastro='Nome não pode ser vazio';
      this.msgCadastroClass='alert-danger';
      return;
    }

    this.salvandoUsuario=true;
    delete usuario.pass;
    
    //
    this.api.put(`${URL_API}/site/user/${usuario._id}`,usuario)
      .then(r=>{
        if(r._id){
          this.msgCadastro=`Usuário salvo com sucesso!`;
          this.msgCadastroClass= 'alert-success';
          this.salvandoUsuario=false;
          usuario.editando=false;
          this.usuarioEditar = null;
          setTimeout(()=>{
            this.msgCadastro='';
          },3000)
        }else{
          if(r.errmsg && r.errmsg.indexOf('email_1 dup key') != -1){
            this.msgCadastro='E-mail já existente no cadastro';
          }else{
            this.msgCadastro=r.msg;
          }
          this.msgCadastroClass= 'alert-danger';
          this.salvandoUsuario=false;
          usuario.editando=false;
          usuario.name=this.usuarioEditar.name;
          usuario.email=this.usuarioEditar.email;
          this.usuarioEditar = null;
        }
      })
      .catch((r)=>{ 
        this.msgCadastro='Ocorreu um erro na requisição';
        this.msgCadastroClass= 'alert-danger';
        this.salvandoUsuario=false;
        usuario.editando=false;
        usuario.name=this.usuarioEditar.name;
        usuario.email=this.usuarioEditar.email;
        this.usuarioEditar = null;
      })
  }

  public editarUsuario(usuario:any):void{
    this.usuarioEditar = Object.assign({}, usuario);
    usuario.editando=true;
    this.msgCadastro='';
  }

  public cancelaEditarUsuario(usuario:any):void{   
    usuario.name=this.usuarioEditar.name;
    usuario.email=this.usuarioEditar.email;
    this.usuarioEditar = null;
    usuario.editando=false;
    this.msgCadastro='';
  }

}
