<div class="bloco">
    <h5 class="titulo">Indicativo de CCF</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
          <div class="col-auto item text-success">
            <h6><i class="far fa-laugh-wink" style="font-size:19px"></i> Não existem CCF para essa empresa</h6>
          </div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
          <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.ccf">
      <div class="row">
          
          <table class="table table-sm ">
              <thead>
                <tr>
                  <th width="30"></th>
                  <th>Banco</th>
                  <th>Agência</th>
                  <th>Quantidade</th>
                  <th>Data Ocorrência</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dados.ccf">
                  <td class="text-danger" style="font-size:19px">
                    <i class="far fa-frown"></i>
                  </td>
                  <td class="text-danger">{{row.banco}}</td>
                  <td class="text-danger">{{row.agencia}}</td>
                  <td class="text-danger">{{row.quantidade}}</td>
                  <td class="text-danger">{{row.data_ocorrencia}}</td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>
    
    
  