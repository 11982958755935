<section class="page_banner internal noprint">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Olá {{usuario.name}}, seja bem vindo(a)!</h1>
      </div>
    </div>
  </div>
</section>
    <div class="container-fluid">
      <div class="clearfix"></div>
      <div class="col-12 msg alert-danger" *ngIf="!usuarioAtivo">
          <i class="fas fa-exclamation-triangle"></i> Atenção, sua conta ainda não foi validada, verifique seu e-mail e procure o link de ativação.
      </div>
      <div class="row noprint showHideMenu"> 
        <div class="col"> 
          <a href="javascript:void(0)" *ngIf="showMenu" class="text-danger" (click)="closeMenu()">
            <i class="fas fa-angle-up"></i> Esconder Menu
          </a>
          <a href="javascript:void(0)" *ngIf="!showMenu" class="text-danger" (click)="openMenu()">
              <i class="fas fa-bars"></i> Mostrar Menu
          </a>
        </div>
      </div>
      <div class="row"> 
        <div  *ngIf="showMenu" class="col-2 col-xs-12 noprint">
          <div class="list-group">
            <ng-container *ngFor="let item of sessoes">
              <a *ngIf="item.disabled" href="javascript:void(0)" 
                class="list-group-item list-group-item-action {{item.disabled?'disabled':''}}">
                <i class="{{item.icon}}"></i> {{item.labelMenu}}
              </a>
              <a *ngIf="!item.disabled" href="javascript:void(0)" routerLink="/{{item.route}}"
                class="list-group-item list-group-item-action {{item.route==activeRoute?'active':''}}">
                <i class="{{item.icon}}"></i> {{item.labelMenu}}
              </a>
            </ng-container>
            <a href="javascript:void(0)" class="list-group-item list-group-item-action" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Sair </a>
          </div>
        </div>
        <div class="{{showMenu?'col-10':'col-12'}} col-xs-12 sessao col-print-12">
          <h3 class="noprint"><i class="{{sessaoAtiva.icon}}"></i> {{sessaoAtiva.label}}</h3>
          <div *ngIf="carregandoSessao" class="col-12">Carregando...</div>
          <div *ngIf="!carregandoSessao">
            <div *ngIf="activeRoute=='area-logada'">
                
                    <div class="col-6" *ngIf="percentualCadastro < 100">
                      <!-- <div class="card">
                        <div class="card-header bg-primary text-white">Complete seu cadastro</div>
                        <div class="card-body"> -->
                          <p class="card-text">
                            Seu cadastro está {{percentualCadastro}}% completo
                          </p>
                          <div class="progress">
                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="percentualCadastro" attr.aria-valuenow="{{percentualCadastro}}" aria-valuemin="0" aria-valuemax="100">{{percentualCadastro}}%</div>
                          </div>
                          <p class="card-text">
                            Complete seu cadastro para ter acesso a todo sistema 😃 
                          </p>
                          <hr />
                          <a href="javascript:void(0)" routerLink="/area-logada/meus-dados" class="btn btn-lg btn-danger"><i class="fas fa-user-cog"></i> Completar Dados Cadastrais</a>
                          <br />
                          <br />
                          <a href="javascript:void(0)" routerLink="/area-logada/financeiro" class="btn btn-lg btn-danger"><i class="fas fa-dollar-sign"></i> Completar Dados Financeiros</a>
                        <!-- </div>
                      </div> -->
                    </div>
                  

                  <div class="col-6" *ngIf="percentualCadastro == 100">
                      <div class="card">
                        <div class="card-header bg-success text-white">Cadastro completo!</div>
                        <div class="card-body">
                          <p class="card-text">
                            Seu cadastro está {{percentualCadastro}}% completo
                          </p>
                          <div class="progress">
                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="percentualCadastro" attr.aria-valuenow="{{percentualCadastro}}" aria-valuemin="0" aria-valuemax="100">{{percentualCadastro}}%</div>
                          </div>
                          
                        </div>
                      </div>
                  </div>
              
            </div>
            <app-meus-dados *ngIf="activeRoute=='area-logada/meus-dados'"
            [dadosUsuario]="dadosUsuario"
            ></app-meus-dados>
            <app-antifraude *ngIf="activeRoute=='area-logada/antifraude'"
            [dadosUsuario]="dadosUsuario"
            ></app-antifraude>
            <app-consultas *ngIf="activeRoute=='area-logada/consultas'"
            [dadosUsuario]="dadosUsuario"
            ></app-consultas>
            <app-consulta-cnh *ngIf="activeRoute=='area-logada/consulta-cnh'"
            [dadosUsuario]="dadosUsuario"
            ></app-consulta-cnh>

            <app-consulta-cnh-sp *ngIf="activeRoute=='area-logada/consulta-cnh-sp'"
            [dadosUsuario]="dadosUsuario"
            ></app-consulta-cnh-sp>

            <app-fdnet *ngIf="activeRoute=='area-logada/consulta-fdnet-pf'"
            [dadosUsuario]="dadosUsuario" segmentoSelecionado="pf"
            ></app-fdnet>

            <app-fdnet *ngIf="activeRoute=='area-logada/consulta-fdnet-pj'"
            [dadosUsuario]="dadosUsuario" segmentoSelecionado="pj"
            ></app-fdnet>


            <app-financeiro *ngIf="activeRoute=='area-logada/financeiro'"
            [dadosUsuario]="dadosUsuario" [isFutureUser]="isFutureUser"
            ></app-financeiro>
            <app-usuarios *ngIf="activeRoute=='area-logada/usuarios'"
            [dadosUsuario]="dadosUsuario"
            ></app-usuarios>
            <app-historico-consultas *ngIf="activeRoute=='area-logada/historico-consultas'"
            [dadosUsuario]="dadosUsuario"
            ></app-historico-consultas>
            <app-integracao *ngIf="activeRoute=='area-logada/integracao'"
            [dadosUsuario]="dadosUsuario"
            ></app-integracao>
            <app-enriquecimentos *ngIf="activeRoute=='area-logada/enriquecimentos'"
            [dadosUsuario]="dadosUsuario"
            ></app-enriquecimentos>
            <app-clientes *ngIf="activeRoute=='area-logada/clientes'"
            [dadosUsuario]="dadosUsuario"
            ></app-clientes>
            <app-relatorios *ngIf="activeRoute=='area-logada/relatorios'"
            [dadosUsuario]="dadosUsuario" [isFutureUser]="isFutureUser"
            ></app-relatorios>
            <app-log-consulta *ngIf="activeRoute=='area-logada/log-consulta'"
            [dadosUsuario]="dadosUsuario" [isFutureUser]="isFutureUser"
            ></app-log-consulta>
          </div>
        </div>
      </div>
    </div>
