import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-socio-pj',
  templateUrl: './socio-pj.component.html',
  styleUrls: ['./../cadastral-pj/cadastral-pj.component.css','./socio-pj.component.css']
})
export class SocioPjComponent implements OnInit {

  @Input() dados:any;
  @Output() documentoSocio = new EventEmitter();
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.socio===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.socio===null){
      this.naoEncontrouRegistros=true;
    }

    // console.log(this.dados.socio);

    // console.log(this.dados.socio[0].documento);

  }

 
  documentoEmitter(documento) {

    this.documentoSocio.emit(documento);
    console.log(this.dados.socio[0].documento.toString());

  }

  public capturaDoc(documento) {

    let docSocio = documento.toString();

    this.documentoEmitter(docSocio);
  }
}
