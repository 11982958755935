<p>
  Essa área é reservada para troca de arquivos entre a Future Data e o cliente.
</p>
<hr>
<div class="row">
  <div class="col-12">
    <div class="login_form">
      <fieldset>
        
        <ng-container *ngIf="showForm">
            <form [formGroup]="formCadastro" novalidate (ngSubmit)="salvarCadastro()">
              <div class="row d-flex justify-content-end">
                  <button class="btn btn-warning" (click)="fechaForm()" tooltip="Voltar para listagem">Voltar</button>
              </div>
              
              <div class="row d-flex justify-content-end">
                <button class="btn btn-success" type="submit" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="showLista">
              <div class="section msg col-12 {{msgClass}}" *ngIf="msg!=''">
                <span [innerHTML]="msg"></span>
              </div>
              <form [formGroup]="form" (ngSubmit)="upload()">
                <div class="row my-3" *ngIf="!isFutureUser">
                  <div class="section col-6">
                      <label class="input">
                        <label>Novo arquivo</label>
                        <input type="file" class="form-control" (change)="selectFile($event)" #fileUpload />
                      </label>
                    </div>
                    <div class="section col-6">
                      <label class="input">
                        <label style="margin-top: 36px">&nbsp;</label>
                        <button class="btn btn-primary" [disabled]="!currentFileUpload || enviandoUpload" >{{txtButtonUpload}}</button>
                      </label>
                    </div>
                </div>
                <div class="row my-3" *ngIf="isFutureUser">
                    <div class="section col-6">
                      <label class="input">
                        <label>Retorno arquivo</label>
                        <input type="file" class="form-control" (change)="selectFileRetorno($event)" #fileUploadRetorno />
                      </label>
                    </div>
                    <!-- <div class="section col-4">
                      <label class="select">
                        <label>Cliente</label>
                        <select formControlName="cliente" (change)="selecionaCliente($event)">
                          <ng-container *ngIf="!loadingClientes">
                            <option value="">Selecione</option>
                            <option *ngFor="let cliente of clientes" [value]="cliente._id">{{cliente.contactName}} {{cliente.campanyName!=undefined?' ('+cliente.campanyName+')':''}}</option>
                          </ng-container>
                          <ng-container *ngIf="loadingClientes">
                            <option value="">Carregando clientes</option>
                          </ng-container>                          
                        </select>
                      </label>
                    </div> -->
                    <div class="section col-6">
                      <label class="input">
                        <label style="margin-top: 36px">&nbsp;</label>
                        <button class="btn btn-primary" [disabled]="!currentFileUploadRetorno || linhaSelecionada==''" >{{txtButtonUpload}}</button>
                      </label>
                    </div>
                  </div>
              </form> 
            <div class="row">    
              <table class="table">
                  <thead>
                    <tr>
                      <th>Nome do arquivo</th>
                      <th>Solicitante</th>
                      <th>Data Solicitação</th>
                      <th>Arquivo retorno</th>
                      <th>Feito por</th>
                      <th>Data retorno</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="loadingRegistros">
                      <tr>
                        <td colspan="6">Carregando listagem...</td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="!loadingRegistros && registros.length > 0">
                      <tr *ngFor="let registro of registros" (click)="selecionaLinha(registro)" class="{{(linhaSelecionada == registro._id)?'bg-info text-white':''}}">
                        <td><a href="{{registro.fileIn.location}}" target="_blank">{{registro.fileIn.name}}</a></td>
                        <td>{{registro.user.name}}</td>
                        <td>{{registro.createAt|date:'dd/MM/yyyy hh:mm'}}</td>
                        <td *ngIf="registro.fileOut"><a href="{{registro.fileOut.location}}" target="_blank">{{registro.fileOut.name}}</a></td>
                        <td *ngIf="!registro.fileOut">-</td>
                        <td *ngIf="registro.userOut">{{registro.userOut.name}}</td>
                        <td *ngIf="!registro.userOut">-</td>
                        <td>{{registro.finalizedAt|date:'dd/MM/yyyy hh:mm'}}</td>
                        
                      </tr>                      
                    </ng-container>
                    <!-- <ng-container *ngIf="!loadingRegistros && registros.length == 0">
                      <tr>
                        <td colspan="6">Nenhum registro para listar</td>

                      </tr>                      
                    </ng-container> -->
                    

                  </tbody>
                </table> 
                
            </div>
          </ng-container>
      </fieldset>
    </div>
  </div>
</div>

 
