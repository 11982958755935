import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-socio-empresa-pf',
  templateUrl: './socio-empresa-pf.component.html',
  styleUrls: ['./../cadastral-pf/cadastral-pf.component.css','./socio-empresa-pf.component.css']
})
export class SocioEmpresaPfComponent implements OnInit {

  @Input() dados:any;
  @Output() documentoCNPJ = new EventEmitter();
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.sociedade===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.sociedade===null){
      this.naoEncontrouRegistros=true;
    }
  }

  documentoEmitter(documento) {

    this.documentoCNPJ.emit(documento);

  }

  public capturaDoc(documento) {

    let docEmpresa = documento;

    this.documentoEmitter(docEmpresa);
  }
}
