<div class="bloco">
  <h5 class="titulo">Endereços</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.localizacao">
    <div class="row">
        <table class="table table-sm ">
            <thead>
              <tr>
                <th>Logradouro</th>
                <th>Nº</th>
                <th>Compl.</th>
                <th>Bairro</th>
                <th>Cidade/UF</th>
                <th width="100">CEP</th>
                <th>Pontuação</th>
                <th width="100">Cód. IBGE</th>
                <th>Valor do M²</th>
                <th><i class="fas fa-map-marker-alt"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let loc of dados.localizacao">
                <td>{{loc.tipo_logradouro}} {{loc.logradouro}}</td>
                <td>{{loc.numero}}</td>
                <td>{{loc.complemento}}</td>
                <td>{{loc.bairro}}</td>
                <td>{{loc.cidade}}/{{loc.uf}}</td>
                <td>{{loc.cep | mascara:'cep'}}</td>
                <td>{{loc.score}}</td>
                <!-- <td>{{loc.recencia_meses}} mes(es)</td> -->
                <td>{{loc.codigo_ibge}}</td>
                <td>{{loc.valor_m2_regiao | currency:'BRL':true}}</td>
                <td *ngIf="loc.latitude!==null && loc.longitude!==null" class="mapIcon"><i (click)="abreMapa(loc)" tooltip="Mostrar ponto no mapa" class="fas fa-map-marker-alt"></i></td>
                <td *ngIf="loc.latitude===null && loc.longitude===null" class="">--</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>

