<div class="row">
  <div class="col-12">
    <div class="login_form">
      <fieldset>
        
          <ng-container *ngIf="showForm">
            <form [formGroup]="formCadastro" novalidate (ngSubmit)="salvarCadastro()">
              <div class="row d-flex justify-content-end">
                  <button class="btn btn-warning" (click)="fechaForm()" tooltip="Voltar para listagem">Voltar</button>
              </div>
              <div class="row my-3">
                <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
                  <span [innerHTML]="msgCadastro"></span>
                </div>    
                <div class="section col-6">
                  <label class="input">
                    <label>Nome</label>
                    <input placeholder="Nome" type="text" formControlName="name" />
                  </label>
                </div>
                <div class="section col-6">
                  <label class="input">
                    <label>E-mail</label>
                    <input placeholder="E-mail" type="email" autocomplete="false" formControlName="email">
                  </label>
                </div>
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <label>Documento</label>
                    <input placeholder="CPF ou CNPJ" type="text" autocomplete="false" formControlName="document">
                  </label>
                </div>
    
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <label>Razão Social</label>
                    <input placeholder="" type="text" autocomplete="false" formControlName="companyName">
                  </label>
                </div>
                <div class="section col-md-6 col-sm-6 col-xs-12">
                  <label class="input">
                    <label>Telefone</label>
                    <input placeholder="" mask='(00) 00009-0000' type="text" autocomplete="false" formControlName="phone">
                  </label>
                </div>

              </div>
              <div class="row my-3">

                <div class="section col-6">
                  <label class="input">
                    <label>Senha</label>
                    <input placeholder="Senha" type="password" autocomplete="false" formControlName="pass" />
                  </label>
                </div>
                <div class="section col-6">
                  <label class="input">
                    <label>Confirme Senha</label>
                    <input placeholder="Confirme Senha" type="password" autocomplete="false" formControlName="passConfirm">
                  </label>
                </div>
                
              </div>
              <div class="row d-flex justify-content-end">
                <button class="btn btn-success" type="submit" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="showLista">
            <div class="row d-flex justify-content-end">
              <button class="btn btn-info mr-2" (click)="loadClientes()" tooltip="Atulizar listagem"><i class="fas fa-sync"></i> Atualizar</button>
              <button class="btn btn-success" (click)="abreForm()" tooltip="Criar usuário"><i class="fas fa-plus"></i> Criar</button>
            </div>
            <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
              <span [innerHTML]="msgCadastro"></span>
            </div>
            <div class="row">    
              <table class="table table-hover">
                  <thead>
                    <tr>
                      <th colspan="7">
                        <input class="w-100" placeholder="Digite para filtrar" (keyup)="filterClientes($event.target.value)" />
                      </th>
                    </tr>
                    <tr>
                      <th>Empresa</th>
                      <th>Nome de contato</th>
                      <th>E-mail</th>
                      <th width="150">É limitado?</th>
                      <th width="150">Ação</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="loadingClientes">
                    <tr><td colspan="4">Carregando...</td></tr>
                  </tbody>
                  <tbody *ngIf="!loadingClientes">
                    
                    <tr *ngFor="let cliente of clientesFiltrados">
          
                        <td>{{cliente.companyName}}</td>
                        <td>{{cliente.contactName}}</td>
                        <td>{{cliente.email}}</td>
                        <!-- <td>{{cliente.queryLimited ? 'Sim' : 'Não'}}</td> -->
                        <td>
                          <div class="btn-group">
                            <ng-container *ngIf="isFutureUserRoot">
                              <button class="btn btn-sm {{cliente.queryLimited?'btn-success':'btn-light'}}" (click)="changeQueryLimited(cliente,true)">Sim</button>
                              <button class="btn btn-sm {{cliente.queryLimited?'btn-light':'btn-danger'}}" (click)="changeQueryLimited(cliente,false)">Não</button>
                            </ng-container>
                            <ng-container *ngIf="!isFutureUserRoot">
                              {{cliente.queryLimited ? 'Sim' : 'Não'}}
                            </ng-container>
                          </div>
                          <!-- <button *ngIf="cliente.queryLimited" [disabled]="salvandocliente" class="btn btn-sm btn-warning" 
                          tooltip="Tem limitação de consultas?"><i class="fas fa-user-lock"></i></button>
                          <button *ngIf="!cliente.queryLimited" [disabled]="salvandocliente" class="btn btn-sm btn-info" 
                          tooltip="Tem limitação de consultas?"><i class="fas fa-user-lock"></i></button> -->
                        </td>
   

                      <!-- <td>
                        <span *ngIf="cliente.status && !cliente.deleted" class="badge bg-success">Ativo</span>
                        <span *ngIf="cliente.deleted" class="badge bg-danger">Desativado</span>
                        <span *ngIf="!cliente.status && !cliente.deleted" class="badge bg-warning">Bloqueado</span>
                      </td> -->
                      <td class="acoes">

                        <button *ngIf="cliente.queryLimited" [disabled]="salvandocliente" class="btn btn-sm btn-info" 
                        tooltip="Checar limitação" (click)="limitacoes(cliente, templateLimitacoes)"><i class="fas fa-battery-half"></i></button>

                        <button *ngIf="cliente.status && !cliente.deleted && !cliente.editando && !cliente.isAdm" [disabled]="salvandocliente" class="btn btn-sm btn-warning" 
                        tooltip="Permissões e precificação" (click)="permissoes(cliente, templatePermissoes)"><i class="fas fa-user-lock"></i></button>
                        
                        <button *ngIf="cliente.status && !cliente.deleted && !cliente.editando && !cliente.isAdm" [disabled]="salvandocliente" class="btn btn-sm btn-success" 
                        tooltip="Limites e Faturamento" (click)="faturamento(cliente, templateBilling)"><i class="fas fa-dollar-sign"></i></button>

                        <!-- <button *ngIf="cliente.status && !cliente.deleted && !cliente.editando && !cliente.isAdm" [disabled]="salvandocliente" class="btn btn-sm btn-danger" 
                        tooltip="Excluir cliente" (click)="excluir(cliente)"><i class="fas fa-trash"></i></button> -->


                      </td>
                    </tr>
  
                  </tbody>
                </table>
            </div>
          </ng-container>
        </fieldset>
      <!-- <div class="login_footer">
        <button type="submit" class="btn btn-lg btn-primary" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
      </div> -->
    </div>
  </div>
</div>

<ng-template #templatePermissoes>
  <div class="modal-header">
    <h5 class="modal-title">Permissões e precificação </h5>
  </div>
  <div class="modal-body" style="max-height: 500px; overflow-y: auto">
    <p *ngIf="loadingPermissoes">Carregando...</p>
    <div *ngIf="!loadingPermissoes">
      <div class="float-left mt-3 mb-3">
        {{savingClientQueries.text}}
      </div>
      <div class="float-right mt-3 mb-3">
        <button class="btn btn-sm btn-info mr-3" (click)="verificarNovasConsultas()"><i class="fas fa-sync-alt"></i> Verificar novas consultas</button>  
        <div class="btn-group">
          <button class="btn btn-sm btn-success" (click)="liberarTodos(permissoesPrecos)">Liberar Todos</button>
          <button class="btn btn-sm btn-danger" (click)="bloquearTodos(permissoesPrecos)">Bloquear Todos</button>
        </div>
      </div>
      <table class="table table-sm table-hover">
          <thead class="table-dark">
            
            <tr >
              <td colspan="2">Nome da consulta / Feature</td>
              <td>
                Pode consultar?
              </td>
              <td width="150">Limite por dia (renovação automática)</td>
              <td width="150">Preço Unitário</td>
            </tr>
          </thead>
        <tbody>
          <th colspan="5">
            <input class="w-100" placeholder="Digite para filtrar" (keyup)="filterPermissoes($event.target.value)" />
          </th>
          <ng-container  *ngFor="let query of permissoesPrecosFiltrados">
            <tr class="active">
              <td colspan="2">{{query.query.name}}
                <ng-container *ngIf="query.features.length>0">
                  <a href="javascript:void(0)" class="badge badge-primary" *ngIf="!query.showFeatures" (click)="query.showFeatures=true"><i class="fas fa-angle-down"></i> Features</a>
                  <a href="javascript:void(0)" class="badge badge-primary" *ngIf="query.showFeatures" (click)="query.showFeatures=false"><i class="fas fa-angle-up"></i> Features</a>
                </ng-container>
              </td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-sm {{query.permission?'btn-success':'btn-light'}}" (click)="changePermission(query,true)">Sim</button>
                  <button class="btn btn-sm {{query.permission?'btn-light':'btn-danger'}}" (click)="changePermission(query,false)">Não</button>
                </div>
              </td>
              <td>
                <input class="form-control form-control-sm" type="number" [(ngModel)]="query.limitPerDay" [disabled]="!isFutureUserRoot" (blur)="save()">
              </td>
              <td>
                  <input class="form-control form-control-sm" type="text"
                  currencyMask [(ngModel)]="query.price" (blur)="save()" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 4 }">
              </td>
            </tr>
            <ng-container *ngIf="query.features.length>0 && query.showFeatures">
                <tr class="active">
                  <td></td>
                  <td>--</td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-sm btn-success" (click)="liberarTodos(query.features)">Liberar Todos</button>
                      <button class="btn btn-sm btn-danger" (click)="bloquearTodos(query.features)">Bloquear Todos</button>
                    </div>
                  </td>
                  <td><a href="javascript:void(0)" class="badge badge-danger" *ngIf="query.features.length>0" (click)="zerarFeatures(query)">Zerar features R$ 0,00</a></td>
                </tr>
                <tr *ngFor="let feature of query.features" class="table-light">
                    <td>&nbsp;</td>
                    <td><i class="fas fa-angle-right"></i> {{feature.name}}</td>
                    <td>
                      <div class="btn-group">
                        <button class="btn btn-sm {{feature.permission?'btn-success':'btn-light'}}" (click)="changePermission(feature,true)">Sim</button>
                        <button class="btn btn-sm {{feature.permission?'btn-light':'btn-danger'}}" (click)="changePermission(feature,false)">Não</button>
                      </div>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" (blur)="save()" type="text"
                        currencyMask [(ngModel)]="feature.price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                    </td>
                  </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #templateLimitacoes>
  <div class="modal-header">
    <h5 class="modal-title">Limitações de consultas</h5>
  </div>
  <div class="modal-body" style="max-height: 500px; overflow-y: auto">
    <p *ngIf="loadingPermissoes">Carregando...</p>
    <div *ngIf="!loadingPermissoes">
      <div class="float-left mt-3 mb-3 d-flex">
        <div>
          Último reset: {{registroSelecionado.lastResetLimitAt | date:'dd/MM/yyyy HH:mm:ss'}}
        </div>
        <!-- {{savingClientQueries.text}} -->
      </div>
      <div class="float-right mt-3 mb-3">
        <button class="btn btn-sm btn-danger mr-3" (click)="resetarLimitacoes()"><i class="fas fa-battery-half"></i> Resetar limites</button>
        <button class="btn btn-sm btn-info" (click)="atualizaLimitacoes()"><i class="fas fa-sync-alt"></i> Atualizar informações</button>  
      </div>
      <div style="clear: both;"></div>
      <!-- <pre>{{registroSelecionado | json}}</pre> -->
      <table class="table">
          <thead class="table-dark">
            <tr >
              <td colspan="2">Nome da consulta</td>
              <!-- <td>Pode consultar?</td> -->
              <td width="150">Saldo atual</td>
              <td width="150">Limite dia (renovação automática)</td>
              <td *ngIf="isFutureUserRoot">Ações</td>
            </tr>
          </thead>
        <tbody>
          <th colspan="5">
            <input class="w-100" placeholder="Digite para filtrar" (keyup)="filterLimitacoes($event.target.value)" />
          </th>
          <ng-container  *ngFor="let query of limitacoesConsultasFiltrados">
            <tr class="active" *ngIf="query.permission">
              <td colspan="2">{{query.name}}</td>
              <!-- <td>
                {{query.permission?'Sim':'Não'}}
              </td> -->
              <td>
                {{query.balance}}
              </td>
              <td>
                {{query.limitPerDay}}
              </td>
              <td class="d-flex" *ngIf="isFutureUserRoot">
                <div class="d-flex">
                  <input class="form-control" style="max-width: 100px;" type="number" [(ngModel)]="query.add" [disabled]="!isFutureUserRoot || query.isProcessing">
                  <button class="btn btn-sm btn-success mr-2" tooltip="Adicionar" (click)="addQuery(query)" [disabled]="!isFutureUserRoot || query.isProcessing"><i class="fas fa-plus"></i></button>
                </div>
                <button class="btn btn-sm btn-info mr-2" tooltip="Renovar" (click)="resetQuery(query)" [disabled]="!isFutureUserRoot || query.isProcessing"><i class="fas fa-sync"></i></button>
                <button class="btn btn-sm btn-danger " tooltip="Zerar" (click)="zeroQuery(query)" [disabled]="!isFutureUserRoot || query.isProcessing"><i class="fas fa-ban"></i></button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #templateBilling>
  <div class="modal-header">
    <h5 class="modal-title">Limites e Faturamento</h5>
  </div>
  <div class="modal-body" style="max-height: 500px; overflow-y: auto">
    <p *ngIf="loadingPermissoes">Carregando...</p>
    <div *ngIf="!loadingPermissoes">
      <div class="row">
        <div class="col-12">
            
          <div class="login_form">
            <form [formGroup]="formBilling" novalidate (ngSubmit)="salvarCadastroBilling()">
            <div class="login_header bgamarelo">Dados cadastrais</div>
            <fieldset>
              <div class="row">
                <div class="section msg col-12 {{msgCadastroBillClass}}" *ngIf="msgCadastroBill!=''">
                  <span [innerHTML]="msgCadastroBill"></span>
                </div>
                
        
                <div class="section col-4">
                  <label class="input">
                    <label>Nome de Contato</label>
                    <input placeholder="Nome" type="text" formControlName="contactName" />
                  </label>
                </div>
                <div class="section col-4">
                  <label class="input">
                    <label>E-mail de faturamento</label>
                    <input placeholder="E-mail" type="email" autocomplete="false" formControlName="email">
                  </label>
                </div>
        
                <div class="section col-4">
                  <label class="input">
                    <label>Telefone de contato</label>
                    <input placeholder="" mask='(00) 00009-0000' type="text" autocomplete="false" formControlName="phone">
                  </label>
                </div>

                <div class="section col-4">
                  <label class="input">
                    <label>Limite R$</label>
                    <input  type="text"  autocomplete="false"
                    currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 2}"
                    formControlName="invoiceLimit">
                  </label>
                </div>
        
        
              </div>
            </fieldset>
            <div class="login_footer">
              <button type="submit" class="btn btn-lg btn-primary" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
            </div>
          </form> 
          </div>
        </div>
        <div class="col-12">
            <div class="login_form">
              <div class="login_header bgvermelho">Últimos 10 lançamentos <button class="btn btn-sm btn-light" [disabled]="loadingBillings" (click)="carregaUltimosBillings()"><i class="fas fa-sync-alt"></i></button></div>
              <fieldset>
                  <div class="row">    
                      <table class="table table-sm">
                        <tr>
                          <td width="200">
                            <input class="form-control form-control-sm" type="text"
                            currencyMask [(ngModel)]="valorCredito" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"></td>
                            <td width="200">
                                <input class="form-control form-control-sm" type="text"
                                 [(ngModel)]="descCredito" placeholder="Descrição do crédito"></td>
                          <td><button class="btn btn-sm btn-success" (click)="addCredito()" [disabled]="loadingBillings || valorCredito <= 0">Add Crédito</button></td>
                        </tr>
                        </table>
                      
                      
                      <table class="table table-sm">
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Descrição</th>
                              <th>Tipo</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let bill of lancamentosFatura;let lastItem = last;let firstItem = first">
                              <tr *ngIf="firstItem">
                                <td></td>
                                <td></td>
                                <td>Saldo Anterior</td>
                                <td class="text-danger text-right" *ngIf="bill.balance < 0">{{bill.balance | currency:'BRL':true}}</td>
                                <td class="text-success text-right"*ngIf="bill.balance > 0">{{bill.balance | currency:'BRL':true}}</td>
                              </tr>
                              <tr *ngIf="!firstItem">
                                <td>{{bill.createAt|date:'dd/MM/yyyy hh:mm:ss'}}</td>
                                <td>{{bill.description}}</td>
                                <td>{{bill.type}}</td>
                                <td class="text-danger text-right" *ngIf="bill.value < 0">{{bill.value | currency:'BRL':true}}</td>
                                <td class="text-success text-right"*ngIf="bill.value > 0">{{bill.value | currency:'BRL':true}}</td>
                              </tr>
                              <tr *ngIf="lastItem">
                                <td></td>
                                <td></td>
                                <td>Saldo Atual</td>
                                <td class="text-danger text-right" *ngIf="bill.balance < 0">{{bill.balance | currency:'BRL':true}}</td>
                                <td class="text-success text-right"*ngIf="bill.balance > 0">{{bill.balance | currency:'BRL':true}}</td>
                              </tr>
                            </ng-container>
                          </tbody>
                          <tbody *ngIf="loadingBillings">
                            <td colspan="4">Carregando...</td>
                          </tbody>
                        </table>     
                    
                    
                  </div>
                </fieldset>
              <!-- <div class="login_footer">
                <button type="submit" class="btn btn-lg btn-primary" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
              </div> -->
            </div>
          </div>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #templateSenha>
  <div class="modal-body text-center">
    <p>Tem certeza que deseja redefinir a senha do usuário?</p>
    <small>O sistema enviará uma senha provisória para o e-mail cadastrado</small>
    <hr />
    <button type="button" class="btn btn-info" (click)="redefineConfirma()" >Sim</button>
    &nbsp;
    <button type="button" class="btn btn-danger" (click)="excluirDeclina()" >Não</button>
  </div>
</ng-template>