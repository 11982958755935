<div class="modal-header">
    <h4 class="modal-title pull-left">Ponto aproximado</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng">
      <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
    </agm-map>
  </div>