import { OnInit, Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'app-historico-razao-social-pj',
    templateUrl: './historico-razao-social-pj.component.html',
    styleUrls: ['./../cadastral-pj/cadastral-pj.component.css']
})

export class HistoricoRazaoSocialPjComponent implements OnInit {

    @Input() dados:any;

    public txtButtonConsultar:string='Consultar agora';
    public consultando:Boolean=false;
    public naoSolicitou:Boolean=false;
    public naoEncontrouRegistros:Boolean=false;
    public modalRef:BsModalRef;

    constructor(private modalService:BsModalService) {}

    ngOnInit(): void {
        
        if(this.dados.historico_razao_social === undefined){
            this.naoSolicitou = true;
        } else if(this.dados.historico_razao_social === null) {
            this.naoEncontrouRegistros = true;
        }
    }

}