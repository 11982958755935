<div class="bloco">
  <h5 class="titulo">CCF</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.cheque_sem_fundo.registro.length > 0">
    <div class="row">
        <table class="table table-sm ">
            <thead>
              <tr>
                <th>Data</th>
                <th>Nº banco</th>
                <th>Nome banco</th>
                <th>Nº agência</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.cheque_sem_fundo.registro">
                <td>{{row.data_da_ocorrencia}}</td>
                <td>{{row.banco}}</td>
                <td>{{row.nome_banco}}</td>
                <td>{{row.agencia}}</td>
                <td>{{row.quantidade_cheques}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>
  