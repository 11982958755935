import { Component, OnInit } from '@angular/core';
import { URL_API, SITE_KEY_GCAPTCHA } from '../app.api';
import { UrlSegment, ActivatedRoute } from '@angular/router';
import { ApiServices } from '../apiServices.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrls: ['./nova-senha.component.css'],
  providers:[ApiServices]
})
export class NovaSenhaComponent implements OnInit {

  public form:FormGroup = new FormGroup({
    'email':new FormControl(null,[Validators.required, Validators.email]),
  });

  public formSenha:FormGroup = new FormGroup({
    'pass':new FormControl(null,[Validators.required]),
    'passConf':new FormControl(null,[Validators.required]),
  });

  public msg: string = ''
  public msgClass: string = '';
  public enviando:Boolean=false;
  public token:string='';
  public carregandoToken:Boolean=false;
  public titulo:string = 'Solicitação de Nova Senha';
  public tokenGCaptcha:string='';

  constructor(private route: ActivatedRoute,private service:ApiServices,private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    this.route.url.subscribe(
      (p: any) => {
        let urlSegment:UrlSegment[] = p;
        if(urlSegment.length > 1){
          this.token = urlSegment[1].toString();
          this.titulo = 'Criação de Nova Senha'
        }
      });

      this.getTokenCaptcha();
  }

  public getTokenCaptcha():Promise<any>{
    return new Promise((resolve,reject)=>{
      this.reCaptchaV3Service.execute(SITE_KEY_GCAPTCHA, 'esqueceuasenha', (token) => {
        resolve(token);
      }, {
          useGlobalDomain: false
      });
    })
  }

  enviar(): void {
    if (this.form.valid) {
      this.getTokenCaptcha()
      .then(token=>{
        this.enviando=true;
        this.msg = 'Processando...'
        this.msgClass = 'alert-info'
        this.service.post(`${URL_API}/getnewpass`, this.form.value,token)
          .then((resposta: any) => {          
            if (resposta.success) {
              this.msg = resposta.message;
              this.msgClass = 'alert-success'
              this.form.controls['email'].setValue('');
              this.form.controls['email'].markAsUntouched();
              setTimeout(()=>{
                this.msg='';
              },5000)
              
            } else {
              this.msg = resposta.message;
              this.msgClass = 'alert-danger'
            }
            this.enviando=false;
          })
      })
    } else {
      this.msg = 'Campos inválidos'
      this.msgClass = 'alert-warning'
    }
  }

  trocarSenha(): void {
    if (this.formSenha.valid) {
      if(this.formSenha.value['pass'] == this.formSenha.value['passConf']){
        this.getTokenCaptcha()
        .then(token=>{
          this.enviando=true;
          this.msg = 'Processando...';
          this.msgClass = 'alert-info';
          let objPost = {
            pass:this.formSenha.value['pass'],
            token: this.token
          }
          this.service.post(`${URL_API}/setnewpass`, objPost,token)
            .then((resposta: any) => {                      
              if (resposta.success) {
                this.formSenha.controls['pass'].setValue('');
                this.formSenha.controls['passConf'].setValue('');
                this.formSenha.controls['pass'].markAsUntouched();
                this.formSenha.controls['passConf'].markAsUntouched();
                this.msg = resposta.message;
                this.msgClass = 'alert-success';
                setTimeout(()=>{
                  this.msg='';
                },5000)
                
              } else {
                this.msg = resposta.message;
                this.msgClass = 'alert-danger'
              }
              this.enviando=false;
            })
        });
        
      }else{
        this.msg = 'As senhas não estão iguais';
        this.msgClass = 'alert-warning'
      }
      

    } else {
      this.msg = 'Campos inválidos'
      this.msgClass = 'alert-warning'
    }
  }

}
