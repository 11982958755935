/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./estudos-daycoval.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./estudos-daycoval.component";
var styles_EstudosDaycovalComponent = [i0.styles];
var RenderType_EstudosDaycovalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EstudosDaycovalComponent, data: {} });
export { RenderType_EstudosDaycovalComponent as RenderType_EstudosDaycovalComponent };
export function View_EstudosDaycovalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["border", "0"], ["frameborder", "0"], ["height", "600"], ["src", "https://app.powerbi.com/view?r=eyJrIjoiNmUzNTJjMGMtZDdjOS00MDVlLWFhNzYtZWRkMTY1N2FmZmQ2IiwidCI6IjY3N2Q0MGUyLTA2ZmEtNGM0Yi1iN2UyLTM4NWQ4NjNjYjczOSJ9"], ["style", "margin: 10px 0"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rodapeMapa"]], null, null, null, null, null))], null, null); }
export function View_EstudosDaycovalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-estudos-daycoval", [], null, null, null, View_EstudosDaycovalComponent_0, RenderType_EstudosDaycovalComponent)), i1.ɵdid(1, 114688, null, 0, i2.EstudosDaycovalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstudosDaycovalComponentNgFactory = i1.ɵccf("app-estudos-daycoval", i2.EstudosDaycovalComponent, View_EstudosDaycovalComponent_Host_0, {}, {}, []);
export { EstudosDaycovalComponentNgFactory as EstudosDaycovalComponentNgFactory };
