<div class="bloco">
  <h5 class="titulo">Dados Cadastrais</h5>
  <div class="row">
    <div class="item col-auto">
      <label>CPF</label>
      {{dados.cpf | mascara:'cpf'}}
    </div>
    <div class="item col-auto">
      <label>Nome</label>
      {{dados.nome}}
    </div>
    <div class="item col-auto">
      <label>Idade</label>
      {{dados.idade}}
    </div>
    <div class="item col-auto">
      <label>Data de Nascimento</label>
      {{dados.data_nascimento}} <i tooltip="Data foi validada junto a Receita Federal" *ngIf="dados.data_nascimento_validada" class="fas fa-check-circle text-success"></i>
    </div>
    <div class="item col-auto">
      <label>Nome Social</label>
      {{dados.nome_social?dados.nome_social:'--'}}
    </div>
    <div class="item col-auto">
      <label>Nome da Mãe</label>
      {{dados.nome_mae}}
    </div>
  </div>
  <div class="row">
    <div class="item col-auto">
      <label>Gênero</label>
      {{dados.genero=='M'?'Masculino':'Feminino'}}
    </div>
    <div class="item col-auto">
      <label>Situação na Receita</label>
      <span *ngIf="dados.situacao_rf!='REGULAR' && dados.situacao_rf!='PENDENTE DE REGULARIZACAO'" class="text-primary">{{dados.situacao_rf}}</span>
      <span *ngIf="dados.situacao_rf=='PENDENTE DE REGULARIZACAO'" class="text-danger font-weight-bold">{{dados.situacao_rf}}</span>
      <span *ngIf="dados.situacao_rf=='REGULAR'" class="text-success font-weight-bold">{{dados.situacao_rf}}</span>
    </div>
    
    
    <div class="item col-auto">
      <label>Data da consulta na Receita</label>
      {{dados.data_consulta_rf}}
    </div>    
    
    <div class="item col-auto">
      <label>Protocolo na Receita</label>
      {{dados.protocolo_rf}}
    </div>
    <div class="item col-auto">
      <label>Dígito Verificador</label>
      {{dados.digito_verificador}}
    </div>
    <div class="item col-auto">
      <label>Data de Emissão</label>
      {{dados.data_emissao}}
    </div>
    <div class="item col-auto">
      <label>Região da Emissão</label>
      {{dados.regiao_emissao}}
    </div>
    <div class="item col-auto">
      <label>Possível óbito?</label>
      {{dados.possivel_obito?'Sim':'Não'}}
    </div>
    
  </div>
</div>