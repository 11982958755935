/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pf/cadastral-pf.component.css.shim.ngstyle";
import * as i1 from "./parente-pf.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../mascara.pipe";
import * as i5 from "./parente-pf.component";
import * as i6 from "ngx-bootstrap/modal";
var styles_ParentePfComponent = [i0.styles, i1.styles];
var RenderType_ParentePfComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ParentePfComponent, data: {} });
export { RenderType_ParentePfComponent as RenderType_ParentePfComponent };
function View_ParentePfComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_ParentePfComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_ParentePfComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), i2.ɵppd(3, 2), (_l()(), i2.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), (_l()(), i2.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i2.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i2.ɵnov(_v.parent.parent, 0), _v.context.$implicit.cpf_relacao, "cpf")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.grau_relacao; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.nome; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.data_nascimento; _ck(_v, 9, 0, currVal_3); }); }
function View_ParentePfComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 13, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 9, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["CPF Rela\u00E7\u00E3o"])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Grau de rela\u00E7\u00E3o"])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nome"])), (_l()(), i2.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Data de Nascimento"])), (_l()(), i2.ɵeld(13, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ParentePfComponent_4)), i2.ɵdid(15, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.relacionamento; _ck(_v, 15, 0, currVal_0); }, null); }
export function View_ParentePfComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i4.MascaraPipe, []), (_l()(), i2.ɵeld(1, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Poss\u00EDveis rela\u00E7\u00F5es"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ParentePfComponent_1)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ParentePfComponent_2)), i2.ɵdid(7, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ParentePfComponent_3)), i2.ɵdid(9, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.dados.relacionamento; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ParentePfComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-parente-pf", [], null, null, null, View_ParentePfComponent_0, RenderType_ParentePfComponent)), i2.ɵdid(1, 114688, null, 0, i5.ParentePfComponent, [i6.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParentePfComponentNgFactory = i2.ɵccf("app-parente-pf", i5.ParentePfComponent, View_ParentePfComponent_Host_0, { dados: "dados" }, {}, []);
export { ParentePfComponentNgFactory as ParentePfComponentNgFactory };
