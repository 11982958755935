import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-representante-pj',
  templateUrl: './representante-pj.component.html',
  styleUrls: ['./../cadastral-pj/cadastral-pj.component.css','./representante-pj.component.css']
})
export class RepresentantePjComponent implements OnInit {

  @Input() dados:any;
  @Output() documentoRepresentante = new EventEmitter();
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.representante===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.representante===null){
      this.naoEncontrouRegistros=true;
    }
  }

  documentoEmitter(documento) {
  
    this.documentoRepresentante.emit(documento);
  
  }
  
  public capturaDoc(documento) {
  
    let docSocio = documento;
  
    this.documentoEmitter(docSocio);
  }
}
