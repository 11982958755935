/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../cadastral-pj/cadastral-pj.component.css.shim.ngstyle";
import * as i1 from "./natureza-juridica-pj.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./natureza-juridica-pj.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_NaturezaJuridicaPjComponent = [i0.styles, i1.styles];
var RenderType_NaturezaJuridicaPjComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_NaturezaJuridicaPjComponent, data: {} });
export { RenderType_NaturezaJuridicaPjComponent as RenderType_NaturezaJuridicaPjComponent };
function View_NaturezaJuridicaPjComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Nenhum registro encontrado "])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-frown"]], null, null, null, null, null))], null, null); }
function View_NaturezaJuridicaPjComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "col-auto item"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Voc\u00EA n\u00E3o solicitou essa consulta."]))], null, null); }
function View_NaturezaJuridicaPjComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 16, "table", [["class", "table table-sm "]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Cod. Natureza"])), (_l()(), i2.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Natureza Jur\u00EDdica"])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Grupo Natureza"])), (_l()(), i2.ɵeld(11, 0, null, null, 7, "tbody", [], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(14, null, ["", ""])), (_l()(), i2.ɵeld(15, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(16, null, ["", ""])), (_l()(), i2.ɵeld(17, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵted(18, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dados.natureza_juridica.codigo_natureza; _ck(_v, 14, 0, currVal_0); var currVal_1 = _co.dados.natureza_juridica.natureza_juridica; _ck(_v, 16, 0, currVal_1); var currVal_2 = _co.dados.natureza_juridica.grupo_natureza; _ck(_v, 18, 0, currVal_2); }); }
export function View_NaturezaJuridicaPjComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [["class", "bloco"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h5", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Natureza Jur\u00EDdica"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NaturezaJuridicaPjComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NaturezaJuridicaPjComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NaturezaJuridicaPjComponent_3)), i2.ɵdid(8, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.naoEncontrouRegistros; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.naoSolicitou; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.dados.natureza_juridica; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_NaturezaJuridicaPjComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-natureza-juridica-pj", [], null, null, null, View_NaturezaJuridicaPjComponent_0, RenderType_NaturezaJuridicaPjComponent)), i2.ɵdid(1, 114688, null, 0, i4.NaturezaJuridicaPjComponent, [i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NaturezaJuridicaPjComponentNgFactory = i2.ɵccf("app-natureza-juridica-pj", i4.NaturezaJuridicaPjComponent, View_NaturezaJuridicaPjComponent_Host_0, { dados: "dados" }, {}, []);
export { NaturezaJuridicaPjComponentNgFactory as NaturezaJuridicaPjComponentNgFactory };
