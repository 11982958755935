<div class="bloco">
    <h5 class="titulo">Sociedades</h5>
    <div *ngIf="naoEncontrouRegistros">
        <div class="row">
          <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
        </div>
    </div>
    <div *ngIf="naoSolicitou">
        <div class="row">
            <div class="col-auto item">Você não solicitou essa consulta.</div>
          <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
        </div>
    </div>
    <div *ngIf="dados.sociedade">
      <div class="row">
          <table class="table table-sm ">
              <thead>
                <tr>
                  <th width="180">CNPJ</th>
                  <th>Razão Social</th>
                  <th>Situação na Receita</th>
                  <th>Cargo</th>
                  <th>Participação %</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dados.sociedade">
                  <td id="documentoSocio">
                    <i class="fas fa-search"></i> 
                    <a 
                      href="javascript:void(0)" 
                      tooltip="Clique aqui para consultar este documento {{row.cnpj | mascara:'cnpj'}}"
                      (click)="capturaDoc(row.cnpj)"
                      routerLink="/area-logada/consultas"> 
                        {{row.cnpj  | mascara:'cnpj'}}
                    </a>
                  </td>
                  <!-- <td>{{row.cnpj  | mascara:'cnpj'}}</td> -->
                  <td>{{row.razao_social}}</td>
                  <td>
                    <span *ngIf="row.situacao_rf != 'ATIVA' && row.situacao_rf != 'BAIXADA'" class="text-primary">{{row.situacao_rf}}</span>
                    <span *ngIf="row.situacao_rf=='BAIXADA'" class="text-danger font-weight-bold">{{row.situacao_rf}}</span>
                    <span *ngIf="row.situacao_rf=='ATIVA'" class="text-success font-weight-bold">{{row.situacao_rf}}</span>
                  </td>
                  <td>{{row.cargo}}</td>
                  <td *ngIf="row.percentual_participacao!==null">{{row.percentual_participacao}} %</td>
                  <td *ngIf="row.percentual_participacao===null">--</td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>