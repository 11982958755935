var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServices } from 'src/app/apiServices.service';
import { URL_API } from 'src/app/app.api';
import { BsModalService } from 'ngx-bootstrap';
import { Invoice } from '../../models/invoice.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
var FinanceiroComponent = /** @class */ (function () {
    function FinanceiroComponent(api, router, modalService) {
        this.api = api;
        this.router = router;
        this.modalService = modalService;
        this.msgCadastro = '';
        this.msgCadastroClass = 'alert-warning';
        this.txtSubmitCadastro = 'Salvar';
        this.enviandoCadastro = false;
        this.loadingFatura = false;
        this.clientes = [];
        this.invoices = [];
        this.datenow = new Date();
        this.invoiceSelected = null;
        this.anoAtual = new Date().getFullYear();
        this.mesAtual = new Date().getMonth() + 1;
        this.rangeAno = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
        this.rangeMes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.formCadastro = new FormGroup({
            'contactName': new FormControl(null, [Validators.required]),
            'phone': new FormControl(null, [Validators.required]),
            'email': new FormControl(null, [Validators.required, Validators.email]),
        });
    }
    FinanceiroComponent.prototype.ngOnInit = function () {
        this.configFormCadastro();
        if (this.isFutureUser) {
            this.loadClients();
        }
    };
    FinanceiroComponent.prototype.configFormCadastro = function () {
        var user = this.dadosUsuario;
        var clientBilling = {
            contactName: null,
            phone: null,
            email: null
        };
        if (user.client.billing) {
            clientBilling = user.client.billing;
        }
        this.formCadastro.controls['contactName'].setValue(clientBilling.contactName);
        this.formCadastro.controls['phone'].setValue(clientBilling.phone);
        this.formCadastro.controls['email'].setValue(clientBilling.email);
        this.loadInvoices();
    };
    FinanceiroComponent.prototype.loadClients = function () {
        var _this = this;
        this.api.get(URL_API + "/site/client/financeiro")
            .then(function (r) {
            _this.clientes = r;
        });
    };
    FinanceiroComponent.prototype.loadInvoices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.invoices = [];
                        return [4 /*yield*/, this.api.get(URL_API + "/site/client/getInvoices/" + this.dadosUsuario.client._id)
                                .then(function (r) {
                                // tslint:disable-next-line: forin
                                for (var _i = 0, r_1 = r; _i < r_1.length; _i++) {
                                    var row = r_1[_i];
                                    var i = new Invoice(row);
                                    _this.invoices.push(i);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FinanceiroComponent.prototype.loadDataClientChangeEvent = function (e) {
        this.loadDataClient(e.target.value);
    };
    FinanceiroComponent.prototype.updateInvoice = function (invoice) {
        var _this = this;
        this.api.get(URL_API + "/site/finances/billing/" + invoice.client + "/" + invoice.reference)
            .then(function (r) {
            _this.loadDataClient(invoice.client);
        });
    };
    FinanceiroComponent.prototype.closeInvoice = function (invoice) {
        var _this = this;
        this.api.get(URL_API + "/site/client/closeInvoice/" + invoice._id)
            .then(function (r) {
            _this.loadDataClient(invoice.client);
        });
    };
    FinanceiroComponent.prototype.editInvoice = function (invoice) {
        // this.api.get(`${URL_API}/finances/billing/${invoice.client}/${invoice.reference}`)
        //   .then(r => {
        //     this.loadDataClient(invoice.client);
        //   });
    };
    // public createInvoice(client): void {
    //   const ano = new Date().getFullYear();
    //   const mes = new Date().getMonth() + 1;
    //   const mesString = mes.toString().padStart(2, '0');
    //   const reference = `${ano}-${mesString}`;
    //   this.api.get(`${URL_API}/finances/billing/${client}/${reference}`)
    //     .then(r => {
    //       this.loadDataClient(client);
    //     });
    // }
    FinanceiroComponent.prototype.createInvoice = function () {
        var _this = this;
        var reference = this.anoAtual + "-" + this.mesAtual.toString().padStart(2, '0');
        this.api.get(URL_API + "/site/finances/billing/" + this.dadosUsuario.client._id + "/" + reference)
            .then(function (r) {
            _this.loadDataClient(_this.dadosUsuario.client._id);
        });
    };
    FinanceiroComponent.prototype.loadDataClient = function (client) {
        var _this = this;
        this.api.get(URL_API + "/site/client/" + client + "?populate=billing")
            .then(function (r) {
            _this.dadosUsuario.client = r;
            _this.configFormCadastro();
        });
    };
    FinanceiroComponent.prototype.salvarCadastro = function () {
        var _this = this;
        return;
        var values = this.formCadastro.value;
        var billingData = {};
        var campos = ['email', 'phone', 'contactName'];
        campos.forEach(function (campo) {
            billingData[campo] = _this.formCadastro.value[campo];
        });
        if (this.dadosUsuario.client.billing) {
            billingData['_id'] = this.dadosUsuario.client.billing._id;
        }
        if (this.formCadastro.valid) {
            this.enviandoCadastro = true;
            this.msgCadastro = '';
            this.txtSubmitCadastro = 'Enviando...';
            this.api.upsert('client-billing', values)
                .then(function (r) {
                var clientData = { billing: r._id };
                _this.api.put(URL_API + "/site/client/" + _this.dadosUsuario.client._id, clientData)
                    .then(function (ruser) {
                    if (ruser) {
                        _this.msgCadastro = "Dados atualizados com sucesso";
                        _this.msgCadastroClass = 'alert-success';
                        setTimeout(function () {
                            _this.msgCadastro = '';
                            if (_this.dadosUsuario.client.billing === undefined) {
                                //this.router.navigate(['/area-logada']);
                                _this.dadosUsuario.client.billing = r;
                            }
                        }, 3000);
                    }
                    else {
                        _this.msgCadastro = 'Erro ao atualizar os Dados';
                        _this.msgCadastroClass = 'alert-danger';
                    }
                    _this.enviandoCadastro = false;
                    _this.txtSubmitCadastro = "Salvar";
                })
                    .catch(function (r) {
                    _this.msgCadastro = 'Ocorreu um erro na requisição';
                    _this.msgCadastroClass = 'alert-danger';
                    _this.enviandoCadastro = false;
                    _this.txtSubmitCadastro = "Salvar";
                });
            })
                .catch(function (r) {
                _this.msgCadastro = 'Ocorreu um erro na requisição';
                _this.msgCadastroClass = 'alert-danger';
                _this.enviandoCadastro = false;
                _this.txtSubmitCadastro = "Salvar";
            });
        }
        else {
            this.msgCadastro = 'Verique os campos obrigatórios';
            this.msgCadastroClass = 'alert-warning';
            var campos_1 = ['client.email', 'client.phone', 'client.contactName', 'name', 'email', 'client.document', 'client.companyName'];
            campos_1.forEach(function (campo) {
                _this.formCadastro.controls[campo].markAsTouched();
            });
        }
    };
    FinanceiroComponent.prototype.extractQueries = function (invoice) {
        this.api.get(URL_API + "/site/finances/extract/" + invoice.client + "/" + invoice.reference)
            .then(function (r) {
            window.open(r.url, '');
        });
    };
    FinanceiroComponent.prototype.detailInvoice = function (template, invoice) {
        var _this = this;
        this.loadingFatura = true;
        this.invoiceSelected = invoice;
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        var curPipe = new CurrencyPipe('pt-br');
        var datePipe = new DatePipe('pt-br');
        this.api.getHtml("https://template-emails.s3-sa-east-1.amazonaws.com/fatura.modelo.html")
            .then(function (html) {
            var linhasFatura = "";
            for (var _i = 0, _a = _this.invoiceSelected.billing; _i < _a.length; _i++) {
                var bill = _a[_i];
                linhasFatura += "<tr>\n            <td>" + bill.queryName + "</td>\n            <td>Consulta API</td>\n            <td>" + bill.count + "</td>\n            <td align=\"right\">" + curPipe.transform(bill.queryPrice, 'BRL', 'R$', '1.4') + "</td>\n            <td align=\"right\">" + curPipe.transform(bill.totalPrice, 'BRL', 'R$', '1.4') + "</td>\n        </tr>";
            }
            html = html.replace('{LINHAS_FATURA}', linhasFatura);
            html = html.replace('{COMPETENCIA}', _this.invoiceSelected.getCompetenciaMes());
            html = html.replace('{NOME}', _this.dadosUsuario.client.billing.contactName);
            html = html.replace('{DESCONTOS}', curPipe.transform(0, 'BRL', 'R$', '1.4'));
            html = html.replace('{TOTAL_FATURA}', curPipe.transform(_this.invoiceSelected.totalBilling, 'BRL', 'R$', '1.4'));
            html = html.replace('{DATA_ENVIO}', datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'));
            _this.htmlFatura = html;
            _this.loadingFatura = false;
        });
    };
    return FinanceiroComponent;
}());
export { FinanceiroComponent };
