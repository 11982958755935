import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localept from '@angular/common/locales/pt';
import {registerLocaleData, CommonModule} from '@angular/common';
registerLocaleData(localept, 'pt');
import {HttpModule} from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule} from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ROUTES, routerOptions } from './app.routes';

import { AppComponent } from './app.component';
import { TopoComponent } from './topo/topo.component';
import { RodapeComponent } from './rodape/rodape.component';
import { MioloComponent } from './miolo/miolo.component';
import { HomeComponent } from './home/home.component';
import { ContatoComponent } from './contato/contato.component';
import { GenericComponent } from './generic/generic.component';
import { FutureDataComponent } from './future-data/future-data.component';
import { SolucoesComponent } from './solucoes/solucoes.component';
import { PainelComponent } from './painel/painel.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ModalModule, TooltipModule, CarouselModule, AccordionModule, CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import {NgxMaskModule} from 'ngx-mask';
import { AreaLogadaComponent } from './area-logada/area-logada.component';
import { AuthGuard } from './auth.guard';
import { MeusDadosComponent } from './area-logada/meus-dados/meus-dados.component';
import { NovaSenhaComponent } from './nova-senha/nova-senha.component';
import { AtivacaoUsuarioComponent } from './ativacao-usuario/ativacao-usuario.component';
import { ConsultasComponent } from './area-logada/consultas/consultas.component';
import { StrpadleftPipe } from './strpadleft.pipe';
import { CadastralPfComponent } from './area-logada/consultas/cadastral-pf/cadastral-pf.component';
import { EnderecoPfComponent } from './area-logada/consultas/endereco-pf/endereco-pf.component';
import { TelefoneMovelPfComponent } from './area-logada/consultas/telefone-movel-pf/telefone-movel-pf.component';
import { TelefoneFixoPfComponent } from './area-logada/consultas/telefone-fixo-pf/telefone-fixo-pf.component';
import { EmailPfComponent } from './area-logada/consultas/email-pf/email-pf.component';
import { DocumentoPfComponent } from './area-logada/consultas/documento-pf/documento-pf.component';
import { RendaEstimadaPfComponent } from './area-logada/consultas/renda-estimada-pf/renda-estimada-pf.component';
import { PpePfComponent } from './area-logada/consultas/ppe-pf/ppe-pf.component';
import { EscolaridadePfComponent } from './area-logada/consultas/escolaridade-pf/escolaridade-pf.component';
import { NacionalidadePfComponent } from './area-logada/consultas/nacionalidade-pf/nacionalidade-pf.component';
import { ImpostoRendaPfComponent } from './area-logada/consultas/imposto-renda-pf/imposto-renda-pf.component';
import { CcfPfComponent } from './area-logada/consultas/ccf-pf/ccf-pf.component';
import { VeiculoPfComponent } from './area-logada/consultas/veiculo-pf/veiculo-pf.component';
import { AeronavePfComponent } from './area-logada/consultas/aeronave-pf/aeronave-pf.component';
import { ImovelPfComponent } from './area-logada/consultas/imovel-pf/imovel-pf.component';
import { SocioEmpresaPfComponent } from './area-logada/consultas/socio-empresa-pf/socio-empresa-pf.component';
import { ParentePfComponent } from './area-logada/consultas/parente-pf/parente-pf.component';
import { FinanceiroComponent } from './area-logada/financeiro/financeiro.component';
import { MapaGoogleComponent } from './area-logada/consultas/mapa-google/mapa-google.component';
import { AgmCoreModule } from '@agm/core';
import { CadastralPjComponent } from './area-logada/consultas/cadastral-pj/cadastral-pj.component';
import { EnderecoPjComponent } from './area-logada/consultas/endereco-pj/endereco-pj.component';
import { TelefoneFixoPjComponent } from './area-logada/consultas/telefone-fixo-pj/telefone-fixo-pj.component';
import { TelefoneMovelPjComponent } from './area-logada/consultas/telefone-movel-pj/telefone-movel-pj.component';
import { EmailPjComponent } from './area-logada/consultas/email-pj/email-pj.component';
import { SitePjComponent } from './area-logada/consultas/site-pj/site-pj.component';
import { CnaePjComponent } from './area-logada/consultas/cnae-pj/cnae-pj.component';
import { SocioPjComponent } from './area-logada/consultas/socio-pj/socio-pj.component';
import { RepresentantePjComponent } from './area-logada/consultas/representante-pj/representante-pj.component';
import { FaturamentoPjComponent } from './area-logada/consultas/faturamento-pj/faturamento-pj.component';
import { FuncionarioPjComponent } from './area-logada/consultas/funcionario-pj/funcionario-pj.component';
import { InscricaoEstadualPjComponent } from './area-logada/consultas/inscricao-estadual-pj/inscricao-estadual-pj.component';
import { CcfPjComponent } from './area-logada/consultas/ccf-pj/ccf-pj.component';
import { VeiculoPjComponent } from './area-logada/consultas/veiculo-pj/veiculo-pj.component';
import { AeronavePjComponent } from './area-logada/consultas/aeronave-pj/aeronave-pj.component';
import { ImovelPjComponent } from './area-logada/consultas/imovel-pj/imovel-pj.component';
import { CotaMelhorComponent } from './mapa/cota-melhor/cota-melhor.component';
import { SubstrPipe } from './substr.pipe';
import {NgxPrintModule} from 'ngx-print';
import { UsuariosComponent } from './area-logada/usuarios/usuarios.component';
import { HistoricoConsultasComponent } from './area-logada/historico-consultas/historico-consultas.component';
import { UrlNomeConsultaPipe } from './url-nome-consulta.pipe';
import { TipoConsultaPipe } from './tipo-consulta.pipe';
import { CorTipoConsultaPipe } from './cor-tipo-consulta.pipe';
import { InputConsultaPipe } from './input-consulta.pipe';
import { IntegracaoComponent } from './area-logada/integracao/integracao.component';
import { NaturezaJuridicaPjComponent } from './area-logada/consultas/natureza-juridica-pj/natureza-juridica-pj.component';
import { MascaraPipe } from './mascara.pipe';
import { EstudosCyrelaComponent } from './mapa/estudos-cyrela/estudos-cyrela.component';
import { EstudosBrainComponent } from './mapa/estudos-brain/estudos-brain.component';
import { EstudosVellocarComponent } from './mapa/estudos-vellocar/estudos-vellocar.component';
import { EstudosAgenciaasComponent } from './mapa/estudos-agenciaas/estudos-agenciaas.component';
import { EstudosBioritmoComponent } from './mapa/estudos-bioritmo/estudos-bioritmo.component';
import { PoliticaDePrivacidadeComponent } from './politica-de-privacidade/politica-de-privacidade.component';
import { EstudosWebmotorsComponent } from './mapa/estudos-webmotors/estudos-webmotors.component';
import { BigDataComponent } from './solucoes/big-data/big-data.component';
import { BiDashboardsComponent } from './solucoes/bi-dashboards/bi-dashboards.component';
import { GestaoDeRiscoComponent } from './solucoes/gestao-de-risco/gestao-de-risco.component';
import { PortalEApisComponent } from './solucoes/portal-e-apis/portal-e-apis.component';
import { MineracaoDeDadosComponent } from './solucoes/mineracao-de-dados/mineracao-de-dados.component';
import { SegurancaEComplianceComponent } from './solucoes/seguranca-e-compliance/seguranca-e-compliance.component';
import { CarouselComponent } from './carousel/carousel.component';
import { TermosComponent } from './area-logada/termos/termos.component';
import { FormContatoComponent } from './shared/form-contato/formContato.component';
import { GestaoDeCampanhasComponent } from './solucoes/gestao-de-campanhas/gestao-de-campanhas.component';
import { AccordionComponent } from './shared/accordion/accordion.component';
import { EstudosTrademktComponent } from './mapa/estudos-trademkt/estudos-trademkt.component';
import { SafePipe } from './safe.pipe';
import { PosicaoBillingComponent } from './area-logada/posicao-billing/posicao-billing.component';
import { EstudosDaycovalComponent } from './mapa/estudos-daycoval/estudos-daycoval.component';
import { LeiLGPDComponent } from './lei-lgpd/lei-lgpd.component';
import { TesteComponent } from './teste/teste.component';
import { EnriquecimentosComponent } from './area-logada/enriquecimentos/enriquecimentos.component';
import { MonetarioPipe } from './monetario.pipe';
import { ScorePFComponent } from './area-logada/consultas/score-pf/score-pf.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ScorePJComponent } from './area-logada/consultas/score-pj/score-pj.component';
import { FilialPjComponent } from './area-logada/consultas/filial-pj/filial-pj.component';
import { HistoricoRazaoSocialPjComponent } from './area-logada/consultas/historico-razao-social-pj/historico-razao-social-pj.component';
import { HistoricoEnderecoPjComponent } from './area-logada/consultas/historico-endereco-pj/historico-endereco-pj.component';
import { ClientesComponent } from './area-logada/clientes/clientes.component';

import { NgxCurrencyModule } from "ngx-currency";

import { AntifraudeSocioPjComponent } from './area-logada/consultas/antifraude-socio-pj/antifraude-socio-pj.component';
import { AntifraudeComponent } from './area-logada/antifraude/antifraude.component';
import { AntifraudeSocioRelacaoPjComponent } from './area-logada/consultas/antifraude-socio-relacao-pj/antifraude-socio-relacao-pj.component';
import { GrupoEmpresarialN1PjComponent } from './area-logada/consultas/grupo-empresarial-n1-pj/grupo-empresarial-n1-pj.component';
import { ParticipacaoEmpresaN1PjComponent } from './area-logada/consultas/participacao-empresa-n1/participacao-empresa-n1-pj.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ConsultaCnhComponent } from './area-logada/consulta-cnh/consulta-cnh.component';
import { ConsultaCnhSPComponent } from './area-logada/consulta-cnh-sp/consulta-cnh-sp.component';
import { DadosCondutorComponent } from './area-logada/consulta-cnh/dados-condutor/dados-condutor.component';
import { DadosCnhComponent } from './area-logada/consulta-cnh/dados-cnh/dados-cnh.component';
import { BloqueiosCnhComponent } from './area-logada/consulta-cnh/bloqueios-cnh/bloqueios-cnh.component';
import { PontuacaoCnhComponent } from './area-logada/consulta-cnh/pontuacao-cnh/pontuacao-cnh.component';
import { InfracoesCnhComponent } from './area-logada/consulta-cnh/infracoes-cnh/infracoes-cnh.component';
import { ExamesCnhComponent } from './area-logada/consulta-cnh/exames-cnh/exames-cnh.component';
import { FdnetComponent } from './area-logada/fdnet/fdnet.component';
import { DadosCadastraisComponent } from './area-logada/fdnet/dados-cadastrais/dados-cadastrais.component';
import { PefinComponent } from './area-logada/fdnet/pefin/pefin.component';
import { RefinComponent } from './area-logada/fdnet/refin/refin.component';
import { ProtestoEstadualComponent } from './area-logada/fdnet/protesto-estadual/protesto-estadual.component';
import { CreditoCcfComponent } from './area-logada/fdnet/credito-ccf/credito-ccf.component';
import { DividasVencidasComponent } from './area-logada/fdnet/dividas-vencidas/dividas-vencidas.component';
import { FdnetResumoComponent } from './area-logada/fdnet/fdnet-resumo/fdnet-resumo.component';
import { RelatoriosComponent } from './area-logada/relatorios/relatorios.component';
import { LogConsultaComponent } from './area-logada/log-consulta/log-consulta.component';
@NgModule({
  declarations: [
    AppComponent,
    TopoComponent,
    RodapeComponent,
    MioloComponent,
    HomeComponent,
    ContatoComponent,
    GenericComponent,
    FutureDataComponent,
    SolucoesComponent,
    PainelComponent,
    AreaLogadaComponent,
    MeusDadosComponent,
    NovaSenhaComponent,
    AtivacaoUsuarioComponent,
    ConsultasComponent,
    ConsultaCnhComponent,
    ConsultaCnhSPComponent,
    StrpadleftPipe,
    SubstrPipe,
    CadastralPfComponent,
    EnderecoPfComponent,
    TelefoneMovelPfComponent,
    TelefoneFixoPfComponent,
    EmailPfComponent,
    DocumentoPfComponent,
    RendaEstimadaPfComponent,
    PpePfComponent,
    EscolaridadePfComponent,
    NacionalidadePfComponent,
    ImpostoRendaPfComponent,
    CcfPfComponent,
    VeiculoPfComponent,
    AeronavePfComponent,
    ImovelPfComponent,
    SocioEmpresaPfComponent,
    ParentePfComponent,
    FinanceiroComponent,
    MapaGoogleComponent,
    CadastralPjComponent,
    EnderecoPjComponent,
    TelefoneFixoPjComponent,
    TelefoneMovelPjComponent,
    EmailPjComponent,
    SitePjComponent,
    CnaePjComponent,
    SocioPjComponent,
    RepresentantePjComponent,
    FaturamentoPjComponent,
    FuncionarioPjComponent,
    InscricaoEstadualPjComponent,
    CcfPjComponent,
    VeiculoPjComponent,
    AeronavePjComponent,
    ImovelPjComponent,
    CotaMelhorComponent,
    UsuariosComponent,
    HistoricoConsultasComponent,
    UrlNomeConsultaPipe,
    TipoConsultaPipe,
    CorTipoConsultaPipe,
    InputConsultaPipe,
    IntegracaoComponent,
    NaturezaJuridicaPjComponent,
    MascaraPipe,
    EstudosCyrelaComponent,
    EstudosBrainComponent,
    EstudosVellocarComponent,
    EstudosAgenciaasComponent,
    EstudosBioritmoComponent,
    PoliticaDePrivacidadeComponent,
    EstudosWebmotorsComponent,
    BigDataComponent,
    BiDashboardsComponent,
    GestaoDeRiscoComponent,
    PortalEApisComponent,
    MineracaoDeDadosComponent,
    SegurancaEComplianceComponent,
    CarouselComponent,
    TermosComponent,
    FormContatoComponent,
    GestaoDeCampanhasComponent,
    AccordionComponent,
    EstudosTrademktComponent,
    SafePipe,
    PosicaoBillingComponent,
    EstudosDaycovalComponent,
    LeiLGPDComponent,
    TesteComponent,
    EnriquecimentosComponent,
    MonetarioPipe,
    ScorePFComponent,
    ScorePJComponent,
    FilialPjComponent,
    HistoricoRazaoSocialPjComponent,
    HistoricoEnderecoPjComponent,
    ClientesComponent,
    AntifraudeSocioPjComponent,
    AntifraudeSocioRelacaoPjComponent,
    AntifraudeComponent,
    GrupoEmpresarialN1PjComponent,
    ParticipacaoEmpresaN1PjComponent,
    SafeHtmlPipe,
    DadosCondutorComponent,
    DadosCnhComponent,
    BloqueiosCnhComponent,
    PontuacaoCnhComponent,
    InfracoesCnhComponent,
    ExamesCnhComponent,
    FdnetComponent,
    DadosCadastraisComponent,
    FdnetResumoComponent,
    PefinComponent,
    RefinComponent,
    ProtestoEstadualComponent,
    CreditoCcfComponent,
    DividasVencidasComponent,
    RelatoriosComponent,
    LogConsultaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    RouterModule.forRoot(ROUTES, routerOptions),
    ReactiveFormsModule,
    NgxCaptchaModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    GaugeChartModule,
    NgxCurrencyModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXqT-Yyb6ZHaNW_qyiK7lGrcDqfzz4uc8'
    })
  ],
  entryComponents:[MapaGoogleComponent],
  providers: [
    {provide:LOCALE_ID,useValue:'pt'},AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
