import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dividas-vencidas',
  templateUrl: './dividas-vencidas.component.html',
  styleUrls: ['./dividas-vencidas.component.css', './../fdnet.component.css']
})
export class DividasVencidasComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if (this.dados.pendencias_vencidas.registro.length === 0) {
      this.naoEncontrouRegistros = true;
    }
  }

}
