<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Mineração de dados</h1>
        <div class="sub_heading_page">Obtenha dados relevantes para a sua empresa</div>
      </div>
    </div>
  </div>
</section>
  
<section class="safe_host">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Mineração de Dados</h3>
        </div>
      </div>
      <div class="pr_info">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="info_right text-left">
            <p>
                Com o uso de algoritmos e de um conjunto de técnicas avançadas capazes de sustentar e orientar por meio de inteligência artificial mecanismos escaláveis para mineração, explorando diversos conjuntos de dados, extraindo e auxiliando a evidenciar padrões consistentes e relacionamentos sistemáticos entre as variáveis dos dados. Oferecemos soluções de extração de dados em grandes quantidades.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-formContato text="Entre em contato conosco"></app-formContato>