import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'corTipoConsulta'
})
export class CorTipoConsultaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value.indexOf('/consulta/pf') != -1){
      return 'bg-primary';
    }
    if(value.indexOf('/consulta/pj') != -1){
      return 'bg-success';
    }
    if(value.indexOf('/consulta/fdnet-pf') !== -1){
      return 'bg-primary';
    }
    if(value.indexOf('/consulta/fdnet-pj') !== -1){
      return 'bg-success';
    }
    if(value.indexOf('/consulta/cnh') !== -1){
      return 'bg-info';
    }
    if(value.indexOf('/consulta/veicular') !== -1){
      return 'bg-danger';
    }
    return value;
  }

}
