import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServices } from 'src/app/apiServices.service';
import { URL_API } from 'src/app/app.api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Invoice } from '../../models/invoice.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.css']
})
export class FinanceiroComponent implements OnInit {

  public formCadastro: FormGroup;
  public msgCadastro = '';
  public msgCadastroClass = 'alert-warning';
  public txtSubmitCadastro = 'Salvar';
  public enviandoCadastro: Boolean = false;
  public loadingFatura: Boolean = false;
  public modalRef: BsModalRef;
  public clientes: any[] = [];
  public invoices: Invoice[] = [];
  public datenow: Date = new Date();
  public invoiceSelected: Invoice = null;

  @Input() dadosUsuario: any;
  @Input() isFutureUser: Boolean;
  htmlFatura: any;
  anoAtual = new Date().getFullYear();
  mesAtual = new Date().getMonth() + 1;
  rangeAno = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
  rangeMes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


  constructor(private api: ApiServices, private router:Router, private modalService:BsModalService) {
    this.formCadastro = new FormGroup({
      'contactName': new FormControl(null, [Validators.required]),
      'phone': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit() {
    this.configFormCadastro();
    if (this.isFutureUser) {
      this.loadClients();
    }
  }

  public configFormCadastro(): void {
    const user = this.dadosUsuario;
    let clientBilling = {
      contactName: null,
      phone: null,
      email: null
    };

    if (user.client.billing){
      clientBilling = user.client.billing;
    }

    this.formCadastro.controls['contactName'].setValue(clientBilling.contactName);
    this.formCadastro.controls['phone'].setValue(clientBilling.phone);
    this.formCadastro.controls['email'].setValue(clientBilling.email);

    this.loadInvoices();
  }

  public loadClients(): void {
    this.api.get(`${URL_API}/site/client/financeiro`)
    .then((r) => {
      this.clientes = r;
    });
  }

  public async loadInvoices(){
    this.invoices = [];
    await this.api.get(`${URL_API}/site/client/getInvoices/${this.dadosUsuario.client._id}`)
    .then((r) => {
      // tslint:disable-next-line: forin
      for (const row of r) {
        const i = new Invoice(row);
        this.invoices.push(i);
      }
    });
  }

  public loadDataClientChangeEvent(e): void{
    this.loadDataClient(e.target.value);
  }

  public updateInvoice(invoice): void {
    this.api.get(`${URL_API}/site/finances/billing/${invoice.client}/${invoice.reference}`)
      .then(r => {
        this.loadDataClient(invoice.client);
      });
  }

  public closeInvoice(invoice): void {
    this.api.get(`${URL_API}/site/client/closeInvoice/${invoice._id}`)
      .then(r => {
        this.loadDataClient(invoice.client);
      });
  }

  public editInvoice(invoice): void {
    // this.api.get(`${URL_API}/finances/billing/${invoice.client}/${invoice.reference}`)
    //   .then(r => {
    //     this.loadDataClient(invoice.client);
    //   });
  }

  // public createInvoice(client): void {
  //   const ano = new Date().getFullYear();
  //   const mes = new Date().getMonth() + 1;
  //   const mesString = mes.toString().padStart(2, '0');
  //   const reference = `${ano}-${mesString}`;
  //   this.api.get(`${URL_API}/finances/billing/${client}/${reference}`)
  //     .then(r => {
  //       this.loadDataClient(client);
  //     });
  // }

  public createInvoice(): void {
    const reference = `${this.anoAtual}-${this.mesAtual.toString().padStart(2, '0')}`;
    this.api.get(`${URL_API}/site/finances/billing/${this.dadosUsuario.client._id}/${reference}`)
      .then(r => {
        this.loadDataClient(this.dadosUsuario.client._id);
      });
  }

  public loadDataClient(client): void{
    this.api.get(`${URL_API}/site/client/${client}?populate=billing`)
      .then(r => {
        this.dadosUsuario.client = r;
        this.configFormCadastro();
      });
  }

  public salvarCadastro(): void{
    return ;
    const values = this.formCadastro.value;
    const billingData = {};
    const campos =['email', 'phone', 'contactName'];
    campos.forEach(campo => {
      billingData[campo] = this.formCadastro.value[campo];
    });

    if (this.dadosUsuario.client.billing) {
      billingData['_id'] = this.dadosUsuario.client.billing._id;
    }

    if (this.formCadastro.valid) {

      this.enviandoCadastro = true;
      this.msgCadastro='';
      this.txtSubmitCadastro='Enviando...';
      this.api.upsert('client-billing', values)
      .then(r=>{
        const clientData = {billing:r._id};
        this.api.put(`${URL_API}/site/client/${this.dadosUsuario.client._id}`,clientData)
        .then(ruser=>{
          if(ruser){
            this.msgCadastro=`Dados atualizados com sucesso`;
            this.msgCadastroClass= 'alert-success';
            setTimeout(()=>{
              this.msgCadastro = '';
              if(this.dadosUsuario.client.billing === undefined){
                //this.router.navigate(['/area-logada']);
                this.dadosUsuario.client.billing = r;
              }
            },3000);
          }else{
            this.msgCadastro='Erro ao atualizar os Dados';
            this.msgCadastroClass= 'alert-danger';
          }
          this.enviandoCadastro=false;
          this.txtSubmitCadastro = "Salvar";
        })
        .catch(r=>{
          this.msgCadastro='Ocorreu um erro na requisição';
          this.msgCadastroClass= 'alert-danger';
          this.enviandoCadastro=false;
          this.txtSubmitCadastro="Salvar";
        })
      })
      .catch(r=>{
        this.msgCadastro='Ocorreu um erro na requisição';
        this.msgCadastroClass= 'alert-danger';
        this.enviandoCadastro=false;
        this.txtSubmitCadastro="Salvar";
      })
    }else{
      this.msgCadastro='Verique os campos obrigatórios';
      this.msgCadastroClass='alert-warning';
      const campos =['client.email','client.phone','client.contactName','name','email','client.document','client.companyName'];
      campos.forEach(campo=>{
        this.formCadastro.controls[campo].markAsTouched();
      });
    }
  }

  public extractQueries(invoice) : void {
    this.api.get(`${URL_API}/site/finances/extract/${invoice.client}/${invoice.reference}`)
      .then(r => {
        window.open(r.url,'');
      });
  }

  public detailInvoice(template: TemplateRef<any>, invoice: Invoice): void {
    this.loadingFatura = true;
    this.invoiceSelected = invoice;
    this.modalRef = this.modalService.show(template,{class: 'modal-lg'});
    const curPipe = new CurrencyPipe('pt-br');
    const datePipe = new DatePipe('pt-br');
      this.api.getHtml(`https://template-emails.s3-sa-east-1.amazonaws.com/fatura.modelo.html`)
      .then(html => {
        
        let linhasFatura = ``;
        for (const bill of this.invoiceSelected.billing){
          linhasFatura += `<tr>
            <td>${bill.queryName}</td>
            <td>Consulta API</td>
            <td>${bill.count}</td>
            <td align="right">${curPipe.transform(bill.queryPrice, 'BRL','R$','1.4')}</td>
            <td align="right">${curPipe.transform(bill.totalPrice, 'BRL','R$','1.4')}</td>
        </tr>`;
        }
        html = html.replace('{LINHAS_FATURA}', linhasFatura);
        html = html.replace('{COMPETENCIA}', this.invoiceSelected.getCompetenciaMes());
        html = html.replace('{NOME}', this.dadosUsuario.client.billing.contactName);
        html = html.replace('{DESCONTOS}', curPipe.transform(0, 'BRL','R$','1.4'));
        html = html.replace('{TOTAL_FATURA}', curPipe.transform(this.invoiceSelected.totalBilling, 'BRL','R$','1.4'));
        html = html.replace('{DATA_ENVIO}', datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'));
        this.htmlFatura = html;
        
        this.loadingFatura = false;
      });
   
  }

}
