<div class="bloco">
  <h5 class="titulo">Infrações</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.Infracoes.qtdInfracoes === '0'">
    <div class="row">
      <div class="col-auto item">Nenhuma infração encontrada</div>
    </div>
  </div>
  
  <div *ngIf="dados.Infracoes.qtdInfracoes > 0">
    <div class="row">
        <table class="table table-sm ">
            <thead>
              <tr>
                <th>Placa</th>
                <th>Data</th>
                <th>Local</th>
                <th>Numero auto</th>
                <th>Orgão autuador</th>
                <th>Situação</th>
                <th>Responsável</th>
                <th>Infração</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.Infracoes.registro">
                <td>{{row.PlacaVeiculo}}</td>
                <td>{{row.DataInfracao}}</td>
                <td>{{row.LocalInfracao}}</td>
                <td>{{row.NumeroAuto}}</td>
                <td>{{row.OrgaoAutuador}}</td>
                <td>{{row.Situacao}}</td>
                <td>{{row.Responsavel}}</td>
                <td>{{row.Infracao}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>
  
  