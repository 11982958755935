<section class="page_banner internal">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Esqueceu sua senha?</h1>
        <div class="sub_heading_page">Solicite uma <strong>nova senha</strong> em segundos!</div> 
      </div>
    </div>
  </div>
</section>
  
<section class="login_box">
  <div class="container">
    <div class="row">
      <div *ngIf="token==''" class="col-12">
        <form [formGroup]="form" novalidate (ngSubmit)="enviar()">
          <div class="login_form">
            <div class="login_header bgamarelo">{{titulo}}</div>
            <fieldset>
              <div class="section msg col-12 {{msgClass}}" *ngIf="msg!=''">
                <span [innerHTML]="msg"></span>
              </div>
              <div class="section">
                <div class="row">
                  <div class="col-md-12">
                    <label class="input">
                      <input type="email" placeholder="seuemail@dominio.com" formControlName="email">
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="login_footer">
              <button type="submit" class="button" [disabled]="enviando">Enviar</button>
            </div>
          </div>
        </form>
      </div>


      <div *ngIf="token!=''" class="col-12">
        <form [formGroup]="formSenha" novalidate (ngSubmit)="trocarSenha()">
          <div class="login_form">
            <div class="login_header bgamarelo">{{titulo}}</div>
            <fieldset>
              <div class="section msg col-12 {{msgClass}}" *ngIf="msg!=''">
                <span [innerHTML]="msg"></span>
              </div>
              <div class="section">
                <div class="row">
                  <div class="col-md-6">
                    <label class="input">
                      <input type="password" placeholder="Nova Senha" formControlName="pass">
                    </label>
                  </div>
                  <div class="col-md-6">
                    <label class="input">
                      <input type="password" placeholder="Confirme Nova Senha" formControlName="passConf">
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="login_footer">
              <button type="submit" class="button" [disabled]="enviando">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

  