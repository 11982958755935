import { Component, OnInit, Input, TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { MapaGoogleComponent } from '../mapa-google/mapa-google.component';


@Component({
  selector: 'app-endereco-pf',
  templateUrl: './endereco-pf.component.html',
  styleUrls: ['./../cadastral-pf/cadastral-pf.component.css','./endereco-pf.component.css']
})
export class EnderecoPfComponent implements OnInit {

  @Input() dados:any;
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }

  ngOnInit() {
    if(this.dados.localizacao===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.localizacao===null){
      this.naoEncontrouRegistros=true;
    }
  }

  public consultar():void{
    this.txtButtonConsultar = 'Consultando, aguarde...';
    this.consultando=true;
    setTimeout(()=>{
      this.naoSolicitou=false;
      this.dados.localizacao = [
        {bairro: "TATUAPE",
        cep: "03090020",
        cidade: "SAO PAULO",
        codigo_ibge: "3550308",
        complemento: "CS 3",
        latitude: -23.528129,
        logradouro: "DA PENHA",
        longitude: -46.557229,
        numero: "356",
        recencia_meses: 42,
        score: 1,
        tipo_logradouro: "EST VL",
        uf: "SP",
        valor_m2_regiao: 7322}
      ]
      //this.dados.localizacao=null;
      //this.naoEncontrouRegistros=true;
      this.consultando=false;
      this.txtButtonConsultar='Consultar agora';
    },3000)
  }


  
  public abreMapa(loc: any):void{
    
    let initialState = {
      lat:loc.latitude,
      lng: loc.longitude,
      class: 'modal-md'
    }
    this.modalRef = this.modalService.show(MapaGoogleComponent,{initialState});
    
    
    
    
  }
}
