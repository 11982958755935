import { Component, OnInit, Input } from '@angular/core';
import { ApiServices } from 'src/app/apiServices.service';
import { GenericValidators } from 'src/validators/generic-validators';
import { URL_API } from 'src/app/app.api';
import { ActivatedRoute, Router } from '@angular/router';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-consulta-cnh',
  templateUrl: './consulta-cnh.component.html',
  styleUrls: ['./consulta-cnh.component.css'],
  providers: [ApiServices]
})
export class ConsultaCnhComponent implements OnInit {

  @Input() dadosUsuario: any;
  public classSegmentoBusca: string = 'primary';
  public segmentoSelecionado: string = 'pf';
  public selecionarTodosPF: Boolean = false;
  public selecionarTodosPJ: Boolean = false;
  public msg: string = '';
  public msgPalavraChave: string = '';
  public msgClass: string = 'alert-info';

  public palavraChave: string = '';
  public erroPalavraChave: Boolean = false;
  public consultando: Boolean = false;
  public campoBuscar = 'cpf';
  public txtSubmit: string = 'Consultar';

  public carregandoTela: Boolean = true;
  public showBusca: Boolean = false;
  public showResultado: Boolean = false;
  public resultadosBusca: any = { dados: [] };
  public carregandoResultado: Boolean = true;
  public logid: string = '';
  public segmentosBusca: any[] = [];

  public permissoes: any = null;
  public carregandoPermissoes: Boolean = true;
  public isPreConsulta: Boolean = false;

  constructor(private api: ApiServices, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe((p: any) => {
      if (p.logid != undefined) {
        this.logid = p.logid;
        this.showResultado = true;
        this.showBusca = false;
        this.getResultadoConsulta();
      } else {
        this.showBusca = true;
        this.showResultado = false;
      }
      this.carregandoTela = false;

      
      this.getPermissoesCliente();
    });

  }
  public getPermissoesCliente(): void {
    this.segmentosBusca = [];
    this.carregandoPermissoes = true;
    this.api.get(`${URL_API}/site/client/getPermissions`)
      .then(r => {
        this.permissoes = r;
        this.carregandoPermissoes = false;
      })
      .catch(e => console.log(e));
  }

  public hasPermission(code: String, feature: String): Boolean {
    let r = false;
    this.permissoes.queries.forEach(q => {
      if (feature == undefined) {
        if (q.code == code && q.permission) {
          r = true;
        }
      } else {
        if (q.code == code && q.permission) {
          q.features.forEach(f => {
            if (f.name == feature && f.permission) {
              r = true;
            }
          })
        }
      }
    })

    return r;
  }

  public consulta: any;

  public getResultadoConsulta(): void {
    this.showBusca = false;
    this.showResultado = true;
    this.carregandoResultado = true;

    this.api.get(`${URL_API}/site/log-query/${this.logid}`)
      .then(r => {

        this.consulta = r.obj;
        this.carregandoResultado = false;
        if(this.consulta.inProcessing && this.consulta.inProcessing === true){
          setTimeout(()=>{this.getResultadoConsulta()},10000);
        }

      })
      .catch(e => console.log(e))
  }

  public changeSegmento(segmento: string): void {
    this.segmentoSelecionado = segmento;
    if (segmento == 'veiculo') {
      this.msg = 'Consulta de Veículos está em fase experimental';
      this.msgClass = 'alert-info';
    } else {
      this.msg = '';
    }
    this.segmentosBusca.forEach(seg => {
      if (seg.value == this.segmentoSelecionado) {
        this.classSegmentoBusca = seg.class;
      }
    })

    this.validaPalavraChave();
  }



  public getTextoPesquisado(input: any): string {

    if (input.nome != undefined) {
      return input.nome;
    } else if (input.telefone != undefined) {
      return input.telefone;
    } else if (input.cpf != undefined) {
      return input.cpf;
    } else if (input.cnpj != undefined) {
      return input.cnpj;
    }
  }

  public validaPalavraChave(): void {
    let qtdValidacoes;
    let qtdErros = 0;



    if (this.segmentoSelecionado === 'pf') {
      qtdValidacoes = 1;



      if (!GenericValidators.isValidCpf(this.palavraChave)) {
        qtdErros++;
      } else {
        this.campoBuscar = 'cpf';
      }

      if (qtdErros == qtdValidacoes && this.palavraChave.length >= 5) {
        this.msgPalavraChave = 'A palavra chave não parece ser um CPF'
        this.erroPalavraChave = true;
      } else {
        this.erroPalavraChave = false;
        this.msgPalavraChave = '';
      }
    }
  }

  public isBlockedSubmit(): Boolean {
    if (this.consultando) {
      return true;
    }
    if (this.palavraChave.length >= 3) {
      if (!this.erroPalavraChave) {
        return false;
      }
    }
    return true;
  }

  public enviar(): void {
    this.resultadosBusca = { dados: [] };
    this.txtSubmit = 'Consultando. Aguarde...';
    this.consultando = true;
    const body = {};
    this.msg = '';
    this.msgClass = '';
    const urlBusca = `${URL_API}/consulta/cnh-async`;
    body[this.campoBuscar] = this.palavraChave.toString();

    this.api.post(urlBusca, body)
      .then(r => {
        
        if (r.success) {
          this.resultadosBusca = r;
          if (!Array.isArray(this.resultadosBusca.dados)) {
            this.logid = this.resultadosBusca.logQuery;
            this.router.navigate(['/area-logada/consulta-cnh'], { queryParams: { logid: this.logid } });
          }
        } else {
          this.msg = r.msg;
          this.msgClass = 'alert-danger';
        }
        this.txtSubmit = 'Consultar';
        this.consultando = false;
      })
      .catch(e => {
        console.log('Caiu no catch', e);
        this.msg = 'Ocorreu um erro interno na consulta';
        this.msgClass = 'alert-danger';
        this.txtSubmit = 'Consultar';
        this.consultando = false;
      })
  }

  public voltar(): void {
    this.logid = '';
    this.resultadosBusca = { dados: [] };
    this.showResultado = false;
    this.showBusca = true;
    this.router.navigate(['/area-logada/consulta-cnh']);
  }

  public voltarHistorico(): void {
    this.router.navigate(['/area-logada/historico-consultas']);
  }

  public consultarResultado(cpf: string): void {
    this.campoBuscar = 'cpf';
    this.palavraChave = cpf;
    //this.validaPalavraChave();
    //setTimeout(() => {
    this.enviar();
    //}, 1000);


  }

  // public toPdf():any{
  //   var data = document.getElementById('resultadoConsulta');
  // 	html2canvas(data).then(canvas => {
  // 		// Few necessary setting options
  // 		var imgWidth = 208;
  // 		var pageHeight = 295;
  // 		var imgHeight = canvas.height * imgWidth / canvas.width;
  // 		var heightLeft = imgHeight;

  // 		const contentDataURL = canvas.toDataURL('image/png')
  // 		let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  // 		var position = 0;
  // 		pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  // 		pdf.save('FutureData-Resultado-Consulta.pdf');
  // 	});
  // }

  public print(): void {
    setTimeout(() => {
      window.print();
    }, 200);
  }

  public reciverDocumento(documentoEmitter: Event, tipo: String) {


    this.segmentosBusca = [];
    let segmento = 'pf';
    

    this.palavraChave = documentoEmitter.toString();


    this.isPreConsulta = true;

    if (tipo == 'cpf') {
      segmento = 'pf';
      this.segmentoSelecionado = segmento;
      this.campoBuscar = 'cpf';
    } else if (tipo == 'cnpj') {
      segmento = 'pj';
      this.segmentoSelecionado = segmento;
      this.campoBuscar = 'cnpj';

    } else {
      // let segmento = 'pj';
      // this.classSegmentoBusca = 'success';
      // this.segmentoSelecionado = segmento;
      // this.campoBuscar = 'cnpj';

      // this.segmentosBuscaBase.forEach((item,index)=>{
      //   let segmento = JSON.parse(JSON.stringify(item));
      //   console.log(segmento);
      //   delete segmento.groups;
      //   if(this.hasPermission(segmento.value,undefined)){
      //     let groups = [];
      //     item.groups.forEach((itemGroup,indexGroup)=>{
      //       let group = JSON.parse(JSON.stringify(itemGroup));
      //       console.log(group);
      //       delete group.features;
      //       let features = [];
      //       itemGroup.features.forEach((itemFeature)=>{
      //         let feature = JSON.parse(JSON.stringify(itemFeature));
      //         console.log(feature);
      //         if(this.hasPermission(segmento.value,itemFeature.value)){
      //           features.push(feature);
      //         }
      //       })
      //       group.features = features;
      //       if(features.length > 0){
      //         groups.push(group);
      //       }
      //     });
      //     segmento.groups = groups;
      //     this.segmentosBusca.push(segmento);
      //   }
      // });
      // this.segmentoSelecionado = this.segmentosBusca[1].value;
      // this.classSegmentoBusca = '';
      // this.consulta.segmento.value = segmento;
      // this.getSegmento('pj');

      // this.changeSegmento(segmento);
      // console.log(segmento);
      // console.log("continuou aqui");
    }
    this.changeSegmento(segmento);
  }

}
