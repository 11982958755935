import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
@Component({
  selector: 'app-inscricao-estadual-pj',
  templateUrl: './inscricao-estadual-pj.component.html',
  styleUrls: ['./../cadastral-pj/cadastral-pj.component.css','./inscricao-estadual-pj.component.css']
})
export class InscricaoEstadualPjComponent implements OnInit {

  @Input() dados:any;
  public txtButtonConsultar:string='Consultar agora';
  public consultando:Boolean=false;
  public naoSolicitou:Boolean=false;
  public naoEncontrouRegistros:Boolean=false;
  public modalRef:BsModalRef;

  constructor(private modalService:BsModalService) { }
  

  ngOnInit() {
    if(this.dados.inscricao_estadual===undefined){
      this.naoSolicitou=true;
    }else if(this.dados.inscricao_estadual===null){
      this.naoEncontrouRegistros=true;
    }
  }

}
