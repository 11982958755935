<div class="bloco">
  <h5 class="titulo">Telefones Móveis</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.telefone_movel">
    <div class="row">
        <table class="table table-sm ">
            <thead>
              <tr>
                <th width="400">Nº com DDD</th>
                <th width="200">Pontuação</th>
                <!-- <th>Atualizado há</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.telefone_movel">
                <td>({{row.ddd}}) {{row.numero | substr:0:5}}-{{row.numero | substr:5:9}}</td>
                <td>{{row.score}}</td>
                <!-- <td>{{row.recencia_meses}} mes(es)</td> -->
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>
  
  