import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-exames-cnh',
  templateUrl: './exames-cnh.component.html',
  styleUrls: ['./../consulta-cnh.component.css', './exames-cnh.component.css']
})
export class ExamesCnhComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.dados.Exames === undefined){
      this.naoEncontrouRegistros = true;
    } else {
      if (!Array.isArray(this.dados.Exames.registro)) {
        let { registro } = this.dados.Exames;
        this.dados.Exames.registro = [registro];
      }
    }
  }

}
