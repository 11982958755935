<div class="bloco">
  <h5 class="titulo">Dados do condutor</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.Dados_Condutor">
    <div class="row">
      <div class="item col-auto">
        <label>Nome</label>
        {{dados.Dados_Condutor.Nome || 'Não informado'}}
      </div>

      <div class="item col-auto">
        <label>CPF</label>
        {{dados.Dados_Condutor.CPF || 'Não informado'}}
      </div>

      <div class="item col-auto">
        <label>Data de nascimento</label>
        {{dados.Dados_Condutor.DataNascimento || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Cidade</label>
        {{dados.Dados_Condutor.Municipio || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>UF</label>
        {{dados.Dados_Condutor.UF || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Nome da mãe</label>
        {{dados.Dados_Condutor.NomeMae || 'Não informado'}}
      </div>
      <div class="item col-auto">
        <label>Nome do pai</label>
        {{dados.Dados_Condutor.NomePai || 'Não informado'}}
      </div>
    </div>
  </div>
</div>
  
  