import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { TOKEN_NAME, URL_API } from './app.api';
import { Router } from '@angular/router';

@Injectable()
export class ApiServices {
    constructor(protected http: Http, protected router: Router) { }

    public getHeaderAuth(): Headers {
        let headers: Headers = new Headers();
        headers.append('Content-Typeany', 'application/json')
        headers.append('x-access-token', localStorage.getItem(TOKEN_NAME))
        return headers
    }

    public getIDUsuarioLogado(): string {
        if (localStorage.getItem('usuarioLogado') !== null) {
            let usuarioLogado = JSON.parse(localStorage.getItem('usuarioLogado'));
            return usuarioLogado.id
        }

        return null;
    }

    public post(url:string,body:any,gcaptcha:any=null):Promise<any>{
        let headers: Headers = this.getHeaderAuth()
        if(gcaptcha){
            headers.append('gcaptcha',gcaptcha);
        }
        return this.http.post(url,body, { headers: headers })
        .toPromise()
        .then((retorno: any) => {
            return retorno.json()
        })
        .catch((err) => {
            return err.json();
        })
    }

    public put(url:string,body:any):Promise<any>{
        try{
            let headers: Headers = this.getHeaderAuth();
            // console.log(body);
            
            return this.http.put(url,body, { headers: headers })
            .toPromise()
            .then((retorno: any) => {
                return retorno.json()
            })
            .catch((err) => {
                return err.json();
            })
        } catch (e) {
            console.log(e);
            
        }
    }

    public get(url:string):Promise<any>{
        let headers: Headers = this.getHeaderAuth()
        return this.http.get(url, { headers: headers })
        .toPromise()
        .then((retorno: any) => {
            return retorno.json()
        })
        .catch((err: Error) => console.log(err))
    }

    public getHtml(url: string): Promise<any>{
        return this.http.get(url)
        .toPromise()
        .then((retorno) => {
            return retorno.text();
        })
        .catch((err: Error) => console.log(err));
    }

    public upsert(route:string,body:any):Promise<any>{
        if(body._id === null || body._id === undefined){
            return this.post(`${URL_API}/site/${route}`,body);
        }else{
            return this.put(`${URL_API}/site/${route}/${body._id}`,body);
        }
    }

    public postFile(url:string,body:any):Promise<any>{
        let headers: Headers = this.getHeaderAuth();
        headers.delete('Content-Type');
        //headers.append('Content-Type', 'multipart/form-data')
        return this.http.post(url,body, { headers: headers })
        .toPromise()
        .then((retorno: any) => {
            return retorno.json()
        })
        .catch((err: Response) => {
            console.log(err);
            return err.json();
        })
    } 

    public putFile(url:string,body:any):Promise<any>{
        let headers: Headers = this.getHeaderAuth();
        headers.delete('Content-Type');
        //headers.append('Content-Type', 'multipart/form-data')
        return this.http.put(url,body, { headers: headers })
        .toPromise()
        .then((retorno: any) => {
            return retorno.json()
        })
        .catch((err: Response) => {
            console.log(err);
            return err.json();
        })
    }

    
}