import { Component, OnInit, Input } from '@angular/core';
import { ApiServices } from 'src/app/apiServices.service';
import { GenericValidators } from 'src/validators/generic-validators';
import { URL_API } from 'src/app/app.api';
import { ActivatedRoute, Router } from '@angular/router';
import { Util } from 'src/app/utils/utils';


@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.css'],
  providers:[ApiServices]
})
export class ConsultasComponent implements OnInit {

  @Input() dadosUsuario:any;
  public classSegmentoBusca:string='primary';
  public segmentoSelecionado:string='pf';
  public selecionarTodosPF:Boolean=false;
  public selecionarTodosPJ:Boolean=false;
  public msg:string='';
  public msgPalavraChave:string='';
  public msgClass:string='alert-info';

  public palavraChave:string='';
  public erroPalavraChave:Boolean=false;
  public consultando:Boolean=false;
  public campoBuscar='cpf';
  public txtSubmit:string='Consultar';

  public carregandoTela:Boolean=true;
  public showBusca:Boolean=false;
  public showResultado:Boolean=false;
  public resultadosBusca:any={dados:[]};
  public carregandoResultado:Boolean=true;
  public logid:string='';
  public segmentosBusca:any[]=[];
  public segmentosBuscaBase:any[]=[
    {label: 'Pessoa Física', value:'pf', class:'primary',disabled:false, 
    groups:[
      {
        label:'Localização/Comunicação', features:[
          {label:'Localização',value:'localizacao'},
          {label:'Telefonia Móvel',value:'telefone_movel'},
          {label:'Telefonia Fixa',value:'telefone_fixo'},
          {label:'E-mail',value:'email'},
        ]
      },
      {
        label:'Documentação', features:[
          {label:'Documentos',value:'documento'},
        ]
      },
      {
        label:'Comportamento/Hábitos de consumo', features:[
          {label:'Renda Estimada',value:'renda_estimada'},
          {label:'Politicamente Exposta',value:'ppe'},
          {label:'Escolaridade',value:'escolaridade'},
          {label:'Nacionalidade',value:'nacionalidade'},
          {label:'Imposto de Renda',value:'irpf'},
          // {label:'Restritivo CCF',value:'ccf'},
        ]
      },
      {
        label:'Propriedades', features:[
          {label:'Veículos',value:'veiculo'},
          {label:'Aeronaves',value:'aeronave'},
          {label:'Imóveis',value:'imovel'},
        ]
      },
      {
        label:'Relacionamentos', features:[
          {label:'Sócio em empresa',value:'sociedade'},
          {label:'Parentes',value:'relacionamento'}          
        ]
      },
      {
        label:'Restritivo/Creditício', new:true, features:[
          {label:'Crédito Plus (Completo)', new:true,value:'credito_plus'},
          {label:'Score Mercado', new:true,value:'score_mercado'},
          {label:'Score Varejo', new:true ,value:'score_varejo'}          
        ]
      },
    ]},
    {label: 'Pessoa Jurídica', value:'pj', class:'success',disabled:false, 
    groups:[
      {
        label:'Localização/Comunicação',features:[
          {label:'Localização',value:'localizacao'},
          {label:'Telefonia Móvel',value:'telefone_movel'},
          {label:'Telefonia Fixa',value:'telefone_fixo'},
          {label:'E-mail',value:'email'},
          {label:'Histórico de Endereço',value:'historico_endereco'}
        ]
      },
      {
        label:'Documentação',features:[
          {label:'Inscrição Estadual',value:'inscricao_estadual'},
          {label:'Site',value:'site'},
        ]
      },
      {
        label:'Comportamento/Hábitos de consumo',features:[
          {label:'Faturamento Presumido',value:'faturamento'},
          {label:'Quantidade de Funcionário',value:'funcionario'},
          {label:'CNAE',value:'cnae'},
          // {label:'CCF',value:'ccf'},
          {label:'Natureza Jurídica',value:'natureza_juridica'},
          {label:'Histórico de Razão Social', value:'historico_razao_social'},
          {label:'Antifraude Sócio', value:'antifraude_socio'},
          {label:'Antifraude Sócio Relação', value:'antifraude_socio_relacao'}
        ]
      },
      {
        label:'Propriedades',features:[
          {label:'Veículos',value:'veiculo'},
          {label:'Aeronaves',value:'aeronave'},
          {label:'Imóveis',value:'imovel'},
        ]
      },
      {
        label:'Relacionamentos',features:[
          {label:'Sócios',value:'socio'},
          {label:'Representante Legal',value:'representante'},
          {label:'Filiais', value: 'filial'},
        ]
      },
      {
        label:'Restritivo/Creditício', new:true, features:[
          {label:'Crédito Plus (Completo)', new:true,value:'credito_plus'},
          {label:'Score Mercado', new:true,value:'score_mercado'},
          {label:'Score Varejo', new:true ,value:'score_varejo'}          
        ]
      },
    ],
    }/*,
    {label: 'Veículos (Beta)', value:'veiculo', class:'danger',disabled:true,features:[
      {label:'Cadastral Proprietário',value:'cadastral'},
    ]},
    ,
    {label: 'Creditício', value:'credito', class:'danger',disabled:true,features:[
      {label:'Consulta Completa',value:'cadastral'},
    ]}*/
  ];

  public features:any={
    pf:{
      localizacao:false,    telefone_movel:false,
      telefone_fixo:false,    email:false,
      documento:false,    renda_estimada:false,
      ppe:false,    escolaridade:false,
      nacionalidade:false,    irpf:false,
          veiculo:false,
      aeronave:false,    imovel:false,
      sociedade:false,    relacionamento:false,
      credito_plus:false, score_varejo:false,
      score_mercado:false
    },
    pj:{
      localizacao:false,		telefone_movel:false,
      telefone_fixo:false,		email:false,
      inscricao_estadual:false,		site:false,
      faturamento:false,		funcionario:false,
      cnae:false,		
      natureza_juridica:false,
      veiculo:false,		aeronave:false,
      imovel:false,		socio:false,
      representante:false, credito_plus:false, 
      score_varejo:false, score_mercado:false,
      filial: false, historico_razao_social: false,
      historico_endereco: false, antifraude_socio: false,
      antifraude_socio_relacao: false
    },
    veiculo:{
      cadastral:false
    }
  }

  public permissoes:any = null;
  public carregandoPermissoes:Boolean=true;
  public isPreConsulta:Boolean=false;

  constructor(private api:ApiServices,private route:ActivatedRoute, private router:Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe((p: any) => {
      if(p.logid != undefined){
        this.logid = p.logid;
        this.showResultado=true;
        this.showBusca = false;
        this.getResultadoConsulta();
      }else{
        this.showBusca=true;
        this.showResultado=false;
      }
      this.carregandoTela=false;
      
      this.changeSelecionaTodos();
      this.getPermissoesCliente();
    });
    
  }
  public getPermissoesCliente():void{
    this.segmentosBusca = [];
    this.carregandoPermissoes = true;
    this.api.get(`${URL_API}/site/client/getPermissions`)
    .then(r=>{
      this.permissoes = r;
      //this.segmentosBusca = JSON.parse(JSON.stringify(this.segmentosBuscaBase));
      this.segmentosBuscaBase.forEach((item,index)=>{
        let segmento = JSON.parse(JSON.stringify(item));
        delete segmento.groups;
        if(this.hasPermission(segmento.value,undefined)){
          let groups = [];
          item.groups.forEach((itemGroup,indexGroup)=>{
            let group = JSON.parse(JSON.stringify(itemGroup));
            delete group.features;
            let features = [];
            itemGroup.features.forEach((itemFeature)=>{
              let feature = JSON.parse(JSON.stringify(itemFeature));
              if(this.hasPermission(segmento.value,itemFeature.value)){
                features.push(feature);
              }
            })
            group.features = features;
            if(features.length > 0){
              groups.push(group);
            }
          });
          segmento.groups = groups;
          this.segmentosBusca.push(segmento);
        }
      });
      
      
      if(!this.isPreConsulta){
        this.segmentoSelecionado = this.segmentosBusca[0].value;
      }
      
      
      this.carregandoPermissoes = false;
    })
    .catch(e=>console.log(e));
  }

  public hasPermission(code:String,feature:String):Boolean{
    let r = false;
    this.permissoes.queries.forEach(q=>{
      if(feature == undefined){
        if(q.code==code && q.permission){
          r = true;
        }
      }else{
        if(q.code==code && q.permission){
          q.features.forEach(f=>{
            if(f.name==feature && f.permission){
              r=true;
            }
          })
        }
      }
    })

    return r;
  }

  public changeSelecionaTodos():void{
    let pf = this.features.pf;
    let pj = this.features.pj;

    if(this.selecionarTodosPF){
      Object.keys(pf).forEach(function(ele,index) {
        pf[ele]=true;
      })
    }else{
      Object.keys(pf).forEach(function(ele,index) {
        pf[ele]=false;
      })
    }
    if(this.selecionarTodosPJ){
      Object.keys(pj).forEach(function(ele,index) {
        pj[ele]=true;
      })
    }else{
      Object.keys(pj).forEach(function(ele,index) {
        pj[ele]=false;
      })
    }
  }

  public consulta:any;

  public getResultadoConsulta():void{
    this.showBusca=false;
    this.showResultado=true;
    this.carregandoResultado=true;

    this.api.get(`${URL_API}/site/log-query/${this.logid}`)
    .then(r=>{  
      
      
      this.consulta=r.obj;
      
      this.consulta.segmento = this.getSegmento(this.consulta.url.replace('/api/consulta/',''));
      console.log(this.consulta.url.replace('/api/consulta/',''));
      console.log(this.consulta.segmento);
      
      this.carregandoResultado=false;
    })
    .catch(e=>console.log(e))
  }

  public changeSegmento(segmento:string):void{
    this.segmentoSelecionado = segmento;
    if(segmento=='veiculo'){
      this.msg='Consulta de Veículos está em fase experimental';
      this.msgClass='alert-info';
    }else{
      this.msg='';
    }
    this.segmentosBusca.forEach(seg=>{
      if(seg.value == this.segmentoSelecionado){
        this.classSegmentoBusca=seg.class;
      }
    })
    
    this.validaPalavraChave();
  }

  public getSegmento(str:string):any{
    let ret =null;
    this.segmentosBuscaBase.forEach(seg=>{
      if(seg.value == str){
        ret=seg;
      }
    });
    return ret;
  }

  public getTextoPesquisado(input:any):string{
    
    if(input.nome!= undefined){
      return input.nome;
    }else if(input.telefone!= undefined){
      return input.telefone;
    }else if(input.cpf!= undefined){
      return input.cpf;
    }else if(input.cnpj!= undefined){
      return input.cnpj;
    }
  }

  public changeFeature(feature:string):void{
    let valueAtual:Boolean = this.features[this.segmentoSelecionado][feature];

    if(valueAtual){
      this.features[this.segmentoSelecionado][feature] = false;
    }else{
      this.features[this.segmentoSelecionado][feature] = true;
    }
  }

  public validaPalavraChave():void{
    let qtdValidacoes;
    let qtdErros=0;
    
    

    if(this.segmentoSelecionado === 'pf'){
      qtdValidacoes = 3;

      if(!GenericValidators.isText(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='nome';
      }

      if(!GenericValidators.isPhoneNumber(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='telefone';
      }

      if(!GenericValidators.isValidCpf(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='cpf';
      }

      if(qtdErros == qtdValidacoes && this.palavraChave.length >= 3){
        this.msgPalavraChave='A palavra chave não parece ser um Nome, CPF ou Telefone'
        this.erroPalavraChave=true;
      }else{
        this.erroPalavraChave=false;
        this.msgPalavraChave='';
      }
    }

    if(this.segmentoSelecionado=='pj'){
      qtdValidacoes = 3;

      if(!GenericValidators.isText(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='razao_social';
      }

      if(!GenericValidators.isPhoneNumber(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='telefone';
      }

      if(!GenericValidators.isValidCnpj(this.palavraChave)){
        qtdErros++;
      }else{
        this.campoBuscar='cnpj';
      }
      
      if(qtdErros == qtdValidacoes && this.palavraChave.length >= 3){
        this.msgPalavraChave='A palavra chave não parece ser uma Razão Social, CNPJ ou Telefone'
        this.erroPalavraChave=true;
      }else{
        this.erroPalavraChave=false;
        this.msgPalavraChave='';
      }
    }

    if(this.segmentoSelecionado=='veiculo'){
      qtdValidacoes = 2;

      if(!GenericValidators.isPlaca(this.palavraChave)){
        qtdErros++;
      }

      if(!GenericValidators.isChassi(this.palavraChave)){
        qtdErros++;
      }     

      if(qtdErros == qtdValidacoes && this.palavraChave.length >= 6){
        this.erroPalavraChave=true;
        this.msgPalavraChave='A palavra chave não parece ser uma Placa ou Chassi'
      }else{
        this.erroPalavraChave=false;
        this.msgPalavraChave='';
      }
    }
  }

  public isBlockedSubmit():Boolean{
    if(this.consultando){
      return true;
    }
    if(this.palavraChave.length >= 3){
      if(!this.erroPalavraChave){
        return false;
      }
    }
    return true;
  }

  public enviar():void{
    this.resultadosBusca = {dados:[]}
    this.txtSubmit='Consultando. Aguarde...';
    this.consultando=true;
    let body = {};
    this.msg='';
    this.msgClass='';
    let urlBusca = `${URL_API}/api/consulta/${this.segmentoSelecionado}`;
    body[this.campoBuscar]=this.palavraChave.toString();
    body['features']=this.features[this.segmentoSelecionado];
    
      this.api.post(urlBusca,body)
      .then(r=>{
        if(r.success){
          this.resultadosBusca = r;        
          if(!Array.isArray(this.resultadosBusca.dados)){
            this.logid=this.resultadosBusca.logQuery;
            this.router.navigate(['/area-logada/consultas'],{queryParams:{logid:this.logid}});
            //this.getResultadoConsulta();
          }
        }else{
          this.msg=r.msg;
          this.msgClass='alert-danger';
        }
        
        this.txtSubmit='Consultar';
        this.consultando=false;
      })
  }

  public voltar():void{
    this.logid='';
    this.resultadosBusca = {dados:[]}
    this.showResultado=false;
    this.showBusca=true;
    this.router.navigate(['/area-logada/consultas']);
  }

  public voltarHistorico():void{
    this.router.navigate(['/area-logada/historico-consultas']);
  }

  public consultarResultado(cpf:string):void{
    this.campoBuscar='cpf';
    this.palavraChave = cpf;
    //this.validaPalavraChave();
    //setTimeout(() => {
      this.enviar();
    //}, 1000);
    

  }

  public consultarResultadoPJ(cnpj:string):void{
    this.campoBuscar='cnpj';
    this.palavraChave = cnpj;
    this.enviar();
  }
  public changeFeatures(features:any[]):void{
    
    if(this.allFeaturesSelecionados(features)){
      features.forEach(f=>{
        this.features[this.segmentoSelecionado][f.value]=false;
      })
    }else{
      features.forEach(f=>{
        this.features[this.segmentoSelecionado][f.value]=true;
      })
    }
  }

  public allFeaturesSelecionados(features:any[]):Boolean{
    let r:Boolean = true;
    features.forEach(f=>{
      if(!this.features[this.segmentoSelecionado][f.value]){
        r=false;
      }
    })
    return r;
  }

  // public toPdf():any{
  //   var data = document.getElementById('resultadoConsulta');
	// 	html2canvas(data).then(canvas => {
	// 		// Few necessary setting options
	// 		var imgWidth = 208;
	// 		var pageHeight = 295;
	// 		var imgHeight = canvas.height * imgWidth / canvas.width;
	// 		var heightLeft = imgHeight;
			 
	// 		const contentDataURL = canvas.toDataURL('image/png')
	// 		let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
	// 		var position = 0;
	// 		pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
	// 		pdf.save('FutureData-Resultado-Consulta.pdf');
	// 	});
  // }

  public print():void{
    setTimeout(()=>{
      window.print();
    },200);
  }

  public reciverDocumento(documentoEmitter:Event,tipo:String) {
    
    
    this.segmentosBusca = [];
    let segmento = 'pf';
    console.log("Evento emitido e chegou na tela de consultas", documentoEmitter);

    this.palavraChave = documentoEmitter.toString();

    console.log(this.palavraChave = documentoEmitter.toString());
    
    this.isPreConsulta=true;
    
    if(tipo == 'cpf') {
      segmento = 'pf';
      this.segmentoSelecionado = segmento;
      this.campoBuscar = 'cpf';
    } else if(tipo == 'cnpj') {
      segmento = 'pj';
      this.segmentoSelecionado = segmento;
      this.campoBuscar = 'cnpj';
      
    }else{
      // let segmento = 'pj';
      // this.classSegmentoBusca = 'success';
      // this.segmentoSelecionado = segmento;
      // this.campoBuscar = 'cnpj';

      // this.segmentosBuscaBase.forEach((item,index)=>{
      //   let segmento = JSON.parse(JSON.stringify(item));
      //   console.log(segmento);
      //   delete segmento.groups;
      //   if(this.hasPermission(segmento.value,undefined)){
      //     let groups = [];
      //     item.groups.forEach((itemGroup,indexGroup)=>{
      //       let group = JSON.parse(JSON.stringify(itemGroup));
      //       console.log(group);
      //       delete group.features;
      //       let features = [];
      //       itemGroup.features.forEach((itemFeature)=>{
      //         let feature = JSON.parse(JSON.stringify(itemFeature));
      //         console.log(feature);
      //         if(this.hasPermission(segmento.value,itemFeature.value)){
      //           features.push(feature);
      //         }
      //       })
      //       group.features = features;
      //       if(features.length > 0){
      //         groups.push(group);
      //       }
      //     });
      //     segmento.groups = groups;
      //     this.segmentosBusca.push(segmento);
      //   }
      // });
      // this.segmentoSelecionado = this.segmentosBusca[1].value;
      // this.classSegmentoBusca = '';
      // this.consulta.segmento.value = segmento;
      // this.getSegmento('pj');

      // this.changeSegmento(segmento);
      // console.log(segmento);
      // console.log("continuou aqui");
    }
    this.changeSegmento(segmento);
  }

}
