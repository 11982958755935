import { Component, OnInit } from '@angular/core';
import { ApiServices } from '../apiServices.service';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { URL_API } from '../app.api';

@Component({
  selector: 'app-area-logada',
  templateUrl: './area-logada.component.html',
  styleUrls: ['./area-logada.component.css'],
  providers: [ApiServices,LoginService]
})
export class AreaLogadaComponent implements OnInit {

  public sessoes:any[]=[];
  public sessaoAtiva:any = {label:'',icon:''};
  public usuario:any = JSON.parse(localStorage.getItem('usuarioLogadoSite'));
  public usuarioAtivo:Boolean=false;
  public activeRoute:string='area-logada';
  public carregandoSessao:Boolean=true;

  public percentualCadastro:number=25;

  public dadosUsuario:any=null;

  public showMenu:Boolean=true;

  constructor(private api:ApiServices, private route:ActivatedRoute, private router:Router,private loginService:LoginService) { }

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem('usuarioLogadoSite'));

    if(this.usuario && this.usuario.activatedAt != null){
      this.usuarioAtivo=true;
    }

    if(!this.usuario.acceptTerms) {
      this.router.navigate(['/area-logada/termos']);
    }

    this.route.url.subscribe(
      (p: any) => {
        let urlSegment:UrlSegment[] = p;
        let pathAtual:string[] = [];
        urlSegment.forEach(segment=>{
          pathAtual.push(segment.toString());
        })
        this.activeRoute = pathAtual.join('/');
      });

    this.carregaInfoUsuario();
    
    
  }

  public carregaInfoUsuario():void{
    //console.log('carregaInfoUsuario() >>');
    
    this.api.get(`${URL_API}/site/user/${this.usuario.id}?populate=client`)
      .then(r=>{
        this.dadosUsuario = r;
        if(r.client){
          
          this.percentualCadastro = 60;
          this.api.get(`${URL_API}/site/client/${r.client._id}?populate=billing`)
            .then(r=>{
              this.dadosUsuario.client = r;
              if(r.billing){
                this.dadosUsuario.client.billing = r.billing;
                this.percentualCadastro=100;
              }
              this.carregaMenuSessoes();
              //this.carregaSessao();
            })
        }else{
          this.carregaMenuSessoes();
          
        }
      })
  }

  public isFutureUser:Boolean=false;
  public carregaMenuSessoes():void{  
    console.log(this.dadosUsuario);
    
    if(this.dadosUsuario.group != undefined){
      this.api.get(`${URL_API}/site/group/${this.dadosUsuario.group}`)
      .then(r=>{
        if(r.type=='adm'){
          this.isFutureUser=true;
        }
        this.sessoes=[];
        this.sessoes.push({label:"Dashboard",labelMenu:"Dashboard", icon: "fas fa-poll-h", route:'area-logada', disabled:false});
        this.sessoes.push({label:"Meus Dados",labelMenu:"Meus Dados", icon: "fas fa-user-cog", route:'area-logada/meus-dados',disabled:false});
        if(this.dadosUsuario.isAdm){
          this.sessoes.push({label:"Usuários",labelMenu:"Usuários", icon: "fas fa-users", route:'area-logada/usuarios',disabled:false});
          this.sessoes.push({label:"Financeiro",labelMenu:"Financeiro", icon: "fas fa-dollar-sign", route:'area-logada/financeiro',disabled:(!(this.usuarioAtivo))});
        }
        //this.sessoes.push({label:"Antifraude", labelMenu: "Antifraude", icon: "fas fa-user-shield", route:'area-logada/antifraude', disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        this.sessoes.push({label:"Consulta PF e PJ",labelMenu:"Consulta PF e PJ", icon: "fab fa-sistrix", route:'area-logada/consultas',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        // this.sessoes.push({label:"Restritivo PF",labelMenu:"Restritivo PF", icon: "fab fa-sistrix", route:'area-logada/consulta-fdnet-pf',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        // this.sessoes.push({label:"Restritivo PJ",labelMenu:"Restritivo PJ", icon: "fab fa-sistrix", route:'area-logada/consulta-fdnet-pj',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        // this.sessoes.push({label:"Consulta CNH",labelMenu:"Consulta CNH", icon: "far fa-id-card", route:'area-logada/consulta-cnh',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        // this.sessoes.push({label:"Consulta CNH SP",labelMenu:"Consulta CNH SP", icon: "far fa-id-card", route:'area-logada/consulta-cnh-sp',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        this.sessoes.push({label:"Histórico de Consultas",labelMenu:"Histórico", icon: "fas fa-history", route:'area-logada/historico-consultas',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        // this.sessoes.push({label:"Integração API",labelMenu:"Integração API", icon: "fas fa-desktop", route:'area-logada/integracao',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 100)});
        // if(this.dadosUsuario.isAdm){
        //   this.sessoes.push({label:"Enriquecimentos",labelMenu:"Enriquecimentos", icon: "fas fa-database", route:'area-logada/enriquecimentos',disabled:(!(this.usuarioAtivo) || this.percentualCadastro < 60)});
        // } 
        
        if(this.dadosUsuario.isAdm && this.isFutureUser){
          this.sessoes.push({label:"Clientes",labelMenu:"Clientes", icon: "fas fa-user-tie", route:'area-logada/clientes',disabled:false});
          this.sessoes.push({label:"Relatórios",labelMenu:"Relatórios", icon: "fas fa-chart-line", route:'area-logada/relatorios',disabled:false});
          this.sessoes.push({label:"Log Consulta",labelMenu:"Log Consulta", icon: "fas fa-list", route:'area-logada/log-consulta',disabled:false});
        }
        this.carregaSessao();
      })
    }
  }
  // <i class="fa-solid fa-magnifying-glass-chart"></i>
  public carregaSessao():void{
    this.sessoes.forEach(item=>{
      if(item.route == this.activeRoute){
        if(item.disabled){
          this.router.navigate(['/area-logada'])
        }else{
          this.sessaoAtiva.label = item.label;
          this.sessaoAtiva.icon = item.icon;
          setTimeout(()=>{
            this.carregandoSessao=false;
          },500);
        }
      }
    })
  }

  public closeMenu():void{
    this.showMenu=false;
  }

  public openMenu():void{
    this.showMenu=true;
  }

  public logout():void{
    this.loginService.logout();
    this.router.navigate(['/painel']);
  }

}
