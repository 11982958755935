import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estudos-webmotors',
  templateUrl: './estudos-webmotors.component.html',
  styleUrls: ['./estudos-webmotors.component.css']
})
export class EstudosWebmotorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
