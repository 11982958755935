import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ReCaptchaV3Service } from "ngx-captcha";
import { BsModalService, BsModalRef } from "ngx-bootstrap";

import { SITE_KEY_GCAPTCHA, URL_API, TOKEN_NAME } from "../app.api";
import { ApiServices } from "../apiServices.service";
import { LoginService } from "../login.service";

@Component({
  selector: "app-painel",
  templateUrl: "./painel.component.html",
  styleUrls: ["./painel.component.css"],
  providers: [ApiServices, LoginService],
})
export class PainelComponent implements OnInit {
  public formLogin: FormGroup;
  public formCadastro: FormGroup;

  public msgLogin: string = "";
  public msgLoginClass: string = "alert-warning";
  public txtSubmitLogin: string = "Entrar";
  public enviandoLogin: Boolean = false;

  public msgCadastro: string = "";
  public msgCadastroClass: string = "alert-warning";
  public txtSubmitCadastro: string = "Cadastrar";
  public enviandoCadastro: Boolean = false;

  public tokenGCaptcha: string = "";
  public modalRef: BsModalRef;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private modalService: BsModalService,
    private api: ApiServices,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    if (localStorage.getItem(TOKEN_NAME) !== null) {
      this.router.navigate(["/area-logada"]);
    } else {
      this.configFormLogin();
      this.configFormCadastro();
      this.getTokenCaptcha();
    }
  }

  public getTokenCaptcha(): any {
    return new Promise((resolve, reject) => {
      try {
        let key = SITE_KEY_GCAPTCHA;
        this.reCaptchaV3Service.execute(
          key,
          "logincadastro",
          (token) => {
            resolve(token);
          },
          {
            useGlobalDomain: false,
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  public configFormLogin(): void {
    this.formLogin = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      pass: new FormControl(null, [Validators.required]),
    });
  }

  public configFormCadastro(): void {
    this.formCadastro = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      pass: new FormControl(null, [Validators.required]),
      passConf: new FormControl(null, [Validators.required]),
      acceptMail: new FormControl(true),
    });
  }

  public enviarCadastro(): void {
    this.enviandoCadastro = true;
    this.msgCadastro = "";
    this.txtSubmitCadastro = "Enviando...";
    this.getTokenCaptcha()
      .then((token) => {
        this.tokenGCaptcha = token;
        let values = this.formCadastro.value;

        if (this.formCadastro.valid) {
          if (values["pass"] != values["passConf"]) {
            this.msgCadastro = "As senhas não estão iguais!";
            this.msgCadastroClass = "alert-danger";
            this.enviandoCadastro = false;
            this.txtSubmitCadastro = "Cadastrar";
            return;
          }

          if (this.tokenGCaptcha == "") {
            this.msgCadastro = "Ocorreu um erro no recaptcha!";
            this.msgCadastroClass = "alert-danger";
            this.enviandoCadastro = false;
            this.txtSubmitCadastro = "Cadastrar";
            return;
          }

          this.api
            .post(`${URL_API}/signup`, values, this.tokenGCaptcha)
            .then((r) => {
              if (r.success) {
                this.msgCadastro = `Cadastro enviado com sucesso! <br />Enviamos um link de validação do seu cadastro para o e-mail para ${values["email"]}.`;
                this.msgCadastroClass = "alert-success";
                this.enviandoCadastro = false;
                this.txtSubmitCadastro = "Cadastrar";
                this.resetFormCadastro();
              } else {
                if (
                  r.err.errmsg &&
                  r.err.errmsg.indexOf("email_1 dup key") != -1
                ) {
                  this.msgCadastro = "E-mail já existente no cadastro.";
                } else {
                  this.msgCadastro = r.msg;
                }
                this.msgCadastroClass = "alert-danger";
                this.enviandoCadastro = false;
                this.txtSubmitCadastro = "Cadastrar";
              }

              this.getTokenCaptcha();
            })
            .catch((r) => {
              this.msgCadastro = "Ocorreu um erro na requisição";
              this.msgCadastroClass = "alert-danger";
              this.enviandoCadastro = false;
              this.txtSubmitCadastro = "Cadastrar";
              this.getTokenCaptcha();
            });
        } else {
          this.msgCadastro = "Verique os campos obrigatórios";
          this.msgCadastroClass = "alert-warning";
          let campos = ["name", "email", "pass", "passConf", "acceptMail"];
          campos.forEach((campo) => {
            this.formCadastro.controls[campo].markAsTouched();
          });
          this.enviandoCadastro = false;
          this.txtSubmitCadastro = "Cadastrar";
        }
      })
      .catch((e) => {
        this.msgCadastro = "Ocorreu um erro na requisição do recaptcha";
        this.msgCadastroClass = "alert-danger";
        this.enviandoCadastro = false;
        this.txtSubmitCadastro = "Cadastrar";
      });
  }

  public enviarLogin(): void {
    let values = this.formLogin.value;

    if (this.formLogin.valid) {
      this.enviandoLogin = true;
      this.msgLogin = "";
      this.txtSubmitLogin = "Enviando...";

      this.loginService
        .logar(values.email, values.pass)
        .then((resposta: any) => {
          if (resposta.success) {
            this.msgLogin = `Usuário logado com sucesso.`;
            this.msgLoginClass = "alert-success";
            this.txtSubmitLogin = "Entrar";

            setTimeout(() => {
              this.router.navigate(["/area-logada"]);
            }, 1000);
          } else {
            this.msgLogin = resposta.message;
            this.msgLoginClass = "alert-danger";
            this.txtSubmitLogin = "Entrar";
          }
          this.enviandoLogin = false;
        })
        .catch((e: any) => {
          const erro = JSON.parse(e._body);
          this.msgLogin = erro.message;
          this.msgLoginClass = "alert-danger";
          this.txtSubmitLogin = "Entrar";
          this.enviandoLogin = false;
        });

      // this.api.post(`${URL_API}/auth`,values)
      // .then(r=>{
      //   if(r.success){
      //     this.msgLogin=`Usuário logado com sucesso.`;
      //     this.msgLoginClass= 'alert-success';
      //     this.enviandoLogin=false;
      //     this.txtSubmitLogin="Entrar";
      //   }else{
      //     this.msgLogin=r.message;
      //     if(r.message.indexOf('password') != -1){
      //       this.msgLogin='Falhou. Senha não confere';
      //     }
      //     if(r.message.indexOf('User') != -1){
      //       this.msgLogin='Falhou. Usuário não encontrado';
      //     }

      //     this.msgLoginClass= 'alert-danger';
      //     this.enviandoLogin=false;
      //     this.txtSubmitLogin="Entrar";
      //   }
      // })
      // .catch(r=>{
      //   this.msgLogin='Ocorreu um erro na requisição';
      //   this.msgLoginClass= 'alert-danger';
      //   this.enviandoLogin=false;
      //   this.txtSubmitLogin="Entrar";
      // })
    } else {
      this.msgLogin = "Verique os campos obrigatórios";
      this.msgLoginClass = "alert-warning";
      let campos = ["email", "pass"];
      campos.forEach((campo) => {
        this.formLogin.controls[campo].markAsTouched();
      });
    }
  }

  public resetFormCadastro(): void {
    let campos = ["name", "email", "pass", "passConf"];
    campos.forEach((campo) => {
      this.formCadastro.controls[campo].setValue("");
      this.formCadastro.controls[campo].markAsUntouched();
    });
  }

  public abreTermos(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
}
