import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-de-privacidade',
  templateUrl: './politica-de-privacidade.component.html',
  styleUrls: ['./politica-de-privacidade.component.css']
})

export class PoliticaDePrivacidadeComponent implements OnInit {
  
  atualizacaoTermo = 'Última atualização em 05 de Março de 2019.';

  termos = [
    {
      header: "1. Informações gerais",
      textos: [
        "Os dados reunidos em nosso banco de dados podem ser coletados de fontes oficiais, públicas, privadas (clientes e parceiros) ou informados por você, em conformidade com a lei, sendo ela o embasamento utilizado, baseado no legítimo interesse dos bancos de dados ou de terceiros, para gestão dos dados, controle de informações de crédito e para o cumprimento de obrigação contratual, regulatória ou legal ou no seu consentimento."
      ]
    },
    {
      header: "2. Dados coletados e finalidade do tratamento",
      textos: [
        "Ao se cadastrar e criar a sua conta em nosso site ou em nossos aplicativos e plataformas para utilizar nossas soluções você aceita as condições deste termo de uso, condições estas que estarão referenciadas nas linhas abaixo.",
        "Os Dados Pessoais são coletados, em geral, para: i) autenticação da sua identidade; ii) fins de apoiar a realização de negócios, projeções comerciais, administrar carteira de clientes, oferecer melhores serviços e gerir informações de crédito; e iii) promover nossas ações de marketing, dos nossos clientes e dos nossos parceiros, conforme detalhado nos tópicos abaixo.",
        "Quando você acessa nossos sites ou aplicativos e plataformas, você nos autoriza a coletar, a utilizar, a armazenar e a tratar, pelo prazo de 15 (quinze) anos, os dados de identificação de dispositivos, de endereços IP e aqueles coletados por meio de cookies em nossos sites ou aplicativos e plataformas, para as finalidades a seguir apresentadas:",
        "a. Proporcionar a você mais segurança em sua navegação nos sites ou nos aplicativos e plataformas;",
        "b. melhorar a sua experiência e as suas interações durante a navegação nos sites ou nos aplicativos e plataformas;",
        "c. identificar o seu perfil para permitir a oferta de informações e serviços mais adequados aos seus interesses;",
        "d. para finalidade de estudos de estatística, visando medir e analisar a audiência do site ou dos aplicativos e plataformas e de melhoria da comunicação com os usuários, sempre de maneira anônima, em conformidade com as leis vigentes. Esses dados anônimos são coletados e compartilhados com empresas parceiras.",
        "Coletamos dados pessoais eventualmente disponíveis publicamente em sites da internet, bem como os dados do seu dispositivo, podendo também ser coletada uma foto de seu rosto e documento de identidade com foto, caso o processo exija e você aceite completar a fase de biometria facial para a finalidade de confirmação de sua identidade e prevenção a fraudes em processos de validação de identidade, isso para garantir sua segurança e sigilo dos seus dados e acessos.",
        "Oferecer serviços, produtos e possibilitar nossas ações de marketing e de nossos clientes e parceiros, bem como para fins de pesquisas de satisfação e para campanhas com ofertas promocionais das nossas soluções e de nossos clientes e parceiros.",
        "O e-mail e telefone celular fornecido por você em seu cadastro poderá ser utilizado para o envio de qualquer comunicado nosso, incluindo, mas não se limitando, para informar a abertura de cadastro e alterações cadastrais, em atendimento à legislação em vigor, por meio de SMS, Whatsapp ou qualquer mensagem eletrônica.",
        "Não interferimos em nenhum processo que envolva a análise e aprovação de crédito dos nossos clientes ou parceiros, ocorridas ou não nos nossos sites ou aplicativos e plataformas, de modo que a decisão final de realização de um negócio, aprovação de crédito ou oferta sempre será do cliente ou parceiro, de acordo com os seus próprios critérios e políticas de crédito e de risco."
      ]
    },
    {
      header: "3. Requisitos de acesso aos nossos serviços",
      textos: [
        "Para acessar os serviços disponíveis de forma online, você tem ciência e concorda que realizemos um processo de validação dos dados fornecidos para fazer parte do cadastro mantido em nossos sites ou aplicativos e plataformas, pois isso é necessário para garantir que a sua identidade seja confirmada e você acesse de maneira segura os serviços oferecidos pela internet.",
        "Você é responsável pela exatidão, veracidade e pela atualização dos dados informados, concordando que eventuais divergências ou inconsistências desses dados poderão ocasionar a não validação da sua identidade, o que poderá impedir o acesso a todos ou a alguns dos serviços oferecidos por nós em todos os nossos meios de comunicação ou nossas plataformas."
      ]
    },
    {
      header: "4. Armazenamento dos Dados Pessoais",
      textos: [
        "Todos os dados recebidos, informados e tratados por nós são armazenadas de acordo com padrões rígidos de confidencialidade e segurança e podem ser repassados a clientes ou parceiros conforme este documento, na forma da lei ou em atendimento à ordem de autoridade administrativa ou judicial."
      ]
    },
    {
      header: "5. Direito de cancelamento de consentimento",
      textos: [
        "Você poderá cancelar seu consentimento quando os seus dados pessoais não forem usados para proteção a fraudes, não houver legítimo interesse no seu tratamento, não for necessário para o cumprimento de um dever legal, contratual ou regulatório da Future Data e de seus clientes ou não se enquadrar em quaisquer outras hipóteses legais que autorizem o tratamento de Dados Pessoais.",
        "O Consentimento poderá ser cancelado gratuitamente, mediante requerimento escrito, enviado via correio eletrônico para o endereço cancelamento@futuredata.com.br. Deverão ser solicitados quais os dados devem ser excluídos, discriminando um a um, para que não ocorram falhas na comunicação. Neste momento, excluiremos todas os dados que você tenha fornecido diretamente a nós e que dependam de consentimento para o seu tratamento na forma da lei vigente à época da sua solicitação de cancelamento.",
        "Os Dados Pessoais poderão ser mantidos em arquivo interno, indisponível para terceiros, caso seja necessário cumprir obrigações legais, regulatórias, ordens judiciais ou administrativas. Eles não serão, em nenhuma hipótese, divulgados, compartilhados ou repassados a nenhum cliente ou parceiro."
      ]
    },
    {
      header: "6. Correção de Dados Pessoais",
      textos: [
        "Você terá livre acesso aos seus dados a qualquer momento. Caso não estejam corretos ou não correspondam à verdade, você poderá pedir a sua atualização, retificação, modificação, o seu cancelamento ou o registro de anotação complementar, gratuitamente, mediante requerimento escrito, enviado via correio eletrônico para o endereço alteracao@futuredata.com.br.Deverão ser solicitados quais os dados devem ser excluídos, discriminando um a um, para que não ocorram falhas na comunicação.",
        "Os Dados Pessoais poderão ser mantidos em arquivo interno, indisponível para terceiros, caso seja necessário cumprir obrigações legais, regulatórias, ordens judiciais ou administrativas. Eles não serão, em nenhuma hipótese, divulgados, compartilhados ou repassados a nenhum cliente ou parceiro."
      ]
    },
    {
      header: "7. Responsabilidade pelo usuário e senha",
      textos: [
        "Você poderá acessar nosso site, plataformas e aplicativos mediante “login”, por meio de conta de usuário e senha exclusiva e individual de uso pessoal, intransferível e de seu conhecimento exclusivo.",
        "Você se responsabiliza pelo resguardo de sua conta de usuário e sua senha, não as repassando a nenhum terceiro, em hipótese alguma."
      ]
    },
    {
      header: "8. Envio de mensagens por meios eletrônicos e publicidade em mídias digitais",
      textos: [
        "Informações sobre os nossos serviços poderão ser enviadas a você por quaisquer canais, incluindo, mas não se limitando, por meios eletrônicos, como e-mail, SMS, WhatsApp ou via Redes Sociais.",
        "Você reconhece e aceita que os seus dados cadastrais e pessoais podem ser utilizados por nós para manter um relacionamento comercial e institucional com você. Podemos enviar a você comunicações periódicas relacionadas às novidades dos sites, às informações de seu interesse específico, para fins de marketing, pesquisas de satisfação e campanhas com ofertas promocionais das soluções oferecidas pela empresa e por parceiros.",
        "Para deixar de receber nossas campanhas promocionais e solicitações para participação em pesquisas por meios eletrônicos, como e-mail ou SMS, você deve entrar em contato via correio eletrônico com o endereço cancelamento@futuredata.com.br."
      ]
    },
    {
      header: "9. Relacionamento com terceiros",
      textos: [
        "A nossa Política de Privacidade é pertinente apenas ao uso dos dados pessoais conforme descrito neste documento e o nosso relacionamento com terceiros atende de forma completa a todos os requisitos definidos na lei vigente.",
        "Ao contratar outras empresas para serviços de apoio, exigimos delas a mesma garantia de privacidade, confidencialidade e segurança assegurada nos termos deste documento.",
        "Para a sua segurança, quaisquer conteúdos que sejam considerados confidenciais (como alteração de dados) não são enviados por meio de links."
      ]
    },
    {
      header: "10. Segurança de dados",
      textos: [
        "Protegemos a segurança durante o acesso aos nossos sites, nas transações e na captação de informações, por meio do processo de criptografia dos dados, utilizando o protocolo de segurança Secure Socket Layers (SSL) que comprova a autenticidade dos nossos sites, assim como assegura a integridade e a confidencialidade dos dados durante a sua transmissão."
      ]
    },
    {
      header: "11. Direitos autorais",
      textos: [
        "Todos os textos, imagens, sons e aplicativos exibidos nos nossos sites, aplicativos e plataformas são protegidos por direitos autorais. Não é permitido modificar, reproduzir, armazenar, transmitir, copiar, distribuir ou utilizar esses recursos de quaisquer outras formas para fins comerciais sem o nosso consentimento prévio e formal, o qual nunca será presumido.",
        "Tentativas de invasão aos nossos sites, aplicativos e plataformas serão consideradas como dano, roubo ou qualquer outra tipificação penal que corresponda às consequências da invasão."
      ]
    },
    {
      header: "12. Alterações deste documento",
      textos: [
        "Este documento está sujeito a alterações a qualquer momento, sempre buscando aperfeiçoar os nossos serviços em seu benefício. Toda e qualquer alteração visa a se adequar às eventuais modificações em nossos sites, sejam de mudanças para novas tecnologias ou sempre que for necessário, bem como a novos requisitos legais, regulatórios ou contratuais. Quando isso acontecer, informaremos a alteração por envio de e-mail ao endereço informado por você ou no seu próximo acesso aos nossos sites ou aplicativos.",
        "Caso você não concorde com as alterações incluídas no documento, você tem direito de solicitar o cancelamento de seu cadastro a qualquer tempo, seguindo item 5 deste documento.",
        "Nós exigimos dos nossos clientes o compromisso de utilizar os serviços contratados em conformidade com a lei, com os respectivos regulamentos, com os princípios da moral, dos bons costumes e de acordo com a ordem pública."
      ]
    },
    {
      header: "13. Canais de Atendimento",
      textos: [
        "Os usuários podem falar e tirar dúvidas por meio de correio eletrônico	via endereço contato@futuredata.com.br. Os Usuários também podem fazer reclamações por meio de correio eletrônico via endereço sac@futuredata.com.br."
      ]
    }
  ]

  constructor() { }

  ngOnInit() {
    
  }
}