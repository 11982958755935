import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'app-filial-pj',
    templateUrl: './filial-pj.component.html',
    styleUrls: ['./../cadastral-pj/cadastral-pj.component.css']
})

export class FilialPjComponent implements OnInit{

    @Input() dados:any;
    @Output() documentoFilial = new EventEmitter();
    
    public txtButtonConsultar:string='Consultar agora';
    public consultando:Boolean=false;
    public naoSolicitou:Boolean=false;
    public naoEncontrouRegistros:Boolean=false;
    public modalRef:BsModalRef;

    constructor(private modalService:BsModalService) {}

    ngOnInit(): void { 

        if(this.dados.filial === undefined){
            this.naoSolicitou = true;
        } else if(this.dados.filial === null) {
            this.naoEncontrouRegistros = true;
        }
    }

    documentoEmitter(documento) {

        this.documentoFilial.emit(documento);
        console.log(documento);
    
      }
    
      public capturaDoc(documento) {
    
        let docFilial = documento;
    
        this.documentoEmitter(docFilial);
      }
}