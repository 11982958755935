import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiServices } from 'src/app/apiServices.service';
import { Router } from '@angular/router';
import { URL_API } from 'src/app/app.api';
import { UploadService } from 'src/app/upload.service';

@Component({
  selector: 'app-enriquecimentos',
  templateUrl: './enriquecimentos.component.html',
  styleUrls: ['./enriquecimentos.component.css'],
  providers:[ApiServices] 
})
export class EnriquecimentosComponent implements OnInit {

  @Input() dadosUsuario:any;

  public registros:any[]=[]; 
  public clientes:any[]=[];
  public registroExcluir:any = null;
  public modalRef:BsModalRef;
  public msg:String='';
  public msgClass:String='alert-info';
  public txtSubmitCadastro:string = 'Salvar';
  public enviandoCadastro:Boolean = false;
  public salvandoUsuario:Boolean = false;
  public showLista:Boolean=true;
  public showForm:Boolean=false;
  public txtButtonUpload:string = 'Upload';
  public uploadResponse:any = {};
  public error: String;
  public currentFileUpload: Array<File> = null;
  public currentFileUploadRetorno: Array<File> = null;
  @ViewChild('fileUpload') public myfileUpload: ElementRef;
  @ViewChild('fileUploadRetorno') public myfileUploadRetorno: ElementRef;
  public enviandoUpload:Boolean=false;
  public form: FormGroup;
  public loadingRegistros:Boolean=true;
  public loadingClientes:Boolean=true;
  public clienteSelecionado:String = '';
  public linhaSelecionada:String = '';

  public isFutureUser:Boolean=false;

  constructor(private formBuilder: FormBuilder,private api:ApiServices,private uploader:UploadService, private router:Router,private modalService:BsModalService ) { }

  ngOnInit() {
    
    this.form = this.formBuilder.group({
      arquivo: [''],
      //cliente:[''],
      linhaSelecionada:['']
    });
    
    console.log(this.dadosUsuario);
    
    if(this.dadosUsuario.group != undefined){
      this.api.get(`${URL_API}/api/group/${this.dadosUsuario.group}`)
      .then(r=>{
        if(r.type=='adm'){
          this.isFutureUser=true;
          this.loadArquivos();
          this.api.get(`${URL_API}/api/client?deleted=false&status=true`)
          .then(r=>{
            this.clientes = r;
            this.loadingClientes=false;
          })
        }else{
          this.loadArquivos();
        }
      })
    }else{
      this.loadArquivos();
    }
    
  }

  // public selecionaCliente(e:any){
  //   this.clienteSelecionado = e.target.value;
  // }

  public selecionaLinha(linha:any){
    if(this.isFutureUser){
      if(this.linhaSelecionada == linha._id){
        this.linhaSelecionada = '';
      }else{
        this.linhaSelecionada = linha._id;
      }
      this.form.get('linhaSelecionada').setValue(this.linhaSelecionada);
    }
    
  }


  public loadArquivos():void{
    this.loadingRegistros = true;
    let client = this.dadosUsuario.client._id; 
    let url = `${URL_API}/api/client-ftp?client=${client}&populate=client user userOut&sort=-createAt`;
    if(this.isFutureUser){
      url = `${URL_API}/api/client-ftp?populate=client user userOut&sort=-createAt`;
    }
    
    this.api.get(url)
    .then(r=>{
      this.loadingRegistros=false;
      this.registros = r;      
      
      // this.registros.forEach(u=>{
      //   u['editando']=false;
      // })
    })
    
  }

  
  public selectFile(event):void{
    this.currentFileUpload = <Array<File>>event.target.files;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('arquivo').setValue(file);
    }
  }

  public selectFileRetorno(event):void{
    this.currentFileUploadRetorno = <Array<File>>event.target.files;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('arquivo').setValue(file);
    }
  }
  

  public resetInputUpload():void{
    
    if(this.isFutureUser && this.linhaSelecionada != ''){
      this.myfileUploadRetorno.nativeElement.value = "";
    }
    if(!this.isFutureUser){
      this.myfileUpload.nativeElement.value = "";
    }
    this.currentFileUpload=null;
    this.currentFileUploadRetorno=null;
    this.txtButtonUpload = 'Upload';
    this.enviandoUpload=false;
    this.form.get('arquivo').setValue('');
    //this.form.get('cliente').setValue('');
    setTimeout(()=>{
      this.msg='';
    },3000)
  }

  
  public upload():void{
    this.msg='';
    this.msgClass='alert-sucsess';
    this.txtButtonUpload = 'Enviando...';
    this.enviandoUpload=true;
    const formData: FormData = new FormData();
    // const files: Array<File> = this.currentFileUpload;

    // for(let x=0;x<files.length;x++){
    //   formdata.append('files[]', files[x]);
    // }
    // if(this.clienteSelecionado != ''){
    //   formData.append('client', this.form.get('cliente').value);  
    // }
    if(this.linhaSelecionada != ''){
      formData.append('id', this.form.get('linhaSelecionada').value);  
    }
    formData.append('file', this.form.get('arquivo').value);
    
    this.uploader.upload(formData)
    .subscribe(
      (res) => {
        this.uploadResponse = res;
        if(this.uploadResponse.status && this.uploadResponse.status=='ok'){
          this.msg='Upload realizado com sucesso!';
          this.msgClass='alert-success';
          this.resetInputUpload();
          this.loadArquivos();
        }
        if(this.uploadResponse.status && this.uploadResponse.status=='progress'){
          if(this.uploadResponse.message < 100){
            this.txtButtonUpload = 'Enviando...'+this.uploadResponse.message+'%';
          }else{
            this.txtButtonUpload = 'Enviado...'+this.uploadResponse.message+'% Processando arquivo...';
          }
          
        }
        //
      },
      (err) => {
        this.msg='Erro ao enviar o arquivo';
        this.msgClass='alert-danger';
        this.error = err;
        this.resetInputUpload();
        console.log(this.error);
        
      }
    );
  }
}