<div class="bloco">
  <h5 class="titulo">Exames</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="dados.Exames.qtdExames === '0'">
    <div class="row">
      <div class="col-auto item">Nenhum exame encontrado</div>
    </div>
  </div>
  
  <div *ngIf="dados.Exames.qtdExames > 0">
    <div class="row">
        <table class="table table-sm ">
            <thead>
              <tr>
                <th>Exame</th>
                <th>Data exame</th>
                <th>Data validade</th>
                <th>Categoria pretendida</th>
                <th>Categoria permitida</th>
                <th>Resultado</th>
                <th>Observação</th>
                <th>Restrição</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dados.Exames.registro">
                <td>{{row.Exame}}</td>
                <td>{{row.DataExame}}</td>
                <td>{{row.DataValidade}}</td>
                <td>{{row.CategoriaPretendida}}</td>
                <td>{{row.CategoriaPermitida}}</td>
                <td>{{row.Resultado}}</td>
                <td>{{row.Observacao}}</td>
                <td>{{row.Restricao}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>