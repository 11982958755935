<!-- ========= Inner Page Banner Section Start ========= -->

<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Fale conosco</h1>
        <div class="sub_heading_page">Entre em contato com a nossa equipe</div>
      </div>
    </div>
  </div>
</section>

<!-- ========= Inner Page Banner Section End ========= --> 

<!-- ========= Contact Form and Address Section Start ========= -->

<app-formContato text="Entre em contato conosco"></app-formContato>

<!-- ========= Contact Form and Address Section Start ========= --> 

<!-- ========= Contact Info Section Start ========= -->