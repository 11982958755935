import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substr'
})
export class SubstrPipe implements PipeTransform {

  transform(value: string, inicio: number, fim: number): any {

    value = value.toString();

    if (!inicio && !fim) {
      return value;
    }

    if (!inicio && fim) {
      return (value.substr(0, fim));
    }

    if (inicio && !fim) {
      return (value.substr(inicio));
    }

    if (inicio && fim) {
      return (value.substr(inicio, fim));
    }
  }

}
