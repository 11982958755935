import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-accordion-termos',
    templateUrl: 'accordion.component.html',
    styleUrls: ['./accordion.component.css']
})

export class AccordionComponent implements OnInit{

    @Input() header='';
    @Input() textos='';

    constructor() {}

    ngOnInit(): void {

    }

}