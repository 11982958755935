/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./estudos-cyrela.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./estudos-cyrela.component";
var styles_EstudosCyrelaComponent = [i0.styles];
var RenderType_EstudosCyrelaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EstudosCyrelaComponent, data: {} });
export { RenderType_EstudosCyrelaComponent as RenderType_EstudosCyrelaComponent };
export function View_EstudosCyrelaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["border", "0"], ["frameborder", "0"], ["height", "600"], ["src", "https://app.powerbi.com/view?r=eyJrIjoiMDA5YzIwMjYtMGNhYi00NTdlLThhNTItMzlhYTY0MTAyMzEwIiwidCI6IjY3N2Q0MGUyLTA2ZmEtNGM0Yi1iN2UyLTM4NWQ4NjNjYjczOSJ9"], ["style", "margin: 10px 0"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rodapeMapa"]], null, null, null, null, null))], null, null); }
export function View_EstudosCyrelaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-estudos-cyrela", [], null, null, null, View_EstudosCyrelaComponent_0, RenderType_EstudosCyrelaComponent)), i1.ɵdid(1, 114688, null, 0, i2.EstudosCyrelaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstudosCyrelaComponentNgFactory = i1.ɵccf("app-estudos-cyrela", i2.EstudosCyrelaComponent, View_EstudosCyrelaComponent_Host_0, {}, {}, []);
export { EstudosCyrelaComponentNgFactory as EstudosCyrelaComponentNgFactory };
