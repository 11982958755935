<section class="page_banner internal">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Ativacao de conta</h1>
        <div class="sub_heading_page">Aguarde enquano o sistema valida o seu <strong>token</strong> de ativação!</div> 
      </div>
    </div>
  </div>
</section>

<div class="container">
  <div class="">
    <h2 *ngIf="processando" class="text-dark">Processando Token <span class="text-primary">{{token}}</span>. Aguarde{{pontos}}</h2>
    <h2 *ngIf="!processando && sucesso" class="text-primary">{{mensagem}} <button class="btn btn-lg btn-success" routerLink="/painel">Ir para Login</button></h2>
    <h2 *ngIf="!processando && !sucesso" class="text-danger">{{mensagem}}</h2>
  </div>
</div>
  
  