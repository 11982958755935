import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { ApiServices } from "src/app/apiServices.service";
import { URL_API, URL_API_V2 } from "src/app/app.api";

@Component({
  selector: "app-relatorios",
  templateUrl: "./relatorios.component.html",
  styleUrls: ["./relatorios.component.css"],
  providers: [ApiServices],
})
export class RelatoriosComponent implements OnInit {
  @Input() dadosUsuario: any;
  @Input() isFutureUser: Boolean;
  anoAtual = new Date().getFullYear();
  mesAtual = new Date().getMonth() + 1;
  periodoAtualMaisUsadas = "1-today";

  rangeAno = [new Date().getFullYear() - 1, new Date().getFullYear()];
  rangeMes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  maisUsadasPeriodos = [
    { label: "Hoje", value: "1-today" },
    { label: "Última hora", value: "1-hours" },
    { label: "Últimas 2 horas", value: "2-hours" },
    { label: "Últimas 6 horas", value: "6-hours" },
    { label: "Últimas 12 horas", value: "12-hours" },
    { label: "Últimas 24 horas", value: "1-days" },
    { label: "Últimos 7 dias", value: "7-days" },
    { label: "Últimos 15 dias", value: "15-days" },
    { label: "Últimos 30 dias", value: "30-days" },
  ];

  totalFaturamento: number = 0;
  faturas: any[] = [];
  maisUsadas: any[] = [];
  maisUsadasFiltrados: any[] = [];
  carregandoFaturas: boolean = false;
  carregandoMaisUsadas: boolean = false;
  sort: string = "total";
  order: string = "desc";

  columns = [
    {
      label: "Consulta",
      field: "queryName",
    },
    {
      label: "Fornecedor",
      field: "supplier",
    },
    {
      label: "Sucessos",
      field: "success",
    },
    {
      label: "Erros",
      field: "errors",
    },
    {
      label: "Total",
      field: "total",
    },
    {
      label: "Tempo médio",
      field: "avgTime",
    },
    {
      label: "Assertividade %",
      field: "accuracy",
    },
  ];

  

  constructor(
    private api: ApiServices,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.carregarFaturamento();
    this.carregarConsultasMaisUsadas();
  }

  public async carregarFaturamento(): Promise<void> {
    this.carregandoFaturas = true;

    const reference = `${this.anoAtual}-${this.mesAtual
      .toString()
      .padStart(2, "0")}`;
    const billing = await this.api.get(
      `${URL_API_V2}/panel/finances/billing/${reference}`
    );
    const filtered = billing.filter((row) => row.total < 0);
    const total = filtered
      .map((row) => row.total)
      .reduce((a, v) => {
        return a + v;
      });
    this.totalFaturamento = parseFloat(Math.abs(total).toFixed(2));
    this.faturas = filtered;
    this.carregandoFaturas = false;
  }

  public changeSort(sort: string = "total", order: string = "asc"): void {
    if (this.isSorted(sort)){
      this.order = this.order === 'asc' ? 'desc' : 'asc';
    }else{
      this.order = order;
    }
    this.sort = sort;
    
    this.carregarConsultasMaisUsadas();
  }

  public async carregarConsultasMaisUsadas(): Promise<void> {
    this.carregandoMaisUsadas = true;
    const parsePeriodo = this.periodoAtualMaisUsadas.split("-");
    const [qtd, frequencia] = parsePeriodo;

    const mostUseds = await this.api.get(
      `${URL_API_V2}/panel/log-query/mosts-used/last/${qtd}/${frequencia}?sort=${this.sort}&order=${this.order}`
    );
    // const mostUseds = await this.api.get(`${URL_API_V2}/panel/log-query/mosts-used/last/${qtd}/${frequencia}`);

    this.maisUsadas = mostUseds.rows;
    this.maisUsadasFiltrados = this.maisUsadas;

    this.carregandoMaisUsadas = false;
  }

  private isSorted(field:string):boolean{
    return this.sort === field;
  }

  private filterMaisUsadas(e:any):void{
    const value = e.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    
    this.maisUsadasFiltrados = this.maisUsadas.filter((e:any)=>{
      for(const c of this.columns){
        if(e[c.field]){          
          const valueField = e[c.field].toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          if(valueField.indexOf(value) !== -1){
            return true
          }
        } 
      }
      return false;
    })
  }

  private isSortedAsc(field:string):string{
    if (this.sort === field){
      return this.order==='asc'?'sorted':''
    }
    return '';
  }

  private isSortedDesc(field:string):string{
    if (this.sort === field){
      return this.order==='desc'?'sorted':''
    }
    return '';
  }
}
