<div class="bloco">
  <h5 class="titulo">CNAE</h5>
  <div *ngIf="naoEncontrouRegistros">
      <div class="row">
        <div class="col-auto item">Nenhum registro encontrado <i class="far fa-frown"></i></div>
      </div>
  </div>
  <div *ngIf="naoSolicitou">
      <div class="row">
          <div class="col-auto item">Você não solicitou essa consulta.</div>
        <!-- <div class="col-auto item"><button (click)="consultar()" [disabled]="consultando" class="btn btn-primary"><i class="fas fa-search"></i> Você não solicitou essa consulta. {{txtButtonConsultar}}</button></div> -->
      </div>
  </div>
  <div *ngIf="dados.cnae">
    <div class="row">
      <table class="table table-sm ">
        <thead>
          <tr>
            <th>Seção</th>
            <th>Código</th>
            <th>CNAE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dados.cnae">
            <td>{{row.secao}}</td>
            <td>{{row.cod_cnae}}</td>
            <td>{{row.cnae}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  
  