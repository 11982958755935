<section class="page_banner big_data">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Big Data</h1>
        <div class="sub_heading_page">Alta tecnologia para tratamento de dados</div>
      </div>
    </div>
  </div>
</section>  
  
<section class="safe_host">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Big Data</h3>
        </div>
      </div>
      <div class="pr_info">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="info_right">
              <p class="text-left">Com a utilização desta solução podemos gerenciar todo o processo percorrido pela informação, proporcionando dados assertivos para consumo e estudos de caso, utilizando-se também do conceito de inferência de dados. Através do uso de nossa solução Big Data podemos entregar informações que possibilitem a realização de projeções comerciais, planejamento estratégico, otimização empresarial, prospecção, dentre outras ações.
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-formContato text="Entre em contato conosco"></app-formContato>