import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-refin',
  templateUrl: './refin.component.html',
  styleUrls: ['./refin.component.css', './../fdnet.component.css']
})
export class RefinComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.dados.refin.registro.length === 0) {
      this.naoEncontrouRegistros = true;
    }
  }

}
