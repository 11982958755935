import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dados-cadastrais',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.css' , './../fdnet.component.css']
})
export class DadosCadastraisComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.dados.dados_cadastrais === undefined){
      this.naoEncontrouRegistros = true;
    }
  }

}
