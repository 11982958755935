/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-bootstrap/accordion/ngx-bootstrap-accordion.ngfactory";
import * as i3 from "ngx-bootstrap/accordion";
import * as i4 from "@angular/common";
import * as i5 from "./accordion.component";
var styles_AccordionComponent = [i0.styles];
var RenderType_AccordionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccordionComponent, data: {} });
export { RenderType_AccordionComponent as RenderType_AccordionComponent };
function View_AccordionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "text-justify recuo"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_AccordionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "accordion", [["class", "accordion-section panel-group"], ["role", "tablist"], ["style", "width: 100% display: block"]], [[1, "aria-multiselectable", 0]], null, null, i2.View_AccordionComponent_0, i2.RenderType_AccordionComponent)), i1.ɵdid(1, 49152, null, 0, i3.AccordionComponent, [i3.AccordionConfig], { isAnimated: [0, "isAnimated"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "accordion-group", [["class", "accordion-section-tittle panel"], ["style", "display: block"]], [[2, "panel-open", null]], null, null, i2.View_AccordionPanelComponent_0, i2.RenderType_AccordionPanelComponent)), i1.ɵdid(3, 245760, null, 0, i3.AccordionPanelComponent, [i3.AccordionComponent], { heading: [0, "heading"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_AccordionComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; _ck(_v, 1, 0, currVal_1); var currVal_3 = _co.header; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.textos; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).closeOthers; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 3).isOpen; _ck(_v, 2, 0, currVal_2); }); }
export function View_AccordionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accordion-termos", [], null, null, null, View_AccordionComponent_0, RenderType_AccordionComponent)), i1.ɵdid(1, 114688, null, 0, i5.AccordionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccordionComponentNgFactory = i1.ɵccf("app-accordion-termos", i5.AccordionComponent, View_AccordionComponent_Host_0, { header: "header", textos: "textos" }, {}, []);
export { AccordionComponentNgFactory as AccordionComponentNgFactory };
