import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-infracoes-cnh',
  templateUrl: './infracoes-cnh.component.html',
  styleUrls: ['./../consulta-cnh.component.css', './infracoes-cnh.component.css']
})
export class InfracoesCnhComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.dados.Infracoes === undefined){
      this.naoEncontrouRegistros = true;
    } else {
      if(!Array.isArray(this.dados.Infracoes.registro)){
        let {registro} = this.dados.Infracoes;
        this.dados.Infracoes.registro = [registro];
      }
    }
  }

}
