<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Portal e API</h1>
        <div class="sub_heading_page">Mais de 245 milhões de pessoas físicas e 40 milhões de pessoas jurídicas</div>
      </div>
    </div>
  </div>
</section>

<section class="safe_host">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Portal de consultas</h3>
        </div>
      </div>
      <div class="pr_info">
        <div class="col-md-6 col-md-offset-1 col-sm-6 col-xs-12">
          <div class="info_right">
            <ul class="text-left">
              <li> <span>Realize consultas dentro do nosso portal de forma fácil e intuitiva. Combine os retornos que deseja e tenha rapidamente em mãos os dados solicitados. Além da consulta, nossa área do cliente também conta com um painel administrativo, que possibilita a gerência dos usuários, histórico de consultas e contém os manuais de integrações.</span></li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 col-sm-6 col-xs-12"> <img src="assets/images/portal-api.jpg" alt="" title="" /></div>
      </div>
    </div>
  </div>
</section>

<section class="text_center">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>Tipos de consultas</h3>
        </div>
      </div>
      <div class="why_choose_hosting">
        <div class="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-duration=".3" data-wow-delay=".1s">
          <div class="hosting_box"><i class="fa fa-user" aria-hidden="true"></i>
            <h5 class="section_sub_heading">Pessoa Física</h5>
            <p>Informações disponíveis do individuo:</p>
            <ul class="text-left">
              <li>- Cadastral</li>
              <li>- Localização</li>
              <li>- Contato</li>
              <li>- Restritivos</li>
              <li>- Documentos</li>
              <li>- Renda estimada</li>
              <li>- Nível de experiência profissional</li>
              <li>- Área profissional</li>
              <li>- Pessoa politicamente exposta</li>
              <li>- Escolaridade</li>
              <li>- Nacionalidade</li>
              <li>- Imposto de renda</li>
              <li>- Histórico dos bancos onde possui conta</li>
              <li>- Patrimônio</li>
              <li>- Relação entre indivíduos e empresas</li>
            </ul>
            </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-duration=".3" data-wow-delay=".3s">
          <div class="hosting_box"><i class="fas fa-user-tie"></i>
            <h5 class="section_sub_heading">Pessoa Jurídica</h5>
            <p>Informações disponíveis de empresas:</p>
            <ul class="text-left">
              <li>- Cadastral</li>
              <li>- Localização</li>
              <li>- Contato</li>
              <li>- Restritivos</li>
              <li>- Quadro societário</li>
              <li>- Documento</li>
              <li>- Representante legal</li>
              <li>- Balanço das empresas S.A.</li>
              <li>- Faturamento estimado</li>
              <li>- Quantidade de funcionários estimado</li>
              <li>- Patrimônio</li>
              <li>- Relação entre empresas e indivíduo</li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-duration=".3" data-wow-delay=".5s">
          <div class="hosting_box"><i class="fa fa-car" aria-hidden="true"></i>
            <h5 class="section_sub_heading">Veicular</h5>
            <p>Principais informações disponíveis:</p>
            <ul class="text-left">
              <li>- Proprietário</li>
              <li>- Histórico de proprietários</li>
              <li>- Dados cadastral</li>
              <li>- Débitos e Restrições</li>
              <li>- Leilão</li>
              <li>- Decodificador de Chassis e Preços</li>
              <li>- Decodificador de Chassis e Preços com Histórico Fipe 12 meses e valor 0KM</li>
              <li>- Recall</li>
              <li>- Nacional</li>
              <li>- Agregados</li>
              <li>- CNH;</li>
              <li>- Gravames</li>
              <li>- Histórico Roubo e Furto</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="safe_host">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h3>API RESTful</h3>
        </div>
      </div>
      <div class="pr_info">
        <div class="col-md-5 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".1s"> <img src="assets/images/wordpess.jpg" alt="" title="" /> </div>
        <div class="col-md-6 col-md-offset-1 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".2s">
          <div class="info_right">
            <ul class="text-left">
              <li> <span>Todas as soluções oferecidas são passíveis de consumo via API RESTful por meio de processo de integração. Possuímos dezenas de rotas disponíveis com arquitetura modularizada, trazendo flexibilidade a estrutura e a customização. Todas as APIs contam com manual de integração e suporte para seu consumo.</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-formContato text="Entre em contato conosco"></app-formContato>