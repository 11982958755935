import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pontuacao-cnh',
  templateUrl: './pontuacao-cnh.component.html',
  styleUrls: ['./../consulta-cnh.component.css', './pontuacao-cnh.component.css']
})
export class PontuacaoCnhComponent implements OnInit {

  @Input() dados: any;
  public naoSolicitou: Boolean = false;
  public naoEncontrouRegistros: Boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.dados.Pontuacao === undefined){
      this.naoEncontrouRegistros = true;
    }
  }

}
