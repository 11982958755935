
<div class="" *ngIf="carregandoTela">
  Aguarde...
</div>
<div class="" *ngIf="showResultado">
  <div class="botoes d-flex justify-content-end noprint">
    <button class="btn btn-primary" (click)="voltar()"><i class="fas fa-search"></i> Nova consulta</button>
    <button class="btn btn-secondary" (click)="voltarHistorico()"><i class="fas fa-history"></i> Histórico consultas</button>
    <button class="btn btn-info" (click)="print();"><i class="fas fa-print"></i> Imprimir</button>
  </div>
    <div class="" *ngIf="carregandoResultado">
      Carregando o resultado da consulta
    </div>
    <div class="resultadoBusca mb-3" id="resultadoConsulta" *ngIf="!carregandoResultado">
      <div class="bloco">
        <h5 class="titulo">Informações da Consulta</h5>
        
        <div class="row">
            <div class="item col-auto">
                <label>Pesquisou por</label>
                {{consulta.input | inputConsulta}}
              </div>
        </div>
        <div class="row">
          <div class="item col-auto">
            <label>ID</label>
            {{consulta._id}}
          </div>
          
          <div class="item col-auto">
            <label>Segmento</label>
            {{ segmentoSelecionado | uppercase }}
          </div>
          <div class="item col-auto">
            <label>Data Início</label>
            {{consulta.startAt | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <div class="item col-auto">
            <label>Data Fim</label>
            {{consulta.endAt | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <div class="item col-auto">
            <label>Tempo total</label>
            {{consulta.time/1000}}
          </div>
          <div class="item col-auto">
            <label>Solicitante</label>
            {{consulta.user.name}}
          </div>
        </div>
      </div>
      
      <ng-container>
        <app-dados-cadastrais [dados]="consulta.output.dados"></app-dados-cadastrais>
        <app-fdnet-resumo [dados]="consulta.output.dados"></app-fdnet-resumo>
        <app-pefin [dados]="consulta.output.dados"></app-pefin>
        <app-refin [dados]="consulta.output.dados"></app-refin>
        <app-dividas-vencidas [dados]="consulta.output.dados"></app-dividas-vencidas>
        <app-protesto-estadual [dados]="consulta.output.dados"></app-protesto-estadual>
        <app-credito-ccf [dados]="consulta.output.dados"></app-credito-ccf>
      </ng-container>
    </div>

</div>
<div class="" *ngIf="showBusca">
  <div class="login_form">
      <!-- <button (click)="getPermissoesCliente()">Carrega permissões</button> -->
      <div class="" *ngIf="carregandoPermissoes">Carregando permissões de acesso</div>
      <div class="" *ngIf="!carregandoPermissoes">

        <div class="clearfix"></div>
        <div class="row">
          <div class="col-9">
            <label class="input campoBusca">
              <input class="inputpf" *ngIf="segmentoSelecionado=='pf'" placeholder="Digite o CPF" type="text" autocomplete="false" [(ngModel)]="palavraChave" (blur)="validaPalavraChaveUF()" >
              <input class="inputpj" *ngIf="segmentoSelecionado=='pj'" placeholder="Digite o CNPJ" type="text" autocomplete="false" [(ngModel)]="palavraChave" (blur)="validaPalavraChaveUF()">
            </label>
          </div>
          <div class="col-3">
            <label class="input campoBusca">
              <select class="{{segmentoSelecionado=='pf' ? 'inputpf' : 'inputpj'}}" [(ngModel)]="ufSelecionada"  (change)="validaPalavraChaveUF()">
                <option value="">Selecione uma UF</option>
                <ng-container *ngFor="let uf of ufs">
                  <option [value]="uf.sigla">{{uf.sigla}}</option>
                </ng-container>
              </select>
            </label>
          </div>
        </div>
        <div class="col-12">
          <div class="alert-danger msgPalavraChave" *ngIf="msgPalavraChave!=''"><i class="fas fa-exclamation-triangle"></i> &nbsp; {{msgPalavraChave}}</div>
        </div>

        

        
        <div class="col-12 d-flex justify-content-around mt-2">
          <button class="btn btn-lg btn-danger btn-block btn-consultar" (click)="enviar()" [disabled]="isBlockedSubmit()" type="button">
            <i class="fas fa-chevron-circle-right"></i> {{txtSubmit}}
          </button>
        </div>

        <div class="col-12 " *ngIf="msg!=''">
          <div class="col-12 section msg {{msgClass}}">
            <span [innerHTML]="msg"></span>
          </div>
        </div>
        

      </div>
  </div>
</div>