import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServices } from 'src/app/apiServices.service';
import { URL_API, URL_API_V2 } from 'src/app/app.api';
import { GenericValidators } from 'src/validators/generic-validators';

@Component({
  selector: 'app-fdnet',
  templateUrl: './fdnet.component.html',
  styleUrls: ['./fdnet.component.css']
})
export class FdnetComponent implements OnInit {

  @Input() dadosUsuario: any;
  public classSegmentoBusca: string = 'primary';
  @Input() segmentoSelecionado: string;
  public selecionarTodosPF: Boolean = false;
  public selecionarTodosPJ: Boolean = false;
  public msg: string = '';
  public msgPalavraChave: string = '';
  public msgClass: string = 'alert-info';

  public palavraChave: string = '';
  public ufSelecionada: string = '';

  public erroPalavraChave: Boolean = false;
  public consultando: Boolean = false;
  public campoBuscar = 'cpf';
  public txtSubmit: string = 'Consultar';

  public carregandoTela: Boolean = true;
  public showBusca: Boolean = false;
  public showResultado: Boolean = false;
  public resultadosBusca: any = { dados: [] };
  public carregandoResultado: Boolean = true;
  public logid: string = '';
  public segmentosBusca: any[] = [];

  public permissoes: any = null;
  public carregandoPermissoes: Boolean = true;
  public isPreConsulta: Boolean = false;

  public ufs: any[] = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' }
  ];

  constructor(private api: ApiServices, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe((p: any) => {
      if (p.logid != undefined) {
        this.logid = p.logid;
        this.showResultado = true;
        this.showBusca = false;
        this.getResultadoConsulta();
      } else {
        this.showBusca = true;
        this.showResultado = false;
      }
      this.carregandoTela = false;


      this.getPermissoesCliente();
    });

  }
  public getPermissoesCliente(): void {
    this.segmentosBusca = [];
    this.carregandoPermissoes = true;
    this.api.get(`${URL_API}/site/client/getPermissions`)
      .then(r => {
        this.permissoes = r;
        this.carregandoPermissoes = false;
      })
      .catch(e => console.log(e));
  }

  public hasPermission(code: String, feature: String): Boolean {
    let r = false;
    this.permissoes.queries.forEach(q => {
      if (feature == undefined) {
        if (q.code == code && q.permission) {
          r = true;
        }
      } else {
        if (q.code == code && q.permission) {
          q.features.forEach(f => {
            if (f.name == feature && f.permission) {
              r = true;
            }
          })
        }
      }
    })

    return r;
  }

  public consulta: any;

  public getResultadoConsulta(): void {
    this.showBusca = false;
    this.showResultado = true;
    this.carregandoResultado = true;

    this.api.get(`${URL_API}/site/log-query/${this.logid}`)
      .then(r => {

        this.consulta = r.obj;
        this.carregandoResultado = false;
      })
      .catch(e => console.log(e))
  }

  public changeSegmento(segmento: string): void {
    this.segmentoSelecionado = segmento;
    if (segmento == 'veiculo') {
      this.msg = 'Consulta de Veículos está em fase experimental';
      this.msgClass = 'alert-info';
    } else {
      this.msg = '';
    }
    this.segmentosBusca.forEach(seg => {
      if (seg.value == this.segmentoSelecionado) {
        this.classSegmentoBusca = seg.class;
      }
    })

    this.validaPalavraChaveUF();
  }

  public getTextoPesquisado(input: any): string {
    if (input.nome != undefined) {
      return input.nome;
    } else if (input.telefone != undefined) {
      return input.telefone;
    } else if (input.cpf != undefined) {
      return input.cpf;
    } else if (input.cnpj != undefined) {
      return input.cnpj;
    }
  }

  public validaPalavraChaveUF(): void {
    let qtdValidacoes;
    let qtdErros = 0;

    if (this.segmentoSelecionado === 'pf') {
      qtdValidacoes = 1;

      if (!GenericValidators.isValidCpf(this.palavraChave)) {
        qtdErros++;
      }

      this.campoBuscar = 'cpf';

      if (qtdErros === qtdValidacoes && this.palavraChave.length >= 3) {
        this.msgPalavraChave = 'A palavra chave não parece ser um CPF';
        this.erroPalavraChave = true;
      } else if (this.ufSelecionada === '') {
        this.msgPalavraChave = 'A UF não pode ser vazia';
        this.erroPalavraChave = true;
      } else {
        this.erroPalavraChave = false;
        this.msgPalavraChave = '';
      }
    }

    if (this.segmentoSelecionado === 'pj') {
      qtdValidacoes = 1;

      if (!GenericValidators.isValidCnpj(this.palavraChave)) {
        qtdErros++;
      }

      this.campoBuscar = 'cnpj';

      if (qtdErros === qtdValidacoes && this.palavraChave.length >= 3) {
        this.msgPalavraChave = 'A palavra chave não parece ser um CNPJ';
        this.erroPalavraChave = true;
      } else if (this.ufSelecionada === '') {
        this.msgPalavraChave = 'A UF não pode ser vazia';
        this.erroPalavraChave = true;
      } else {
        this.erroPalavraChave = false;
        this.msgPalavraChave = '';
      }
    }

  }

  public isBlockedSubmit(): Boolean {
    if (this.consultando) {
      return true;
    }
    if (this.palavraChave.length >= 3) {
      if (!this.erroPalavraChave) {
        return false;
      }
    }
    return true;
  }

  public enviar(): void {
    this.resultadosBusca = { dados: [] };
    this.txtSubmit = 'Consultando. Aguarde...';
    this.consultando = true;
    const body = {};
    this.msg = '';
    this.msgClass = '';
    const urlBusca = `${URL_API_V2}/consulta/fdnet-${this.segmentoSelecionado}`;
    body[this.campoBuscar] = this.palavraChave.toString();
    body['uf'] = this.ufSelecionada;

    this.api.post(urlBusca, body)
      .then(r => {
        if (r.success) {
          this.resultadosBusca = r;
          if (!Array.isArray(this.resultadosBusca.dados)) {
            this.logid = this.resultadosBusca.logQuery;
            this.router.navigate([`/area-logada/consulta-fdnet-${this.segmentoSelecionado}`], { queryParams: { logid: this.logid } });
          }
        } else {
          this.msg = r.msg;
          this.msgClass = 'alert-danger';
        }
        this.txtSubmit = 'Consultar';
        this.consultando = false;
      })
      .catch(e => {
        
        this.msg = 'Ocorreu um erro interno na consulta';
        this.msgClass = 'alert-danger';
        this.txtSubmit = 'Consultar';
        this.consultando = false;
      })
  }

  public voltar(): void {
    this.logid = '';
    this.resultadosBusca = { dados: [] };
    this.showResultado = false;
    this.showBusca = true;
    this.router.navigate([`/area-logada/consulta-fdnet-${this.segmentoSelecionado}`]);
  }

  public voltarHistorico(): void {
    this.router.navigate(['/area-logada/historico-consultas']);
  }

  public consultarResultado(cpf: string): void {
    this.campoBuscar = 'cpf';
    this.palavraChave = cpf;
    this.enviar();
  }

  public print(): void {
    setTimeout(() => {
      window.print();
    }, 200);
  }

  public reciverDocumento(documentoEmitter: Event, tipo: String) {
    this.segmentosBusca = [];
    let segmento = 'pf';
    this.palavraChave = documentoEmitter.toString();
    this.isPreConsulta = true;
    if (tipo == 'cpf') {
      segmento = 'pf';
      this.segmentoSelecionado = segmento;
      this.campoBuscar = 'cpf';
    } else if (tipo == 'cnpj') {
      segmento = 'pj';
      this.segmentoSelecionado = segmento;
      this.campoBuscar = 'cnpj';
    }
    this.changeSegmento(segmento);
  }

}
