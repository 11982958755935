/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topo/topo.component.ngfactory";
import * as i3 from "./topo/topo.component";
import * as i4 from "./miolo/miolo.component.ngfactory";
import * as i5 from "./miolo/miolo.component";
import * as i6 from "@angular/router";
import * as i7 from "./rodape/rodape.component.ngfactory";
import * as i8 from "./rodape/rodape.component";
import * as i9 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topo", [], null, null, null, i2.View_TopoComponent_0, i2.RenderType_TopoComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopoComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-miolo", [], null, null, null, i4.View_MioloComponent_0, i4.RenderType_MioloComponent)), i1.ɵdid(3, 114688, null, 0, i5.MioloComponent, [i6.Router], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-rodape", [], null, null, null, i7.View_RodapeComponent_0, i7.RenderType_RodapeComponent)), i1.ɵdid(5, 114688, null, 0, i8.RodapeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i9.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
