<section class="page_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Business Intelligence</h1>
        <div class="sub_heading_page">O poder da análise de dados</div>
      </div>
    </div>
  </div>
</section>  

<section class="safe_host">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="headings black_text">
          <h4>Business Intelligence</h4>  
        </div>
      </div>
      <div class="pr_info">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="info_right">
            <ul>
              <li class="text-left">
                <p>
                  A solução de Business Intelligence oferece um conjunto de técnicas e conceitos que auxiliam a tomada de decisão. Uso de dashboards intuitivos que permitem visualizar os processos ou dados necessários e filtrar diversas informações. Essa visão gráfica é o diferencial nas ações decisivas pois são baseadas nas informações tratadas e devolvidas como dados ordenados. Deixe nossas soluções de Business Intelligence auxiliarem sua empresa!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-formContato text="Entre em contato conosco"></app-formContato>