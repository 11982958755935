import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estudos-daycoval',
  templateUrl: './estudos-daycoval.component.html',
  styleUrls: ['./estudos-daycoval.component.css']
})
export class EstudosDaycovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
