import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cota-melhor',
  templateUrl: './cota-melhor.component.html',
  styleUrls: ['./cota-melhor.component.css']
})
export class CotaMelhorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
