<div class="row">
<div class="col-5">
    
  <div class="login_form">
    <form [formGroup]="formCadastro" novalidate (ngSubmit)="salvarCadastro()">
    <div class="login_header bgamarelo">Dados cadastrais</div>
    <fieldset>
      <div class="row">
        <div class="section msg col-12 {{msgCadastroClass}}" *ngIf="msgCadastro!=''">
          <span [innerHTML]="msgCadastro"></span>
        </div>
        <div class="section col-12" *ngIf="isFutureUser">
          <label class="input">
            <select class="w-100" (change)="loadDataClientChangeEvent($event)">
              <ng-container *ngIf="clientes.length > 0">
                <option *ngFor="let cliente of clientes" [value]="cliente._id" [selected]="cliente._id === dadosUsuario.client._id">{{cliente.companyName}} ({{cliente.contactName}})</option>
              </ng-container>
              <ng-container *ngIf="clientes.length === 0"><option>-</option></ng-container>
            </select>
            <!-- <pre>{{clientes | json}}</pre> -->
          </label>
        </div>

        <div class="section col-12">
          <label class="input">
            <label>Nome de Contato</label>
            <input placeholder="Nome" type="text" formControlName="contactName" />
          </label>
        </div>
        <div class="section col-12">
          <label class="input">
            <label>E-mail de faturamento</label>
            <input placeholder="E-mail" type="email" autocomplete="false" formControlName="email">
          </label>
        </div>

        <div class="section col-12">
          <label class="input">
            <label>Telefone de contato</label>
            <input placeholder="" mask='(00) 00009-0000' type="text" autocomplete="false" formControlName="phone">
          </label>
        </div>


      </div>
    </fieldset>
    <!-- <div class="login_footer">
      <button type="submit" class="btn btn-lg btn-primary" [disabled]="enviandoCadastro">{{txtSubmitCadastro}}</button>
    </div> -->
  </form> 
  </div>
</div>
<div class="col-7">
    
    <div class="login_form">
      
      <div class="login_header bgvermelho">Faturas e Limites</div>
      <fieldset>
        <div class="row">         
  
          <div class="section col-12">
            <label class="input">
              <label>Limite para faturamento mensal</label><br />
              {{dadosUsuario.client.billing.invoiceLimit | currency:'BRL':true}}
            </label>
          </div>
          <!-- <div class="section col-6">
            <label class="input">
              <label>Maior fatura (02/2019)</label><br />
              {{800.85 | currency:'BRL':true}} <span class="badge bg-info" (click)="fatura(templateFatura)"><i class="fas fa-search"></i> Detalhar</span>
            </label>
          </div>
          <div class="section col-6">
            <label class="input">
              <label>Menor fatura (01/2019)</label><br />
              {{698.85 | currency:'BRL':true}} <span class="badge bg-info"><i class="fas fa-search"></i> Detalhar</span>
            </label>
          </div> -->
         
          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <select class="w-100" [(ngModel)]="anoAtual">
                <option *ngFor="let ano of rangeAno" [value]="ano" [selected]="anoAtual===ano">{{ano}}</option>
              </select>
            </label>
          </div>
          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <select class="w-100" [(ngModel)]="mesAtual">
                <option *ngFor="let mes of rangeMes" [value]="mes" [selected]="mesAtual===mes">{{mes}}</option>
              </select>
            </label>
          </div>
          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <button class="btn btn-sm btn-info" (click)="createInvoice()" tooltip="Gerar fatura"><i class="fas fa-sync-alt"></i> Gerar Fatura</button>
            </label>
          </div>
        </div>

          <div class="row">  
                
              <table class="table">
                  <thead>
                    <tr>
                      <th>Competência</th>
                      <th>Valor</th>
                      <th>Vencimento</th>
                      <th>Status</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="invoices.length > 0" >
                      <tr *ngFor="let invoice of invoices">
                        <td>{{invoice.getCompetencia()}}</td>
                        <td>{{invoice.totalBilling | currency:'BRL':true}}</td>
                        <td>{{invoice.dueAt | date:'dd/MM/yyyy'}}</td>
                        <td><span class="badge {{invoice.getSituacao().badge}}">{{invoice.getSituacao().situacao}}</span></td>
                        <td class="faturas acoes">
                          <button class="btn btn-sm btn-info" (click)="detailInvoice(templateFatura,invoice)" tooltip="Detalhar fatura"><i class="fas fa-search"></i></button>
                          <button class="btn btn-sm btn-primary" (click)="extractQueries(invoice)" tooltip="Extrato fatura (json)"><i class="fas fa-file-alt"></i></button>
                          <button class="btn btn-sm btn-warning" (click)="updateInvoice(invoice)" *ngIf="invoice.getSituacao().situacao=='Aberta'" tooltip="Atualizar fatura"><i class="fas fa-sync-alt"></i></button>
                          <!-- <button class="btn btn-sm btn-success" (click)="editInvoice(invoice)" *ngIf="!invoice.closed && isFutureUser && invoice.getSituacao().situacao !='Aberta'" tooltip="Editar fatura"><i class="fas fa-edit"></i></button>
                          <button class="btn btn-sm btn-primary" (click)="closeInvoice(invoice)" *ngIf="!invoice.closed && isFutureUser && invoice.getSituacao().situacao !='Aberta'" tooltip="Fechar fatura"><i class="fas fa-check"></i></button> -->
                        </td>
                      </tr>     
                    </ng-container>
                    <ng-container *ngIf="invoices.length === 0" >
                      <tr>
                        <td>Atual</td>
                        <td>{{0 | currency:'BRL':true}}</td>
                        <td>{{datenow | date:'20/MM/yyyy'}}</td>
                        <td></td>
                        <td class="faturas acoes">
                          <button class="btn btn-sm btn-warning" (click)="createInvoice(dadosUsuario.client._id)" tooltip="Atualizar fatura"><i class="fas fa-sync-alt"></i></button>
                        </td>
                      </tr>     
                    </ng-container>
                  </tbody>
                </table>     
          </div>
        </fieldset>
    </div>
  </div>
</div>

<ng-template #templateFatura>
  <div class="modal-header">
    <h5 class="modal-title">Fatura {{invoiceSelected.getCompetencia()}}</h5>
  </div>
  <div class="modal-body" style="max-height: 500px; overflow-y: auto">
    <p *ngIf="loadingFatura">Carregando...</p>
    <div *ngIf="!loadingFatura" class="fatura" [innerHTML]="htmlFatura | safeHtml">
    
    </div>
  </div>
</ng-template>